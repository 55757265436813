import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { ClosureHistoryFiltersState, ClosureHistoryPaginationState } from "./closures.state";
import { CashHistoryItem, Closure } from "../entities";

export const closuresUiLoadingSelector = createSelector(
  (state: RootState) => state.closures.ui.isLoadingClosure,
  (state: boolean) => state
);

export const closuresUiEmptySelector = createSelector(
  (state: RootState) => state.closures.ui.isEmptyClosures,
  (state: boolean) => state
);

export const closuresHistorySelector = createSelector(
  (state: RootState) => state.closures.data.history.closures,
  (state: CashHistoryItem[]) => state
);

export const closuresHistoryPaginationSelector = createSelector(
  (state: RootState) => state.closures.data.history.pagination,
  (state: ClosureHistoryPaginationState) => state
);

export const closuresHistoryFiltersSelector = createSelector(
  (state: RootState) => state.closures.data.history.filters,
  (state: ClosureHistoryFiltersState) => state
);

export const closuresDetailSelector = createSelector(
  (state: RootState) => state.closures.data.closure,
  (state: Closure) => state
);

export const closureSelectedSelector = createSelector(
  (state: RootState) => state.closures.data.history.closureSelected,
  (state: number) => state
);

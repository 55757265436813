import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { BillsDataState, BillsErrorsState } from ".";

export const billsDataSelector = createSelector(
  (state: RootState) => state.bills.data,
  (state: BillsDataState) => state.bills
);

export const billsErrorsSelector = createSelector(
  (state: RootState) => state.bills.errors,
  (state: BillsErrorsState) => state
);

export const billsDetailSelector = createSelector(
  (state: RootState) => state.bills.data,
  (state: BillsDataState) => state.detail.id
);

export const billsFiltersSelector = createSelector(
  (state: RootState) => state.bills.data,
  (state: BillsDataState) => state.filters
);

export const billFormSelector = createSelector(
  (state: RootState) => state.bills.data,
  (state: BillsDataState) => state.form
);

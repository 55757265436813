import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from '@config/store';
import CustomerTransaction from '@modules/customers/components/customer-transaction';
import styles from './customer-payment-transaction-mobile.module.sass';
import { PATH } from '@modules/customers/constants';
import { useParams } from 'react-router-dom';

interface CustomerPaymentTransactionMobileProps { }

const CustomerPaymentTransactionMobile: FC<CustomerPaymentTransactionMobileProps> = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: 'Abono',
      routeTo: `/${PATH}/${customerId}`
    }));
  }, [customerId, dispatch]);

  return (
    <div className={styles.container}>
      <CustomerTransaction isPaymentTransaction />
    </div>
  );
}

export default CustomerPaymentTransactionMobile;

import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions as globalActions } from '@config/store';
import { actions, customersErrorsSelector, customersUiSelector } from '@modules/customers/store';
import { Customer } from '@modules/customers/entities';
import { TABS } from '@modules/customers/constants';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import CustomersResume from '@modules/customers/components/customers-resume-mobile';
import CustomersSearchBarMobile from '@modules/customers/components/customers-search-bar-mobile';
import CustomersListMobile from '@modules/customers/components/customers-list-mobile';
import UiButton from '@ui/ui-button-v2';
import UiNoDataMessage from '@ui/ui-no-data-message';
import styles from './customers-home-mobile.module.sass';

interface CustomersHomeMobileProps { }

const CustomersHomeMobile: FC<CustomersHomeMobileProps> = () => {
  const { hasRequestedCustomers } = useSelector(customersUiSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errors = useSelector(customersErrorsSelector);
  const { getCustomers } = useCustomersFetch();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: 'Clientes',
      routeTo: '/'
    }));
  }, [dispatch]);

  useEffect(() => {
    if (!hasRequestedCustomers) getCustomers();
    dispatch(actions.setCustomerDetail({} as Customer));
  }, [hasRequestedCustomers, dispatch, getCustomers]);

  const handleOpenCreation = useCallback((): void => {
    navigate(`${TABS.creation}`)
  }, [navigate]);

  if (errors.getCustomers) return <UiNoDataMessage message="No pudimos obtener su lista de clientes" />;

  return (
    <div className={styles.container}>
      <CustomersResume />

      <CustomersSearchBarMobile />

      <CustomersListMobile />

      <div className={styles.button}>
        <UiButton text='Crear cliente' onClick={handleOpenCreation} />
      </div>
    </div>
  );
}

export default CustomersHomeMobile;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from './bills.state';
import { Bill, BillPreviewPayload, BillsFiltersPayload } from "../entities";

export const billsSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setHasRequestedBills: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasRequestedBills = payload;
    },
    setHasNoSearchResults: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasNoSearchResults = payload;
    },
    setFieldOnFocus: (state, { payload }: PayloadAction<string>) => {
      state.ui.fieldOnFocus = payload;
    },
    setInfoFirstTime: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isFirstTimeEdit = payload;
    },
    setMustRequestBills: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.mustRequestBills = payload;
    },
    setGetBillsError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getBillsError = payload;
    },
    setBills: (state, { payload }: PayloadAction<Bill[]>) => {
      state.data.bills = payload;
    },
    setDetailId: (state, { payload }: PayloadAction<string>) => {
      state.data.detail.id = payload;
    },
    setFilter: (state, { payload }: PayloadAction<BillsFiltersPayload>) => {
      state.data.filters = { ...state.data.filters, ...payload };
    },
    setForm: (state, { payload }: PayloadAction<BillPreviewPayload>) => {
      state.data.form = { ...state.data.form, ...payload };
    },
  },
});

export const actions = billsSlice.actions;
export default billsSlice.reducer;

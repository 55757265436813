import { CountryCodeInfo, AsideMenuItem, ProfileHeaderOption, ProfileSections } from '@entities';
import colombiaFlagImg from '@img/colombia-flag.png';
import mexicoFlagImg from '@img/mexico-flag.png';
import brasilFlagImg from '@img/brasil-flag.png';
import chileFlagImg from '@img/chile-flag.png';

export const MIN_WIDTH_DESKTOP = 768;
export const TIME_TO_HIDE_NOTIFICATION = 4000;
export const STORAGE_TOKEN = 't';
export const STORAGE_TOKEN_SUPPORT = 'st';
export const INVALID_TOKEN_STATUS = 403;
export const TIME_TO_DEBOUNCE = 700;

export const COLOMBIA_ISO_CODE = 'CO';
export const MEXICO_ISO_CODE = 'MX';
export const COLOMBIA_AREA_CODE = '+57';
export const MEXICO_AREA_CODE = '+52';

export const COUNTRY_CODES: CountryCodeInfo[] = [
  {
    name: 'Colombia',
    code: 57,
    flag: colombiaFlagImg
  },
  {
    name: 'Mexico',
    code: 52,
    flag: mexicoFlagImg
  },
  {
    name: 'Brasil',
    code: 55,
    flag: brasilFlagImg
  },
  {
    name: 'Chile',
    code: 56,
    flag: chileFlagImg
  }
];

export const DASHBOARD_ITEMS: AsideMenuItem[] = [
  {
    name: 'Informes',
    icon: 'reports',
    routeTo: `/reports/sales`
  },
  {
    name: 'Inventario',
    icon: 'inventory',
    routeTo: '/inventory'
  },
  {
    name: 'Cierres',
    icon: 'closures',
    routeTo: '/closures'
  },
  {
    name: 'Proveedores',
    icon: 'providers',
    routeTo: '/providers'
  },
  {
    name: 'Pedidos',
    icon: 'orders',
    routeTo: '/orders'
  },
  {
    name: 'Clientes',
    icon: 'clients',
    routeTo: '/customers'
  },
  {
    name: 'Colaboradores',
    icon: 'bulletin',
    routeTo: '/collaborators'
  },
];

export const PROFILE_PATH = 'profile';

export const PROFILE_SECTIONS: ProfileSections = {
  userInformation: 'user_information',
  storeInformation: 'store_information',
  invoicingInformation: 'invoicing_information',
  security: 'security',
};

export const PROFILE_NAMES: string[] = [
  'Datos personales',
  'Datos del negocio',
  'Datos de facturación',
  'Seguridad'
];

export const PROFILE_HEADER_OPTIONS: ProfileHeaderOption[] = [
  {
    name: PROFILE_NAMES[0],
    routeTo: `${PROFILE_PATH}/${PROFILE_SECTIONS.userInformation}`,
  },
  {
    name: PROFILE_NAMES[1],
    routeTo: `${PROFILE_PATH}/${PROFILE_SECTIONS.storeInformation}`,
  },
  {
    name: PROFILE_NAMES[2],
    routeTo: `${PROFILE_PATH}/${PROFILE_SECTIONS.invoicingInformation}`,
  },
  {
    name: PROFILE_NAMES[3],
    routeTo: `${PROFILE_PATH}/${PROFILE_SECTIONS.security}`,
  },
];

export const SUPPORT_MENU_ITEMS: AsideMenuItem[] = [
  {
    name: 'Clientes sin transacciones',
    icon: 'notepad',
    routeTo: `/support/users-without-transactions`
  }
];

export const MENU_ITEMS = [
  {
    name: 'Informes',
    icon: 'reports-2',
    routeTo: `/reports/sales`
  },
  {
    name: 'Inventario',
    icon: 'package',
    routeTo: '/inventory'
  },
  {
    name: 'Cierres',
    icon: 'key',
    routeTo: '/closures'
  },
  {
    name: 'Proveedores',
    icon: 'truck',
    routeTo: '/providers'
  },
  {
    name: 'Pedidos',
    icon: 'money-cash',
    routeTo: '/orders'
  },
  {
    name: 'Clientes',
    icon: 'customer',
    routeTo: '/customers'
  },
  {
    name: 'Facturas',
    icon: 'bill',
    routeTo: '/bills'
  },
];

export const MODULES = [
  ...MENU_ITEMS, {
    name: 'Colaboradores',
    icon: '',
    routeTo: '/collaborators'
  }
];

import { sendNewPassword } from "@modules/auth/auth.services";
import { actions as globalActions } from "@config/store";
import { actions, authDataSelector } from "@modules/auth/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NewPassword } from "../entities";

const useNewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phoneNumber, validationCode } = useSelector(authDataSelector);

  const sendUserNewPassword = (password: string) => {
    const args: NewPassword = {
      phone: phoneNumber.phone,
      phoneCode: phoneNumber.phoneCode,
      otp: validationCode,
      password: password
    };
    dispatch(actions.setIsLoadingLogin(true));
    sendNewPassword(args)
      .then(() => navigate('/login'))
      .catch(() => dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Lo sentimos, no podemos crear su nueva contraseña',
      })))
      .finally(() => dispatch(actions.setIsLoadingLogin(false)));
  };

  return {
    sendUserNewPassword,
  };
};

export default useNewPassword;
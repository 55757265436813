import axios, { AxiosError, AxiosResponse } from 'axios';

import { INVALID_TOKEN_STATUS, STORAGE_TOKEN } from '@constants';
import { Tokens } from '@entities';

axios.defaults.baseURL = process.env.REACT_APP_URL_POS_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['requestorigin'] = 'admin';

axios.interceptors.request.use(request => {
  const tokenString = localStorage.getItem(STORAGE_TOKEN);
  const tokens: Tokens = JSON.parse(tokenString || "{}");
  if (tokens.accessToken && request.headers) {
    request.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
  }
  return request;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use((request: AxiosResponse) => request, async (error: AxiosError) => {
  if (error.response?.status === INVALID_TOKEN_STATUS) {
    const user = JSON.parse(localStorage.getItem(STORAGE_TOKEN) || '');
    const { data } = await axios.post<Tokens>('auth/refresh-token', {
      grant_type: 'refresh_token',
      refresh_token: user.refreshToken,
      userId: user.id,
    });
    localStorage.setItem(STORAGE_TOKEN, JSON.stringify({ ...user, ...data }));
    return axios(error.response.config);
  }
  return Promise.reject(error);
});

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ui-notification.module.sass';
import { actions, globalUiNotficationSelector } from '@config/store';
import { TIME_TO_HIDE_NOTIFICATION } from '@constants';

interface UiNotificationProps { }

const UiNotification: FC<UiNotificationProps> = () => {
  const dispatch = useDispatch();
  const { show = false, state = 'normal', message = '' } = useSelector(globalUiNotficationSelector);

  const hideNotification = useCallback(() => {
    dispatch(actions.notification({ show: false }));
  }, [dispatch]);

  useEffect(() => {
    let timeoutFunction: NodeJS.Timeout;
    if (show) {
      timeoutFunction =
        setTimeout(() => hideNotification(), TIME_TO_HIDE_NOTIFICATION);
    }
    return () => {
      if (timeoutFunction) clearTimeout(timeoutFunction);
    }
  }, [show, hideNotification]);

  return (
    <div
      className={`${styles.wrapper} ${show ? styles.show : styles.hide} ${styles[state]}`}
      onClick={hideNotification}
      data-testid='hide-notification'
    >
      <div className={styles.check}>
        <span className={`${styles.icon} ${state === 'error' ? 'icon-close' : 'icon-check'}`}></span>
      </div>
      <div className={styles.text}>{message}</div>
      <div className={styles.close}>
        <span className={`${styles.icon} icon-close`}></span>
      </div>
    </div>
  );
}

export default UiNotification;
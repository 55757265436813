import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useLocation } from 'react-router-dom';
import logoPosImg from '@img/logo-chiper-pos.png';
import styles from './ui-desktop-header.module.sass';
import UiMenu from '../ui-menu';
import { MODULES } from '@constants';

interface UiDesktopHeaderProps {
  navigate: NavigateFunction;
}

const UiDesktopHeader: FC<UiDesktopHeaderProps> = ({ navigate }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const location = useLocation();
  const [module, setModule] = useState('');

  useEffect(() => {
    const currentModule = MODULES.find(e => location.pathname.includes(e.routeTo))
    setModule(currentModule?.name || 'Menú')
  }, [location.pathname]);

  const handleMenuIconClick = () => {
    setIsOpenMenu(true)
  };

  const handleLogoClick = () => {
    navigate('/');
    setIsOpenMenu(false)
  };

  const handleCloseMenu = useCallback(() => {
    setIsOpenMenu(false)
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.menu} onClick={handleMenuIconClick}>
            <span className={`icon-burguer-2 ${styles.icon}`} />
            <span className={styles.title}>{module}</span>
          </div>
          <img src={logoPosImg} alt='logo' className={styles.logo} onClick={handleLogoClick} data-testid='logo-pos' />
        </div>
      </div>

      <UiMenu navigate={navigate} closeMenu={handleCloseMenu} isOpen={isOpenMenu} />
    </>
  )
}

export default memo(UiDesktopHeader);
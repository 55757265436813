import { PROFILE_NAMES, PROFILE_PATH, PROFILE_SECTIONS } from "@constants";
import { MenuOption } from "../entities";

export const PATH = PROFILE_PATH;

export const TABS = PROFILE_SECTIONS;

export const MENU_OPTIONS: MenuOption[] = [
  {
    title: PROFILE_NAMES[0],
    path: TABS.userInformation,
    icon: 'icon-person',
  },
  {
    title: PROFILE_NAMES[1],
    path: TABS.storeInformation,
    icon: 'icon-store',
  },
  {
    title: PROFILE_NAMES[2],
    path: TABS.invoicingInformation,
    icon: 'icon-invoice',
  },
  {
    title: PROFILE_NAMES[3],
    path: TABS.security,
    icon: 'icon-padlock',
  },
];

export const DATE_FORMAT = 'dd MMM yyyy';

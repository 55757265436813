import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UiInputField from '@ui/ui-input-field-v2/ui-input-field';
import loginLoader from '@img/login-loader.gif';
import newLogo from '@img/new-logo.png';
import computer from '@img/computer.png';
import elipse from '@img/elipse.png';
import dots from '@img/dots.png';
import styles from './login.module.sass';
import { useSelector } from 'react-redux';
import { authUiSelector } from '@modules/auth/store';
import useLogin from '@modules/auth/hooks/use-login';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { loginSchema } from '@modules/auth/auth.schemas';
import UiButton from '@ui/ui-button-v2';
import { PhosphorIcons } from '@chiper-inc/sb-web-chiper/dist/Theme/Icon';

const Login: React.FC = () => {
  const { authToken, moduleToRedirection } = useParams();
  const [ inputType, setInputType ] = useState<string>('password');
  const [ inputIcon, setInputIcon ] = useState<keyof typeof PhosphorIcons>('EyeClosed');
  const { t } = useTranslation();
  const { normalUserLogin, loginFromPOS } = useLogin(moduleToRedirection);
  const { isLoadingLogin } = useSelector(authUiSelector);
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      user: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: (v) => normalUserLogin(v.user, v.password)
  });

  useEffect(() => {
    if (authToken) loginFromPOS(authToken);
  }, [authToken, loginFromPOS]);


  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') form.handleSubmit();
  };

  const handleDisableButton = useCallback(() => {
    if (form.values.user !== '' && form.values.password !== '' && !form.errors.user && !form.errors.password) {
      return false
    } else return true
  }, [form]);

  const handlePasswordRecuperation = useCallback(() => {
    navigate('/login/password-recuperation')
  }, [navigate]); 

  const handleInputIconClick = useCallback(() => {
    if (inputIcon === 'EyeClosed') {
      setInputType('text')
      setInputIcon('Eye')
    } else {
      setInputType('password')
      setInputIcon('EyeClosed')
    }
  }, [inputIcon]);

  if (isLoadingLogin) return (
    <div className={styles.loaderWrapper}>
      <img src={loginLoader} alt='loader' className={styles.loader} data-testid='loader-gif' />
    </div>
  )

  return (
    <div className={styles.wrapper}>
      <figure className={styles.logoWrapper} >
        <img src={newLogo} alt='logo' className={styles.logo} />
      </figure>
      <div className={styles.messageWrapper}>
        <span className={styles.title}> {t('auth.title')} </span>
        <span className={styles.message}> {t('auth.text')} </span>
      </div>
      <form className={styles.inputWrapper} onSubmit={form.handleSubmit} onKeyDown={handleKeyDown}>
        <div className={styles.phone}>
          <UiInputField
            placeholder={t('auth.phone')}
            name={'user'}
            type={'text'}
            value={form.values.user}
            hasError={!!form.touched.user && !!form.errors.user}
            error={form.errors.user}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            hasAutoFocus
          />
        </div>
        <div className={styles.password}>
          <UiInputField
            placeholder={t('auth.password')}
            name='password'
            type={inputType}
            value={form.values.password}
            hasError={!!form.touched.password && !!form.errors.password}
            error={form.errors.password}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            iconRight={inputIcon}
            onIconClick={handleInputIconClick}
          />
        </div>
        <UiButton 
          type='submit' 
          data-testid='login-button' 
          text={t("Iniciar sesión")}
          isDisabled={handleDisableButton()}
        />
      </form>

      <div className={styles.passwordRecuperation} onClick={handlePasswordRecuperation}>
        {t("¿Olvidaste tu contraseña?")}
      </div>
      
      <div className={styles.images}>
        <img src={computer} alt='computer' className={styles.computer} />
        <img src={elipse} alt='elipse' className={styles.elipse} />
        <img src={dots} alt='dots' className={styles.rightDots} />
        <img src={dots} alt='dots2' className={styles.leftDots} />
      </div>
    </div>
  )
}

export default Login;
import React, { FC, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { actions as globalActions, currentUserPermissionsSelector } from '@config/store';
import { inventoryUiSelector, productDetailSelector } from '@modules/inventory/store';
import { Product, ProductUpdateProperty } from '@modules/inventory/entities';
import { getUpdateSchema, getUpdateSectionName } from '@modules/inventory/utils';
import useProductFetch from '@modules/inventory/hooks/use-product-fetch';
import UiInputField from '@ui/ui-input-field';
import UiInputMoney from '@ui/ui-input-money';
import UiButton from '@ui/ui-button';
import UiLoader from '@ui/ui-loader';
import UiAccessDeniedMessage from '@ui/ui-access-denied-message';
import styles from './product-update-mobile.module.sass';
import ProductDisableNotificationMobile from '@modules/inventory/components/product-disable-notification';

interface ProductUpdateMobileProps { }

const ProductUpdateMobile: FC<ProductUpdateMobileProps> = () => {
  const product = useSelector(productDetailSelector);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(inventoryUiSelector);
  const { sku, property } = useParams();
  const { updateProduct } = useProductFetch();
  const routeToBack = useRef(`/product/${sku}`).current
  const {
    canEditInventoryPurchasePrice, canEditInventoryQuantities, canEditInventorySalePrice
  } = useSelector(currentUserPermissionsSelector);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: `Editar ${getUpdateSectionName(property || '')}`,
      routeTo: routeToBack
    }));
  }, [dispatch, property, routeToBack]);

  const handleSubmit = (values: Product) => {
    updateProduct(values, routeToBack);
  };

  const form = useFormik({
    initialValues: product,
    onSubmit: handleSubmit,
    validationSchema: getUpdateSchema(String(property))
  });

  const hasAccessDenied = (): boolean => {
    return (property === ProductUpdateProperty.stock && !canEditInventoryQuantities)
      || (property === ProductUpdateProperty.totalPrice && !canEditInventorySalePrice)
      || (property === ProductUpdateProperty.managerTotal && !canEditInventoryPurchasePrice)
  };

  const handlePrice = () => {
    return property === ProductUpdateProperty.managerTotal ? 'Precio de compra' : 'Precio de venta'
  };

  if (!product.sku || !Object.values<string>(ProductUpdateProperty).includes(String(property)))
    return <Navigate to={routeToBack} />;

  if (hasAccessDenied()) return <UiAccessDeniedMessage navigate={navigate} />;

  if (!product.isActive) return <ProductDisableNotificationMobile />;

  return (
    <>
      {isLoading && <UiLoader />}
      <form className={styles.container} onSubmit={form.handleSubmit}>
        <div className={styles.wrapper}>
          <h1 className={styles.name}>{product.name}</h1>
          {property === ProductUpdateProperty.stock ? (
            <UiInputField
              type='tel'
              hasAutoFocus
              name={property}
              onChange={form.handleChange}
              label='¿Cuántos tengo en inventario?'
              value={String(form.values.stock)}
              onBlur={form.handleBlur}
              hasError={!!form.errors.stock}
              error={form.errors.stock}
            />
          ) : (
            <UiInputMoney
              name={String(property)}
              hasAutoFocus
              onChange={form.handleChange}
              label={handlePrice()}
              value={form.values[property as keyof object]}
              onBlur={form.handleBlur}
              hasError={form.errors[property as keyof object]}
              error={form.errors[property as keyof object]}
            />
          )}

          <UiButton type='submit' isFullWidth text='Actualizar' isDisabled={!form.isValid || !form.dirty} />
        </div>
      </form>
    </>
  );
}

export default ProductUpdateMobile;

import { FC } from 'react';

import styles from './bill-edit-preview-message.module.sass';
import { BILL_PREVIEW_FORMAT_DATE } from '@modules/bills/constants';
import { billFormSelector, billsUiSelector } from '@modules/bills/store';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface BillEditPreviewMessageProps { }

const BillEditPreviewMessage: FC<BillEditPreviewMessageProps> = () => {
  const billForm = useSelector(billFormSelector);
  const focusedField = useSelector(billsUiSelector);
  const currentDate = format(new Date(), BILL_PREVIEW_FORMAT_DATE, { locale: es });

  return (
    <div className={styles.wrapper}>
      <span className={styles.date}>{currentDate}</span>
      <div className={`${focusedField.fieldOnFocus === 'message' && styles.focus} ${styles.message}`}>
        {billForm.message}
      </div>
      <p className={styles.copy}>
        <span>AUTORIZACIÓN DE FACTURACIÓN NO.</span>
        <span>15423568794521</span>
        <span>del 03 Agosto 2022</span>
      </p>
    </div>
  );
}

export default BillEditPreviewMessage;

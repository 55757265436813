import React, { FC, memo } from 'react';
import { COLUMNS_BALANCES_TABLE } from '@modules/customers/constants';
import { customerBalancesSelector } from '@modules/customers/store';
import { formatMoney } from '@utils/format-money';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import styles from './customer-balances-table.module.sass';

interface CustomerBalancesTableProps { }

const CustomerBalancesTable: FC<CustomerBalancesTableProps> = () => {
  const balances = useSelector(customerBalancesSelector);

  if (!balances.length) return <div className={styles.noTransacctions}>Parece que aún no tiene saldos</div>;

  return (
    <div className={styles.table}>
        <div className={styles.header}>
          {COLUMNS_BALANCES_TABLE.map((column, i) => (
            <div className={styles.cell} key={`customers-column-table-${i}`}>
              {column.title}
              {column.icon && <span className={`icon-${column.icon} ${styles.icon}`} />}
            </div>
          ))}
        </div>
        {balances.map((balance, i) => (
          <div key={`balances-table-item-${i}`} className={styles.body}>
            <div className={styles.cell}>{balance.transactionId}</div>
            <div className={styles.cell}>{format(new Date(balance.createdAt), 'd MMM yyyy')}</div>
            <div className={styles.cell}>{balance.name}</div>
            <div className={`${styles.cell} ${balance.isLoan ? styles.orange : styles.green}`}>
              {balance.isLoan && '-'}
              {formatMoney(balance.total, 0)}
            </div>
          </div>
        ))}
    </div>
  );
}

export default memo(CustomerBalancesTable);

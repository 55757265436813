export const REPORTS_PATH: string = 'reports';

export enum ReportsTabs {
  Empty = '',
  Sales = 'sales',
  Transactions = 'transactions',
  Skus = 'skus',
  SlowTurnover = 'slow_turnover',
};

export const DAYS_SI: string[] = ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'];

export const DAYS: string[] = [
  'lun',
  'mar',
  'mié',
  'jue',
  'vie',
  'sab',
  'dom',
];

export const DAY_HOURS: string[] = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Dashboard } from "../entities";
import { SearchedProduct } from "../entities/products-search.entities";
import { initialState } from './dashboard.state';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setIsLoadingDashboard: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingDashboard = payload;
    },
    setIsLoadingProductsSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingProductsSearch = payload;
    },
    setIsSearching: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isSearching = payload;
    },
    setHasDashboardData: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasDashboardData = payload;
    },
    setDashboardData: (state, { payload }: PayloadAction<Dashboard>) => {
      state.data.dashboard = payload;
    },
    setSearchedProducts: (state, { payload }: PayloadAction<SearchedProduct[]>) => {
      state.data.productsSearch.products = payload;
    },
    setSearchHistory: (state, { payload }: PayloadAction<string[]>) => {
      state.data.productsSearch.searchHistory = payload;
    },
    setHistoryOptionSelected: (state, { payload }: PayloadAction<string>) => {
      state.data.productsSearch.historyOptionSelected = payload;
    },
    setSearchProductsError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.searchProducts = payload;
    },
  },
});

export const actions = dashboardSlice.actions;
export default dashboardSlice.reducer;

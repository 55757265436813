import React, { FC } from 'react';

import styles from './reports-page-no-data.module.sass';

interface ReportsPageNoDataProps {}

const ReportsPageNoData: FC<ReportsPageNoDataProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>{children}</div>
    </div>
  );
};
 
export default ReportsPageNoData;

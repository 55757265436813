import React, { FC, useEffect } from 'react';
import ProductDetailCard from '@modules/inventory/components/product-detail-card';
import styles from './product-discounts.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message';
import { useDispatch, useSelector } from 'react-redux';
import { actions, inventoryErrorsSelector, inventoryUiSelector, productDetailSelector } from '@modules/inventory/store';
import { actions as globalActions } from '@config/store';
import ProductDiscountsForm from '@modules/inventory/components/product-discounts-form';
import ProductDiscountsTable from '@modules/inventory/components/product-discounts-table';
import useDiscountsFetch from '@modules/inventory/hooks/use-discounts-fetch';
import UiLoader from '@ui/ui-loader';
import { ProductDiscount } from '@modules/inventory/entities';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH, TABS } from '@modules/inventory/constants';
import useProductFetch from '@modules/inventory/hooks/use-product-fetch';
import Clevertap from '@utils/clevertap';
import { DISCOUNTS_DURATION, DISCOUNTS_OPEN } from '@constants';

interface ProductDiscountsProps { }

const ProductDiscounts: FC<ProductDiscountsProps> = () => {
  const { isLoading, hasRequestedDiscounts, isLoadingGetDiscount } = useSelector(inventoryUiSelector);
  const errors = useSelector(inventoryErrorsSelector);
  const { getDiscounts } = useDiscountsFetch();
  const { getProduct } = useProductFetch();
  const dispatch = useDispatch();
  const product = useSelector(productDetailSelector);
  const { sku } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!product.sku) getProduct(String(sku))
  }, [getProduct, product.sku, sku]);

  useEffect(() => {
    if (product.sku && !product.isActive) {
      navigate(`/${PATH}/${TABS.productDetail}/${product.sku}`);
      dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Habilita el producto para gestionar los descuentos'
      }))
    }
  }, [dispatch, navigate, product.isActive, product.sku]);

  useEffect(() => {
    if (!hasRequestedDiscounts) getDiscounts();
    dispatch(actions.setIsUpdatingDiscount(false))
    dispatch(actions.setCurrentDiscount({} as ProductDiscount));
  }, [dispatch, getDiscounts, hasRequestedDiscounts]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(DISCOUNTS_OPEN, { sku: String(sku) });
    return () => Clevertap.pushDurationEvent(DISCOUNTS_DURATION, openedAt);
  }, [sku]);
  
  if (errors.getProductDiscounts && !isLoading)
    return <UiNoDataMessage message='No pudimos hallar los descuentos de este producto' />;

  if (!hasRequestedDiscounts && isLoading) return <div className={styles.loading}>Cargando...</div>;

  if (isLoadingGetDiscount)
    return (
      <div className={styles.loading}>
        <UiLoader />
        Cargando el descuento seleccionado...
      </div>
    );

  return (
    <div className={styles.container}>
      <ProductDetailCard isDiscountView />

      <ProductDiscountsForm />

      <ProductDiscountsTable />
    </div>
  )
}

export default ProductDiscounts;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Product } from '@modules/inventory/entities';
import { actions, productDetailSelector } from '@modules/inventory/store';
import { actions as globalActions, currentUserSelector } from '@config/store';
import { useCallback } from 'react';
import {
  fetchChangeProductState, fetchGetCategories,
  fetchGetProductDetail, fetchUpdateProduct, fetchUpdateProductPOS
} from '@modules/inventory/services';
import useInventory from '../use-invetory';
import Clevertap from '@utils/clevertap';
import { PRODUCT_DISABLE, PRODUCT_EDITION, PRODUCT_ENABLE, PRODUCT_GET_DATA } from '@constants';

const useProductFetch = () => {
  const user = useSelector(currentUserSelector);
  const product = useSelector(productDetailSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUpdatedProduct, setChangeProductState } = useInventory();

  const getProduct = useCallback((sku: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetProductDetail(user.storeId, sku)
      .then((data) => {
        dispatch(actions.setProduct({ ...data, categoryId: String(data.categoryId) }));
        dispatch(actions.setGetProductDetailError(false));
        dispatch(actions.setHasRequestedDiscounts(false));
        dispatch(actions.setProductDiscounts([]));
        Clevertap.pushCheckSuccessEvent(PRODUCT_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setProduct({} as Product));
        dispatch(actions.setGetProductDetailError(true));
        Clevertap.pushCheckSuccessEvent(PRODUCT_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user.storeId]);

  const getCategories = useCallback((): void => {
    fetchGetCategories(user.storeId)
      .then((data) => dispatch(actions.setProductCategories(data)));
  }, [dispatch, user.storeId]);

  const updateProduct = useCallback((values: Product, routeTo?: string): void => {
    dispatch(actions.setIsLoading(true));
    const body: Product = {
      ...values,
      managerPrice: values.managerTotal,
      salesUnit: values.packagingType,
      action: "update"
    };
    fetchUpdateProductPOS(body, user.storeId);
    fetchUpdateProduct(body)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Producto ${body.name} actualizado con éxito`
        }));
        Clevertap.pushEvent(PRODUCT_EDITION, {
          sku: body.sku,
          packagingType: body.packagingType,
          category: body.categoryId,
          hasNewManagerPrice: product.managerTotal !== body.managerTotal,
          hasNewCustomerPrice: product.totalPrice !== body.totalPrice,
          hasNewStock: product.stock !== body.stock
        });
        setUpdatedProduct(body);
        if (routeTo) navigate(routeTo);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: `Lo sentimos. No pudimos actualizar su producto`
        }));
        Clevertap.pushCheckSuccessEvent(PRODUCT_EDITION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, navigate, product.managerTotal, product.stock, product.totalPrice, setUpdatedProduct, user.storeId]);

  const changeState = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchChangeProductState(product)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: product.isActive ? 'normal' : 'success',
          message: `Producto ${product.isActive ? 'inhabilitado' : 'habilitado'} con éxito`
        }));
        Clevertap.pushEvent(product.isActive ? PRODUCT_DISABLE : PRODUCT_ENABLE, { sku: product.sku });
        dispatch(actions.setShowMobileDisableNotification(product.isActive))
        setChangeProductState();
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: `Lo sentimos. No pudimos ${product.isActive ? 'inhabilitar' : 'habilitar'} su producto`
        }));
        Clevertap.pushEvent(product.isActive ? PRODUCT_DISABLE : PRODUCT_ENABLE, { sku: product.sku }, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, product, setChangeProductState]);

  return {
    getProduct,
    getCategories,
    updateProduct,
    changeState,
  }
}

export default useProductFetch;

import React, { FC } from 'react';
import { customerTransactionDetailSelector } from '@modules/customers/store';
import { useSelector } from 'react-redux';
import styles from './customer-transaction-detail-footer.module.sass';
import { getTransactionFooterItems } from '@modules/customers/utils';

interface CustomerTransactionDetailFooterProps { }

const CustomerTransactionDetailFooter: FC<CustomerTransactionDetailFooterProps> = () => {
  const transactions = useSelector(customerTransactionDetailSelector);

  return (
    <div className={styles.container}>
      {getTransactionFooterItems(transactions).map((item) =>
        <div className={styles.wrapper} key={`transactions-footer-item-${item.title}`}>
          <span className={styles.item}>
            {item.title}
          </span>
          <span className={styles.item}>
            {item.value}
          </span>
        </div>
      )}
    </div>
  )
}

export default CustomerTransactionDetailFooter;

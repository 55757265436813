import React, { FC, useCallback } from 'react';
import UiModal from '@ui/ui-modal';
import styles from './upload-preview-table.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { actions, inventoryUploadFileSelector } from '@modules/inventory/store';
import { PREVIEW_UPLOAD_TABLE_COLUMNS } from '@modules/inventory/constants';
import { formatMoney } from '@utils/format-money';
import UiButton from '@ui/ui-button';
import useInventoryFetch from '@modules/inventory/hooks/use-inventory-fetch';

interface UploadPreviewTableProps { }

const UploadPreviewTable: FC<UploadPreviewTableProps> = () => {
  const preview = useSelector(inventoryUploadFileSelector);
  const dispatch = useDispatch();
  const { uploadInventoryFile } = useInventoryFetch();

  const handleClosePreview = useCallback((): void => {
    dispatch(actions.setShowPreviewTable(false));
    dispatch(actions.setUploadedFileData([]));
  }, [dispatch]);

  return (
    <UiModal onCloseClick={handleClosePreview}>
      <div className={styles.wrapper}>
        <span className={styles.total}>{`Total: ${preview.data.length}`}</span>
        <span className={styles.title}>Cargando inventario</span>

        <div className={styles.tableWrapper}>
          <table className={styles.table} cellSpacing='0'>
            <thead>
              <tr>
                {PREVIEW_UPLOAD_TABLE_COLUMNS.map((column) => (
                  <th key={`preview-table-column-${column}`} className={styles.head}>
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {preview.data.map((item, i) => (
                <tr key={`preview-table-item-${String(i)}`} className={styles.row}>
                  <td className={styles.cell}>{item.ean}</td>
                  <td className={styles.cell}>{item.name}</td>
                  <td className={styles.cell}>{item.brandName}</td>
                  <td className={styles.cell}>{item.packagingType}</td>
                  <td className={styles.cell}>{formatMoney(item.managerPrice, 0)}</td>
                  <td className={styles.cell}>{formatMoney(item.customerPrice, 0)}</td>
                  <td className={styles.cell}>{item.stock}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.buttons}>
          <UiButton text='Cancelar' leftIcon='icon-cancel' onClick={handleClosePreview} isSecondary />
          <UiButton text='Cargar inventario' leftIcon='icon-upload' onClick={uploadInventoryFile} />
        </div>
      </div>
    </UiModal>
  )
}

export default UploadPreviewTable;

import React, { FC, ReactNode } from "react";
import styles from "./closures-home-detail-box.module.sass";

interface ClosuresHomeDetailBoxProps {
  children: ReactNode;
  className?: string;
}

const ClosuresHomeDetailBox: FC<ClosuresHomeDetailBoxProps> = ({ children, className }) => {

  return (
    <div className={`${styles.wrapper} ${className}`}>
      {children}
    </div>
  );
}

export default ClosuresHomeDetailBox;

import { COLOMBIA_ISO_CODE, COLOMBIA_IDENTIFICATIONS, MEXICO_IDENTIFICATIONS } from "@constants";
import { ContactChannel, ProfileInput } from "../entities";

export const getUserInfotmationInput = (isoCode: string, contactChannels: ContactChannel[]): ProfileInput[] => [
  {
    label: 'Nombre',
    name: 'firstName',
    isDisabled: true,
    isSelect: false,
  },
  {
    label: 'Tipo de documento',
    name: 'documentTypeId',
    isDisabled: true,
    isSelect: true,
    options: isoCode === COLOMBIA_ISO_CODE ? COLOMBIA_IDENTIFICATIONS : MEXICO_IDENTIFICATIONS,
  },
  {
    label: 'Celular',
    name: 'phone',
    isDisabled: true,
    isSelect: false,
  },
  {
    label: 'Número de documento',
    name: 'document',
    isDisabled: true,
    isSelect: false,
  },
  {
    type: 'date',
    label: 'Fecha de nacimiento',
    name: 'birthDay',
    isDisabled: true,
    isSelect: false,
  },
  {
    label: 'Email',
    name: 'email',
    isDisabled: false,
    isSelect: false,
  },
  {
    label: '¿Cómo nos comunicamos contigo?',
    name: 'contactChannelId',
    isDisabled: false,
    isSelect: true,
    options: contactChannels?.map(e => ({ title: e.value, value: e.id })),
  },
];

import React, { FC, useCallback, useEffect } from 'react';
import CustomersForm from '../../components/customers-form';
import { actions as globalActions } from '@config/store';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@modules/customers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { customerDetailSelector } from '@modules/customers/store';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';

interface CustomersUpdateProps { }

const CustomersUpdate: FC<CustomersUpdateProps> = () => {
  const customer = useSelector(customerDetailSelector);
  const navigate = useNavigate();
  const { updateCustomer } = useCustomersFetch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: 'Editar Cliente',
      routeTo: `/${PATH}/${customer.id}`
    }));
  }, [customer.id, dispatch]);


  const goBack = useCallback((): void => {
    navigate(`/${PATH}/${customer.id}`)
  }, [customer.id, navigate]);

  return (
    <CustomersForm
      title='Editar cliente'
      routes={[
        { name: 'Clientes', path: `/${PATH}` },
        { name: customer.name, path: `/${PATH}/${customer.id}` }
      ]}
      onCancel={goBack}
      onSubmit={updateCustomer}
    />
  )
}

export default CustomersUpdate;

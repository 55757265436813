import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions, currentUserSelector } from "@config/store";
import { actions, consecutivesSelector } from "@modules/profile/store";
import {
  fetchCreateConsecutive, fetchGetInvoicingInformation,
  fetchUpdateBusinessData, fetchUpdateShowNit
} from "@modules/profile/services";
import { BussiessData, NewConsecutiveForm } from "@modules/profile/entities";
import Clevertap from "@utils/clevertap";
import { PROFILE_CONSECUTIVE_CREATION, PROFILE_UPDATE_INVOICING_INFORMATION } from "@constants";

const useInvoicingInformationFetch = () => {
  const user = useSelector(currentUserSelector);
  const consecutives = useSelector(consecutivesSelector);
  const dispatch = useDispatch();

  const getInvoicingInformation = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetInvoicingInformation(user.storeId)
      .then((data) => {
        if (!!data.length) {
          data.sort((a, b) => Number(b.id) - Number(a.id));
          dispatch(actions.setBusinessData({
            id: data[0].id,
            numberStore: data[0].numberStore,
            storeName: data[0].storeName,
            showNit: data[0].showNit,
          }));
        }
        dispatch(actions.setConsecutives(data.filter(e => e.prefix !== null)));
        dispatch(actions.setGetInvoicingInformationError(false));
      })
      .catch(() => dispatch(actions.setGetInvoicingInformationError(true)))
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user.storeId]);

  const updateBusinessData = useCallback((values: BussiessData): void => {
    dispatch(actions.setIsLoading(true));
    fetchUpdateBusinessData(values, user.storeId)
      .then(() => fetchUpdateShowNit(values.showNit))
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Datos actualizados con éxito'
        }));
        dispatch(actions.setBusinessData(values));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_INVOICING_INFORMATION);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos actualizar sus datos'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_INVOICING_INFORMATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user.storeId]);

  const createNewConsecutive = useCallback((values: NewConsecutiveForm): void => {
    dispatch(actions.setIsLoading(true));
    fetchCreateConsecutive(values)
      .then((newConsecutive) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Consecutivo agregado con éxito'
        }));
        dispatch(actions.setConsecutives([newConsecutive, ...consecutives]));
        dispatch(actions.setShowNewConsecutiveModal(false));
        Clevertap.pushCheckSuccessEvent(PROFILE_CONSECUTIVE_CREATION);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos agregar su consecutivo'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_CONSECUTIVE_CREATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [consecutives, dispatch]);

  return {
    getInvoicingInformation,
    updateBusinessData,
    createNewConsecutive
  }
};

export default useInvoicingInformationFetch;

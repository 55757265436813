import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { businessDataSchema } from '@modules/profile/schemas';
import { businessDataSelector } from '@modules/profile/store';
import { BussiessData } from '@modules/profile/entities';
import useInvoicingInformationFetch from '@modules/profile/hooks/use-invoicing-information';
import UiInputField from '@ui/ui-input-field';
import UiSwitch from '@ui/ui-switch';
import UiButton from '@ui/ui-button';
import styles from './business-data.module.sass';

interface BusinessDataProps { }

const BusinessData: FC<BusinessDataProps> = () => {
  const businessData = useSelector(businessDataSelector);
  const { updateBusinessData } = useInvoicingInformationFetch();

  const handleSubmit = (values: BussiessData): void => {
    updateBusinessData(values);
    form.resetForm({ values });
  };

  const form = useFormik({
    initialValues: businessData,
    onSubmit: handleSubmit,
    validationSchema: businessDataSchema,
  });

  const handleSwitChange = useCallback((): void => {
    form.setFieldValue('showNit', !form.values.showNit)
  }, [form]);

  const isDisabled = () => !form.isValid || (form.values.showNit === businessData.showNit && !form.dirty);

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      <h1 className={styles.title}>Datos del negocio</h1>

      <div className={styles.inputs}>
        <UiInputField
          name='storeName'
          label='¿A nombre de quién facturar?'
          type='text'
          value={form.values.storeName}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.errors.storeName}
          hasError={!!(form.errors.storeName && form.touched.storeName)}
        />
        <UiInputField
          name='numberStore'
          label='Número de telefono de la tienda'
          type='text'
          value={form.values.numberStore}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.errors.numberStore}
          hasError={!!(form.errors.numberStore && form.touched.numberStore)}
        />
      </div>
      <div className={styles.switchWrapper}>
        <UiSwitch
          isChecked={form.values.showNit}
          onChange={handleSwitChange}
        />
        Mostrar documento en facturas
      </div>

      <UiButton text='Guardar' isFullWidth isDisabled={isDisabled()} type='submit' />
    </form>
  );
}

export default BusinessData;

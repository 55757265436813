import { Provider } from '@modules/providers/entities';
import { OrderDetail, OrdersData, OrdersFilters } from '../entities/orders.entities';

export interface OrdersState {
  ui: OrdersUiState;
  data: OrdersDataState;
  search: OrdersSearchState;
}

export interface OrdersUiState {
  isLoading: boolean;
  filters: OrdersFilters;
  page: number;
}

export interface OrdersDataState {
  providers: Provider[];
  orders: OrdersData;
  detail: OrderDetail;
}

export interface OrdersSearchState {
  currentSearchedValue: string;
}

export const initialState: OrdersState = {
  ui: {
    isLoading: false,
    filters: {
      query: '',
      isPaid: undefined,
      providerId: '',
    },
    page: 1
  },
  data: {
    providers: [],
    orders: {
      orders: [],
      ordersQuantity: 0,
      pages: 0
    } as OrdersData,
    detail: {} as OrderDetail
  },
  search: {
    currentSearchedValue: '',
  },
}

import * as yup from 'yup';
import { t } from 'i18next'
import {
  BILL_ADDRESS_MAX_LENGTH, BILL_MESSAGE_MAX_LENGTH, BILL_NAME_MAX_LENGTH,
  BILL_NIT_MAX_LENGTH, BILL_RFC_LENGTH, PHONE_LENGTH, REG_EXP_ONLY_NUMBERS
} from '@constants';

export const billUpdateSchema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required'))
    .max(BILL_NAME_MAX_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  nit: yup.string().required(t('errors.required')).when('nit', {
    is: (exist: boolean) => !!exist,
    then: yup.string()
      .required(t('errors.required'))
      .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
      .max(BILL_NIT_MAX_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
    otherwise: yup.string()
  }),
  rfc: yup.string().when('rfc', {
    is: (exist: boolean) => !!exist,
    then: yup.string()
      .required(t('errors.required'))
      .min(BILL_RFC_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
      .max(BILL_RFC_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
    otherwise: yup.string()
  }),
  address: yup.string()
    .required(t('errors.required'))
    .max(BILL_ADDRESS_MAX_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  phone: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  message: yup.string()
    .required(t('errors.required'))
    .max(BILL_MESSAGE_MAX_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
}, [['nit', 'nit'], ['rfc', 'rfc']]);

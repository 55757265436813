import { sendPhone } from "@modules/auth/auth.services";
import { actions as globalActions } from "@config/store";
import { PhoneInterface, actions } from "@modules/auth/store";
import { useDispatch } from "react-redux";
import { CODE } from "../constants";

const useSendPhone = () => {
  const dispatch = useDispatch();

  const sendUserPhone = ( phone: string, phoneCode: number, send: boolean ) => {
    const args: PhoneInterface = {
      phone: phone,
      phoneCode: phoneCode,
      send: send
    };
    dispatch(actions.setIsLoadingLogin(true));
    sendPhone(args)
      .then(() => dispatch(actions.setPasswordRecuperationPage(CODE)))
      .catch(() => dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Lo sentimos, ese número no existe.',
      })))
      .finally(() => dispatch(actions.setIsLoadingLogin(false)));
  };

  return {
    sendUserPhone,
  };
};

export default useSendPhone;
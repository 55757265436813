import { UiTopSectionRoutes } from '@entities';
import React, { FC, Fragment, memo, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styles from './ui-top-section.module.sass';

interface UiTopSectionProps {
  title: string;
  routes?: UiTopSectionRoutes[]
  leftButton?: ReactElement;
  rightButton?: ReactElement | string;
  hasShadow?: boolean;
}

const UiTopSection: FC<UiTopSectionProps> = ({
  title,
  routes = [] as UiTopSectionRoutes[],
  leftButton,
  rightButton,
  hasShadow,
}) => {
  return (
    <div className={`${styles.container} ${hasShadow && styles.shadow }`}>
      <div className={styles.information}>

        {!!routes.length &&
          <div>
            {routes.map((route, i) => (
              <Fragment key={`ui-top-section-route-${String(i)}`}>
                <Link to={route.path} className={styles.routeLink}>
                  {route.name}
                </Link>
                <span className={`icon-arrow ${styles.icon}`} />
              </Fragment>
            ))}
            <span className={styles.actualRoute}>
              {title}
            </span>
          </div>
        }
        <span className={styles.title}>
          {title}
        </span>
      </div>

      <div className={styles.buttons}>
        {leftButton}
        {rightButton}
      </div>
    </div>
  );
}

export default memo(UiTopSection);

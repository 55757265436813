import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import './config/translations/i18n';
import './config/axios/axios-config';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


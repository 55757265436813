import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@config/store";
import { InvoicingInformationState, ProfileDataState, ProfileErrorsState, ProfileOptionsState, ProfileUiState } from ".";

export const profileUiSelector = createSelector(
  (state: RootState) => state.profile.ui,
  (state: ProfileUiState) => state
);

export const profileErrorsSelector = createSelector(
  (state: RootState) => state.profile.errors,
  (state: ProfileErrorsState) => state
);

export const businessDataSelector = createSelector(
  (state: RootState) => state.profile.inovicingInformation,
  (state: InvoicingInformationState) => state.businessData
);

export const consecutivesSelector = createSelector(
  (state: RootState) => state.profile.inovicingInformation,
  (state: InvoicingInformationState) => state.consecutives
);

export const userInformationDataSelector = createSelector(
  (state: RootState) => state.profile.profile,
  (state: ProfileDataState) => state.userInformation
);

export const storeInformationDataSelector = createSelector(
  (state: RootState) => state.profile.profile,
  (state: ProfileDataState) => state.storeInformation
);

export const profileOptionsSelector = createSelector(
  (state: RootState) => state.profile.options,
  (state: ProfileOptionsState) => state
);

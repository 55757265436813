import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TABS } from '../constants';
import CollaboratorsHome from '../pages/home';
import CollaboratorsCreation from '../pages/creation';
import CollaboratorsUpdate from '../pages/update';

interface CollaboratorsRouterProps {}

const CollaboratorsRouter: FC<CollaboratorsRouterProps> = () => {
  return (
    <Routes>
      <Route path={`/${TABS.creation}`} element={<CollaboratorsCreation />} />
      <Route path={`/${TABS.update}/:collaboratorId`} element={<CollaboratorsUpdate />} />
      <Route path={`/${TABS.home}`} element={<CollaboratorsHome />} />
    </Routes>
  );
}

export default CollaboratorsRouter;
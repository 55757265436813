import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikHandlers, FormikValues } from 'formik';
import { productCategoriesSelector, productDetailSelector } from '@modules/inventory/store';
import styles from './product-detail-inputs.module.sass';
import { getInputs } from '@modules/inventory/utils';
import UiSelect from '@ui/ui-select';
import UiInputField from '@ui/ui-input-field';
import UiButton from '@ui/ui-button';
import useProductFetch from '@modules/inventory/hooks/use-product-fetch';
import UiInputMoney from '@ui/ui-input-money';
import { currentUserPermissionsSelector } from '@config/store';

interface ProductDetailInputsProps {
  form: FormikHandlers & FormikValues
}

const ProductDetailInputs: FC<ProductDetailInputsProps> = ({ form }) => {
  const categories = useSelector(productCategoriesSelector);
  const product = useSelector(productDetailSelector);
  const {
    canEditInventoryPurchasePrice, canEditInventoryQuantities, canEditInventorySalePrice
  } = useSelector(currentUserPermissionsSelector);
  const { getCategories, changeState } = useProductFetch();

  useEffect(() => {
    if (!categories.length) getCategories();
  }, [categories.length, getCategories]);

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      <div className={styles.inputs}>
        {getInputs(categories).map((input) => (
          <div className={styles.input} key={`product-input-${input.name}`}>
            {input.isSelect
              ? (
                <UiSelect
                  label={input.title}
                  name={input.name}
                  defaultValue={input.title}
                  options={input.options}
                  value={form.values[input.name as keyof object]}
                  disabled={!product.isActive}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  hasError={form.errors[input.name as keyof object]}
                  error={form.errors[input.name as keyof object]}
                />
              ) : (
                <UiInputField
                  type='text'
                  label={input.title}
                  name={input.name}
                  value={form.values[input.name as keyof object]}
                  disabled={!product.isActive || (input.name === 'stock' && !canEditInventoryQuantities)}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  hasError={form.errors[input.name as keyof object]}
                  error={form.errors[input.name as keyof object]}
                />
              )}
          </div>
        ))}
        <div className={styles.input}>
          <UiInputMoney
            name='managerTotal'
            label='Precio de compra'
            value={form.values.managerTotal}
            disabled={!product.isActive || !canEditInventoryPurchasePrice}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.errors.managerTotal}
            hasError={!!(form.errors.managerTotal)}
          />
        </div>
        <div className={styles.input}>
          <UiInputMoney
            name='totalPrice'
            label='Precio de venta'
            value={form.values.totalPrice}
            disabled={!product.isActive || !canEditInventorySalePrice}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.errors.totalPrice}
            hasError={!!(form.errors.totalPrice)}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.button}>
          <UiButton
            text={product.isActive ? 'Inhabilitar' : 'Habilitar'}
            onClick={changeState}
            isSecondary
            isFullWidth
          />
        </div>
        <div className={styles.button}>
          <UiButton
            text='Actualizar'
            type='submit'
            isDisabled={!form.isValid || !form.dirty}
            isFullWidth
          />
        </div>
      </div>
    </form>
  );
}

export default ProductDetailInputs;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./global.state";
import { MobileHeaderSection, Notification, Toast, User, UserPermissions } from "@entities";

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    notification: (state, action: PayloadAction<Notification>) => {
      state.ui.notification = { ...state.ui.notification, ...action.payload };
    },
    toast: (state, action: PayloadAction<Toast>) => {
      state.ui.toast = { ...state.ui.toast, ...action.payload };
    },
    setHasCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.ui.hasCurrentUser = action.payload;
    },
    setIsFromPOS: (state) => {
      state.ui.isFromPOS = true;
    },
    setMobileHeaderSection: (state, action: PayloadAction<MobileHeaderSection>) => {
      state.ui.mobileHeaderSection = action.payload;
    },
    saveCurrentUser: (state, action: PayloadAction<User>) => {
      state.data.currentUser = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<UserPermissions>) => {
      state.data.permissions = action.payload;
    },
  },
});

export const actions = globalSlice.actions;
export default globalSlice.reducer;

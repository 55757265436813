import React, { FC, memo, useState } from 'react';
import { BillDetail } from '@modules/bills/entities';
import styles from './bill-devolution-products.module.sass';
import BillDevolutionCard from '../bill-devolution-card';
import BillDevolutionModal from '../bill-devolution-modal';
import UiNoDataMessage from '@ui/ui-no-data-message-v2';

interface BillDevolutionProductsProps {
  products: BillDetail[];
  onProductClick: (p: BillDetail, quantity: string) => void;
  title: string;
  hasLengthValidation?: boolean;
  isRight?: boolean;
}

const BillDevolutionProducts: FC<BillDevolutionProductsProps> = ({ products, onProductClick, title, hasLengthValidation, isRight }) => {
  const [showQuantityModal, setShowQuantityModal] = useState<boolean>(false);
  const [productSelected, setProductSelected] = useState<BillDetail>({} as BillDetail);

  const handleCardClick = (product: BillDetail) => {
    if (product.quantity === '1') { onProductClick(product, '1') }
    else {
      setShowQuantityModal(true);
      setProductSelected(product);
    }
  }

  const handleSubmitClick = (quantity: string) => {
    onProductClick(productSelected, quantity);
    setShowQuantityModal(false);
  }

  return (
    <>
      <div className={`${styles.wrapper} ${isRight && styles.right}`}>
        <p className={styles.title}>{title}</p>

        {(hasLengthValidation && !products.length) && (
          <div className={styles.emptyState}>
            <UiNoDataMessage
              title='No hay productos seleccionados'
              message='Selecciona los productos para devolución'
            />
          </div>
        )}

        {products.map((product, index) => (
          <div key={`bill-return-product-item-${index}`} onClick={() => handleCardClick(product)}>
            <BillDevolutionCard
              index={+product.quantity}
              name={product.coalesce}
              total={product.customerTotal}
            />
          </div>
        ))}
      </div>

      {showQuantityModal && (
        <BillDevolutionModal
          maxQuantity={productSelected.quantity}
          onClose={() => setShowQuantityModal(false)}
          onSubmit={handleSubmitClick}
          buttonText={`${isRight ? "Quitar" : "Agregar"} devolución`}
        />
      )}
    </>
  );
}

export default memo(BillDevolutionProducts);

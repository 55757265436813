import React, { FC, useCallback } from "react";
import styles from "./closures-mobile-home-top.module.sass";
import { useSelector } from "react-redux";
import { closuresDetailSelector } from "@modules/closures/store";
import { useNavigate } from "react-router-dom";
import { TABS } from "@modules/closures/constants";
import UiInputField from "@ui/ui-input-field-v2/ui-input-field";
import { format } from "date-fns";
import { es } from "date-fns/locale";

interface ClosureMobileHomeTopProps {}

const ClosureMobileHomeTop: FC<ClosureMobileHomeTopProps> = () => {
  const closure = useSelector(closuresDetailSelector);
  const navigate = useNavigate();

  const handleFieldClick = useCallback(() => {
    navigate(`${TABS.history}`);
  }, [navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Historial de tus cierres de caja</div>

      <div className={styles.date} onClick={handleFieldClick}>
        <UiInputField
          name="history"
          placeholder={'Fecha'}
          helperText="Cambia la fecha para consultar otro cierre"
          value={closure.startSession ? format(new Date(closure.startSession), 'MMM dd, yyyy', { locale: es }) : closure.date}
          readOnly
          iconRight="CalendarBlank"
        />
      </div>
    </div>
  );
};

export default ClosureMobileHomeTop;

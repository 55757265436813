import React, { FC, useEffect, useState } from 'react';

import styles from './ui-paginator.module.sass';

interface UiPaginatorProps {
  pagesQuantity: number;
  onChangePage: (page: number) => void;
  currentPage?: number;
}

const UiPaginator: FC<UiPaginatorProps> = ({
  pagesQuantity,
  onChangePage,
  currentPage
}) => {
  const LIMIT_ELEMENTS = 7;
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(currentPage || 1)
  }, [currentPage, pagesQuantity]);

  const isPreviousPageButtonDisabled = () => page === 1;
  const isNextPageButtonDisabled = () => page === pagesQuantity;

  const handlePreviousPageClick = () => {
    if (!isPreviousPageButtonDisabled()) {
      setPage(page - 1);
      onChangePage(page - 1);
    }
  }
  const handleNextPageClick = () => {
    if (!isNextPageButtonDisabled()) {
      setPage(page + 1);
      onChangePage(page + 1);
    }
  }
  const handlePageElementClick = (selectedPage: number) => {
    if (page !== selectedPage) {
      setPage(selectedPage);
      onChangePage(selectedPage);
    }
  }
  const getLimits = (elements: number[]): number[] => {
    if (elements.length < 10) return [0, pagesQuantity]
    if (page < (LIMIT_ELEMENTS / 2))
      return [0, LIMIT_ELEMENTS];
    if (page > pagesQuantity - (LIMIT_ELEMENTS / 2))
      return [pagesQuantity - LIMIT_ELEMENTS, pagesQuantity];
    return [page - (LIMIT_ELEMENTS / 2), page + (LIMIT_ELEMENTS / 2)];
  };
  
  const pageElements = () => {
    const elements = new Array(pagesQuantity).fill(0).map((_, index) => index + 1);

    const limits = getLimits(elements);
    return [1, ...elements.slice(limits[0] + 1, limits[1] - 1), ...(pagesQuantity > 1 ? [pagesQuantity]: [])].map(e => (
      <div
        data-testid={`paginator-page-${e}`}
        key={`paginator-page-element-${e}`}
        className={`${styles.square} ${page === e ? styles.active : ''}`}
        onClick={() => handlePageElementClick(e)}>
        {e}
      </div>
    )
    );
  }

  return (
    <div className={`${styles.wrapper}`}>
      <div
        className={`${styles.square} ${styles.left} ${isPreviousPageButtonDisabled() ? styles.disabled : ''}`}
        onClick={handlePreviousPageClick}>
        <span className={`icon-arrow ${styles.icon}`} />
      </div>
      <div className={`${styles.numbers}`}>
        {pageElements()}
      </div>
      <div
        className={`${styles.square} ${isNextPageButtonDisabled() ? styles.disabled : ''}`}
        onClick={handleNextPageClick}>
        <span className={`icon-arrow ${styles.icon}`} />
      </div>
    </div>
  );
}

export default UiPaginator;
import React, { FC } from 'react';
import { customerBalancesSelector } from '@modules/customers/store';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import styles from './customer-detail-mobile-transactions.module.sass';
import { formatMoney } from '@utils/format-money';

interface CustomerDetailMobileTransactionsProps { }

const CustomerDetailMobileTransactions: FC<CustomerDetailMobileTransactionsProps> = () => {
  const customer = useSelector(customerBalancesSelector);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Movimientos</div>
      {customer.map((item, index) => (
        <div className={styles.container} key={index}>
          <div className={styles.left}>
            <span className={`icon-money-cash ${styles.cash}`} />
            <div className={styles.info}>
              <span className={styles.type}>{item.isLoan ? 'Fiado' : 'Abono'}</span>
              <span className={styles.date}>{format(new Date(item.createdAt), 'dd/MM/yyyy')}</span>
            </div>
          </div>
          <div className={styles.right}>
            {item.isLoan && '-'}{formatMoney(item.total)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default CustomerDetailMobileTransactions;
import React, { FC, useCallback, useEffect } from 'react';
import { ChartData } from 'chart.js';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSalesByDates } from '../../services/reports.services';
import { TransactionByDate } from '@modules/reports/entities/transaction.entities';
import { useIsMounted } from '@utils/use-is-mounted';
import { actions, reportsDatesFilterSelector, reportsTransactionsSelector, reportsUiSelector } from '@modules/reports/store';
import Clevertap from '../../../../utils/clevertap';
import { REPORTS_GET_TRANSACTION_DATA, REPORTS_TRANSACTION_DURATION, REPORTS_TRANSACTION_SECTION } from '@constants';
import styles from './report-transactions.module.sass';
import ReportsChart from '../../components/reports-chart';
import ReportsPageNoData from '../../components/reports-page-no-data/reports-page-no-data';
import Loader from '@ui/ui-loader';

interface ReportTransactionsProps {}

const ReportTransactions: FC<ReportTransactionsProps> = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { isLoadingTransactions } = useSelector(reportsUiSelector);
  const { transactionsByDate } = useSelector(reportsTransactionsSelector);
  const { startDate, finishDate } = useSelector(reportsDatesFilterSelector);

  const transactionsData = (): ChartData<'line'> => {
    const sales = transactionsByDate.reduce<{ [key: string]: TransactionByDate[] }>((salesInfo, sale) => {
      const date = format(new Date(sale.date), 'MM-dd');
      if (!salesInfo[date]) salesInfo[date] = [];
      salesInfo[date].push(sale);
      return salesInfo;
    }, {});
    const array = Object.keys(sales).map((date) => ({
      date,
      totalTransactions: sales[date]
    }));
    return {
      labels: array.map((item) => item.date),
      datasets: [
        {
          label: 'Transacciones',
          data: array.map((item) => item.totalTransactions.reduce<number>((prev, current) => prev + +current.totalTransactions, 0)),
        },
      ],
    };
  }

  const getSalesData = useCallback((dates: { initDate: string, endDate: string }): void => {
    const args = { startDate: dates.initDate, finishDate: dates.endDate };
    dispatch(actions.setIsLoadingTransactionsData(true));
    fetchSalesByDates(args)
      .then((responseByDate) => {
        if (isMounted.current) {
          dispatch(actions.setTransactionsData({ transactionsByDate: responseByDate }));
          dispatch(actions.setIsLoadingTransactionsData(false));
        }
        Clevertap.pushCheckSuccessEvent(REPORTS_GET_TRANSACTION_DATA);
      }).catch((error) => {
        if (isMounted.current) {
          dispatch(actions.setTransactionsData({ transactionsByDate: [] }));
          dispatch(actions.setIsLoadingTransactionsData(false));
        }
        Clevertap.pushCheckSuccessEvent(REPORTS_GET_TRANSACTION_DATA, error?.message);
      });
  }, [isMounted, dispatch]);

  const hasTransactionsDataToShow = (): boolean => !!transactionsByDate.length;

  useEffect(() => {
    (startDate && finishDate) && getSalesData({ initDate: startDate, endDate: finishDate });
  }, [startDate, finishDate, getSalesData]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(REPORTS_TRANSACTION_SECTION);
    return () => Clevertap.pushDurationEvent(REPORTS_TRANSACTION_DURATION, openedAt);
  }, []);

  const handleLoading = () => {
    if (isLoadingTransactions) {
      return 'Cargando'
    }
    return 'No hay registro de transacciones durante este periodo.'
  }

  return (
    <div className={styles.wrapper}>
      {isLoadingTransactions && <Loader />}
      { !hasTransactionsDataToShow() ? (
        <ReportsPageNoData>
          {handleLoading()}
        </ReportsPageNoData>
      ) : (
        <div className={styles.charts}>
          <div className={styles.chart}>
            <ReportsChart data={transactionsData()} />
          </div>
        </div> 
      )}
    </div>
  );
};
 
export default ReportTransactions;

import React, { FC, useState } from 'react';
import { COLUMNS_TABLE } from '@modules/customers/constants';
import {
  customersDataSelector, customersErrorsSelector,
  customersPagintationSelector, customersUiSelector
} from '@modules/customers/store';
import UiLoader from '@ui/ui-loader';
import { formatMoney } from '@utils/format-money';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './customers-table.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiPaginator from '@ui/ui-paginator';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import { Icon } from '@chiper-inc/sb-web-chiper';

interface CustomersTableProps { }

const CustomersTable: FC<CustomersTableProps> = () => {
  const [ activeColumn, setActiveColumn ] = useState<string>('');
  const [ sortType, setSortType ] = useState<boolean>(false);
  
  const navigate = useNavigate();
  const customers = useSelector(customersDataSelector);
  const pagination = useSelector(customersPagintationSelector);
  const { hasNoSearchResult, hasRequestedCustomers } = useSelector(customersUiSelector);
  const errors = useSelector(customersErrorsSelector);
  const { getPage, getCustomers } = useCustomersFetch();

  const handleClick = (title: string) => {
    setActiveColumn(title);
    setSortType(!sortType);
    const sort = JSON.stringify({
      field: activeColumn,
      type: !sortType ? 'ASC' : 'DESC'
    })
    getCustomers(sort)
  };

  if (errors.getCustomers) return <UiNoDataMessage message="No pudimos obtener su lista de clientes" />;

  if (hasNoSearchResult) return <p className={styles.noResults}>No se encontraron coincidencias. Intenta nuevamente.</p>;

  if (hasRequestedCustomers && !customers.length) return (
    <p className={styles.noResults}>Parece que aún no tienes clientes</p>
  );

  if (!customers.length) return <UiLoader />;

  return (
    <>
      <div className={styles.table}>
        <div className={styles.header}>
          {COLUMNS_TABLE.map((item) => (
            <div className={`${styles.cell} ${activeColumn === item.title && styles.active}`} key={`inventory-table-column-${item.title}`}>
              <div className={styles.title} onClick={() => handleClick(item.title)}>{item.title}</div>
              {!!item.icon && (
              <div className={styles.icon} onClick={() => handleClick(item.title)} data-testid="table-head-item">
                <Icon size="12px" weight="bold" name={item.icon} />
              </div>
              )}
            </div>
          ))}
        </div>

        {customers.map((customer, i) => (
          <div key={`customers-table-item-${i}`} className={styles.body} onClick={() => navigate(customer.id)}>
            <div className={styles.cell}>{customer.name}</div>
            <div className={styles.cell}>{customer.document}</div>
            <div className={styles.cell}>{customer.address || '-'}</div>
            <div className={styles.cell}>{customer.phone}</div>
            <div className={styles.cell}>{customer.transactions}</div>
            <div className={styles.cell}>{formatMoney(customer.loans - customer.payments, 0)}</div>
            <div className={styles.cell}>
              <p className={styles.lastPayment}>
                {formatMoney(Number(customer.lastPaymentAmount || 0), 0)}
              </p>
              <p className={styles.lastPayment}>
                {!customer.lastpaidloan ? '-' : format(new Date(customer.lastpaidloan), 'd MMM yyyy')}
              </p>
            </div>
          </div>
        ))}
      </div>

      <UiPaginator pagesQuantity={pagination.totalPages} onChangePage={getPage} currentPage={pagination.currentPage} />
    </>
  );
}

export default CustomersTable;

import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHandlers, FormikValues } from 'formik';
import { productDetailSelector } from '@modules/inventory/store';
import { actions as globalActions } from '@config/store';
import styles from './product-detail-card.module.sass';
import UiInputField from '@ui/ui-input-field';
import { formatMoney } from '@utils/format-money';

interface ProductDetailCardProps {
  isDiscountView?: boolean;
  form?: FormikHandlers & FormikValues
  setNewImage?: Dispatch<SetStateAction<File | null>>
}

const ProductDetailCard: FC<ProductDetailCardProps> = ({ isDiscountView, form, setNewImage }) => {
  const product = useSelector(productDetailSelector);
  const dispatch = useDispatch();

  const handleInputFile = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    if (target.files?.length && setNewImage) {
      if (!target.files[0].type.includes('image'))
        dispatch(globalActions.notification({ show: true, state: 'error', message: 'Formato invalido' }));
      else {
        setNewImage(target.files[0])
        const reader = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => { form?.setFieldValue('imageUrl', reader.result); };
      }
    }
  };

  const canUpdateImage = (): boolean => !isDiscountView && product.own && product.isActive;

  return (
    <div className={styles.card}>
      <div className={styles.imageWrapper}>
        <span className={styles.title}>{product.name}</span>
        <img src={isDiscountView ? product.imageUrl : form?.values.imageUrl} alt="imagen" className={styles.image} />

        {canUpdateImage() && (
          <div className={styles.addImageWrapper}>
            <span className={`icon-add ${styles.icon}`} />
            <input type="file" onChange={handleInputFile} accept=".jpg,.png" className={styles.inputFile} />
          </div>
        )}
      </div>

      <div className={styles.input}>
        <UiInputField
          type='text'
          label={isDiscountView ? 'Precio Regular' : 'EAN'}
          name=''
          value={isDiscountView ? formatMoney(product.totalPrice, 0) : product.sku}
          disabled
        />
      </div>
      <div className={styles.input}>
        <UiInputField
          type='text'
          label='Descripción'
          name='name'
          value={isDiscountView ? product.name : form?.values.name}
          disabled={!product.isActive || !form}
          onChange={form?.handleChange}
          onBlur={form?.handleBlur}
          error={form?.errors.name}
          hasError={!!form?.errors.name}
        />
      </div>
    </div>
  )
}

export default ProductDetailCard;

export const CITIZENSHIP_IDENTIFICATION_ID = '1';
export const FOREIGN_IDENTIFICATION_ID = '2';
export const PASSPORT_IDENTIFICATION_ID = '4';
export const INE_IDENTIFICATION_ID = '5';
export const CURP_IDENTIFICATION_ID = '7';
export const PENDING_IDENTIFICATION_ID = '10';

export const COLOMBIA_IDENTIFICATIONS = [
  {
    title: 'Cédula de ciudadanía',
    value: CITIZENSHIP_IDENTIFICATION_ID,
  },
  {
    title: 'Cédula de extranjería',
    value: FOREIGN_IDENTIFICATION_ID,
  },
  {
    title: 'Número de pasaporte',
    value: PASSPORT_IDENTIFICATION_ID,
  },
];

export const MEXICO_IDENTIFICATIONS = [
  {
    title: 'INE',
    value: INE_IDENTIFICATION_ID,
  },
  {
    title: 'CURP',
    value: CURP_IDENTIFICATION_ID,
  },
  {
    title: 'Sin identificación',
    value: PENDING_IDENTIFICATION_ID,
  },
];

import React, { FC, memo } from 'react';
import { SWITCH_OPTIONS } from '@modules/customers/constants';
import styles from './customer-detail-swtich.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { actions, customersUiSelector, CustomerSwitchOption } from '@modules/customers/store';

interface CustomerDetailSwitchProps { }

const CustomerDetailSwitch: FC<CustomerDetailSwitchProps> = () => {
  const { activeSwitchOption } = useSelector(customersUiSelector);
  const dispatch = useDispatch();

  const handleSwitchChange = (name: CustomerSwitchOption) => {
    dispatch(actions.changeSwitchOption(name));
    dispatch(actions.setShowSalesTable(name === SWITCH_OPTIONS[0].name))
  };
  
  return (
    <div className={styles.wrapper}>
      {SWITCH_OPTIONS.map((option) => (
        <button
          name={option.name}
          onClick={() => handleSwitchChange(option.name)}
          className={`${styles.switch} ${activeSwitchOption === option.name && styles.active}`}
          key={`customer-switch-option-${option.name}`}
        >
          {option.title}
        </button>
      ))}
    </div>
  )
}

export default memo(CustomerDetailSwitch);

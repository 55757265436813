import React, { FC } from "react";
import styles from "./closures-mobile-home-boxes.module.sass";
import { Icon, IPhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import { formatMoney } from "@utils/format-money";

interface ClosuresMobileHomeBoxesProps {
  icon: IPhosphorIcons,
  title: string,
  value: number
};

const ClosuresMobileHomeBoxes: FC<ClosuresMobileHomeBoxesProps> = ({ icon, title, value }) => {
  return (
    <div className={styles.box}>
      <Icon {...icon}/>
      <p className={styles.title}>{title}</p>
      <p className={styles.value}>{title !== 'Total transacciones' ? formatMoney(value) : value}</p>
    </div>
  )
}

export default ClosuresMobileHomeBoxes;
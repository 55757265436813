import {
  mockInfoBilEmpty,
} from "@mocks/responses/bills";
import axios from "axios";
import { format } from "date-fns";
import { Bill, BillPreview, BillsFiltersPayload } from "../entities";

export const fetchGetBills = ({ initDate, endDate, search }: BillsFiltersPayload): Promise<Bill[]> => {
  const params = {
    date: initDate ? format(new Date(initDate), 'yyyy-MM-dd') : undefined,
    endDate: endDate ? format(new Date(endDate), 'yyyy-MM-dd') : undefined,
    search
  }
  return axios.get<Bill[]>('orders', { params })
    .then(({ data }) => data);
};

export const fetchInfoBill = (): Promise<BillPreview> => {
  return Promise.resolve(mockInfoBilEmpty);
};

export const fetchCreateDevolution = (body: any): Promise<boolean> => {
  return axios.post('devolution/create', body)
    .then(() => true);
};

export const fetchPrintOrder = (body: any): Promise<boolean> => {
  return axios.post('order/print', body)
    .then(() => true);
};

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customerDetailSelector } from '@modules/customers/store';
import styles from './customer-detail-mobile-info.module.sass';
import { TABS } from '@modules/customers/constants';

interface CustomerDetailMobileInfoProps { }

const CustomerDetailMobileInfo: FC<CustomerDetailMobileInfoProps> = () => {
  const navigate = useNavigate();
  const { name, phone } = useSelector(customerDetailSelector);

  const items = [
    { title: 'Nombre', value: name, path: TABS.updateName },
    { title: 'Teléfono', value: phone, path: TABS.updatePhone }
  ];

  return (
    <div className={styles.wrapper}>
      {items.map((item, index) => (
        <div className={styles.container} key={index}>
          <div className={styles.left}>
            <span className={styles.title}>{item.title}</span>
            <span className={styles.value}>{item.value}</span>
          </div>
          <div className={styles.edition} onClick={() => navigate(item.path)}>
            Editar
          </div>
        </div>
      ))}
    </div>
  )
}

export default CustomerDetailMobileInfo;

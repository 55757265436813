import React, { FC } from "react";
import { MIN_WIDTH_DESKTOP } from "@constants";
import ClosuresHome from "./pages/closures-home";
import ClosuresMobileRouter from "./routers";

interface ClosuresProps { }

const Closures: FC<ClosuresProps> = () => {
  return (
    <div>
      {window.innerWidth > MIN_WIDTH_DESKTOP && <ClosuresHome />}
      {window.innerWidth <= MIN_WIDTH_DESKTOP && <ClosuresMobileRouter />}
    </div>
  );
}

export default Closures;

import React, { FC, memo, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { actions as globalActions } from '@config/store';
import { customerDetailSelector } from '@modules/customers/store';
import { PATH } from '@modules/customers/constants';
import { customerUpdateNameSchema, customerUpdatePhoneSchema } from '@modules/customers/schemas';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import styles from './customers-updata-mobile.module.sass';
import UiInputField from '@ui/ui-input-field-v2/ui-input-field';
import UiButton from '@ui/ui-button-v2/ui-button';

interface CustomersUpdateMobileProps {
  isUpdatingName?: boolean;
}

const CustomersUpdateMobile: FC<CustomersUpdateMobileProps> = ({ isUpdatingName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const customer = useSelector(customerDetailSelector);
  const { updateCustomer } = useCustomersFetch();

  useEffect(() => {
    if (!customer.id) navigate(`/${PATH}/${customerId}`)
  }, [customer.id, customerId, navigate]);

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: 'Editar Cliente',
      routeTo: `/${PATH}/${customerId}`
    }));
  }, [customerId, dispatch]);

  const form = useFormik({
    initialValues: { id: customer.id, name: customer.name, phone: customer.phone },
    onSubmit: updateCustomer,
    validationSchema: isUpdatingName ? customerUpdateNameSchema : customerUpdatePhoneSchema,
  });

  const nameProp = useMemo(() => isUpdatingName ? 'name' : 'phone', [isUpdatingName]);

  return (
    <form className={styles.container} onSubmit={form.handleSubmit}>
      <p className={styles.title}>{isUpdatingName ? 'Nombre cliente' : 'Teléfono cliente'}</p>

      <UiInputField
        name={nameProp}
        placeholder={isUpdatingName ? customer.name : customer.phone}
        onChange={form.handleChange}
        error={form.errors[nameProp]}
        hasError={!!form.errors[nameProp]}
        value={form.values[nameProp]}
      />

      <div className={styles.button}>
        <UiButton
          text='Guardar'
          isDisabled={!form.isValid || !form.dirty}
          type='submit'
        />
      </div>
    </form>
  );
}

export default memo(CustomersUpdateMobile);

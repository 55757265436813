import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TABS } from '../constants';
import ClosureMobileHome from '../pages/closure-mobile-home';
import ClosureMobileHistory from '../pages/closure-mobile-history';

interface CollaboratorsRouterProps {}

const CollaboratorsRouter: FC<CollaboratorsRouterProps> = () => {

  return (
    <Routes>
      <Route path={`/${TABS.home}`} element={<ClosureMobileHome />} />
      <Route path={`/${TABS.history}`} element={<ClosureMobileHistory />} />
      <Route path='*' element={<Navigate to='closure'/>}/>
    </Routes>
  );
}

export default CollaboratorsRouter;
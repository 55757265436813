import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import styles from './ui-switch.module.sass';

interface UiSwitchProps {
  name?: string;
  value?: string;
  id?: string;
  isChecked?: boolean;
  isBlocked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlockedClick?: () => void;
}

const UiSwitch: FC<UiSwitchProps> = ({
  isChecked = false,
  name,
  value,
  id,
  isBlocked,
  onChange,
  onBlockedClick = () => true
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isChecked);
  }, [isChecked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isBlocked) onBlockedClick();
    else {
      setIsActive(wasActive => !wasActive);
      onChange(event);
    }
  };

  return (
    <label className={styles.switch}>
      <input
        data-testid='ui-switch'
        name={name}
        type="checkbox"
        className={styles.input}
        checked={isActive}
        onChange={handleChange}
        value={value}
        id={id}
      />
      <span className={`${styles.slider} ${styles.round}`} />
    </label>
  );
};

export default memo(UiSwitch);

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@config/store';
import { ReportsDataState, ReportsUiState } from '.';

export const reportsUiSelector = createSelector(
  (state: RootState) => state.reports.ui,
  (state: ReportsUiState) => state
);

export const reportsResumeDataSelector = createSelector(
  (state: RootState) => state.reports.data,
  (state: ReportsDataState) => state.resumeData
);

export const reportsDatesFilterSelector = createSelector(
  (state: RootState) => state.reports.data,
  (state: ReportsDataState) => state.dates
);

export const reportsSalesSelector = createSelector(
  (state: RootState) => state.reports.data,
  (state: ReportsDataState) => state.salesData
);

export const reportsTransactionsSelector = createSelector(
  (state: RootState) => state.reports.data,
  (state: ReportsDataState) => state.transactionsData
);

export const reportsSkusSelector = createSelector(
  (state: RootState) => state.reports.data,
  (state: ReportsDataState) => state.skusData
);

export const reportsSlowTurnoverSelector = createSelector(
  (state: RootState) => state.reports.data,
  (state: ReportsDataState) => state.slowTurnoverData
);

import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { consecutivesSelector, profileErrorsSelector, profileUiSelector } from '@modules/profile/store';
import styles from './invoicing-information.module.sass';
import useInvoicingInformationFetch from '@modules/profile/hooks/use-invoicing-information';
import BusinessData from '@modules/profile/components/business-data';
import Consecutives from '@modules/profile/components/consecutives';
import UiNoDataMessage from '@ui/ui-no-data-message';

interface InvoicingInformationProps { }

const InvoicingInformation: FC<InvoicingInformationProps> = () => {
  const consecutives = useSelector(consecutivesSelector);
  const { isLoading } = useSelector(profileUiSelector);
  const error = useSelector(profileErrorsSelector);
  const { getInvoicingInformation } = useInvoicingInformationFetch();

  useEffect(() => {
    if (!consecutives.length) getInvoicingInformation();
  }, [consecutives.length, getInvoicingInformation]);

  if (isLoading && !consecutives.length) return <span className={styles.loading}>Cargando...</span>;

  if (error.getInvoicingInformation) return <UiNoDataMessage message='No pudimos cargar sus datos de facturación' />;

  return (
    <>
      <h1 className={styles.title}>Datos de facturación</h1>

        <div className={styles.information}>
          <BusinessData />
          <Consecutives />
        </div>
    </>
  );
}

export default InvoicingInformation;

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions, providersDataSelector, providersErrorsSelector,
  providersPaginationSelector, providersUiSelector
} from '@modules/providers/store';
import styles from './providers-table.module.sass';
import useProvidersFetch from '@modules/providers/hooks/use-providers-fetch';
import { COLUMNS_TABLE } from '@modules/providers/constants';
import { Provider } from '@modules/providers/entities';
import { Icon } from '@chiper-inc/sb-web-chiper';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiPaginator from '@ui/ui-paginator';

interface ProvidersTableProps { }

const ProvidersTable: FC<ProvidersTableProps> = () => {
  const [ activeColumn, setActiveColumn ] = useState<string>('');
  const [ sortType, setSortType ] = useState<boolean>(false);
  
  const dispatch = useDispatch();
  const getProvidersError = useSelector(providersErrorsSelector);
  const providers = useSelector(providersDataSelector);
  const { isLoading, hasNoSearchResult } = useSelector(providersUiSelector);
  const { currentPage, totalPages } = useSelector(providersPaginationSelector);
  const { getPage, getProviders } = useProvidersFetch();

  const handleClick = (provider: Provider) => {
    dispatch(actions.setProviderToUpdate(provider));
    dispatch(actions.setShowModal(true));
  };

  const handleTableClick = (title: string) => {
    setActiveColumn(title);
    setSortType(!sortType);
    const sort = JSON.stringify({
      field: title,
      type: !sortType ? 'ASC' : 'DESC'
    })
    getProviders(1, sort)
  };

  useEffect(() => {
    getProviders()
  }, [getProviders]);

  if (getProvidersError) return <UiNoDataMessage message='No pudimos cargar su lista de proveedores' />;

  if (!providers.length && isLoading) return <span className={styles.noTableMessage}>Cargando...</span>;

  if (hasNoSearchResult) return (
    <span className={styles.noTableMessage}>
      No se encontraron coincidencias. Inteta nuevamente.
    </span>
  );

  return (
    <>
      <div className={styles.table}>
        <div className={styles.header}>
          {COLUMNS_TABLE.map((item) => (
            <div className={`${styles.cell} ${activeColumn === item.title && styles.active}`} key={`providers-column-${item.title}`}>
            <div className={styles.title} onClick={() => {handleTableClick(item.title); setSortType(!sortType)}}>{item.title}</div>
              {!!item.icon && (
                <div className={styles.icon} onClick={() => {handleTableClick(item.title); setSortType(!sortType)}} data-testid="table-head-item">
                  <Icon size="12px" weight="bold" name={item.icon} />
                </div>
              )}
            </div>
          ))}
        </div>
        {providers.map((item) => (
          <div key={`providers-table-row-${item.id}`} className={styles.body} onClick={() => handleClick(item)}>
            <div className={styles.cell}> {item.codeProvider}</div>
            <div className={styles.cell}>{item.nameProvider}</div>
            <div className={styles.cell}>{item.nit}</div>
            <div className={styles.cell}>{item.contactName}</div>
            <div className={styles.cell}>{item.phone}</div>
            <div className={styles.cell}>{item.email}</div>
          </div>
        ))}
      </div>
      {!totalPages
        ? <span className={styles.noTableMessage}>Aún no tienes proveedores creados.</span>
        : <UiPaginator pagesQuantity={totalPages} onChangePage={getPage} currentPage={currentPage} />
      }
    </>
  )
}

export default ProvidersTable;

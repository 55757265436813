import React, { ChangeEventHandler, FC, memo } from 'react';
import useDebounce from '@utils/use-debounce';
import styles from './ui-search-bar.module.sass';

interface UiSearchBarProps {
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string;
}

const UiSearchBar: FC<UiSearchBarProps> = ({ placeholder, onChange, defaultValue = '' }) => {
  const { handleInputChange, value } = useDebounce(onChange, defaultValue);

  return (
    <div className={`${styles.wrapper} ${value && styles.active}`}>
      <span className={`icon-search ${styles.icon}`} />
      <input
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={styles.input}
      />
    </div>
  );
}

export default memo(UiSearchBar);

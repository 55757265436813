import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import styles from './bill-edit-form.module.sass';
import UiButton from '@ui/ui-button-v2/ui-button';
import UiInputField from '@ui/ui-input-field-v2';
import UiTextAreaField from '@ui/ui-text-area-field';
import { BillPreview } from '@modules/bills/entities';
import { actions as globalActions, currentUserSelector } from '@config/store';
import { actions, billFormSelector } from '@modules/bills/store';
import { billUpdateSchema } from '@modules/bills/schemas';
import { BILL_MESSAGE_MAX_LENGTH, COLOMBIA_ISO_CODE } from '@constants';
import { BILL_EDIT_FORM_FIELDS, FIELD_NIT, FIELD_RFC } from '@modules/bills/constants';

interface BillEditFormProps { }

type EventHandler = ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>;

const BillEditForm: FC<BillEditFormProps> = () => {
  const dispatch = useDispatch();
  const billForm = useSelector(billFormSelector);
  const { country } = useSelector(currentUserSelector);

  const handleSubmit = (values: BillPreview): void => {
    dispatch(globalActions.notification({
      show: true,
      state: 'success',
      message: `La información de su recibo ha sido modificada con éxito`
    }));
    form.resetForm({ values });
  };

  const form = useFormik({
    initialValues: billForm,
    onSubmit: handleSubmit,
    validationSchema: billUpdateSchema,
  });

  const setFocusedField = useCallback((fieldName: string = '') => {
    dispatch(actions.setFieldOnFocus(fieldName));
  }, [dispatch]);

  const handleFieldChange = useCallback((event: EventHandler, name: string = 'message') => {
    form.handleChange(event);
    dispatch(actions.setForm({ [name as keyof object]: event.target.value }));
  }, [dispatch, form]);

  const handleFieldOnBlur = useCallback((event: EventHandler) => {
    form.handleBlur(event);
    setFocusedField();
  }, [form, setFocusedField]);

  const getBillEditFormFields = useMemo(() => [
    ...BILL_EDIT_FORM_FIELDS,
    country === COLOMBIA_ISO_CODE ? FIELD_NIT : FIELD_RFC
  ], [country]);

  return (
    <form onSubmit={form.handleSubmit} className={styles.wrapper}>
      <span className={styles.title}>Información de facturación</span>
      {getBillEditFormFields.map((field, i) =>
        <div className={styles.input} key={`${field.name}-${i}`} >
          <span className={`icon-${field.icon} ${styles.icon}`} />
          <UiInputField
            label={field.placeholder}
            onFocus={() => setFocusedField(field.name)}
            name={field.name}
            placeholder={field.placeholder}
            value={form.values[field.name as keyof object]}
            onChange={(e) => { handleFieldChange(e, field.name) }}
            onBlur={handleFieldOnBlur}
            hasError={!!form.errors[field.name as keyof object] && form.touched[field.name as keyof object]}
            error={form.errors[field.name as keyof object]}
          />
        </div>
      )}
      <div className={styles.areaText}>
        <span className={`icon-message ${styles.icon}`} />
        <UiTextAreaField
          onFocus={() => setFocusedField('message')}
          name='message'
          onChange={handleFieldChange}
          placeholder='Mensaje'
          value={form.values.message}
          label='Mensaje'
          maxLength={BILL_MESSAGE_MAX_LENGTH}
          onBlur={handleFieldOnBlur}
          hasError={!!form.errors.message && form.touched.message}
        />
      </div>
      <div className={styles.submit}>
        <UiButton
          type='submit'
          text='Guardar'
          isDisabled={!form.isValid}
        />
      </div>
    </form>
  )
}

export default BillEditForm;

import React, { FC, memo } from "react";
import styles from "./ui-button.module.sass";
import { Icon, PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";

interface UiButtonProps {
  text: string;
  type?: 'submit' | 'reset' | 'button';
  isSecondary?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  leftIcon?: string;
  leftIconSb?: keyof typeof PhosphorIcons;
}

const UiButton: FC<UiButtonProps> = ({
  text,
  type = 'button',
  isSecondary,
  isDisabled,
  onClick,
  leftIcon,
  leftIconSb
}) => {
  return (
    <button
      data-testid='ui-button'
      className={`${styles.wrapper} ${isSecondary && styles.secondary}`}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
    >
      {leftIcon && <span className={`${leftIcon} ${styles.leftIcon}`}/>}
      {leftIconSb && <div className={styles.leftIcon}> <Icon name={leftIconSb}/> </div>}
      <div className={styles.text}>{text}</div>
    </button>
  );
}

export default memo(UiButton);
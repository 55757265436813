import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@config/store';
import { OrdersUiState, OrdersDataState , OrdersSearchState } from '.';

export const ordersUiSelector = createSelector(
  (state: RootState) => state.orders.ui,
  (state: OrdersUiState) => state
);

export const ordersSearchSelector = createSelector(
  (state: RootState) => state.orders.search,
  (state: OrdersSearchState) => state.currentSearchedValue
);

export const providersSelector = createSelector(
  (state: RootState) => state.orders.data,
  (state: OrdersDataState) => state.providers
);

export const ordersSelector = createSelector(
  (state: RootState) => state.orders.data,
  (state: OrdersDataState) => state.orders
);

export const detailSelector = createSelector(
  (state: RootState) => state.orders.data,
  (state: OrdersDataState) => state.detail
);

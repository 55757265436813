import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import styles from './order-detail.module.sass';
import { fetchOrderDetails, setPaidOutOrder } from '@modules/orders/services/orders.services';
import { actions, detailSelector, ordersUiSelector } from '@modules/orders/store';
import { actions as globalActions } from '@config/store';
import { formatMoney } from '@utils/format-money';
import UiLoader from '@ui/ui-loader';
import Clevertap from '@utils/clevertap';
import { ORDER_PAY, ORDER_DETAIL } from '@constants';

interface OrderDetailProps { }

const OrderDetail: FC<OrderDetailProps> = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading } = useSelector(ordersUiSelector);
  const detail = useSelector(detailSelector);

  const handlePaidOutOrder = () => {
    setPaidOutOrder(detail.id)
      .then(() => {
        dispatch(actions.setIsLoading(false));
        dispatch(actions.setOrderDetail({ ...detail, isPaid: true }));
        dispatch(globalActions.notification({ show: true, state: 'success', message: 'Factura pagada correctamente.' }));
        Clevertap.pushEvent(ORDER_PAY, { id: String(id) });
      })
      .catch((error) => {
        Clevertap.pushEvent(ORDER_PAY, { id: String(id) }, error?.message);
        dispatch(actions.setIsLoading(false));
        dispatch(globalActions.notification({ show: true, state: 'error', message: 'Error pagando la factura.' }));
      })
  }

  useEffect(() => {
    dispatch(actions.setIsLoading(true));
    fetchOrderDetails(id || '')
      .then(r => {
        dispatch(actions.setOrderDetail(r));
        dispatch(actions.setIsLoading(false));
        Clevertap.pushEvent(ORDER_DETAIL, { id: String(id) });
      })
      .catch((error) => {
        dispatch(actions.setIsLoading(false));
        Clevertap.pushEvent(ORDER_DETAIL, { id: String(id) }, error?.message);
      });
  }, [dispatch, id]);

  return (
    <>
      {isLoading && <UiLoader />}
      <div className={styles.wrapper}>
        <div className={styles.resume}>
          <div className={styles.title}>Resumen de pedido {id}</div>
          <div className={styles.items}>
            {detail.providerOrderDetail?.map(i => (
              <React.Fragment key={`order-detail-item-${i.id}`}>
                <div className={styles.label}>{i.inventory.reference.name} x {i.quantity}</div>
                <div className={styles.value}>{formatMoney(i.managerTotal * +i.quantity, 0)}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.provider}>
            <div className={styles.strong}>Proveedor</div>
            <div className={`${styles.value} ${styles.strong}`}>{detail.providers?.nameProvider}</div>
            <div className={styles.label}>Fecha de entrega</div>
            <div className={styles.value}>{detail.createdAt && format(new Date(detail.createdAt), 'dd MMM yyyy', { locale: es })}</div>
            <div className={styles.label}>Fecha de pago</div>
            <div className={styles.value}>
              {detail.invoicePayDate && format(new Date(detail.invoicePayDate), 'dd MMM yyyy', { locale: es })}
            </div>
            <div className={styles.divider} />
            {detail.id && !detail.isPaid && (
              <div className={styles.payBox}>
                <span className={styles.text}>
                  Paga la factura antes del {
                    detail.invoicePayDate && format(new Date(detail.invoicePayDate), 'dd MMM yyyy', { locale: es })
                  }
                </span>
                <div className={styles.havePaid} onClick={handlePaidOutOrder}>Ya pagué</div>
              </div>
            )}
          </div>
          <div className={styles.totals}>
            <div className={styles.label}>Subtotal</div>
            <div className={styles.value}>{formatMoney(detail.subtotal, 0)}</div>
            <div className={styles.label}>Descuento</div>
            <div className={styles.value}>{formatMoney(0, 0)}</div>
            <div className={styles.label}>IVA</div>
            <div className={styles.value}>{formatMoney(0, 0)}</div>
            <div className={styles.label}>Total del pedido</div>
            <div className={styles.value}>{formatMoney(detail.total, 0)}</div>
            <div className={styles.divider} />
            <div className={styles.strong}>Total</div>
            <div className={`${styles.value} ${styles.strong}`}>{formatMoney(detail.total, 0)}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderDetail;

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, subDays } from 'date-fns';
import { actions, billsFiltersSelector } from '@modules/bills/store';
import styles from './bill-date-box.module.sass';
import useDateBox from '@modules/bills/hooks/use-date-box';
import Clevertap, { BILLS_SEARCH_DATE } from '@config/clevertap';

interface BillDateBoxProps { }

const BillDateBox: FC<BillDateBoxProps> = () => {
  const { initDate } = useSelector(billsFiltersSelector);
  const dispatch = useDispatch();
  const { date, isDisableNextDate, isInARangeDate } = useDateBox();

  const searchDate = (searchedDate: Date) => {
    dispatch(actions.setFilter({
      initDate: searchedDate.toDateString(),
    }));
    Clevertap.pushEvent(BILLS_SEARCH_DATE, { search: searchedDate });
  }

  const handleNextDate = () => {
    if (!isDisableNextDate) searchDate(addDays(new Date(initDate), 1))
  }

  const handleBackDate = () => {
    if (!isInARangeDate) searchDate(subDays(new Date(initDate), 1));
  }

  return (
    <div className={styles.wrapper}>
      <span
        data-testid='date-box-arrow-1'
        className={`icon-arrow-2 ${styles.arrow1} ${isInARangeDate && styles.disable}`}
        onClick={handleBackDate}
      />
      <span className={styles.date}>
        {date}
      </span>
      <span
        data-testid='date-box-arrow-2'
        className={`icon-arrow-2 ${styles.arrow} ${isDisableNextDate && styles.disable}`}
        onClick={handleNextDate}
      />
    </div>
  );
}

export default BillDateBox;

import { Collaborator } from "../entities";

export interface CollaboratorsState {
  ui: CollaboratorsUiState;
  data: CollaboratorsDataState;
  pagination: CollaboratorsPaginationState;
  errors: CollaboratorsErrosState;
}

export interface CollaboratorsUiState {
  isLoading: boolean;
  showDeleteCollaboratorModal: boolean;
  showSuccessPasswordCreationScreen: boolean;
  hasRequestedCollaborators: boolean;
}

export interface CollaboratorsDataState {
  collaborators: Collaborator[];
}

export interface CollaboratorsPaginationState {
  totalPages: number,
  currentPage: number,
}

export interface CollaboratorsErrosState {
  getCollaborators: boolean
}

export const initialState: CollaboratorsState = {
  ui: {
    isLoading: false,
    showDeleteCollaboratorModal: false,
    showSuccessPasswordCreationScreen: false,
    hasRequestedCollaborators: false,
  },
  data: {
    collaborators: [] as Collaborator[],
  },
  pagination: {
    totalPages: 0,
    currentPage: 1,
  },
  errors: {
    getCollaborators: false,
  }
}

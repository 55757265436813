import { MobileHeaderSection, Notification, Toast, User, UserPermissions } from "@entities";

export interface GlobalState {
  ui: GlobalUiState;
  data: GlobalDataState;
}

export interface GlobalUiState {
  notification: Notification;
  toast: Toast;
  hasCurrentUser: boolean;
  mobileHeaderSection: MobileHeaderSection;
  isFromPOS: boolean;
}

export interface GlobalDataState {
  currentUser: User;
  permissions: UserPermissions;
}

export const initialState: GlobalState = {
  ui: {
    notification: {
      show: false,
      message: 'Message',
      state: 'normal',
    },
    toast: { show: false },
    hasCurrentUser: false,
    mobileHeaderSection: { section: '', routeTo: '' },
    isFromPOS: false
  },
  data: {
    currentUser: {} as User,
    permissions: {
      canViewClosuresHistory: true,
      canEditInventorySalePrice: true,
      canEditInventoryQuantities: true,
      canEditInventoryPurchasePrice: true,
    }
  }
}
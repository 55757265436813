import { FORM_BASE } from "../constants";
import { Provider, ProvidersForm } from "../entities";

export const getForm = (storeId: number, provider: Provider): ProvidersForm => {
  if (!provider.id) return { ...FORM_BASE, storeId };
  return {
    id: provider.id,
    codeProvider: provider.codeProvider,
    contactName: provider.contactName,
    email: provider.email,
    nameProvider: provider.nameProvider,
    nit: provider.nit,
    phone: provider.phone,
    storeId
  }
}
import React, { FC } from 'react';
import styles from './closures-mobile-home-responsable.module.sass';
import { useSelector } from 'react-redux';
import { closuresDetailSelector } from '@modules/closures/store';

interface ClosuresMobileHomeResponsableProps { }

const ClosuresMobileHomeResponsable: FC<ClosuresMobileHomeResponsableProps> = () => {
  const closure = useSelector(closuresDetailSelector);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Responsable del cierre</div>
      <div className={styles.responsable}>{closure.takeOutCashier}</div>
    </div>
  )
}

export default ClosuresMobileHomeResponsable;
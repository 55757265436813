import axios from "axios";
import { LIMIT_GET_COLLABORATORS } from "../constants";
import { Collaborator, CollaboratorForm, Collaborators } from "../entities";

export const fetchGetCollaborators = (skip: number, storeId: number, sort?: string): Promise<Collaborators> =>
  axios.get<Collaborators>(`employee/get-store-employee/${storeId}?limit=${LIMIT_GET_COLLABORATORS}&skip=${skip * LIMIT_GET_COLLABORATORS}${!!sort ? `&sort=${sort}` : ''}`)
    .then(({ data }) => data);

export const fetchUpdateCollaborator = (body: Collaborator | CollaboratorForm, permissionsId: string[]): Promise<boolean> =>
  axios.put('employee/update-store-employee', { ...body, permissionsId })
    .then(() => true);

export const fetchCreateCollaborator = (body: CollaboratorForm, permissionsId: string[]): Promise<boolean> =>
  axios.post('employee/create-store-employee', { ...body, permissionsId })
    .then(() => true);

export const fetchGetCollaboratorToUpdate = (collaboratorId?: string): Promise<Collaborator> =>
  axios.get(`employee/get-one-employee/${collaboratorId}`)
    .then(({ data }) => data);

export const fetchDeleteCollaborator = (id?: string): Promise<boolean> =>
  axios.put('employee/delete-employee', { id })
    .then(() => true);

export const createCollaboratorPassword = (user: string, password: string): Promise<boolean> =>
  axios.post('pos-admin/update-password', { phone: user, password }, {
    headers: { Authorization: 'b37bfe6e-2120-40e4-9c31-c7d64a94fb37' },
  })
    .then(() => true)
    .catch(() => false);

import React, { FC } from 'react';
import styles from './closures-mobile-history-content.module.sass';
import useClosureHistory from '@modules/closures/hooks/use-closure-history';
import ClosuresMobileHistoryList from '../closures-mobile-history-list/closures-mobile-history-list';
import ClosuresMobileHistoryPaginatorButton from '../closures-mobile-history-paginator-button';

interface ClosuresMobileHistoryContentProps { }

const ClosuresMobileHistoryContent: FC<ClosuresMobileHistoryContentProps> = () => {
  const { pagination } = useClosureHistory();

  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>Historial de tus cierres de caja</div>

      <div className={styles.history}>

        <ClosuresMobileHistoryList />

        {pagination.current !== pagination.total &&
          <ClosuresMobileHistoryPaginatorButton />
        }

      </div>

    </div>
  )
}

export default ClosuresMobileHistoryContent;
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./dashboard-mobile.module.sass";
import { fetchDashboardData } from "@modules/dashboard/services";
import { currentUserSelector } from "@config/store";
import { actions, dashboardDataSelector, dashboardUiSelector } from "@modules/dashboard/store";
import { formatMoney } from "@utils/format-money";
import { STORAGE_TOKEN } from "@constants";
import UiLoader from "@ui/ui-loader";
import DashboardProduct from "@modules/dashboard/components/dashboard-product-item";
import { Icon } from "@chiper-inc/sb-web-chiper";

interface DashboardMobileProps { }

const DashboardMobile: FC<DashboardMobileProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoadingDashboard, hasDashboardData } = useSelector(dashboardUiSelector);
  const user = useSelector(currentUserSelector);
  const dashboard = useSelector(dashboardDataSelector);

  const handleLogout = () => {
    localStorage.removeItem(STORAGE_TOKEN);
    navigate('/login', { replace: true });
  }

  const handleCustomerClick = (): void => {
    navigate('customers');
  };
  
  const handleClosureClick = (): void => {
    navigate('closure');
  };

  useEffect(() => {
    if (!hasDashboardData) {
      dispatch(actions.setIsLoadingDashboard(true));
      fetchDashboardData(user.storeId)
        .then(data => {
          dispatch(actions.setDashboardData(data));
          dispatch(actions.setIsLoadingDashboard(false));
          dispatch(actions.setHasDashboardData(true));
        })
        .catch(() => {
          dispatch(actions.setIsLoadingDashboard(false));
        });
    }
  }, [dispatch, hasDashboardData, user.storeId]);

  const handleInputClick = (): void => {
    navigate('products-search');
  };

  return (
    <>
      {isLoadingDashboard && <UiLoader />}
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.inputContainer}>
            <span className={`icon-search ${styles.icon}`} />
            <input className={styles.input} type="text" placeholder="Busca un producto" onClick={handleInputClick} />
          </label>
          <div className={styles.texts}>
            <span className={styles.name}>Hola {user.firstName}</span>
            <span className={styles.logout} onClick={handleLogout}>Salir</span>
          </div>
        </div>
        <div className={styles.resume}>
          <div className={styles.title}>Hoy has vendido</div>
          <div className={styles.value}>{formatMoney(dashboard.salesDay, 0)}</div>
          <div className={styles.yesterday}>Ayer vendiste: {formatMoney(dashboard.salesLastDay, 0)}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Gestiona tu negocio</div>
          <div className={styles.actions}>
            <div className={styles.action} onClick={handleCustomerClick}>
              <div className={styles.circle}>
                <Icon name='User' size={28}/>
              </div>
              <div className={styles.name}>Clientes</div>
            </div>
            <div className={styles.action} onClick={handleClosureClick}>
              <div className={styles.circle}>
                <Icon name='Calculator' size={28}/>
              </div>
              <div className={styles.name}>Cierres</div>
            </div>
          </div>
          {dashboard.mostSold && (
            <div className={styles.soldProducts}>
              <div className={styles.title}>Más vendidos del mes</div>
              <div className={styles.productItems}>
                {!dashboard.mostSold.length && <span className={styles.empty}>Aún no posees ventas</span>}
                {dashboard.mostSold.map((product, i) => (
                  <React.Fragment key={`dashboard-product-item-${product.referencename}-${i}`}>
                    <DashboardProduct product={product} number={i + 1} />
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          {dashboard.leastSold && (
            <div className={styles.soldProducts}>
              <div className={styles.title}>Menos vendidos del mes</div>
              <div className={styles.productItems}>
                {!dashboard.leastSold.length && <span className={styles.empty}>Aún no posees ventas</span>}
                {dashboard.leastSold.map((product, i) => (
                  <React.Fragment key={`dashboard-product-item-${product.inventoryid}-${i}`}>
                    <DashboardProduct product={product} number={i + 1} />
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardMobile;
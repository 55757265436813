import * as yup from 'yup';
import { t } from 'i18next'
import { PHONE_LENGTH, REG_EXP_ONLY_NUMBERS } from '@constants';

export const storeInformationSchema = yup.object().shape({
  alternativePhone: yup.string()
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  numberOfEmployees: yup.string()
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers')),
  openingTime: yup.number()
    .typeError(t('errors.only_numbers'))
    .max(new Date().getFullYear(), t('errors.invalid_date')),
  storeName: yup.string()
    .max(35, (max) => t('errors.max_digits', { digits: max.max })),
});

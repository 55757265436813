import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import {
  CustomerDetailState, CustomersDataState, CustomersErrorsState,
  CustomersPaginationState, CustomersResumeState, CustomersUiState
} from ".";

export const customersUiSelector = createSelector(
  (state: RootState) => state.customers.ui,
  (state: CustomersUiState) => state
);

export const customersDataSelector = createSelector(
  (state: RootState) => state.customers.data,
  (state: CustomersDataState) => state.customers
);

export const customersResumeSelector = createSelector(
  (state: RootState) => state.customers.resume,
  (state: CustomersResumeState) => state
);

export const customersErrorsSelector = createSelector(
  (state: RootState) => state.customers.errors,
  (state: CustomersErrorsState) => state
);

export const customerDetailSelector = createSelector(
  (state: RootState) => state.customers.detail,
  (state: CustomerDetailState) => state.customer
);

export const customerSalesSelector = createSelector(
  (state: RootState) => state.customers.detail,
  (state: CustomerDetailState) => state.sales
);

export const customerBalancesSelector = createSelector(
  (state: RootState) => state.customers.detail,
  (state: CustomerDetailState) => state.balances
);

export const customerTransactionDetailSelector = createSelector(
  (state: RootState) => state.customers.detail,
  (state: CustomerDetailState) => state.transactionDetail
);

export const customerSearchSelector = createSelector(
  (state: RootState) => state.customers.search.currentSearchedValue,
  (state: string) => state
);

export const customersPagintationSelector = createSelector(
  (state: RootState) => state.customers.pagination,
  (state: CustomersPaginationState) => state
);

import React, { ChangeEvent, FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './inventory-filters.module.sass';
import { currentUserSelector } from '@config/store';
import { fetchDownloadInventory } from '@modules/inventory/services';
import { actions, inventoryResumeSelector, inventorySearchSelector } from '@modules/inventory/store';
import { formatMoney } from '@utils/format-money';
import UiSearchBar from '@ui/ui-search-bar';
import UiSquareButton from '@ui/ui-square-button';
import useInventoryFetch from '@modules/inventory/hooks/use-inventory-fetch';
import Clevertap from '@utils/clevertap';
import { INVENTORY_DOWNLOAD } from '@constants';

interface InventoryFiltersProps { }

const InventoryFilters: FC<InventoryFiltersProps> = () => {
  const user = useSelector(currentUserSelector);
  const resume = useSelector(inventoryResumeSelector);
  const currentSearchedValue = useSelector(inventorySearchSelector);
  const dispatch = useDispatch();
  const { searchProduct } = useInventoryFetch();

  const handleDownload = (): void => {
    fetchDownloadInventory(user.storeId, user.country);
    Clevertap.pushSimpleEvent(INVENTORY_DOWNLOAD);
  };

  const handleSearch = useCallback(({target}: ChangeEvent<HTMLInputElement>): void => {
    searchProduct(target.value)
  }, [searchProduct]);

  const handleUploadInventory = useCallback((): void => {
    dispatch(actions.setIsUploadingInventory(true));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.resume}>
        <div className={styles.resumeItem}>
          <span className={styles.resumeTitle}>
            SKUs totales
          </span>
          {resume.totalSkus}
        </div>
        <div className={styles.resumeItem}>
          <span className={styles.resumeTitle}>
            Costo total del inventario
          </span>
          {formatMoney(Number(resume.totalCost), 0)}
        </div>
      </div>
      <div className={styles.searchBar}>
        <UiSearchBar placeholder='Buscar producto' onChange={handleSearch} defaultValue={currentSearchedValue} />
      </div>
      <UiSquareButton icon='icon-upload' onClick={handleUploadInventory} />
      <UiSquareButton icon='icon-download' onClick={handleDownload} />
    </div>
  )
}

export default memo(InventoryFilters);

import { formatMoney } from "@utils/format-money";
import { format } from "date-fns";
import { LOAN_TRANSACTION_TYPE, NEW_CUSTOMER_FORM, PAYMENT_TRANSACTION_TYPE, TRANSACTION_EGRESS, TRANSACTION_INCOME, TRANSACTION_INCOME_CARD, TRANSACTION_LOAN, TRANSACTION_PAY_LOAN } from "../constants";
import { Customer, CustomersFormBase, ResumeItem, TransactionDetailItem, TransactionForm, TransactionsItem } from "../entities";
import { CustomersResumeState } from "../store";

export const getResumeItems = (resume: CustomersResumeState): ResumeItem[] => {
  return [
    {
      title: 'Deuda total',
      value: formatMoney(resume.totalLoan, 0),
      isRed: resume.totalLoan > 0
    },
    {
      title: 'Clientes con deuda',
      value: resume.totalCustomerLoan,
      isRed: false
    },
    {
      title: 'Clientes registrados',
      value: resume.totalCustomers,
      isRed: false
    }
  ]
};

export const getIntialValues = (currentCustomer: Customer): CustomersFormBase => {
  if (!currentCustomer.id) return NEW_CUSTOMER_FORM;

  return {
    id: currentCustomer.id,
    name: currentCustomer.name || '',
    phone: currentCustomer.phone || '',
    document: currentCustomer.document || '',
    address: currentCustomer.address || ''
  }
}

export const getCustomerDetailResumeItems = (customer: Customer): ResumeItem[] => {
  return [
    {
      title: 'Celular',
      value: customer.phone,
      isRed: false,
    },
    {
      title: 'Dirección',
      value: customer.address || '...',
      isRed: false
    },
    {
      title: 'Transacciones',
      value: customer.transactions,
      isRed: false
    },
    {
      title: 'Deuda actual',
      value: formatMoney((customer.loans - customer.payments) || 0, 0),
      isRed: ((customer.loans - customer.payments) || 0) > 0
    },
    {
      title: 'Último abono',
      value: !customer.lastpaidloan ? '...' : format(new Date(customer.lastpaidloan), 'd MMM yyyy'),
      isRed: false
    }
  ]
};

export const getPaymentMethod = (transaction: string): string => {
  switch (transaction) {
    case TRANSACTION_INCOME: return 'Efectivo';
    case TRANSACTION_INCOME_CARD: return 'Tarjeta';
    case TRANSACTION_LOAN: return 'Fiado';
    case TRANSACTION_PAY_LOAN: return 'Abono';
    case TRANSACTION_EGRESS: return 'Retiro';
    default: return '';
  }
};

export const getTransactionResume = (transactions: TransactionDetailItem[]): TransactionsItem[] => {
  const getSubtotal = (): number => transactions.reduce((total, sale) => total + sale.total, 0);
  return [
    { title: 'Subtotal', value: formatMoney(getSubtotal(), 0) },
    { title: 'IVA', value: formatMoney(Number(transactions[0]?.iva || 0), 0) },
    { title: 'TOTAL', value: formatMoney(getSubtotal() + Number(transactions[0]?.iva || 0), 0) }
  ]
};

export const getTransactionFooterItems = (transactions: TransactionDetailItem[]): TransactionsItem[] => {
  return [
    ...getTransactionResume(transactions),
    { title: 'Método de pago', value: transactions[0]?.transactionType || '...' }
  ]
};

export const getTransactionForm = (customerId: string, phone: string, isPayment: boolean): TransactionForm => {
  return {
    description: null,
    paymentAccountId: phone,
    total: '',
    isMobile: '1',
    customerId,
    createdAt: new Date(),
    updatedAt: new Date(),
    transactionType: isPayment ? PAYMENT_TRANSACTION_TYPE : LOAN_TRANSACTION_TYPE,
  }
}

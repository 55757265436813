import axios from "axios";
import { format } from "date-fns";

import { Dashboard } from "../entities";

export const fetchDashboardData = (storeId: number): Promise<Dashboard> => {
  return axios.post('/order-dash', {
    dateNow: format(new Date(), 'yyyy-MM-dd'),
    storeId
  }).then(response => response.data);
}

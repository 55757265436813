import { Customer, CustomerBalance, CustomerSale, TransactionDetailItem } from "../entities";

export interface CustomersState {
  ui: CustomersUiState;
  data: CustomersDataState;
  detail: CustomerDetailState;
  resume: CustomersResumeState;
  pagination: CustomersPaginationState;
  search: CustomerSearchState;
  errors: CustomersErrorsState;
}

export type CustomerSwitchOption = 'balances' | 'sales';

export interface CustomersUiState {
  isLoading: boolean;
  activeSwitchOption: CustomerSwitchOption;
  showSalesTable: boolean;
  hasNoSearchResult: boolean;
  hasRequestedCustomers: boolean;
  hasCustomers: boolean;
}

export interface CustomersDataState {
  customers: Customer[];
}

export interface CustomerDetailState {
  customer: Customer;
  sales: CustomerSale[];
  balances: CustomerBalance[];
  transactionDetail: TransactionDetailItem[]
}

export interface CustomersPaginationState {
  totalPages: number;
  currentPage: number;
}

export interface CustomerSearchState {
  currentSearchedValue: string;
}

export interface CustomersResumeState {
  totalCustomers: number;
  totalLoan: number;
  totalCustomerLoan: number;
}

export interface CustomersErrorsState {
  getCustomers: boolean;
  getCustomerDetail: boolean;
  getTransactionDetail: boolean;
}

export const initialState: CustomersState = {
  ui: {
    isLoading: false,
    activeSwitchOption: 'sales',
    showSalesTable: true,
    hasNoSearchResult: false,
    hasRequestedCustomers: false,
    hasCustomers: true,
  },
  data: {
    customers: [] as Customer[]
  },
  resume: {
    totalCustomers: 0,
    totalLoan: 0,
    totalCustomerLoan: 0
  },
  pagination: {
    totalPages: 0,
    currentPage: 1
  },
  detail: {
    customer: {} as Customer,
    sales: [] as CustomerSale[],
    balances: [] as CustomerBalance[],
    transactionDetail: [] as TransactionDetailItem[]
  },
  search: {
    currentSearchedValue: ''
  },
  errors: {
    getCustomers: false,
    getCustomerDetail: false,
    getTransactionDetail: false
  },

}

import React, { ChangeEvent, FC, useCallback } from 'react';
import XLSX from 'xlsx';
import styles from './upload-inventory.module.sass';
import UiModal from '@ui/ui-modal';
import { useDispatch, useSelector } from 'react-redux';
import { actions, inventoryUiSelector } from '@modules/inventory/store';
import UiButton from '@ui/ui-button';
import { currentUserSelector, actions as globalActions } from '@config/store';
import { fetchDownloadFileTemplate } from '@modules/inventory/services';
import { InventoryFileItem } from '@modules/inventory/entities';
import useInventoryFetch from '@modules/inventory/hooks/use-inventory-fetch';
import { FILE_STRUCTURE, MESSAGE_ERROR, MIME_TYPE_FILE } from '@modules/inventory/constants';

interface UploadInventoryProps { }

const UploadInventory: FC<UploadInventoryProps> = () => {
  const user = useSelector(currentUserSelector);
  const { isReadingFile } = useSelector(inventoryUiSelector);
  const { setFile } = useInventoryFetch();
  const dispatch = useDispatch();

  const handleCloseModal = useCallback((): void => {
    dispatch(actions.setIsUploadingInventory(false));
  }, [dispatch]);

  const handleDownloadTemplate = useCallback((): void => {
    fetchDownloadFileTemplate(user.storeId);
  }, [user.storeId]);

  const handleDisplayError = (errorMessage: string) => {
    dispatch(globalActions.notification({
      show: true,
      state: 'error',
      message: errorMessage
    }));
    dispatch(actions.setIsLoading(false));
    dispatch(actions.setIsReadingdFile(false));
    dispatch(actions.setIsUploadingInventory(false));
  }

  const hasCorrectEstructure = (file: XLSX.WorkSheet): boolean => {
    return (file?.A1?.v === FILE_STRUCTURE.A1 && file?.B1?.v === FILE_STRUCTURE.B1 && file?.C1?.v === FILE_STRUCTURE.C1
      && file?.D1?.v === FILE_STRUCTURE.D1 && file?.E1?.v === FILE_STRUCTURE.E1 && file?.F1?.v === FILE_STRUCTURE.F1
      && file?.G1?.v === FILE_STRUCTURE.G1 && file?.H1?.v === FILE_STRUCTURE.H1 && file?.I1?.v === FILE_STRUCTURE.I1)
  };

  const handleFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setIsLoading(true));
    dispatch(actions.setIsReadingdFile(true));
    const fileReader = new FileReader();
    if (target.files) {
      if (!(target.files[0].type === MIME_TYPE_FILE.xls || target.files[0].type === MIME_TYPE_FILE.xlsx)) {
        handleDisplayError(MESSAGE_ERROR.invalidFile);
      } else {
        fileReader.readAsArrayBuffer(target.files[0]);
        fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            const data = new Uint8Array(event.target.result as ArrayBufferLike);
            const workbook = XLSX.read(data, { type: 'array' });
            if (!hasCorrectEstructure(workbook.Sheets.Inventory)) {
              handleDisplayError(MESSAGE_ERROR.invalidStructure)
            } else {
              workbook.SheetNames.forEach((item) => {
                const fileRows = XLSX.utils.sheet_to_json<InventoryFileItem>(workbook.Sheets[item]);
                if (fileRows.length === 0) {
                  handleDisplayError(MESSAGE_ERROR.emptyFile)
                } else {
                  if (target.files) setFile(fileRows, target.files[0].name);
                }
              })
            }
          }
        };
      }
    }
  };

  return (
    <UiModal onCloseClick={handleCloseModal}>
      <div className={styles.wrapper}>
        {isReadingFile && (
          <div className={styles.reading}>
            <p className={styles.readingText}>Estamos procesando su archivo</p>
            <p className={styles.readingText}>Este proceso podría demorar unos minutos...</p>
          </div>
        )}
        <span className={styles.title}>Cargar archivo</span>

        <span className={styles.description}>
          Descarga el formato o carga tu archivo con tu inventario
        </span>
        <span className={styles.formatFile}>
          Formatos permitidos: .xlsx, .xls
        </span>

        <div className={styles.buttons}>
          <UiButton
            onClick={handleDownloadTemplate}
            text='Descargar plantilla'
            leftIcon='icon-download'
            isSecondary
          />
          <div className={styles.inputWrapper}>
            <UiButton text='Subir archivo' leftIcon='icon-upload' />
            <input type='file' onChange={handleFile} accept='.xlsx,.xls' className={styles.input} />
          </div>
        </div>
      </div>
    </UiModal>
  );
};

export default UploadInventory;

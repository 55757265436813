import React, { ChangeEvent, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, billsUiSelector } from '@modules/bills/store';
import styles from './bills-filters.module.sass';
import UiSearchBar from '@ui/ui-search-bar-v2';
import BillDatePicker from '../bill-date-picker';
import BillDateBox from '../bill-date-box';
import Clevertap, { BILLS_SEARCH } from '@config/clevertap';

interface BillsFiltersProps { }

const BillsFilters: FC<BillsFiltersProps> = () => {
  const dispatch = useDispatch();
  const { hasNoSearchResults } = useSelector(billsUiSelector);
  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const checkValue = () => {
      if (target.value.toLowerCase() === 'fiado') return 'loan';
      if (target.value.toLowerCase() === 'venta') return 'income';
      return target.value
    };

    if (!!target.value) Clevertap.pushEvent(BILLS_SEARCH, { search: target.value });
    dispatch(actions.setFilter({ search: checkValue() || undefined }));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchbar}>
        <UiSearchBar
          placeholder='Busca tus facturas'
          onChange={handleInputChange}
          hasError={hasNoSearchResults}
        />
      </div>

      <BillDatePicker />

      <BillDateBox />
    </div>
  );
}

export default BillsFilters;

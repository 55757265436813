import React, { FC } from "react";
import { Icon, Paginator, Theme } from "@chiper-inc/sb-web-chiper";
import styles from "./closures-home-history.module.sass";
import useClosureHistory from "@modules/closures/hooks/use-closure-history";
import ClosuresHomeHistoryList from "../closures-home-history-list";
import UiSkeleton from "@ui/ui-skeleton/ui-skeleton";
import { DEFAULT_LIMIT_HISTORY } from "@modules/closures/constants";

interface ClosuresHomeHistoryProps { }

const ClosuresHomeHistory: FC<ClosuresHomeHistoryProps> = () => {
  const { changePage, isLoading, pagination } = useClosureHistory();

  return (
    <div className={styles.wrapper} data-testid="closures-home-history">
      <div className={styles.title}>
        <div className={styles.icon}>
          <Icon name="Calendar" weight="fill" size={32} color={Theme.colors.primary.white} />
        </div>

        <div className={styles.texts}>
          <p className={styles.top}>Aquí puedes consultar el</p>
          <p className={styles.bottom}>Historial de tus cierres de caja</p>
        </div>
      </div>

      {/* <ClosuresHomeHistoryPickers /> */}

      <div className={styles.falsePickers} />

      <div className={styles.list}>
        {isLoading ? (
          <div className={styles.loading}>
            {Array(DEFAULT_LIMIT_HISTORY).fill("").map((_, i) => (
              <UiSkeleton key={`loading-${_ + i}`} height="40px" width="100%" />
            ))}
          </div>
        ) : <ClosuresHomeHistoryList />
        }
      </div>

      <div className={styles.paginator}>
        <Paginator
          currentPage={pagination.current}
          totalPages={pagination.total}
          onChangePage={changePage}
          maxPagesToDisplay={5}
        />
      </div>
    </div>
  );
}

export default ClosuresHomeHistory;

import React, { FC, useCallback, useEffect } from 'react';
import { ChartData } from 'chart.js';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSkusReferences } from '../../services/reports.services';
import { actions, reportsDatesFilterSelector, reportsSkusSelector, reportsUiSelector } from '@modules/reports/store';
import { useIsMounted } from '@utils/use-is-mounted';
import Clevertap from '@utils/clevertap';
import { REPORTS_GET_SKUS_DATA, REPORTS_SKUS_DURATION, REPORTS_SKUS_SECTION } from '@constants';
import styles from './report-skus.module.sass';
import ReportsChart from '../../components/reports-chart';
import ReportsPageNoData from '../../components/reports-page-no-data';
import UiLoader from '@ui/ui-loader';

interface ReportSkusProps {}

const ReportSkus: FC<ReportSkusProps> = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { isLoadingSkus } = useSelector(reportsUiSelector);
  const { skus } = useSelector(reportsSkusSelector);
  const { startDate, finishDate } = useSelector(reportsDatesFilterSelector);

  const skusData = (): ChartData<'line'> => {
    return {
      labels: skus.map(item => format(new Date(item.date), 'MM-dd')),
      datasets: [
        {
          label: 'SKUs',
          data: skus.map(item => +item.sku)
        }
      ],
    }
  }
  const getSkusReferences = useCallback((dates: { initDate: string, endDate: string }): void => {
    const args = { startDate: dates.initDate, finishDate: dates.endDate };
    dispatch(actions.setIsLoadingSkusData(true));
    fetchSkusReferences(args)
      .then((response) => {
        if (isMounted.current) {
          dispatch(actions.setSkusData({ skus: response }));
          dispatch(actions.setIsLoadingSkusData(false));
        }
        Clevertap.pushCheckSuccessEvent(REPORTS_GET_SKUS_DATA);
      })
      .catch((error) => {
        if (isMounted.current) {
          dispatch(actions.setSkusData({ skus: [] }));
          dispatch(actions.setIsLoadingSkusData(false));
        }
        Clevertap.pushCheckSuccessEvent(REPORTS_GET_SKUS_DATA, error?.message);
      });
  }, [isMounted, dispatch]);
  
  const hasSkusDataToShow = (): boolean => !!skus.length;

  useEffect(() => {
    if (startDate && finishDate) {
      getSkusReferences({ initDate: startDate, endDate: finishDate });
    }
  }, [startDate, finishDate, getSkusReferences]);
  
  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(REPORTS_SKUS_SECTION);
    return () => Clevertap.pushDurationEvent(REPORTS_SKUS_DURATION, openedAt);
  }, []);

  const handleLoading = () => {
    if (isLoadingSkus) {
      return 'Cargando'
    }
    return 'No hay registro de SKUs'
  }

  return (
    <div className={styles.wrapper}>
      {isLoadingSkus && <UiLoader />}
      { !hasSkusDataToShow() ? (
        <ReportsPageNoData>
          {handleLoading()}
        </ReportsPageNoData>
      ) : (
        <div className={styles.charts}>
          <div className={styles.chart}>
            <ReportsChart data={skusData()} />
          </div>
        </div> 
      )}
    </div>
  );
};
 
export default ReportSkus;

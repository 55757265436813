import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { billsDataSelector } from '@modules/bills/store';
import styles from './bills-list.module.sass';
import BillsCard from '../bills-card';

interface BillsListProps { }

const BillsList: FC<BillsListProps> = () => {
  const bills = useSelector(billsDataSelector);

  return (
    <div className={styles.wrapper}>
      {bills.map(e => (
        <BillsCard bill={e} key={`bill-list-item-${e.id}`} />
      ))}
    </div>
  );
}

export default BillsList;

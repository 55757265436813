import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChiperButton, Icon, Theme } from "@chiper-inc/sb-web-chiper";
import Clevertap, { CLOSURE_DOWNLOAD } from "@config/clevertap";
import { currentUserSelector, actions as globalActions } from "@config/store";
import { closuresDetailSelector } from "@modules/closures/store";
import { formatMoney } from "@utils/format-money";
import { fetchDownloadClosure } from "@modules/closures/services";
import ClosuresHomeDetailBox from "../closures-home-detail-box";
import styles from "./closures-home-detail-top-info.module.sass";
import UiDivider from "@ui/ui-divider";

interface ClosuresHomeDetailTopInfoProps { }

const ClosuresHomeDetailTopInfo: FC<ClosuresHomeDetailTopInfoProps> = () => {
  const closure = useSelector(closuresDetailSelector);
  const storeId = useSelector(currentUserSelector).storeId;
  const dispatch = useDispatch();

  const iconProps: object = {
    size: 24,
    weight: "fill",
    className: styles.boxIcon,
    color: Theme.colors.primary.darkBlueGray
  }

  const handleDownloadClick = () => {
    fetchDownloadClosure(closure.id, storeId);
    Clevertap.pushEvent(CLOSURE_DOWNLOAD, { closureId: +closure.id })
    setTimeout(() => {
      dispatch(globalActions.toast({
        show: true,
        title: "Informe descargado",
        body: "El informe descargado puedes encontrarlo en tu carpeta de descargas",
        type: "positive"
      }))
    }, 1000)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.balance}>
        <p className={styles.title}>Total de ingresos del cierre</p>

        <div className={styles.total}>
          Total de ingresos
          <p className={styles.value}>
            {formatMoney(closure.totalIncome)}
          </p>
        </div>

        <Icon
          name="CurrencyCircleDollar"
          size={250}
          color={Theme.colors.primary.darkBlueGray}
          className={styles.icon}
          weight="fill"
        />
      </div>

      <ClosuresHomeDetailBox className={styles.box}>
        <div className={styles.boxItem}>
          <Icon name="User" {...iconProps} />
          <div className={styles.user}>
            Responsable del cierre:
            <p className={styles.name}>{closure.takeOutCashier}</p>
          </div>
        </div>

        <UiDivider />

        <div className={styles.boxItem}>
          <Icon name="Download" {...iconProps} />
          <div className={styles.download}>
            Informe de cierres de caja
          </div>
        </div>

        <ChiperButton
          text="Descargar"
          onClick={handleDownloadClick}
          data-testid="closures-download-button"
        />
      </ClosuresHomeDetailBox>
    </div>
  );
}

export default ClosuresHomeDetailTopInfo;

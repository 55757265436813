import React, { FC } from "react";
import styles from "./closures-home.module.sass";
import ClosuresHomeHistory from "@modules/closures/components/closures-home-history";
import ClosuresHomeDetail from "@modules/closures/components/closures-home-detail";

interface ClosuresHomeProps { }

const ClosuresHome: FC<ClosuresHomeProps> = () => {
  return (
    <div className={styles.container} data-testid="closures-home">

      <ClosuresHomeHistory />

      <ClosuresHomeDetail />

    </div>
  );
}

export default ClosuresHome;

import React, { FC } from "react";
import styles from "./ui-divider.module.sass";

interface UiDividerProps {
  width?: string;
  height?: string;
}

const UiDivider: FC<UiDividerProps> = ({ width = "100%", height = "1px" }) => {

  return (
    <div className={styles.divider} style={{ width, height }} />
  );
}

export default UiDivider;

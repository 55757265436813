import React, { FC, useEffect } from 'react';
import * as yup from 'yup';
import { t } from 'i18next'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { customerDetailSelector } from '@modules/customers/store';
import { getTransactionForm } from '@modules/customers/utils';
import UiButton from '@ui/ui-button';
import UiInputMoney from '@ui/ui-input-money';
import styles from './customer-transaction.module.sass';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import { TransactionForm } from '@modules/customers/entities';
import { actions } from '@config/store';
import { MAX_MONEY_VALUE } from '@constants';
import { formatMoney } from '@utils/format-money';
import { useNavigate } from 'react-router-dom';

interface CustomerTransactionProps {
  isPaymentTransaction?: boolean;
}

const CustomerTransaction: FC<CustomerTransactionProps> = ({ isPaymentTransaction = false }) => {
  const customer = useSelector(customerDetailSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createNewTransaction } = useCustomersFetch();

  useEffect(() => {
    if (!customer.id) navigate(-1)
  }, [customer.id, navigate])

  const handleSubmit = (values: TransactionForm): void => {
    createNewTransaction(values, isPaymentTransaction);
  };

  const form = useFormik({
    initialValues: getTransactionForm(customer.id, customer.phone, isPaymentTransaction),
    onSubmit: handleSubmit,
    validationSchema: yup.object().shape({
      total: yup.number()
        .required(t('errors.required'))
        .test('test', t('errors.zero_amount'), (total) => Number(total) > 0)
        .test('overpay-test', t('errors.overpay'), (total) => {
          const isError = isPaymentTransaction && (Number(total) > Number(customer.loans - customer.payments));
          if (isError) dispatch(actions.notification({ show: true, state: 'error', message: t('errors.overpay') }));
          return !isError
        })
        .max(MAX_MONEY_VALUE, t('errors.max_money_value', { max: formatMoney(MAX_MONEY_VALUE) })),
    })
  });

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      <UiInputMoney
        name='total'
        hasAutoFocus
        label={`Ingrese el valor del ${isPaymentTransaction ? 'abono' : 'prestamo'}`}
        onChange={form.handleChange}
        value={form.values.total}
        hasError={!!form.errors.total}
        error={form.errors.total}
      />
      <UiButton
        text={isPaymentTransaction ? 'Abonar' : 'Prestar'}
        isFullWidth
        type='submit'
        isDisabled={!form.isValid}
      />
    </form>
  );
}

export default CustomerTransaction;

import React, { FC, useCallback } from 'react';
import styles from './bill-devolution.module.sass';
import BillDevolutionProducts from '@modules/bills/components/bill-devolution-products';
import UiButton from '@ui/ui-button-v2';
import useDevolution from '@modules/bills/hooks/use-devolution';

interface BillDevolutionProps { }

const BillDevolution: FC<BillDevolutionProps> = () => {
  const {
    totalToDevolution, productsList, devolutionsList, addProductToDevolution, removeProductToDevolution, createNewDevolution
  } = useDevolution();

  const handleDevolutionClick = useCallback(() => {
    createNewDevolution();
  }, [createNewDevolution]);

  return (
    <div className={styles.container}>
      <p className={styles.text}>Selecciona los productos para devolución</p>

      <div className={styles.devolution}>
        <BillDevolutionProducts
          products={productsList}
          onProductClick={addProductToDevolution}
          title="Lista de productos"
        />
        <div className={styles.divisor} />
        <BillDevolutionProducts
          products={devolutionsList}
          onProductClick={removeProductToDevolution}
          title="Productos por devolución"
          hasLengthValidation
          isRight
        />
      </div>

      <div className={styles.footer}>
        <UiButton
          text={`Devolver ${totalToDevolution}`}
          isDisabled={!devolutionsList.length}
          onClick={handleDevolutionClick}
        />
      </div>
    </div>
  );
}

export default BillDevolution;

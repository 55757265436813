import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { addMonths, format, getDaysInMonth, subMonths, startOfMonth, endOfMonth, isAfter, isBefore } from 'date-fns';
import { es } from 'date-fns/locale';

import styles from './ui-date-picker.module.sass';
import { DAYS_SI } from '@modules/reports/constants';
import useOutsideClickDetector from '../../hooks/use-outside-click-detector';

interface UiDatePickerProps {
  label: string;
  value?: Date;
  hasError?: boolean;
  error?: string;
  formatToShow?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date) => void;
  haveMaxDate?: boolean;
}

const UiDatePicker: FC<UiDatePickerProps> = ({
  label,
  value,
  hasError,
  error,
  minDate = new Date(2020, 0, 1),
  maxDate,
  formatToShow = 'dd-MM-yyyy',
  onChange,
  haveMaxDate = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectingYear, setIsSelectingYear] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(value || new Date());
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);
  const datePickerRef = useRef<HTMLDivElement>(null);
  useOutsideClickDetector(datePickerRef, () => setIsOpen(false));

  const isCurrentDay = (day: number) => {
    const currentDay = selectedDate?.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const isSameDay = currentDay === day;
    const isSameMonth = currentMonth === selectedDate?.getMonth();
    const isSameYear = currentYear === selectedDate?.getFullYear();
    return isSameDay && isSameMonth && isSameYear;
  }

  const isDisabledDay = (day: number) => {
    const newDate = new Date(currentDate.valueOf());
    newDate.setDate(day);
    return (maxDate && isAfter(newDate, maxDate)) || (minDate && (isBefore(newDate, minDate)));
  }

  const handleToggle = () => setIsOpen(o => !o);
  const handleSelectingYearToggle = () => {
    setIsSelectingYear(i => !i);
  }
  const handleSelectDate = (day: number) => {
    if (isDisabledDay(day)) return;
    const newDate = new Date(currentDate.valueOf());
    newDate.setDate(day);
    setIsOpen(false);
    setSelectedDate(newDate);
    onChange(newDate);
  }

  const handleBlockNextMonth = () => {
    const nextMonth = addMonths(currentDate, 1);
    if (maxDate && isAfter(nextMonth, maxDate)){
      return false
    } return true
  };

  const handleBlockPreivousMonth = () => {
    const previousMonth = subMonths(currentDate, 1);
    if (minDate && isBefore(previousMonth, minDate)){
      return false
    } return true
  };

  const handlePreviousMonth = () => {
    const previousMonth = subMonths(currentDate, 1);
  
    if (minDate && isBefore(previousMonth, minDate)) {
      return;
    }
  
    setCurrentDate(previousMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = addMonths(currentDate, 1);
  
    if (maxDate && isAfter(nextMonth, maxDate)) {
      return;
    }
  
    setCurrentDate(nextMonth);
  };

  const handleSelectYear = (year: number) => {
    const newDate = new Date();
    newDate.setFullYear(year);
    setCurrentDate(newDate);
    setIsSelectingYear(false);
  }

  const daysNumberBeforeCurrentMonthElements = () => {
    const previousMonth = subMonths(currentDate || new Date(), 1);
    const endOfPreviousMonth = endOfMonth(previousMonth);
    const day = format(endOfPreviousMonth, 'EEEEEE', { locale: es });
    const dayEndOfMonth = endOfPreviousMonth.getDate();
    const index = DAYS_SI.findIndex(d => d.toLowerCase() === day);
    return index >= 0 && (index + 1) !== DAYS_SI.length ? new Array(index + 1).fill(dayEndOfMonth - index).map((d, i) => (
      <div key={`day-number-before-${i}`} className={styles.cell}>
        <div className={`${styles.day} ${styles.disabled}`}>{d + i}</div>
      </div>
    )) : null;
  }
  const daysNumberOfCurrentMonthElements = () => {
    const daysNumber = getDaysInMonth(currentDate || new Date());
    return new Array(daysNumber).fill(daysNumber).map((d, i) => (
      <div key={`day-number-current-${i}`} className={`${styles.cell} ${isCurrentDay(i + 1) && styles.active} ${isDisabledDay(i + 1) && styles.isDisabled}`}>
        <div className={styles.day} onClick={() => handleSelectDate(i + 1)}>{i + 1}</div>
      </div>
    ));
  }
  const daysNumberAfterCurrentMonthElements = () => {
    const day = format(startOfMonth(addMonths(currentDate || new Date(), 1)), 'EEEEEE', { locale: es });
    const index = DAYS_SI.findIndex(d => d.toLowerCase() === day);
    return index > 0 ? new Array(DAYS_SI.length - index).fill(0).map((d, i) => (
      <div key={`day-number-after-${i}`} className={styles.cell}>
        <div className={`${styles.day} ${styles.disabled}`}>{i + 1}</div>
      </div>
    )) : null;
  }

  const yearsElements = () => {
    const currentYear = new Date().getFullYear();
    const yearsToShow = [currentYear];
    for (let index = 1; index <= 3; index++) {
      haveMaxDate && yearsToShow.push(currentYear + index);
      yearsToShow.unshift(currentYear - index);
    }
    return yearsToShow.map(y => (
      <div key={`year-number-${y}`} className={styles.year} onClick={() => handleSelectYear(y)}>{y}</div>
    ));
  }

  useEffect(() => {
    if (value) {
      setCurrentDate(value);
      setSelectedDate(value);
    }
  }, [value]);

  return (
    <div className={`${styles.container} ${hasError ? styles.error : ''}`} ref={datePickerRef}>
      {selectedDate && <label className={styles.label}>{label}</label>}
      {selectedDate ? (
        <span className={styles.text} onClick={handleToggle}>{format(selectedDate, formatToShow)}</span>
      ) : (
        <span className={`${styles.text} ${styles.placeholder}`} onClick={handleToggle}>{label}</span>
      )}
      <span className={`icon-calendar ${styles.icon}`} onClick={handleToggle} data-testid='ui-date-picker' />
      {isOpen && (
        <div className={styles.pickerDates}>
          <div className={styles.header}>
            <button className={`${!handleBlockPreivousMonth() ? styles.disabledIcon : styles.arrowContent} ${styles.left}`} onClick={handlePreviousMonth}>
              <span className={`icon-arrow ${styles.icon}`} />
            </button>
            <div className={styles.text} onClick={handleSelectingYearToggle}>
              <div className={styles.month}>{format(currentDate, 'LLLL', { locale: es })}</div>
              <div className={styles.year} data-testid='ui-date-picker-year'>{format(currentDate, 'yyyy', { locale: es })}</div>
              <span className={`icon-dropdown-arrow ${styles.icon}`} />
            </div>
            <button className={`${!handleBlockNextMonth() ? styles.disabledIcon : styles.arrowContent} ${styles.right}`} onClick={handleNextMonth}>
              <span className={`icon-arrow ${styles.icon}`} />
            </button>
          </div>
          <div className={styles.daysSelector}>
            {DAYS_SI.map(d => (<div key={`cell-day-${d}`} className={`${styles.cell} ${styles.dayName}`}>{d}</div>))}
            {daysNumberBeforeCurrentMonthElements()}
            {daysNumberOfCurrentMonthElements()}
            {daysNumberAfterCurrentMonthElements()}
          </div>
          {isSelectingYear && (
            <div className={styles.yearsSelector}>
              {yearsElements()}
            </div>
          )}
        </div>
      )}
      <span className={styles.errorMessage}>{error}</span>
    </div>
  );
}

export default memo(UiDatePicker);
import React, { FC, memo } from 'react';
import { formatMoney } from '@utils/format-money';
import { Bill } from '@modules/bills/entities';
import styles from './bill-detail-list.module.sass';
import { formatIndex } from '@modules/bills/utils';

interface BillDetailListProps {
  bill: Bill;
}

const BillDetailList: FC<BillDetailListProps> = ({ bill }) => {

  const getList = () => (
    bill.details.map(e => ({ ...e, deletedAt: !!e.deletedAt })).sort((a, b) => Number(a.deletedAt) - Number(b.deletedAt))
  );

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Lista de productos</p>

      <div className={styles.list}>
        {getList().map((e, i) => (
          <div className={`${styles.row} ${e.deletedAt && styles.devolution}`} key={`bill-detail-producto-${i}`}>
            <div>{formatIndex(+e.quantity)}</div>
            <div className={styles.name}>{e.coalesce}</div>
            <div>{formatMoney(+e.customerTotal)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(BillDetailList);

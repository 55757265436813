import {
  StoreInformation, BussiessData, Consecutive,
  Municipality, StoreType, UserInformation
} from "../entities";

export interface ProfileState {
  ui: ProfileUiState;
  inovicingInformation: InvoicingInformationState;
  profile: ProfileDataState;
  options: ProfileOptionsState;
  errors: ProfileErrorsState;
}

export interface ProfileUiState {
  isLoading: boolean;
  showChangePasswordModal: boolean;
  showNewConsecutiveModal: boolean;
  hasFieldOptions: boolean;
  hasProfileData: boolean;
  isRequestingProfile: boolean;
}

export interface ProfileErrorsState {
  getInvoicingInformation: boolean;
  getProfileData: boolean;
}

export interface InvoicingInformationState {
  businessData: BussiessData;
  consecutives: Consecutive[];
}

export interface ProfileDataState {
  userInformation: UserInformation;
  storeInformation: StoreInformation;
}

export interface ProfileOptionsState {
  municipalities: Municipality[];
  storeTypes: StoreType[];
  dailySales: string[];
}

export const initialState: ProfileState = {
  ui: {
    isLoading: false,
    showChangePasswordModal: false,
    showNewConsecutiveModal: false,
    hasFieldOptions: false,
    hasProfileData: false,
    isRequestingProfile: false,
  },
  inovicingInformation: {
    businessData: {} as BussiessData,
    consecutives: [] as Consecutive[]
  },
  profile: {
    userInformation: {} as UserInformation,
    storeInformation: {} as StoreInformation
  },
  options: {
    municipalities: [] as Municipality[],
    storeTypes: [] as StoreType[],
    dailySales: []
  },
  errors: {
    getInvoicingInformation: false,
    getProfileData: false,
  }
}

import React, { FC, memo, useState } from 'react';
import { addMonths, format, getDaysInMonth, subMonths, startOfMonth, endOfMonth, isAfter, isBefore, subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import styles from './ui-date-picker.module.sass';

const WEEK_DAYS: string[] = ['LU', 'MA', 'MI', 'JU', 'VI', 'SA', 'DO'];

interface UiDatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  onApply: (initDate?: Date, endDate?: Date) => void;
  onCancel: () => void;
}

const UiDatePicker: FC<UiDatePickerProps> = ({
  minDate = new Date(2020, 0, 1),
  maxDate,
  onCancel,
  onApply,
}) => {
  const [isSelectingYear, setIsSelectingYear] = useState<boolean>(false);
  const [pickerDate, setPickerDate] = useState<Date>(new Date());
  const [selectedInitDate, setSelectedInitDate] = useState<Date | undefined>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();
  

  const isSameDate = (day: number, date: Date) => (
    date.getDate() === day
    && date.getMonth() === pickerDate?.getMonth()
    && date.getFullYear() === pickerDate?.getFullYear()
  )

  const isInToDatesRange = (day: number) => {
    const currentDate = new Date(pickerDate.getFullYear(), pickerDate.getMonth(), day);
    return !!selectedInitDate && !!selectedEndDate
      && isAfter(currentDate, subDays(selectedInitDate, 1)) && isBefore(currentDate, selectedEndDate)
  }

  const isToday = (day: number) => isSameDate(day, pickerDate);

  const isInitDate = (day: number) => !!selectedInitDate && isSameDate(day, selectedInitDate);

  const isEndDate = (day: number) => !!selectedEndDate && isSameDate(day, selectedEndDate);

  const isSelectedDate = (day: number) => isInitDate(day) || isEndDate(day);

  const isDisabledDay = (day: number) => {
    const newDate = new Date();
    newDate.setDate(day);
    newDate.setMonth(pickerDate.getMonth());
    newDate.setFullYear(pickerDate.getFullYear());
    return (!!maxDate && isAfter(newDate, maxDate)) || (!!minDate && (isBefore(newDate, minDate)));
  }

  const handleBlockNextMonth = () => {
    const nextMonth = addMonths(pickerDate, 1);
    if (maxDate && isAfter(nextMonth, maxDate)){
      return false
    } return true
  };

  const handleBlockPreivousMonth = () => {
    const previousMonth = subMonths(pickerDate, 1);
    if (minDate && isBefore(previousMonth, minDate)){
      return false
    } return true
  };

  const handlePreviousMonth = () => {
    const previousMonth = subMonths(pickerDate, 1);
  
    if (minDate && isBefore(previousMonth, minDate)) {
      return;
    }
  
    setPickerDate(previousMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = addMonths(pickerDate, 1);
  
    if (maxDate && isAfter(nextMonth, maxDate)) {
      return;
    }
  
    setPickerDate(nextMonth);
  };

  const handleSelectingYearToggle = () => setIsSelectingYear(i => !i);

  const handleSelectDate = (day: number) => {
    if (isDisabledDay(day)) return;
    if (isInitDate(day)) return;
    const setDates = (init: Date, end?: Date) => {
      setSelectedInitDate(init);
      setSelectedEndDate(end);
    }
    const newDate = new Date(pickerDate.valueOf());
    newDate.setDate(day);

    if (!!selectedInitDate && !!selectedEndDate) setDates(newDate)
    else if (!selectedInitDate) setSelectedInitDate(newDate)
    else if (isAfter(newDate, selectedInitDate)) setSelectedEndDate(newDate)
    else setDates(newDate, selectedInitDate)

    setPickerDate(newDate);
  }

  const handleApplyClick = () => onApply(selectedInitDate, selectedEndDate)

  const handleSelectYear = (year: number) => {
    const newDate = new Date();
    newDate.setFullYear(year);
    setPickerDate(newDate);
    setIsSelectingYear(false);
  }

  const daysNumberBeforeCurrentMonthElements = () => {
    const previousMonth = subMonths(pickerDate || new Date(), 1);
    const endOfPreviousMonth = endOfMonth(previousMonth);
    const day = format(endOfPreviousMonth, 'EEEEEE', { locale: es });
    const dayEndOfMonth = endOfPreviousMonth.getDate();
    const index = WEEK_DAYS.findIndex(d => d.toLowerCase() === day);
    return index >= 0 && (index + 1) !== WEEK_DAYS.length ? new Array(index + 1).fill(dayEndOfMonth - index).map((d, i) => (
      <div key={`day-number-before-${i}`} className={styles.cell}>
        <div className={`${styles.day} ${styles.disabled}`}>{d + i}</div>
      </div>
    )) : null;
  }

  const daysNumberOfCurrentMonthElements = () => {
    const daysNumber = getDaysInMonth(pickerDate || new Date());
    return new Array(daysNumber).fill(daysNumber).map((d, i) => (
      <div
        key={`day-number-current-${i}`}
        className={`${styles.cell} 
          ${isToday(i + 1) && styles.today}
          ${isInToDatesRange(i + 1) && styles.range}
          ${isSelectedDate(i + 1) && styles.active}
          ${isDisabledDay(i + 1) && styles.isDisabled}
          ${(!!selectedEndDate && isInitDate(i + 1)) && styles.borderLeft}
          ${isEndDate(i + 1) && styles.borderRight}
        `}
      >
        <div className={styles.day} onClick={() => handleSelectDate(i + 1)}>{i + 1}</div>
      </div>
    ));
  }

  const daysNumberAfterCurrentMonthElements = () => {
    const day = format(startOfMonth(addMonths(pickerDate || new Date(), 1)), 'EEEEEE', { locale: es });
    const index = WEEK_DAYS.findIndex(d => d.toLowerCase() === day);
    return index > 0 ? new Array(WEEK_DAYS.length - index).fill(0).map((d, i) => (
      <div key={`day-number-after-${i}`} className={styles.cell}>
        <div className={`${styles.day} ${styles.disabled}`}>{i + 1}</div>
      </div>
    )) : null;
  }

  const yearsElements = () => {
    const currentYear = new Date().getFullYear();
    const yearsToShow = [currentYear];
    for (let index = 1; index <= 3; index++) {
      yearsToShow.push(currentYear - index);
    }
    return yearsToShow.map(y => (
      <div key={`year-number-${y}`} className={styles.year} onClick={() => handleSelectYear(y)}>{y}</div>
    ));
  }

  return (
    <div className={styles.wrapper} data-testid='ui-date-picker'>
      <div className={styles.header}>
        <span className={`icon-arrow ${!handleBlockPreivousMonth() ? styles.disabledArrow : styles.arrow}`} onClick={handlePreviousMonth} data-testid='ui-date-picker-arrow-1' />

        <div className={styles.text} onClick={handleSelectingYearToggle}>
          <span className={styles.month}>{format(pickerDate, 'LLLL', { locale: es })}</span>
          <span className={styles.year} data-testid='ui-date-picker-year'>{format(pickerDate, 'yyyy', { locale: es })}</span>
          <span className={`icon-dropdown-arrow ${styles.icon}`} />
        </div>

        <span className={`icon-arrow ${!handleBlockNextMonth() ? styles.disabledArrow : styles.arrow}`} onClick={handleNextMonth} />
      </div>

      <div className={styles.daysSelector}>
        {WEEK_DAYS.map(d => (<div key={`cell-day-${d}`} className={`${styles.cell} ${styles.dayName}`}>{d}</div>))}
        {daysNumberBeforeCurrentMonthElements()}
        {daysNumberOfCurrentMonthElements()}
        {daysNumberAfterCurrentMonthElements()}
      </div>
      {isSelectingYear && (
        <div className={styles.yearsSelector}>{yearsElements()}</div>
      )}
      <div className={styles.buttons}>
        <button className={styles.button} onClick={onCancel}>Cancelar</button>
        <button className={styles.button} disabled={!selectedInitDate} onClick={handleApplyClick}>
          Aplicar
        </button>
      </div>
    </div>
  );
}

export default memo(UiDatePicker);

import React, { FC, memo } from 'react';
import stopImage from '../../assets/img/stop-barrier.png';
import styles from './ui-no-data-message.module.sass';

interface UiNoDataMessageProps {
  message?: string;
}

const UiNoDataMessage: FC<UiNoDataMessageProps> = ({ message }) => {
  return (
    <div className={styles.wrapper}>
      <img src={stopImage} alt="Stop" className={styles.image} />

      <span className={styles.text}>
        Ha ocurrido un error
      </span>
      {message &&
        <span className={styles.text}>
          {message}
        </span>
      }
      <span className={styles.text}>
        Recarga la página o intentalo más tarde.
      </span>
    </div>
  );
}

export default memo(UiNoDataMessage);

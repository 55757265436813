import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as globalActions } from '@config/store';
import { useParams } from 'react-router-dom';
import { customersErrorsSelector, customerTransactionDetailSelector } from '@modules/customers/store';
import { formatMoney } from '@utils/format-money';
import { getTransactionResume } from '@modules/customers/utils';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import UiNoDataMessage from '@ui/ui-no-data-message';
import styles from './customer-sale-detail-mobile.module.sass';
import { PATH } from '@modules/customers/constants';
import Clevertap from '@utils/clevertap';
import { CUSTOMER_TRANSACTION_DETAIL } from '@constants';

interface CustomerSaleDetailMobileProps { }

const CustomerSaleDetailMobile: FC<CustomerSaleDetailMobileProps> = () => {
  const { customerId, transactionId } = useParams();
  const transaction = useSelector(customerTransactionDetailSelector);
  const errors = useSelector(customersErrorsSelector);
  const dispatch = useDispatch();
  const { getTransactionDetail } = useCustomersFetch();

  useEffect(() => {
    if (transactionId) getTransactionDetail(transactionId);
  }, [getTransactionDetail, transactionId]);

  useEffect(() => {
    Clevertap.pushSimpleEvent(CUSTOMER_TRANSACTION_DETAIL);
  }, []);

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: transaction[0]?.date,
      routeTo: `/${PATH}/${customerId}`
    }));
  }, [customerId, dispatch, transaction]);

  if (errors.getTransactionDetail) return <UiNoDataMessage message="No pudimos obtener el detalle de su factura" />;

  if (!transaction.length) return <div className={styles.loading}>Cargando...</div>;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h1 className={styles.title}>{transaction[0].transactionType}</h1>
        {getTransactionResume(transaction).map(item => (
          <div className={styles.item} key={`transaction-resume-item-${item.title}`}>
            <span className={styles.value}>{item.title}</span>
            <span className={styles.value}>{item.value}</span>
          </div>
        ))}
      </div>
      <div className={styles.products}>
        <h1 className={styles.title}>Productos</h1>
        {transaction.map((item, i) => (
          <div className={styles.item} key={`products-list-item-${i}`}>
            <div className={styles.left}>
              <span className={styles.name}>{item.name}</span>
              <span className={styles.quantity}>{item.quantity} Uds.</span>
            </div>
            <div className={styles.total}>{formatMoney(item.total, 0)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomerSaleDetailMobile;

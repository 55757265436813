export interface PhoneInterface {
  phoneCode: number,
  phone: string,
  send?: boolean
}

export interface AuthState {
  ui: AuthUiState;
  data: AuthDataState;
}

export interface AuthUiState {
  isLoadingLogin: boolean;
  showUserInputs: boolean;
  showPasswordInputs: boolean;
  passwordRecuperationPage: string;
}

export interface AuthDataState {
  phoneNumber: PhoneInterface,
  validationCode: string;
}

export const initialState: AuthState = {
  ui: {
    isLoadingLogin: false,
    showUserInputs: false,
    showPasswordInputs: false,
    passwordRecuperationPage: ''
  },
  data: {
    phoneNumber: {
      phoneCode: 0,
      phone: ''
    },
    validationCode: ''
  }
}

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentUserSelector, actions as globalActions } from "@config/store";
import { fetchCreateCollaborator, fetchDeleteCollaborator, fetchGetCollaborators, fetchUpdateCollaborator } from "@modules/collaborators/services";
import { actions, collaboratorsDataSelector, collaboratorsPaginationSelector } from "@modules/collaborators/store";
import { getNotificationMessage, getPermissionToUpdate } from "@modules/collaborators/utils";
import { CollaboratorForm } from "@modules/collaborators/entities";
import { PATH, TABS } from "@modules/collaborators/constants";
import Clevertap from "@utils/clevertap";
import { COLLABORATORS_GET_DATA, COLLABORATOR_CHANGE_PERMISSION, COLLABORATOR_CREATION, COLLABORATOR_DELETED, COLLABORATOR_EDITION } from "@constants";

const useCollaboratorsFetch = () => {
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const collaborators = useSelector(collaboratorsDataSelector);
  const pagination = useSelector(collaboratorsPaginationSelector);
  const navigate = useNavigate();

  const goBack = useCallback((): void => {
    navigate(`/${PATH}/${TABS.home}`)
  }, [navigate]);

  const getCollaborators = useCallback((page: number = 1, sort?): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCollaborators(page - 1, user.storeId, sort)
      .then((data) => {
        dispatch(actions.setCollaborators(data.employees));
        dispatch(actions.setTotalPages(data.totalPages));
        dispatch(actions.setGetCollaboratorError(false));
        dispatch(actions.setCurrentPage(page));
        dispatch(actions.setHasRequestedCollaborators(true));
        Clevertap.pushCheckSuccessEvent(COLLABORATORS_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setCollaborators([]));
        dispatch(actions.setGetCollaboratorError(true));
        Clevertap.pushCheckSuccessEvent(COLLABORATORS_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user.storeId]);

  const changePermissionState = useCallback((collaboratorId: string, permissionId, isActivation: boolean): void => {
    const collaborator = collaborators.find((e) => e.id === collaboratorId);
    if (collaborator) {
      const permissions = getPermissionToUpdate(collaborator, permissionId);
      dispatch(actions.setIsLoading(true));
      fetchUpdateCollaborator(collaborator, permissions)
        .then(() => {
          Clevertap.pushEvent(COLLABORATOR_CHANGE_PERMISSION, { permission: permissionId });
          dispatch(globalActions.notification({
            show: true,
            state: isActivation ? 'success' : 'error',
            message: getNotificationMessage(collaborator.name, permissionId, isActivation)
          }));
          dispatch(actions.setCollaborators(collaborators.map(item => item.id === collaborator.id ? {
            ...item,
            employeePermission: permissions.map(p => { return { id: p } })
          } : item)));
        })
        .catch((error) => {
          dispatch(globalActions.notification({
            show: true,
            state: 'error',
            message: 'Lo sentimos. No pudimos actualizar el permiso'
          }));
          Clevertap.pushCheckSuccessEvent(COLLABORATOR_CHANGE_PERMISSION, error?.message);
        })
        .finally(() => dispatch(actions.setIsLoading(false)));
    }
  }, [collaborators, dispatch]);

  const updateCollaborator = useCallback((collaborator: CollaboratorForm, permissions: string[]): void => {
    dispatch(actions.setIsLoading(true));
    fetchUpdateCollaborator(collaborator, permissions)
      .then(() => {
        const oldCollaborator = collaborators.find(e => e.id === collaborator.id);
        Clevertap.pushEvent(COLLABORATOR_EDITION, {
          hasNewName: oldCollaborator?.name !== collaborator.name,
          hasNewRole: oldCollaborator?.roleId !== collaborator.roleId,
          hasNewPhone: oldCollaborator?.phone !== collaborator.phone,
          hasNewDocumentType: oldCollaborator?.documentTypeId !== collaborator.documentTypeId,
          hasNewDocument: oldCollaborator?.document !== collaborator.document,
        });
        dispatch(actions.setCollaborators(collaborators.map(item => item.id === collaborator.id ? {
          ...item,
          document: collaborator.document,
          phone: collaborator.phone,
          roleId: collaborator.roleId,
          name: collaborator.name,
          employeePermission: permissions.map(p => { return { id: p } })
        } : item)));
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `${collaborator.name} ha sido modificado exitosamente`
        }));
        goBack();
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. Ha ocurrido un error. No se pudo actualizar el colaborador'
        }));
        Clevertap.pushCheckSuccessEvent(COLLABORATOR_EDITION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [collaborators, dispatch, goBack]);

  const createCollaborator = useCallback((newCollaborator: CollaboratorForm, permissions: string[]): void => {
    dispatch(actions.setIsLoading(true));
    fetchCreateCollaborator(newCollaborator, permissions)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Colaborador ${newCollaborator.name} creado exitosamente`
        }));
        getCollaborators();
        goBack();
        Clevertap.pushCheckSuccessEvent(COLLABORATOR_CREATION);
      })
      .catch(({ response }) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: response?.data.data?.message || 'Ha ocurrido un error. No se pudo crear el colaborador'
        }));
        Clevertap.pushCheckSuccessEvent(COLLABORATOR_CREATION, response?.data.data?.message || response.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, getCollaborators, goBack]);

  const deleteCollaborator = useCallback((collaboratorId: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchDeleteCollaborator(collaboratorId)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Su colaborador ha sido eliminado correctamente`
        }));
        dispatch(actions.setShowDeleteCollaboratorModal(false));
        getCollaborators(pagination.currentPage);
        goBack();
        Clevertap.pushCheckSuccessEvent(COLLABORATOR_DELETED);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No se pudo eliminar el colaborador'
        }));
        Clevertap.pushCheckSuccessEvent(COLLABORATOR_DELETED, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, getCollaborators, goBack, pagination.currentPage]);

  return {
    getCollaborators,
    changePermissionState,
    updateCollaborator,
    createCollaborator,
    deleteCollaborator
  }
}

export default useCollaboratorsFetch;

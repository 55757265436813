import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { inventoryErrorsSelector, inventoryUiSelector, productDetailSelector } from '@modules/inventory/store';
import styles from './product-detail.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message';
import ProductForm from '@modules/inventory/components/product-form';
import useProductFetch from '@modules/inventory/hooks/use-product-fetch';
import Clevertap from '@utils/clevertap';
import { PRODUCT_DETAIL_OPEN, PRODUCT_DURATION } from '@constants';

interface ProductDetailProps { }

const ProductDetail: FC<ProductDetailProps> = () => {
  const { isLoading } = useSelector(inventoryUiSelector);
  const product = useSelector(productDetailSelector);
  const errors = useSelector(inventoryErrorsSelector);
  const { sku } = useParams();
  const { getProduct } = useProductFetch();

  useEffect(() => {
    if (!product.sku && sku) getProduct(sku);
  }, [getProduct, product.sku, sku]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(PRODUCT_DETAIL_OPEN, { sku: String(sku) });
    return () => Clevertap.pushDurationEvent(PRODUCT_DURATION, openedAt);
  }, [sku]);

  if (errors.getProductDetail && !isLoading) return <UiNoDataMessage message='No pudimos hallar este producto' />;

  if (!product.sku && isLoading) return <div className={styles.loading}>Cargando...</div>;

  return (
    <div className={styles.container}>
      <ProductForm />
    </div>
  );
}

export default ProductDetail;

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsWithLowTurnover } from '../../services/reports.services';
import { useIsMounted } from '@utils/use-is-mounted';
import { actions, reportsDatesFilterSelector, reportsSlowTurnoverSelector, reportsUiSelector } from '@modules/reports/store';
import Clevertap from '@utils/clevertap';
import { REPORTS_GET_LOW_TURNOVER_DATA, REPORTS_LOW_TURNOVER_DURATION, REPORTS_LOW_TURNOVER_SECTION } from '@constants';
import styles from './report-slow-turnover.module.sass';
import ReportsTable from '../../components/reports-table';
import ReportsPageNoData from '../../components/reports-page-no-data';
import Loader from '@ui/ui-loader';

interface ReportSlowTurnoverProps { }

const ReportSlowTurnover: FC<ReportSlowTurnoverProps> = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { isLoadingSlowTurnover } = useSelector(reportsUiSelector);
  const { products } = useSelector(reportsSlowTurnoverSelector);
  const { startDate, finishDate } = useSelector(reportsDatesFilterSelector);

  const getOrdersLowTurnover = useCallback((dates: { initDate: string, endDate: string }) => {
    const args = { startDate: dates.initDate, finishDate: dates.endDate };
    dispatch(actions.setIsLoadingSlowTurnoverData(true));
    fetchProductsWithLowTurnover(args)
      .then((response) => {
        if (isMounted.current) {
          dispatch(actions.setSlowTurnoverData({ products: response }));
          dispatch(actions.setIsLoadingSlowTurnoverData(false));
        }
        Clevertap.pushCheckSuccessEvent(REPORTS_GET_LOW_TURNOVER_DATA);
      }).catch((error) => {
        if (isMounted.current) {
          dispatch(actions.setSlowTurnoverData({ products: [] }));
          dispatch(actions.setIsLoadingSlowTurnoverData(false));
        }
        Clevertap.pushCheckSuccessEvent(REPORTS_GET_LOW_TURNOVER_DATA, error?.message);
      });
  }, [isMounted, dispatch]);

  const hasProductsDataToShow = (): boolean => !!products.length;

  useEffect(() => {
    if (startDate && finishDate) {
      getOrdersLowTurnover({ initDate: startDate, endDate: finishDate });
    }
  }, [startDate, finishDate, getOrdersLowTurnover]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(REPORTS_LOW_TURNOVER_SECTION);
    return () => Clevertap.pushDurationEvent(REPORTS_LOW_TURNOVER_DURATION, openedAt);
  }, []);

  const handleLoading = () => {
    if (isLoadingSlowTurnover) {
      return 'Cargando'
    }
    return 'No hay registro de ventas durante este periodo'
  }

  return (
    <div className={styles.wrapper}>
      {isLoadingSlowTurnover && <Loader />}
      { !hasProductsDataToShow() ? (
        <ReportsPageNoData>
          {handleLoading()}
        </ReportsPageNoData>
      ) : (
        <>
          <div className={styles.charts}>
            <div className={styles.chart}>
              <ReportsTable products={products} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportSlowTurnover;

import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from '@config/store';
import CustomerTransaction from '@modules/customers/components/customer-transaction';
import styles from './customer-loan-transaction-mobile.module.sass';
import { PATH } from '@modules/customers/constants';

interface CustomerLoanTransactionMobileProps { }

const CustomerLoanTransactionMobile: FC<CustomerLoanTransactionMobileProps> = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: 'Prestamo',
      routeTo: `/${PATH}/${customerId}`
    }));
  }, [customerId, dispatch]);
  
  return (
    <div className={styles.container}>
      <CustomerTransaction />
    </div>
  );
}

export default CustomerLoanTransactionMobile;

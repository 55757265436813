import React, { FC, useEffect } from 'react';
import ProvidersFilters from '@modules/providers/components/providers-filters';
import styles from './providers-home.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { actions, providersUiSelector } from '@modules/providers/store';
import useProvidersFetch from '@modules/providers/hooks/use-providers-fetch';
import ProvidersTable from '@modules/providers/components/providers-table';
import ProvidersForm from '@modules/providers/components/providers-form';
import Clevertap from '@utils/clevertap';
import { PROVIDERS_DURATION, PROVIDERS_OPEN } from '@constants';

interface ProvidersProps { }

const ProvidersHome: FC<ProvidersProps> = () => {
  const { showProvidersFormModal } = useSelector(providersUiSelector);
  const dispatch = useDispatch();
  const { getProviders } = useProvidersFetch();

  useEffect(() => {
    getProviders();
    return () => { dispatch(actions.setProviders([])) };
  }, [dispatch, getProviders]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(PROVIDERS_OPEN);
    return () => Clevertap.pushDurationEvent(PROVIDERS_DURATION, openedAt);
  }, []);

  return (
    <div className={styles.container}>

      {showProvidersFormModal && <ProvidersForm />}

      <ProvidersFilters />

      <ProvidersTable />

    </div>
  )
}

export default ProvidersHome;

import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { actions, consecutivesSelector, profileUiSelector } from '@modules/profile/store';
import { DATE_FORMAT } from '@modules/profile/constants';
import NewConsecutiveModal from '../new-consecutive-modal';
import UiButton from '@ui/ui-button';
import styles from './consecutives.module.sass';

interface ConsecutivesProps { }

const Consecutives: FC<ConsecutivesProps> = () => {
  const consecutives = useSelector(consecutivesSelector);
  const { showNewConsecutiveModal } = useSelector(profileUiSelector);
  const dispatch = useDispatch();

  const handleOpenModalClick = useCallback((): void => {
    dispatch(actions.setShowNewConsecutiveModal(true));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      {showNewConsecutiveModal && <NewConsecutiveModal />}

      <h1 className={styles.title}>Consecutivos</h1>

      <div className={styles.tableWrapper}>
        <table className={styles.table} cellSpacing='0'>
          <thead>
            <tr className={styles.row}>
              <th className={styles.header}>Expedición</th>
              <th className={styles.header}>Prefijo</th>
              <th className={styles.header}>Vigencia</th>
            </tr>
          </thead>
          <tbody>
            {consecutives.map((item, i) => (
              <tr key={`consecutive-item-row-${String(i)}`} className={styles.row}>
                <td className={styles.cell}>{format(new Date(item.dianAuthDateExp), DATE_FORMAT)}</td>
                <td className={styles.cell}>{item.prefix}</td>
                <td className={styles.cell}>{format(new Date(item.dianAuthDate), DATE_FORMAT)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UiButton text='Agregar nuevo' isFullWidth onClick={handleOpenModalClick} />
    </div>
  );
}

export default Consecutives;

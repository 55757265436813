import React, { FC, memo } from 'react';
import { format, isAfter, isEqual, subDays,  } from 'date-fns';

import styles from './reports-filters.module.sass';
import { ReportsTabs } from '../../constants';
import { DatesFilter } from '@modules/reports/entities';
import UiButton from '@ui/ui-button';
import UiDatePicker from '@ui/ui-date-picker';

interface ReportsFiltersProps {
  currentTab: string;
  initDate: string;
  endDate: string;
  onChangeTab: (tab: ReportsTabs) => void;
  onChangeDates: (dates: DatesFilter) => void;
  onClickBillsButton: () => void;
  onClickResumeButton: () => void;
}

const ReportsFilters: FC<ReportsFiltersProps> = ({
  currentTab,
  initDate,
  endDate,
  onChangeDates,
  onClickBillsButton,
  onClickResumeButton,
  onChangeTab,
}) => {
  const handleStartDateChange = (v: Date): void => {
    onChangeDates({ startDate: format(v, 'MM-dd-yyyy'), finishDate: endDate });
  };

  const handleEndDateChange = (v: Date): void => {
    const date = new Date(initDate.replace(/-/g, '/'));
    if (isAfter(v, date) || isEqual(v, date)) {
      onChangeDates({ startDate: initDate, finishDate: format(v, 'MM-dd-yyyy') });
    } else {
      onChangeDates({ startDate: format(subDays(new Date(v), 1), 'MM-dd-yyyy'), finishDate: format(v, 'MM-dd-yyyy') });
    }
  };

  const handleChangeTab = (tab: ReportsTabs): void => {
    if (tab !== currentTab) onChangeTab(tab);
  };

  const isTabActive = (tab: string): boolean => tab === currentTab;

  return (
    <div className={styles.wrapper}>
      <div className={styles.datePickersButtons}>
        <div className={styles.pickers}>
          <UiDatePicker 
            label='Fecha inicio'
            value={initDate ? new Date(initDate.replace(/-/g, '/')) : undefined}
            maxDate={endDate ? new Date(endDate.replace(/-/g, '/')) : new Date()}
            onChange={handleStartDateChange}
            haveMaxDate={false}
          />
          <UiDatePicker 
            label='Fecha Fin'
            value={endDate ? new Date(endDate.replace(/-/g, '/')) : undefined}
            minDate={initDate ? new Date(initDate.replace(/-/g, '/')) : new Date()}
            maxDate={new Date()}
            onChange={handleEndDateChange}
            haveMaxDate={false}
          />
        </div>
        <UiButton onClick={onClickBillsButton} text="Descargar facturas" />
        <UiButton onClick={onClickResumeButton} text="Descargar resumen" />
      </div>
      <div className={styles.tabs}>
        <div 
          className={`${styles.tab} ${isTabActive(ReportsTabs.Sales) && styles.active}`}
          onClick={() => handleChangeTab(ReportsTabs.Sales)}
        >
          Ventas
        </div>
        <div 
          className={`${styles.tab} ${isTabActive(ReportsTabs.Transactions) && styles.active}`}
          onClick={() => handleChangeTab(ReportsTabs.Transactions)}
        >
          Transacciones
        </div>
        <div 
          className={`${styles.tab} ${isTabActive(ReportsTabs.Skus) && styles.active}`}
          onClick={() => handleChangeTab(ReportsTabs.Skus)}
        >
          SKUs
        </div>
        <div 
          className={`${styles.tab} ${isTabActive(ReportsTabs.SlowTurnover) && styles.active}`}
          onClick={() => handleChangeTab(ReportsTabs.SlowTurnover)}
        >
          Baja rotación
        </div>
      </div>
    </div>
  );
};
 
export default memo(ReportsFilters);

import { Bill, BillDetail } from "@modules/bills/entities";

export const mockBillDetail: BillDetail[] = [
  {
    id: "27141878",
    orderId: "15138093",
    customerTotal: "5000",
    quantity: "1",
    coalesce: "Shampoo",
    soldByWeight: false,
    sku: "877343830007660544"
  },
  {
    id: "27141870",
    orderId: "15138093",
    customerTotal: "7000",
    quantity: "2",
    coalesce: "Alfajores",
    soldByWeight: false,
    sku: "877343830007660544"
  }
]

export const mockBills: Bill[] = [
  {
    id: "15138096",
    uid: "1021788878120398848",
    total: "5000.000000",
    subtotal: "5000.000000",
    totalTaxes: "0",
    date: "20/09/2022",
    name: "",
    paymentMethod: "Efectivo",
    phone: "",
    deletedAt: null,
    transactionDate: "20 de sep. de 2022",
    transactionHour: "9:24",
    type: "Compra",
    details: mockBillDetail
  }
]

export const mockBillDevolution = [
  {
    orderId: '15138096',
    userId: '1021788878120398848',
    type: 'PARCIAL',
    details: mockBillDetail
  }
]

export const mockGetOrdersResponse = [
  {
    "id": "15138096",
    "uid": "1021788878120398848",
    "total": "5000.000000",
    "subtotal": "5000.000000",
    "totalTaxes": "0",
    "date": "20/09/2022",
    "name": "",
    "paymentMethod": "Efectivo",
    "phone": "",
    "deletedAt": null,
    "transactionDate": "20 de sep. de 2022",
    "transactionHour": "9:24",
    "type": "Compra",
    "details": [
      {
        "id": "27141882",
        "orderId": "15138096",
        "customerTotal": "5000.000000",
        "quantity": "1",
        "coalesce": "newnew",
        "soldByWeight": false,
        "sku": "877343830007660544"
      }
    ]
  },
  {
    "id": "15138084",
    "uid": "1021244692221063168",
    "total": "3500.000000",
    "subtotal": "2941.180000",
    "totalTaxes": "558",
    "date": "18/09/2022",
    "name": "",
    "paymentMethod": "Efectivo",
    "phone": "",
    "deletedAt": null,
    "transactionDate": "18 de sep. de 2022",
    "transactionHour": "21:21",
    "type": "Nequi",
    "details": [
      {
        "id": "27141863",
        "orderId": "15138084",
        "customerTotal": "3500.000000",
        "quantity": "1",
        "coalesce": "Cocucha",
        "soldByWeight": false,
        "sku": "712008273645281"
      }
    ]
  },
  {
    "id": "15138003",
    "uid": "1015305341855264768",
    "total": "3500.000000",
    "subtotal": "0.000000",
    "totalTaxes": "3500",
    "date": "02/09/2022",
    "name": "Jeferson Salazar",
    "phone": "3106507828",
    "deletedAt": null,
    "transactionDate": "2 de sep. de 2022",
    "transactionHour": "12:10",
    "type": "Fiado",
    "details": [
      {
        "id": "27141775",
        "orderId": "15138003",
        "customerTotal": "3500.000000",
        "quantity": "1",
        "coalesce": "Cocucha",
        "soldByWeight": false,
        "sku": "712008273645281"
      }
    ]
  },
  {
    "id": "15138058",
    "uid": "1016726941961617408",
    "total": "52.000000",
    "subtotal": "52.000000",
    "totalTaxes": "0",
    "date": "06/09/2022",
    "name": "",
    "paymentMethod": "Efectivo",
    "phone": "",
    "deletedAt": null,
    "transactionDate": "6 de sep. de 2022",
    "transactionHour": "10:09",
    "type": "Pago con tarjeta",
    "details": []
  }
]

export const mockProduct = {
  id: '7701345678999',
  name: 'Coca-Cola 250ml',
  price: 10000
}

export const mockInfoBill = {
  name: 'Tienda Donde Betty',
  address: 'Calle 80d # 73Sur-25 / Bogotá',
  phone: '3202346677',
  nit: '12345678',
  rfc: 'JIOM971003LS1',
  message: 'Gracias por tu compra, para domicilios contactar via whatsapp al 320 234 6677',
}

export const mockInfoBilEmpty = {
  name: '',
  address: '',
  phone: '',
  nit: '',
  rfc: '',
  message: '',
}


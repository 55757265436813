import React, { ChangeEventHandler, FC, memo } from 'react';
import { UiSelectOption } from '../../entities';
import styles from './ui-select.module.sass';

interface UiSelectProps {
  label: string;
  name: string;
  value: string;
  defaultValue: string;
  hasError?: boolean;
  disabled?: boolean;
  error?: string;
  options: UiSelectOption[];
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: ChangeEventHandler<HTMLSelectElement>;
  isMedium?: boolean;
}

const UiSelect: FC<UiSelectProps> = ({
  name,
  value,
  onChange,
  defaultValue,
  label,
  options,
  disabled,
  error,
  hasError,
  onBlur,
  isMedium,
}) => {
  return (
    <div className={`${styles.container} ${hasError ? styles.error : ''} ${disabled && styles.disabled}`}>
      {String(value) && <label className={styles.label}>{label}</label>}

        <select
          className={`${styles.select} ${isMedium && styles.medium}`}
          onChange={onChange}
          name={name}
          value={value}
          onBlur={onBlur}
          disabled={disabled}
        >
          <option value="" disabled>{defaultValue}</option>
          {options && options.map((option, i) => (
            <option value={option.value} key={`select-${name}-option-${String(i)}`}>
              {option.title}
            </option>
          ))}
        </select>
      <span className={`icon-dropdown-arrow ${styles.icon}`} />
      <span className={styles.errorMessage}>{error}</span>
    </div>
  );
};

export default memo(UiSelect);

import React, { FC, useCallback, useEffect } from 'react';
import styles from './product-discounts-form.module.sass';
import UiButton from '@ui/ui-button';
import UiInputMoney from '@ui/ui-input-money';
import UiInputField from '@ui/ui-input-field';
import { useDispatch, useSelector } from 'react-redux';
import { actions, currentDiscountSelector, inventoryUiSelector, productDetailSelector } from '@modules/inventory/store';
import UiDatePicker from '@ui/ui-date-picker';
import { RECURRENCY_DAYS } from '@modules/inventory/constants';
import { useFormik } from 'formik';
import { changeRecurrency, getForm } from '@modules/inventory/utils';
import { ProductDiscountForm } from '@modules/inventory/entities';
import { productDiscountSchema } from '@modules/inventory/schemas';
import useDiscountsFetch from '@modules/inventory/hooks/use-discounts-fetch';

interface ProductDiscountsFormProps { }

const ProductDiscountsForm: FC<ProductDiscountsFormProps> = () => {
  const product = useSelector(productDetailSelector);
  const currentDiscount = useSelector(currentDiscountSelector);
  const { showDatePickers, isUpdatingDiscount } = useSelector(inventoryUiSelector);
  const dispatch = useDispatch();
  const { createDiscount, updateDiscount } = useDiscountsFetch();

  useEffect(() => {
    dispatch(actions.setShowDatePickers(false));
  }, [dispatch]);

  const handleShowDatePickers = useCallback((): void => {
    dispatch(actions.setShowDatePickers(!showDatePickers));
  }, [dispatch, showDatePickers]);

  const handleSubmit = useCallback((values: ProductDiscountForm, { resetForm }): void => {
    if (isUpdatingDiscount) updateDiscount(values)
    else createDiscount(values);
    resetForm({ values: getForm(product) })
  }, [createDiscount, isUpdatingDiscount, product, updateDiscount]);

  const form = useFormik({
    initialValues: getForm(product, currentDiscount),
    onSubmit: handleSubmit,
    validationSchema: productDiscountSchema,
  });

  const handleChangeInitDate = useCallback((date: Date): void => {
    form.setFieldValue('initAt', date)
  }, [form]);

  const handleChangeEndDate = useCallback((date: Date): void => {
    form.setFieldValue('endAt', date)
  }, [form]);

  const changeRecurrencyDay = (id: string): void => {
    form.setFieldValue('recurrency', changeRecurrency(form.values.recurrency, id))
  };

  const isRecurrentDay = (id: string): boolean => {
    return !!form.values.recurrency.find(day => day.scheduleId === id);
  };

  const isNextButtonDisabled = (): boolean => {
    return !!form.errors.customerDiscountPrice || !!form.errors.quantity || !form.values.customerDiscountPrice
  };

  const handleCloseUpdate = (): void => {
    dispatch(actions.setIsUpdatingDiscount(false));
    dispatch(actions.setShowDatePickers(false));
    form.resetForm({ values: getForm(product) })
  };

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      {isUpdatingDiscount && (
        <span className={`icon-close ${styles.icon} ${styles.closeIcon}`} onClick={handleCloseUpdate} />
      )}

      {showDatePickers && (
        <span className={`icon-arrow ${styles.icon} ${styles.backIcon}`} onClick={handleShowDatePickers} />
      )}

      <span className={styles.title}>
        {isUpdatingDiscount ? `ID ${currentDiscount.id}` : 'Nuevo descuento'}
      </span>

      <div className={styles.inputsWrapper}>
        {!showDatePickers ? (
          <>
            <div className={styles.inputs}>
              <div className={styles.input}>
                <UiInputMoney
                  name='customerDiscountPrice'
                  label='Ingresa precio'
                  value={form.values.customerDiscountPrice}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  error={form.errors.customerDiscountPrice}
                  hasError={!!(form.errors.customerDiscountPrice && form.touched.customerDiscountPrice)}
                />
              </div>
              <div className={styles.input}>
                <UiInputField
                  name='quantity'
                  label='Límite de unidades (Opcional)'
                  type='text'
                  value={form.values.quantity}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  error={form.errors.quantity}
                  hasError={!!(form.errors.quantity && form.touched.quantity)}
                />
              </div>
            </div>
            <UiButton text='Siguiente' isFullWidth onClick={handleShowDatePickers} isDisabled={isNextButtonDisabled()} />
          </>
        ) : (
          <>
            <div className={styles.inputs}>
              <div className={styles.input}>
                <UiDatePicker
                  label='Fecha inicio'
                  value={form.values.initAt}
                  onChange={handleChangeInitDate}
                  hasError={!!form.errors.initAt}
                  error={String(form.errors.initAt)}
                />
              </div>
              <div className={styles.input}>
                <UiDatePicker
                  label='Fecha Fin'
                  value={form.values.endAt}
                  onChange={handleChangeEndDate}
                  hasError={!!(form.errors.endAt && form.values.endAt)}
                  error={form.errors.endAt}
                />
              </div>
            </div>
            <div className={styles.recurrency}>
              Descuento Recurrente
              <div className={styles.daysWrapper}>
                {RECURRENCY_DAYS.map((day) => (
                  <div
                    key={`recurrency-day-${day.id}`}
                    className={`${styles.day} ${isRecurrentDay(day.id) && styles.active}`}
                    onClick={() => { changeRecurrencyDay(day.id) }}
                  >
                    {day.title}
                  </div>
                ))}
              </div>
            </div>
            <UiButton text='Guardar' isFullWidth type='submit' isDisabled={!form.isValid || !form.dirty} />
          </>
        )}
      </div>
    </form>
  )
}

export default ProductDiscountsForm;

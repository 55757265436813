import axios from 'axios';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions as globalActions, currentUserSelector } from '@config/store';
import { Permission, UserPermissions } from '@entities';
import {
  ACCESS_POS_WEB_PERMISSION_ID, EDIT_INVENTORY_PERMISSION_ID, EDIT_PURCHASE_PRICE_PERMISSION_ID,
  EDIT_SALE_PRICE_PERMISSION_ID, OWNER_ROLE_ID, STORAGE_TOKEN, VIEW_CASH_DECK_CONTROL_PERMISSION_ID
} from '@constants';

const useUserPermissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useRef(useSelector(currentUserSelector));
  const hasBeenRequested = useRef(false);

  const getPermissions = useCallback((): void => {
    if (!hasBeenRequested.current && user.current.roleId !== OWNER_ROLE_ID) {
      const permissions: UserPermissions = {
        canViewClosuresHistory: false,
        canEditInventorySalePrice: false,
        canEditInventoryQuantities: false,
        canEditInventoryPurchasePrice: false,
      };
      axios.get<Permission[]>(`employee/permissions/${user.current.id}`)
        .then(({ data }) => {
          if (!data.find(e => e.id === ACCESS_POS_WEB_PERMISSION_ID)) {
            localStorage.removeItem(STORAGE_TOKEN);
            navigate('/login');
            dispatch(globalActions.notification({
              show: true,
              state: 'error',
              message: 'Lo sentimos. Ya no tienes autorización para ingresar al administrador.'
            }));
          } else {
            data.forEach(e => {
              switch (e.id) {
                case EDIT_PURCHASE_PRICE_PERMISSION_ID:
                  permissions.canEditInventoryPurchasePrice = true;
                  break;
                case EDIT_INVENTORY_PERMISSION_ID:
                  permissions.canEditInventoryQuantities = true;
                  break;
                case EDIT_SALE_PRICE_PERMISSION_ID:
                  permissions.canEditInventorySalePrice = true;
                  break;
                case VIEW_CASH_DECK_CONTROL_PERMISSION_ID:
                  permissions.canViewClosuresHistory = true;
                  break;
                default: break;
              }
            });
          }
        })
        .catch(() => dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'No pudimos gestionar sus permisos. Por precaución permanecerán deshabilitados'
        })))
        .finally(() => {
          dispatch(globalActions.setUserPermissions(permissions))
          hasBeenRequested.current = true;
        });
    }
  }, [dispatch, navigate]);

  return getPermissions
};

export default useUserPermissions;

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as globalActions } from '@config/store';
import { actions, customerDetailSelector } from '@modules/customers/store';
import { PATH } from '@modules/customers/constants';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import { useParams } from 'react-router-dom';
import styles from './customer-detail-mobile.module.sass';
import CustomerDetailInfo from '@modules/customers/components/customer-detail-mobile-info';
import CustomerDetailButtons from '@modules/customers/components/customer-detail-mobile-buttons';
import CustomerTransactions from '@modules/customers/components/customer-detail-mobile-transactions';
import { formatMoney } from '@utils/format-money';

interface CustomerDetailMobileProps { }

const CustomerDetailMobile: FC<CustomerDetailMobileProps> = () => {
  const dispatch = useDispatch();
  const customer = useSelector(customerDetailSelector);
  const { customerId } = useParams();
  const { getCustomerDetail } = useCustomersFetch();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: 'Fiar',
      routeTo: `/${PATH}`
    }));
  }, [dispatch]);
    
  useEffect(() => {
    if (!customer.id && customerId) { 
      getCustomerDetail(customerId);
    }
    dispatch(actions.setTransactionDetail([]));
  }, [customer.id, customerId, dispatch, getCustomerDetail]);

  if (!customer.id) return <div />

  return (
    <div className={styles.wrapper}>

      <CustomerDetailInfo />

      <div className={styles.debtsInfo}>
        <div className={styles.debts}>Deudas</div>
        <div className={styles.total}> 
          <span className={styles.sign}>{customer.loans - customer.payments > 0 && '-'}$</span> 
          <span className={styles.number}>{formatMoney(customer.loans - customer.payments, 0, false)}</span> 
        </div>
      </div>

      <CustomerDetailButtons />

      <CustomerTransactions />

    </div>
  )
}

export default CustomerDetailMobile;
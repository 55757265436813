import React, { FC, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, authDataSelector } from "@modules/auth/store";
import useSendOtp from "@modules/auth/hooks/use-send-otp";
import { useTranslation } from "react-i18next";
import { PHONE } from "@modules/auth/constants";
import styles from "./password-recuperation-code.module.sass"; 
import UiTokenInput from "@ui/ui-token-input";
import useSendPhone from "@modules/auth/hooks/use-send-phone";

interface PasswordRecuperationCodeProps { }

const PasswordRecuperationCode: FC<PasswordRecuperationCodeProps> = () => {
  const [ inputValue, setInputValue ] = useState<string>("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { phoneNumber } = useSelector(authDataSelector);
  const { sendUserOtp } = useSendOtp();
  const { sendUserPhone } = useSendPhone();

  const handleInputChange = (value: any) => {
    setInputValue(value);
  };

  const handleBackPage = () => {
    setInputValue('');
    dispatch(actions.setPasswordRecuperationPage(PHONE));
  };

  const handleBlockButton = () => {
    if ( inputValue.length !== 4 ) {
      return true
    } return false
  };

  const handleSendAntotherCode = () => {
    sendUserPhone(phoneNumber.phone, phoneNumber.phoneCode, true)
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch(actions.setValidationCode(inputValue));
    sendUserOtp(inputValue, phoneNumber.phone, phoneNumber.phoneCode);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.messageWrapper}>
        <span className={styles.message}> {t(`Ingresa el código de verificación que enviamos por mensaje de texto al número +${phoneNumber.phoneCode} ${phoneNumber.phone}`)} </span>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <UiTokenInput value={inputValue} onChange={handleInputChange} error={"Debes poner los 4 digitos"}/>
        <button type='submit' className={styles.button} data-testid='login-button' disabled={handleBlockButton()}/>
      </form>

      <div className={styles.resend}>
        <span className={styles.message}> {t('¿No recibiste ningún código?')} </span>
        <button className={styles.button} onClick={() => handleSendAntotherCode()}> {t('Reenviar código')} </button>
      </div>
      
      <button className={styles.back} onClick={() => handleBackPage()} data-testid='back-button'>
        {t('Cambiar el número de celular')}
      </button>
    </div>
  )
}

export default PasswordRecuperationCode;
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, closureSelectedSelector, closuresHistorySelector } from "../store";
import useGetClosure from "./use-get-closure";

const useLastClosure = () => {
  const dispatch = useDispatch();
  const hasLastClosure = useRef(true);
  const { getClosure } = useGetClosure();
  const history = useSelector(closuresHistorySelector);
  const closureSelected = useSelector(closureSelectedSelector);

  useEffect(() => {
    dispatch(actions.setIsLoadingClosure(hasLastClosure.current));
  }, [dispatch]);

  useEffect(() => {
    if (hasLastClosure.current && !!history.length) {
      getClosure(closureSelected ? closureSelected : +history[0].balanceId);
      hasLastClosure.current = false;
    }
  }, [getClosure, history, closureSelected]);
}

export default useLastClosure;

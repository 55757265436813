/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import styles from "./ui-token-input.module.sass";

interface UiTokenInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

const UiTokenInput: React.FC<UiTokenInputProps> = ({ value, onChange, error }) => {
  const [ digits, setDigits ] = useState(["", "", "", ""]);
  const [ hasError, setHasError ] = useState<boolean>(false); 
  const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!inputRefs.some((ref) => ref.current?.contains(e.target as Node)) && digits.length !== 4) {
        setHasError(true)
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [inputRefs]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = e.target.value;
  
    if (/^\d*$/.test(newValue) && newValue.length <= 1) {
      const newDigits = [...digits];
      newDigits[index] = newValue;
  
      if (newValue === "") {
        if (index > 0 && inputRefs[index - 1].current) {
          inputRefs[index - 1].current?.focus();
        }
      } else {
        onChange(newDigits.join(""));
  
        if (index < 3 && newValue.length === 1 && inputRefs[index + 1].current) {
          inputRefs[index + 1].current?.focus();
        }
      }
  
      setDigits(newDigits);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
  
    const clipboardData = e.clipboardData.getData("text/plain");
    const firstDigit = clipboardData.match(/^\d/);
  
    if (firstDigit) {
      const newDigits = [...digits];
      newDigits[index] = firstDigit[0];
      onChange(newDigits.join(""));
      setDigits(newDigits);
  
      if (index < 3 && inputRefs[index + 1].current) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  return (
    <>
    <div className={styles.fourDigitInput}>
      {digits.map((digit, index) => (
        <input
          placeholder="-"
          key={index}
          type="text"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          className={`${styles.digitInput} ${hasError && styles.error}`}
          ref={inputRefs[index]}
          data-testid='ui-token-input'
        />
      ))}
    </div>
    {hasError && <span className={styles.errorMessage}>{error}</span>}
    </>
  );
};

export default UiTokenInput;
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@config/store";
import {
  ProvidersDataState, ProvidersErrorsState, ProvidersPaginationState,
  ProvidersResumeState, ProvidersUiState, ProvidersUpdateState
} from ".";

export const providersUiSelector = createSelector(
  (state: RootState) => state.providers.ui,
  (state: ProvidersUiState) => state
);

export const providersDataSelector = createSelector(
  (state: RootState) => state.providers.data,
  (state: ProvidersDataState) => state.providers
);

export const providersUpdateSelector = createSelector(
  (state: RootState) => state.providers.update,
  (state: ProvidersUpdateState) => state.provider
);

export const providersPaginationSelector = createSelector(
  (state: RootState) => state.providers.pagination,
  (state: ProvidersPaginationState) => state
);

export const providersResumeSelector = createSelector(
  (state: RootState) => state.providers.resume,
  (state: ProvidersResumeState) => state.total
);

export const providersErrorsSelector = createSelector(
  (state: RootState) => state.providers.errors,
  (state: ProvidersErrorsState) => state.getProviders
);

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format, isSameDay, subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { billsFiltersSelector } from '@modules/bills/store';

const useDateBox = () => {
  const { initDate, endDate } = useSelector(billsFiltersSelector);

  const isInARangeDate = useMemo(() => (
    !!initDate && !!endDate
  ), [endDate, initDate]);

  const isDisableNextDate = useMemo(() => (
    isInARangeDate || isSameDay(new Date(initDate), new Date())
  ), [initDate, isInARangeDate]);

  const getDate = (getedDate: string, dateFormat: string) => (
    format(new Date(getedDate), dateFormat, { locale: es })
  );

  const getDay = (getedDay: string) => {
    if (isSameDay(new Date(getedDay), new Date())) {
      return 'Hoy,'
    } else if (isSameDay(new Date(getedDay), subDays(new Date(), 1))) {
      return 'Ayer,'
    }
    return ''
  };

  const date = useMemo(() => {
    return isInARangeDate
      ? `${getDate(initDate, 'dd MMM')} - ${getDate(endDate!, 'dd MMM yyyy',)}`
      : `${getDay(initDate)} ${getDate(initDate, 'dd MMMM yyyy')}`
  }, [endDate, initDate, isInARangeDate]);

  return {
    isInARangeDate,
    isDisableNextDate,
    date,
  }
};

export default useDateBox;

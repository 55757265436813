import { FC, memo } from 'react'
import { Route, Routes } from 'react-router-dom';
import { ReportsTabs } from '../constants';

import ReportSales from '@modules/reports/pages/report-sales';
import ReportTransactions from '@modules/reports/pages/report-transactions';
import ReportSkus from '@modules/reports/pages/report-skus';
import ReportSlowTurnover from '@modules/reports/pages/report-slow-turnover';

interface ReportsRouterProps { }

const ReportsRouter: FC<ReportsRouterProps> = () => {

  return (
    <Routes>
      <Route path={`${ReportsTabs.Sales}`} element={<ReportSales />} />
      <Route path={`${ReportsTabs.Transactions}`} element={<ReportTransactions />} />
      <Route path={`${ReportsTabs.Skus}`} element={<ReportSkus />} />
      <Route path={`${ReportsTabs.SlowTurnover}`} element={<ReportSlowTurnover />} />
    </Routes>
  )
}

export default memo(ReportsRouter);

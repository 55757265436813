import React, { ChangeEvent, FC, memo } from 'react';
import { customerSearchSelector, customersUiSelector } from '@modules/customers/store';
import { useSelector } from 'react-redux';
import styles from './customers-search-bar.module.sass';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import useDebounce from '@utils/use-debounce';

interface CustomersSearchBarProps { }

const CustomersSearchBar: FC<CustomersSearchBarProps> = () => {
  const currentSearchdedValue = useSelector(customerSearchSelector);
  const { hasNoSearchResult } = useSelector(customersUiSelector);
  const { searchCustomers } = useCustomersFetch();

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    searchCustomers(target.value);
  };
  const { handleInputChange, clearValue, value } = useDebounce(handleSearch, currentSearchdedValue);

  const handleRestartInput = (): void => {
    searchCustomers('');
    clearValue();
  };

  return (
    <div data-testid="search-bar" className={`${styles.wrapper} ${hasNoSearchResult && styles.noResults}`}>
      <input
        id='search-input'
        placeholder="Buscar Cliente"
        onChange={handleInputChange}
        className={styles.input}
        value={value}
        autoComplete='off'
      />
      {currentSearchdedValue
        ? <span className={`icon-close ${styles.closeIcon}`} onClick={handleRestartInput} data-testid='close-icon' />
        : (
          <div className={styles.icon}>
            <span className='icon-lens' />
          </div>
        )}
    </div>
  )
}
export default memo(CustomersSearchBar);
import React, { ChangeEvent, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, providersResumeSelector } from '@modules/providers/store';
import styles from './providers-filters.module.sass';
import useProvidersFetch from '@modules/providers/hooks/use-providers-fetch';
import UiSearchBar from '@ui/ui-search-bar';
import UiSquareButton from '@ui/ui-square-button';
import { currentUserSelector } from '@config/store';
import { fetchDownloadProviders } from '@modules/providers/services';
import Clevertap from '@utils/clevertap';
import { PROVIDERS_DOWNLOAD } from '@constants';

interface ProvidersFiltersProps { }

const ProvidersFilters: FC<ProvidersFiltersProps> = () => {
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const totalProviders = useSelector(providersResumeSelector);
  const { searchProviders } = useProvidersFetch();

  const handleDownload = useCallback((): void => {
    fetchDownloadProviders(user.storeId);
    Clevertap.pushSimpleEvent(PROVIDERS_DOWNLOAD);
  }, [user.storeId]);

  const handleShowModal = useCallback((): void => {
    dispatch(actions.setShowModal(true));
  }, [dispatch]);

  const handleSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    searchProviders(target.value)
  }, [searchProviders]);

  return (
    <div className={styles.wrapper}>

      <div className={styles.resume}>
        <span className={styles.resumeTitle}>Proveedores Totales</span>
        <span className={styles.resumeTotal}>{totalProviders}</span>
      </div>

      <div className={styles.searchBar}>
        <UiSearchBar
          placeholder='Buscar proveedor'
          onChange={handleSearch}
        />
      </div>
      <UiSquareButton icon='icon-download' onClick={handleDownload} />
      <UiSquareButton icon='icon-add' onClick={handleShowModal} />
    </div>
  )
}

export default ProvidersFilters;

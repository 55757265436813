import React, { ChangeEventHandler, FC, memo } from 'react';

import styles from './ui-input-field.module.sass';

interface UiInputFieldProps {
  label: string;
  type?: string;
  name: string;
  id?: string;
  value?: string;
  disabled?: boolean;
  hasAutoFocus?: boolean;
  hasError?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  hasArrow?: boolean;
  onArrowClick?: () => void;
}

const UiInputField: FC<UiInputFieldProps> = ({
  hasError = false,
  error,
  label = '',
  name,
  id = '',
  value,
  disabled,
  hasAutoFocus,
  type = 'text',
  onChange,
  onBlur,
  hasArrow,
  onArrowClick
}) => {
  return (
    <label className={`${styles.wrapper} ${hasError ? styles.error : ''} ${disabled && styles.disabled}`}>
      <div className={styles.left}>
        {value !== '' && <span className={styles.label}>{label}</span>}
        <input
          data-testid={`ui-input-field-${id}`}
          type={type}
          name={name}
          value={value}
          className={styles.input}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={hasAutoFocus}
          placeholder={label}
          disabled={disabled}
          autoComplete='off'
        />
      </div>
      {hasArrow && (
        <div className={styles.icon} onClick={onArrowClick}>
          <span className="icon-arrow" />
        </div>
      )}
      <span className={styles.errorMessage}>{error}</span>
    </label>
  );
}

export default memo(UiInputField);
export const LOGIN = "LoginAdmin";

export const BILLS_SEARCH = "InvoiceSearchAdmin";
export const BILLS_SEARCH_DATE_PICKER = "InvoiceAdvFilterAdmin";
export const BILLS_SEARCH_DATE = "InvoiceFilterAdmin";
export const BILLS_DETAIL = "InvoiceDetailAdmin";
export const BILLS_DEVOLUTION_OPEN = "InvoiceDevolutionAdmin";
export const BILLS_DEVOLUTION = "InvoiceDevolutionCompletedAdmin";
export const BILLS_PRINT = "InvoicePrintAdmin";

export const CLOSURE_DETAIL = "ClosureDetailAdmin";
export const CLOSURE_DOWNLOAD = "ClosureDownloadAdmin";

export const OPEN_REPORTS = "clickPosAdminInformes";
export const OPEN_IVENTORY = "clickPosAdminInventario";
export const OPEN_CLOSURES = "clickPosAdminCierres";
export const OPEN_PROVIDERS = "clickPosAdminProveedores";
export const OPEN_REQUESTS = "clickPosAdminPedidos";
export const OPEN_CUSTOMERS = "clickPosAdminClientes";
export const OPEN_COLLABORATORS = "clickPosAdminColaboradores";
export const OPEN_BILLS = "clickPosAdminBills";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "@modules/bills/store";
import { fetchInfoBill } from "@modules/bills/services";

const useGetInfoBill = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setIsLoading(true));
    fetchInfoBill()
      .then(data => {
        dispatch(actions.setForm(data));
        if (data.name) dispatch(actions.setInfoFirstTime(false));
      })
      .catch(() => dispatch(actions.setGetBillsError(true)))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }, [dispatch]);
};

export default useGetInfoBill;

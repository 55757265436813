import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styles from './customer-detail-mobile-buttons.module.sass';
import { useNavigate } from 'react-router-dom';
import { TABS } from '@modules/customers/constants';
import { customerDetailSelector } from '@modules/customers/store';
import UiButton from '@ui/ui-button-v2/ui-button';

interface CustomerDetailMobileButtonProps { }

const CustomerDetailMobileButton: FC<CustomerDetailMobileButtonProps> = () => {
  const navigate = useNavigate();
  const customer = useSelector(customerDetailSelector);

  const handleOpenNewLoan = useCallback((): void => {
    navigate(`${TABS.transaction}/${TABS.loanTransaction}`);
  }, [navigate]);

  const handleOpenNewPayment = useCallback((): void => {
    navigate(`${TABS.transaction}/${TABS.paymentTransaction}`);
  }, [navigate]);

  const handleBlockButton = () => customer.loans - customer.payments <= 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <UiButton 
          text='Fiar' 
          onClick={handleOpenNewLoan} 
          isSecondary 
          leftIcon='icon-light-minus'
        />
      </div>
      <div className={styles.right}>
        <UiButton 
          text='Abonar' 
          onClick={handleOpenNewPayment} 
          isSecondary 
          leftIcon='icon-light-plus' 
          isDisabled={handleBlockButton()} 
        />
      </div>
    </div>
  )
}

export default CustomerDetailMobileButton;
import React, { FC, useCallback } from 'react';
import { actions, profileUiSelector } from '@modules/profile/store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './security.module.sass';
import ChangePasswordModal from '@modules/profile/components/change-password-modal';

interface SecurityProps { }

const Security: FC<SecurityProps> = () => {
  const { showChangePasswordModal } = useSelector(profileUiSelector);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(actions.setShowChangePasswordModal(true));
  }, [dispatch]);

  return (
    <>
      {showChangePasswordModal && <ChangePasswordModal />}
      
      <h1 className={styles.title}>Seguridad</h1>

      <div className={styles.changePassword} onClick={handleClick}>
        Cambiar contraseña
        <span className={`icon-arrow ${styles.icon}`} />
      </div>
    </>
  );
}

export default Security;

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { billsUiLoaderSelector } from './store';
import BillsRouter from './routers';
import styles from './bills.module.sass';
import UiLoader from '@ui/ui-loader';
import useGetBills from './hooks/use-get-bills';

interface BillsProps { }

const Bills: FC<BillsProps> = () => {
  const isLoading = useSelector(billsUiLoaderSelector);
  useGetBills();

  return (
    <div className={styles.container}>
      {isLoading && <UiLoader />}
      <BillsRouter />
    </div>
  );
}

export default Bills;

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions } from "@config/store";
import { actions, providersDataSelector } from "@modules/providers/store";
import {
  fetchCreateProvider, fetchGetNewProviderCode, fetchGetProviders,
  fetchSearchProviders, fetchUpdateProvider
} from "@modules/providers/services";
import { Provider, ProvidersForm } from "@modules/providers/entities";
import Clevertap from "@utils/clevertap";
import { PROVIDERS_CREATION, PROVIDERS_EDITION, PROVIDERS_GET_DATA, PROVIDERS_SEARCH } from "@constants";

const useCollaboratorsFetch = () => {
  const dispatch = useDispatch();
  const providers = useSelector(providersDataSelector);

  const getProviders = useCallback((page: number = 1, sort?: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetProviders(page, sort)
      .then((data) => {
        dispatch(actions.setProviders(data.providers));
        dispatch(actions.setTotalPages(data.totalPages));
        dispatch(actions.setGetProvidersError(false));
        dispatch(actions.setResume(data.total));
        dispatch(actions.setHasNoSearchResults(false));
        dispatch(actions.setCurrentPage(1));
        Clevertap.pushCheckSuccessEvent(PROVIDERS_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setProviders([]));
        dispatch(actions.setGetProvidersError(true));
        Clevertap.pushCheckSuccessEvent(PROVIDERS_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);

  const getPage = useCallback((page: number = 1): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetProviders(page - 1)
      .then((data) => {
        dispatch(actions.setProviders(data.providers));
        dispatch(actions.setGetProvidersError(false));
        dispatch(actions.setCurrentPage(page));
      })
      .catch(() => {
        dispatch(actions.setProviders([]));
        dispatch(actions.setGetProvidersError(true));
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);

  const searchProviders = useCallback((value: string): void => {
    if (!value) getProviders();
    else {
      dispatch(actions.setIsLoading(true));
      fetchSearchProviders(value)
        .then((data) => {
          dispatch(actions.setProviders(data));
          dispatch(actions.setGetProvidersError(false));
          dispatch(actions.setHasNoSearchResults(!data.length));
          dispatch(actions.setTotalPages(1));
          dispatch(actions.setCurrentPage(1));
          Clevertap.pushEvent(PROVIDERS_SEARCH, { key: value, coincidenceQuantity: data.length });
        })
        .catch((error) => {
          dispatch(actions.setProviders([]));
          dispatch(actions.setGetProvidersError(true));
          Clevertap.pushEvent(PROVIDERS_SEARCH, { key: value }, error?.message);
        })
        .finally(() => dispatch(actions.setIsLoading(false)));
    }
  }, [dispatch, getProviders]);

  const createProvider = useCallback((provider: ProvidersForm): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetNewProviderCode()
      .then((code) => provider.codeProvider = code)
      .then(() => fetchCreateProvider(provider))
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Proveedor ${provider.nameProvider} creado con éxito`
        }));
        dispatch(actions.setShowModal(false))
        getProviders();
        dispatch(actions.setProviderToUpdate({} as Provider));
        Clevertap.pushEvent(PROVIDERS_CREATION, { providerName: provider.nameProvider });
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: `Lo sentimos. No pudimos crear su proveedor`
        }));
        Clevertap.pushCheckSuccessEvent(PROVIDERS_CREATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, getProviders]);

  const updateProvider = useCallback((provider: ProvidersForm): void => {
    dispatch(actions.setIsLoading(true));
    fetchUpdateProvider(provider)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Proveedor ${provider.nameProvider} actualizado con éxito`
        }));
        const oldProvider = providers.find(e => e.id === provider.id);
        Clevertap.pushEvent(PROVIDERS_EDITION, {
          hasNewName: oldProvider?.nameProvider !== provider.nameProvider,
          hasNewContactName: oldProvider?.contactName !== provider.contactName,
          hasNewEmail: oldProvider?.email !== provider.email,
          hasNewNit: oldProvider?.nit !== provider.nit,
          hasNewPhone: oldProvider?.phone !== provider.phone,
        });
        dispatch(actions.setProviders(providers.map(item => {
          return provider.id === item.id ? { ...item, ...provider } : item
        })))
        dispatch(actions.setShowModal(false));
        dispatch(actions.setProviderToUpdate({} as Provider));
      })
      .catch((error) => {
        dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: `Lo sentimos. No pudimos actualizar su proveedor`
      }));
      Clevertap.pushCheckSuccessEvent(PROVIDERS_EDITION, error?.message);
    })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, providers]);

  return {
    getProviders,
    getPage,
    searchProviders,
    createProvider,
    updateProvider
  }
}

export default useCollaboratorsFetch;

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as globalActions, currentUserSelector } from "@config/store";
import { PATH, TABS, TRANSACTION_LOAN, TRANSACTION_PAY_LOAN } from "@modules/customers/constants";
import {
  fetchCreateCustomer, fetchGetCustomerBalances, fetchGetCustomerDetail,
  fetchGetCustomers, fetchGetCustomerSales, fetchGetCustomerUrlResume, fetchGetTransactionDetail,
  fetchNewTransaction, fetchSearchCustomers, fetchSendWhatsApp, fetchUpdateCustomer
} from "@modules/customers/services";
import { actions, customerBalancesSelector, customerDetailSelector, customersDataSelector } from "@modules/customers/store";
import { CustomersFormBase, TransactionForm } from "@modules/customers/entities";
import {
  COLOMBIA_AREA_CODE, COLOMBIA_ISO_CODE, CUSTOMERS_GET_DATA, CUSTOMERS_SEARCH,
  CUSTOMER_CREATION, CUSTOMER_DETAIL_GET_DATA, CUSTOMER_EDITION, CUSTOMER_LOAN_TRANSACTION,
  CUSTOMER_PAYMENT_TRANSACTION, CUSTOMER_SEND_MESSAGE, CUSTOMER_TRANSACTION_DETAIL_GET_DATA,
  MEXICO_AREA_CODE, MIN_WIDTH_DESKTOP
} from "@constants";
import Clevertap from "@utils/clevertap";

const useCustomersFetch = () => {
  const user = useSelector(currentUserSelector);
  const customers = useSelector(customersDataSelector);
  const customer = useSelector(customerDetailSelector);
  const balances = useSelector(customerBalancesSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goHome = useCallback((): void => {
    navigate(`/${PATH}/${TABS.customers}`)
  }, [navigate]);

  const getCustomers = useCallback((sort?: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCustomers(0, sort)
      .then((data) => {
        dispatch(actions.setCustomers(data.customers));
        dispatch(actions.setResume({
          totalCustomers: data.totalCustomers,
          totalLoan: data.totalLoan,
          totalCustomerLoan: data.totalCustomerLoan
        }));
        dispatch(actions.setTotalPages(data.totalPages));
        dispatch(actions.setNoSearchResult(false));
        dispatch(actions.setCurrentSearchedValue(''));
        dispatch(actions.setCurrentPage(1));
        dispatch(actions.setHasRequestedCustomers(true));
        dispatch(actions.setGetCustomersError(false));
        dispatch(actions.setHasCustomers(!!data.customers.length));
        Clevertap.pushCheckSuccessEvent(CUSTOMERS_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setGetCustomersError(true));
        Clevertap.pushCheckSuccessEvent(CUSTOMERS_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);

  const getPage = useCallback((page: number = 1): void => {
    const isMobile = window.innerWidth <= MIN_WIDTH_DESKTOP;
    dispatch(actions.setIsLoading(true));
    fetchGetCustomers(page - 1)
      .then((data) => {
        dispatch(actions.setCustomers(isMobile ? [...customers, ...data.customers] : data.customers));
        dispatch(actions.setCurrentPage(page));
      })
      .catch(() => dispatch(actions.setGetCustomersError(true)))
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [customers, dispatch]);

  const searchCustomers = (value: string): void => {
    if (!value) getCustomers();
    else {
      dispatch(actions.setIsLoading(true));
      fetchSearchCustomers(value)
        .then((data) => {
          dispatch(actions.setCustomers(data));
          dispatch(actions.setNoSearchResult(!data.length));
          dispatch(actions.setCurrentSearchedValue(value));
          dispatch(actions.setCurrentPage(1));
          dispatch(actions.setTotalPages(1));
          Clevertap.pushEvent(CUSTOMERS_SEARCH, { key: value, coincidenceQuantity: data.length });
        })
        .catch((error) => {
          dispatch(actions.setGetCustomersError(true));
          Clevertap.pushEvent(CUSTOMERS_SEARCH, { key: value }, error?.message);
        })
        .finally(() => dispatch(actions.setIsLoading(false)));
    }
  };

  const createCustomer = useCallback((newCustomer: CustomersFormBase): void => {
    dispatch(actions.setIsLoading(true));
    fetchCreateCustomer(newCustomer)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Cliente ${newCustomer.name} creado con éxito`
        }));
        getCustomers();
        goHome();
        Clevertap.pushCheckSuccessEvent(CUSTOMER_CREATION);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: `Lo sentimos.${error?.response?.data?.exist ? 'Ya existe un usuario registrado con ese número' : 'No se pudo crear el cliente'}`
        }));
        Clevertap.pushCheckSuccessEvent(CUSTOMER_CREATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, getCustomers, goHome]);

  const updateCustomer = useCallback((customerUpdated: CustomersFormBase): void => {
    dispatch(actions.setIsLoading(true));
    fetchUpdateCustomer(customerUpdated)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: `Cliente ${customerUpdated.name} actualizado con éxito`
        }));
        Clevertap.pushEvent(CUSTOMER_EDITION, {
          phone: customer.phone,
          hasNewAddress: customer.address !== customerUpdated?.address,
          hasNewName: customer.name !== customerUpdated.name,
          hasNewDocument: customer.document !== customerUpdated?.document,
        });
        dispatch(actions.setCustomers(customers.map(item => item.id === customerUpdated.id ? { ...item, ...customerUpdated } : item)));
        dispatch(actions.setCustomerDetail({ ...customer, ...customerUpdated }))
        navigate(`/${PATH}/${customerUpdated.id}`);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No se pudo actualizar el cliente'
        }));
        Clevertap.pushCheckSuccessEvent(CUSTOMER_EDITION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [customer, customers, dispatch, navigate]);

  const getCustomerDetail = useCallback((id: string): void => {
    dispatch(actions.setIsLoading(true));
    Promise.all([
      fetchGetCustomerDetail(id),
      fetchGetCustomerSales(id),
      fetchGetCustomerBalances(id)
    ]).then(([customerDetail, customerSales, customerBalances]) => {
      dispatch(actions.setCustomerDetail(customerDetail));
      dispatch(actions.setCustomerSales(customerSales));
      dispatch(actions.setCustomerBalances(customerBalances.data));
      dispatch(actions.setGetCustomerDetailError(false));
      Clevertap.pushCheckSuccessEvent(CUSTOMER_DETAIL_GET_DATA);
    })
      .catch((error) => {
        dispatch(actions.setGetCustomerDetailError(true));
        Clevertap.pushCheckSuccessEvent(CUSTOMER_DETAIL_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);

  const sendResumeMessage = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCustomerUrlResume(customer.id)
      .then((url) => {
        const phone: string = `${user.country === COLOMBIA_ISO_CODE ? COLOMBIA_AREA_CODE : MEXICO_AREA_CODE}${customer.phone}`;
        const message: string = `Buenos días ${customer.name}, te habla ${user.firstName} de ${user.storeName}.
        Adjunto a este mensaje te envío el detalle de tus transacciones, ingresa al link para verlo ${url}`;
        Clevertap.pushCheckSuccessEvent(CUSTOMER_SEND_MESSAGE);
        return fetchSendWhatsApp(phone, message);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'No pudimos contactar al servicio. Intentalo de nuevo más tarde'
        }));
        Clevertap.pushCheckSuccessEvent(CUSTOMER_SEND_MESSAGE, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [customer, dispatch, user]);

  const createNewTransaction = useCallback((transaction: TransactionForm, isPayment: boolean): void => {
    dispatch(actions.setIsLoading(true));
    fetchNewTransaction(transaction)
      .then((response) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Transacción registrada con éxito'
        }));
        dispatch(actions.setCustomerBalances([{
          name: isPayment ? TRANSACTION_PAY_LOAN : TRANSACTION_LOAN,
          isLoan: !isPayment,
          createdAt: response.createdAt,
          total: Number(transaction.total),
          transactionId: response.id
        }, ...balances]));
        dispatch(actions.setCustomerDetail({
          ...customer,
          lastpaidloan: isPayment ? response.createdAt : customer.lastpaidloan,
          loans: customer.loans + (isPayment ? 0 : Number(transaction.total)),
          payments: customer.payments + (isPayment ? Number(transaction.total) : 0)
        }));
        Clevertap.pushEvent(isPayment ? CUSTOMER_PAYMENT_TRANSACTION : CUSTOMER_LOAN_TRANSACTION, { total: transaction.total });
        navigate(-1);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos registrar su transaccion'
        }));
        Clevertap.pushEvent(
          isPayment ? CUSTOMER_PAYMENT_TRANSACTION : CUSTOMER_LOAN_TRANSACTION,
          { total: transaction.total },
          error.mensaje
        );
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [balances, customer, dispatch, navigate]);

  const getTransactionDetail = useCallback((id: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetTransactionDetail(id)
      .then((data) => {
        dispatch(actions.setTransactionDetail(data));
        dispatch(actions.setGetTransactionDetailError(false));
        Clevertap.pushCheckSuccessEvent(CUSTOMER_TRANSACTION_DETAIL_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setGetTransactionDetailError(true));
        Clevertap.pushCheckSuccessEvent(CUSTOMER_TRANSACTION_DETAIL_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);

  return {
    getCustomers,
    getPage,
    searchCustomers,
    createCustomer,
    updateCustomer,
    getCustomerDetail,
    sendResumeMessage,
    createNewTransaction,
    getTransactionDetail
  }
}

export default useCustomersFetch;

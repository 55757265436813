import axios from "axios";
import { Municipality, Profile, StoreInformationUpdateBody, StoreTypesResponse, UserInformation } from "../entities";

export const fetchGetMunicipalities = (isoCode: string): Promise<Municipality[]> =>
  axios.get<Municipality[]>(`store-prefix/get-municipalities/${isoCode}`)
    .then(({ data }) => data);

export const fetchGetStoreTypes = (isoCode: string): Promise<StoreTypesResponse> =>
  axios.get<StoreTypesResponse>(`store-prefix/get-store-types/${isoCode}`)
    .then(({ data }) => data);

export const fetchGetProfileData = (storeId: number): Promise<Profile> =>
  axios.get<Profile>(`/store-prefix/get-profile/${storeId}`)
    .then(({ data }) => data);

export const fetchUpdateStoreInformation = (body: StoreInformationUpdateBody): Promise<boolean> =>
  axios.put('store-prefix/store', body)
    .then(() => true);

export const fetchUpdateUserInformation = (body: UserInformation): Promise<boolean> =>
  axios.put('store-prefix/user-information', body)
    .then(() => true);

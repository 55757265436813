import React, { FC, useCallback, useEffect, useState } from 'react'
import { Collaborator } from '@modules/collaborators/entities';
import { actions, collaboratorsUiSelector } from '@modules/collaborators/store';
import { actions as globalActions } from '@config/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetCollaboratorToUpdate } from '@modules/collaborators/services';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH, TABS } from '@modules/collaborators/constants';
import CollaboratorsForm from '@modules/collaborators/components/form/collaborators-form';
import UiButton from '@ui/ui-button';
import UiLoader from '@ui/ui-loader';
import CollaboratorsDeleteModal from '../../components/delete-confirmation';
import useCollaboratorsFetch from '@modules/collaborators/hooks/use-collaborators-fetch';

interface CollaboratorsUpdateProps { }

const CollaboratorsUpdate: FC<CollaboratorsUpdateProps> = () => {
  const { showDeleteCollaboratorModal } = useSelector(collaboratorsUiSelector);
  const [collaborator, setCollaborator] = useState<Collaborator>({} as Collaborator);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collaboratorId } = useParams();
  const { updateCollaborator } = useCollaboratorsFetch();

  const getCollaborator = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCollaboratorToUpdate(collaboratorId)
      .then((data) => setCollaborator(data))
      .catch(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. Por el momento no se puede actualizar este colaborador'
        }));
        navigate(`/${PATH}/${TABS.home}`);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [collaboratorId, dispatch, navigate]);

  const handleShowDeleteConfirmation = (): void => {
    dispatch(actions.setShowDeleteCollaboratorModal(true));
  };

  useEffect(() => {
    getCollaborator();
  }, [collaboratorId, getCollaborator]);

  if (!collaborator.id) return <UiLoader />;

  return (
    <>
      {showDeleteCollaboratorModal && <CollaboratorsDeleteModal />}

      <CollaboratorsForm
        title='Editar colaborador'
        collaborator={collaborator}
        onSubmit={updateCollaborator}
        leftButton={
          <UiButton text='Eliminar' leftIcon='icon-delete' onClick={handleShowDeleteConfirmation} isSecondary />
        }
      />
    </>
  );
}

export default CollaboratorsUpdate;
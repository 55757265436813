import UiButton from '@ui/ui-button';
import React, { FC, memo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import stopImage from '../../assets/img/stop-barrier.png';
import styles from './ui-access-denied-message.module.sass';

interface UiAccessDeniedMessageProps {
  navigate: NavigateFunction;
}

const UiAccessDeniedMessage: FC<UiAccessDeniedMessageProps> = ({ navigate }) => {
  const handleClick = (): void => {
    navigate('/');
  };

  return (
    <div className={styles.wrapper}>
      <img src={stopImage} alt="stop" className={styles.image} />
      <span className={styles.text}>Lo sentimos</span>
      <span className={styles.text}>No tienes autorización para ingresar a este módulo</span>
      <div className={styles.button}>
        <UiButton text='Volver al inicio' onClick={handleClick} />
      </div>
    </div>
  );
}

export default memo(UiAccessDeniedMessage);

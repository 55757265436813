import * as yup from 'yup';
import { t } from 'i18next'
import { MAX_MONEY_VALUE, MAX_STOCK_VALUE, PRODUCT_NAME_LENGTH, REG_EXP_NO_ONLY_SPACES } from '@constants';
import { formatMoney } from '@utils/format-money';

export const productSchema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required'))
    .max(PRODUCT_NAME_LENGTH, t('errors.max_characters', { digits: PRODUCT_NAME_LENGTH }))
    .matches(REG_EXP_NO_ONLY_SPACES, t('errors.invalid_description')),
  brandName: yup.string()
    .required(t('errors.required'))
    .max(25, t('errors.max_characters', { digits: 25 })),
  iva: yup.string()
    .required(t('errors.required')),
  ico: yup.string()
    .required(t('errors.required')),
  categoryId: yup.string()
    .required(t('errors.required')),
  packagingType: yup.string()
    .required(t('errors.required')),
  managerTotal: yup.number()
    .required(t('errors.required'))
    .max(yup.ref('totalPrice'), t('errors.max_purchase_price'))
    .test('test', t('errors.zero_amount'), (total) => Number(total) > 0)
    .max(MAX_MONEY_VALUE, t('errors.max_money_value', { max: formatMoney(MAX_MONEY_VALUE) })),
  totalPrice: yup.number()
    .required(t('errors.required'))
    .min(yup.ref('managerTotal'), t('errors.min_sale_price'))
    .test('test', t('errors.zero_amount'), (total) => Number(total) > 0)
    .max(MAX_MONEY_VALUE, t('errors.max_money_value', { max: formatMoney(MAX_MONEY_VALUE) })),
  stock: yup.number()
    .required(t('errors.required'))
    .typeError(t('errors.only_numbers'))
    .min(0, t('errors.zero_amount'))
    .max(MAX_STOCK_VALUE, t('errors.max_money_value', { max: MAX_STOCK_VALUE })),

});

export const productDiscountSchema = yup.object().shape({
  customerDiscountPrice: yup.number()
    .required(t('errors.required'))
    .max(yup.ref('customerSubTotalPrice'), t('errors.max_discount_price'))
    .test('test', t('errors.zero_amount'), (total) => Number(total) > 0),
  quantity: yup.number()
    .min(0, t('errors.zero_amount'))
    .typeError(t('errors.only_numbers'))
    .max(MAX_STOCK_VALUE, t('errors.max_money_value', { max: MAX_STOCK_VALUE })),
  initAt: yup.date()
    .required(t('errors.required'))
    .min(new Date(new Date().setHours(0)), t('errors.not_past_dates')),
  endAt: yup.date()
    .required(t('errors.required'))
    .min(yup.ref('initAt'), t('errors.end_date_not_equal_init_date')),
});
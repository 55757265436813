import React, { FC, memo } from 'react';
import { formatMoney } from '@utils/format-money';
import { Bill } from '@modules/bills/entities';
import styles from './bill-detail-footer.module.sass';
import { formatIndex } from '@modules/bills/utils';
import BillDetailFooterButtons from '../bill-detail-footer-buttons';

interface BillDetailFooterProps {
  bill: Bill;
}

const BillDetailFooter: FC<BillDetailFooterProps> = ({ bill }) => {

  const getTotal = () => formatIndex(bill.details.reduce((a, b) => a + +b.quantity, 0));

  return (
    <div className={styles.wrapper}>

      <div className={styles.top}>
        <div className={styles.quantity}>
          <span className={styles.length}>{getTotal()}</span>
          Productos
        </div>

        <div className={styles.subtotal}>
          <p>Subtotal</p>
          <p className={styles.value}>{formatMoney(+bill.subtotal)}</p>
          <p>IVA</p>
          <p className={styles.value}>{formatMoney(+bill.totalTaxes)}</p>
        </div>
      </div>

      <div className={styles.total}>
        Total
        <p className={styles.value}>{formatMoney(+bill.total)}</p>
      </div>

      <BillDetailFooterButtons bill={bill} />

    </div>
  );
}

export default memo(BillDetailFooter);

import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import UiTopSection from '@ui/ui-top-section';
import styles from './collaborators-form.module.sass';
import UiButton from '@ui/ui-button';
import { ALL_PERMISSIONS, PATH } from '@modules/collaborators/constants';
import { Collaborator, CollaboratorForm } from '@modules/collaborators/entities';
import { useSelector } from 'react-redux';
import { getCollaboratorPermissions, getCollaboratorToForm, getInputs, hasOwnerRole } from '@modules/collaborators/utils';
import { FormikValues, useFormik } from 'formik';
import { currentUserSelector } from '@config/store';
import { collaboratorSchema } from '@modules/collaborators/schemas';
import UiSelect from '@ui/ui-select';
import UiInputField from '@ui/ui-input-field';
import { UiSelectOption } from '@entities';
import CollaboratorsFormPermissions from '../form-permissions';
import { OWNER_ROLE_ID } from '@constants';

interface CollaboratorsFormProps {
  title: string;
  leftButton: ReactElement;
  collaborator?: Collaborator;
  onSubmit: (newCollaborator: CollaboratorForm, permissions: string[]) => void;
}

const CollaboratorsForm: FC<CollaboratorsFormProps> = ({ title, leftButton,  collaborator, onSubmit}) => {
  const user = useSelector(currentUserSelector);

  const [permissions, setPermissions] = useState<string[]>(getCollaboratorPermissions(collaborator));

  const handleSubmit = useCallback((values: FormikValues): void => {
    onSubmit(values as CollaboratorForm, permissions)
  }, [onSubmit, permissions]);

  const form = useFormik({
    initialValues: getCollaboratorToForm(user.storeId, user.id, user.country, collaborator),
    onSubmit: handleSubmit,
    validationSchema: collaboratorSchema,
  });

  useEffect(() => {
    if (form.values.roleId === OWNER_ROLE_ID) setPermissions(ALL_PERMISSIONS);
  }, [form.values.roleId]);

  return (
    <form onSubmit={form.handleSubmit} className={styles.container}>
      <UiTopSection
        title={title}
        routes={[{ name: "Colaboradores", path: `/${PATH}` }]}
        leftButton={leftButton}
        rightButton={
          <UiButton type="submit" text="Guardar" isDisabled={!form.isValid} leftIcon='icon-save' />
        }
        hasShadow
      />

      <div className={styles.inputs}>
        {getInputs(user.country).map((input, i) => (
          <div className={styles.inputWrapper} key={`collaborator-input-${String(i)}`}>
            {input.isSelect
              ? (
                <UiSelect
                  name={input.name}
                  value={form.values[input.name as keyof object]}
                  defaultValue={input.title}
                  label={input.title}
                  onChange={form.handleChange}
                  options={input.options as UiSelectOption[]}
                  hasError={form.errors[input.name as keyof object] && form.touched[input.name as keyof object]}
                  error={form.errors[input.name as keyof object]}
                  onBlur={form.handleBlur}
                />
              ) : (
                <UiInputField
                  type='text'
                  name={input.name}
                  value={form.values[input.name as keyof object]}
                  onChange={form.handleChange}
                  label={input.title}
                  hasError={form.errors[input.name as keyof object] && form.touched[input.name as keyof object]}
                  error={form.errors[input.name as keyof object]}
                  onBlur={form.handleBlur}
                />
              )}
          </div>
        ))}
      </div>

      <CollaboratorsFormPermissions
        permissions={permissions}
        setPermissions={setPermissions}
        hasPermissionsBlocked={hasOwnerRole(form.values.roleId)}
      />
    </form>
  );
}

export default CollaboratorsForm;

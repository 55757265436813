import { ChangeEvent, ChangeEventHandler, useRef, useState } from "react";
import { TIME_TO_DEBOUNCE } from "@constants";

const useDebounce = (onChange: ChangeEventHandler<HTMLInputElement>, initialValue?: string) => {
  const [value, setValue] = useState<string>(initialValue || '');
  const debounceFunction = useRef<NodeJS.Timeout>({} as NodeJS.Timeout)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    if (debounceFunction.current) clearTimeout(debounceFunction.current);
    debounceFunction.current = setTimeout(() => { onChange(event) }, TIME_TO_DEBOUNCE);
  };

  const changeValue = (newValue: string) => {
    setValue(newValue)
  };

  const clearValue = (): void => {
    setValue('')
  };

  return {
    value,
    handleInputChange,
    clearValue,
    changeValue,
  }
};

export default useDebounce;

import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closuresHistorySelector,
  closuresDetailSelector,
  actions,
} from "@modules/closures/store";
import { formatMoney } from "@utils/format-money";
import styles from "./closures-mobile-history-list.module.sass";
import { Icon, Theme } from "@chiper-inc/sb-web-chiper";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";

interface ClosuresMobileHistoryListProps {}

const ClosuresMobileHistoryList: FC<ClosuresMobileHistoryListProps> = () => {
  const history = useSelector(closuresHistorySelector);
  const closure = useSelector(closuresDetailSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closureSelect = useCallback((balance: string) => {
    navigate(-1);
    dispatch(actions.setClosureSelected(+balance))
  }, [navigate, dispatch]);

  return (
    <div className={styles.wrapper}>
      {history.map((e) => (
        <div
          className={`${styles.closure} ${
            closure.id === e.balanceId ? styles.active : ""
          }`}
          key={`history.closure-${e.balanceId}`}
          onClick={() => closureSelect(e.balanceId)}
          data-testid="closures-home-history-item"
        >
          <div className={styles.left}>
            {format(new Date(e.closingTime), 'MMM dd, yyyy', { locale: es })}
          </div>
          <div className={styles.right}>
            {formatMoney(+e.expectedTotal)}
            <Icon
              name="CaretRight"
              size={16}
              color={Theme.colors.primary.yankeesBlue}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClosuresMobileHistoryList;

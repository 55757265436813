import React, { FC } from "react";
import styles from "./closures-mobile-home-content.module.sass";
import { useSelector } from "react-redux";
import { closuresDetailSelector } from "@modules/closures/store";
import { currentUserSelector } from "@config/store";
import { COLOMBIA_ISO_CODE } from "@constants";
import UiDivider from "@ui/ui-divider";
import { formatMoney } from "@utils/format-money";

interface ClosuresMobileHomeContentProps { }

const ClosuresMobileHomeContent: FC<ClosuresMobileHomeContentProps> = () => {
  const closure = useSelector(closuresDetailSelector);
  const userCountry = useSelector(currentUserSelector).country;

  const Balances = [
    {
      title: "Ingresos por método de pago",
      list: [
        { title: "Ventas en Efectivo", value: closure.income },
        { title: "Ventas en Tarjeta", value: closure.income_card },
        ...(userCountry === COLOMBIA_ISO_CODE
          ? [
              { title: "Ventas en Daviplata", value: closure.daviplata },
              { title: "Ventas en Nequi", value: closure.nequi },
            ]
          : [{ title: "Ventas en Codi", value: closure.codi }]),
        { title: "Abonos", value: closure.pay_loan },
        { title: "Adicionales", value: closure.entry },
      ],
    },
    {
      title: "Devoluciones y egresos",
      list: [
        { title: "Pago a proveedores", value: closure.provider },
        { title: "Pago de servicios", value: closure.services },
        { title: "Personal", value: closure.personal },
        { title: "Otros", value: closure.others },
        { title: "Devoluciones", value: closure.devolutions },
      ],
    },
  ];

  return (
    <div className={styles.wrapper}>
      <UiDivider />
      {Balances.map((e) => (
        <div key={`${e.title}-list`}>
          <div className={styles.title}>{e.title}</div>
          <>
              {!e.list.length && <p className={styles.empty}>Sin novedades</p>}
              {e.list.map((el) => (
                <div className={styles.list} key={`list-item-${el.title}`}>
                <p
                  className={styles.text}
                  key={`closure-content-item-${el.title}`}
                >
                  {el.title}
                </p>
                <p className={styles.value}>{formatMoney(el.value)}</p>
                </div>
              ))}
              <UiDivider />
          </>
        </div>
      ))}
    </div>
  );
};

export default ClosuresMobileHomeContent;

import { FC } from 'react';

import styles from './bill-edit-preview-header.module.sass';
import { BILL_PREVIEW_FIELDS, FIELD_NIT, FIELD_RFC } from '@modules/bills/constants';
import { billFormSelector, billsUiSelector } from '@modules/bills/store';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '@config/store';
import { COLOMBIA_ISO_CODE } from '@constants';

interface BillEditPreviewHeaderProps { }

const BillEditPreviewHeader: FC<BillEditPreviewHeaderProps> = () => {
  const focusedField = useSelector(billsUiSelector);
  const billForm = useSelector(billFormSelector);
  const { country } = useSelector(currentUserSelector);
  const dniPreviewField = country === COLOMBIA_ISO_CODE ? FIELD_NIT: FIELD_RFC;

  return (
    <div className={styles.wrapper}>
      {BILL_PREVIEW_FIELDS.map((item,i) =>
        <span className={`${focusedField.fieldOnFocus === item.name && styles.focus} ${styles.text}`} key={`${item.name}-${i}`}>
          {billForm[item.name as keyof object]}
        </span>
      )}

      {(billForm.nit || billForm.rfc) && <span className={`${focusedField.fieldOnFocus === dniPreviewField.name && styles.focus} ${styles.text}`}>
        {`${dniPreviewField.placeholder}: `} {billForm[dniPreviewField.name as keyof object]}
      </span>}
    </div>
  );
}

export default BillEditPreviewHeader;

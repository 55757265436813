import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./global.slice";
import authReducer from "@modules/auth/store/auth.slice";
import dashboardReducer from "@modules/dashboard/store/dashboard.slice";
import collaboratorsReducer from "@modules/collaborators/store/collaborators.slice";
import customersReducer from "@modules/customers/store/customers.slice";
import closuresReducer from "@modules/closures/store/closures.slice";
import reportsReducer from "@modules/reports/store/reports.slice";
import inventoryReducer from "@modules/inventory/store/inventory.slice";
import providersReducer from "@modules/providers/store/providers.slice";
import profileReducer from "@modules/profile/store/profile.slice";
import ordersReducer from "@modules/orders/store/orders.slice";
import billsReducer from "@modules/bills/store/bills.slice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    collaborators: collaboratorsReducer,
    customers: customersReducer,
    closures: closuresReducer,
    reports: reportsReducer,
    inventory: inventoryReducer,
    providers: providersReducer,
    profile: profileReducer,
    orders: ordersReducer,
    bills: billsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

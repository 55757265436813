import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, billsFiltersSelector, billsUiRequestSelector } from "@modules/bills/store";
import { fetchGetBills } from "@modules/bills/services";

const useGetBills = () => {
  const dispatch = useDispatch();
  const filters = useSelector(billsFiltersSelector);
  const mustRequestBills = useSelector(billsUiRequestSelector);

  const getBills = useCallback(() => {
    dispatch(actions.setIsLoading(true));
    fetchGetBills(filters)
      .then(data => {
        dispatch(actions.setBills(data));
        dispatch(actions.setGetBillsError(false));
        dispatch(actions.setDetailId(''));
        dispatch(actions.setHasNoSearchResults(!!filters.search && !data.length))
      })
      .catch(() => dispatch(actions.setGetBillsError(true)))
      .finally(() => {
        dispatch(actions.setHasRequestedBills(true));
        dispatch(actions.setIsLoading(false));
      })
  }, [dispatch, filters]);

  useEffect(() => {
    getBills();
  }, [getBills]);

  useEffect(() => {
    if (mustRequestBills) getBills();
    dispatch(actions.setMustRequestBills(false));
}, [dispatch, getBills, mustRequestBills]);
};

export default useGetBills;
import React, { FC } from 'react'
import UiLoader from '@ui/ui-loader';
import { useSelector } from 'react-redux';
import { providersUiSelector } from './store';
import ProvidersHome from './pages/providers-home';

interface ProvidersProps { }

const Providers: FC<ProvidersProps> = () => {
  const { isLoading } = useSelector(providersUiSelector);

  return (
    <>
      <ProvidersHome />
      {isLoading && <UiLoader />}
    </>
  )
}

export default Providers;

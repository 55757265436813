import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions, currentUserSelector } from "@config/store";
import { actions } from "@modules/profile/store";
import {
  fetchGetMunicipalities, fetchGetProfileData,
  fetchGetStoreTypes, fetchUpdateStoreInformation, fetchUpdateUserInformation
} from "@modules/profile/services";
import { StoreInformation, UserInformation } from "@modules/profile/entities";
import Clevertap from "@utils/clevertap";
import { PROFILE_GET_DATA, PROFILE_UPDATE_STORE_INFORMATION, PROFILE_UPDATE_USER_INFORMATION } from "@constants";

const useProfileFetch = () => {
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  const getFieldOptions = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    Promise.all([
      fetchGetMunicipalities(user.country),
      fetchGetStoreTypes(user.country)
    ]).then(([municipalitiesResponse, storeTypesResponse]) => {
      dispatch(actions.setHasFieldOptions(true));
      dispatch(actions.setMunicipalities(municipalitiesResponse));
      dispatch(actions.setStoreTypes(storeTypesResponse.storeTypes));
      dispatch(actions.setDailySales(storeTypesResponse.dailySales));
      Clevertap.pushCheckSuccessEvent(PROFILE_GET_DATA);
    })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'normal',
          message: 'No pudimos obtener todos los datos. Algunos campos no podrán ser editados'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user.country]);

  const getProfileData = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    dispatch(actions.setIsRequestingProfile(true));
    fetchGetProfileData(user.storeId)
      .then((data) => {
        dispatch(actions.setGetProfileDataError(false));
        dispatch(actions.setStoreInformationData(data.storeProfile));
        dispatch(actions.setUserInformationData(data.userProfile));
        dispatch(actions.setHasProfileData(true));
      })
      .catch(() => dispatch(actions.setGetProfileDataError(true)))
      .finally(() => {
        dispatch(actions.setIsLoading(false));
        dispatch(actions.setIsRequestingProfile(false));
      });
  }, [dispatch, user.storeId]);

  const updateStoreInformation = useCallback((values: StoreInformation): void => {
    dispatch(actions.setIsLoading(true));
    fetchUpdateStoreInformation({ ...values, storeId: user.storeId, userId: user.id })
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Datos de negocio actualizados con éxito'
        }));
        if (user.storeName !== values.storeName) {
          dispatch(globalActions.saveCurrentUser({ ...user, storeName: values.storeName }))
        }
        dispatch(actions.setStoreInformationData(values));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_STORE_INFORMATION);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos actualizar sus datos de negocio'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_STORE_INFORMATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user]);

  const updateUserInformation = useCallback((values: UserInformation): void => {
    dispatch(actions.setIsLoading(true));
    fetchUpdateUserInformation({ ...values, userId: user.id, storeId: user.storeId })
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Datos personales actualizados con éxito'
        }));
        dispatch(actions.setUserInformationData(values));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_USER_INFORMATION);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos actualizar sus datos personales'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_USER_INFORMATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, user]);

  return {
    getFieldOptions,
    getProfileData,
    updateStoreInformation,
    updateUserInformation
  }
};

export default useProfileFetch;

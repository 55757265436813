import { PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import { OrdersTabs } from "../entities";

export const TABS: OrdersTabs = {
  orders: 'orders',
  ordersDetail: 'detail'
};

export const ORDERS_TABLE_COLUMNS: {title: string, icon: keyof typeof PhosphorIcons}[] = [
  {title: 'Proveedor', icon: 'ArrowsDownUp'},
  {title: '# de pedido', icon: 'ArrowsDownUp'},
  {title: 'Factura', icon: 'ArrowsDownUp'},
  {title: 'Fecha', icon: 'ArrowsDownUp'},
  {title: 'Total', icon: 'ArrowsDownUp'},
  {title: 'Pago', icon: 'ArrowsDownUp'},
];

export const PAID_OPTIONS = [
  {
    title: 'Todos',
    value: 'undefined',
  },
  {
    title: 'Pagado',
    value: 'true',
  },
  {
    title: 'No pagado',
    value: 'false',
  }
];
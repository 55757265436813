import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions, billsDataSelector, billsDetailSelector } from "@modules/bills/store";
import { BillDetail } from "@modules/bills/entities";
import { formatMoney } from "@utils/format-money";
import { actions as globalActions, currentUserSelector } from "@config/store";
import { fetchCreateDevolution } from "@modules/bills/services";
import { BILLS_PATH } from "@modules/bills/constants";
import clevertap, { BILLS_DEVOLUTION } from "@config/clevertap";

interface Devolution {
  id: string;
  quantity: string;
  price: string;
}

const useDevolution = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(currentUserSelector);
  const bills = useSelector(billsDataSelector);
  const billDetailId = useSelector(billsDetailSelector);
  const [devolutions, setDevolutions] = useState<Devolution[]>([]);

  const billProducts = useMemo(() => (
    bills.find(bill => bill.id === billDetailId)?.details || []
  ), [billDetailId, bills]);

  const devolutionsList = useMemo(() => (
    devolutions.map(d => {
      const product = billProducts.find(e => e.id === d.id)!;
      return { ...product, quantity: d.quantity, customerTotal: String(+d.price * +d.quantity) }
    })
  ), [billProducts, devolutions]);

  const totalToDevolution = useMemo(() => (
    formatMoney(devolutions.reduce((a, b) => +a + (+b.price * +b.quantity), 0))
  ), [devolutions]);

  const productsList = useMemo(() => (
    billProducts.map(e => {
      const product = devolutions.find(d => d.id === e.id);
      return product ? {
        ...e,
        quantity: String(+e.quantity - +product.quantity),
        customerTotal: String(+e.customerTotal - (+product.price * +product.quantity))
      } : e
    }).filter(e => !!+e.quantity)
  ), [billProducts, devolutions]);

  const addProductToDevolution = useCallback((product: BillDetail, quantity: string = '1') => {
    const hasDevolution = !!devolutions.find(e => e.id === product.id);
    setDevolutions(hasDevolution
      ? devolutions.map(e => e.id === product.id ? { ...e, quantity: String(+e.quantity + +quantity) } : e)
      : [...devolutions, { id: product.id, quantity, price: String(+product.customerTotal / +product.quantity) }]
    )
  }, [devolutions]);

  const removeProductToDevolution = useCallback((product: BillDetail, quantity: string = '1') => {
    const devolution = devolutions.find(e => e.id === product.id);
    setDevolutions(Number(devolution?.quantity) > +quantity
      ? devolutions.map(e => e.id === product.id ? { ...e, quantity: String(+e.quantity - +quantity) } : e)
      : devolutions.filter(e => e.id !== product.id)
    )
  }, [devolutions]);

  const createNewDevolution = useCallback(() => {
    dispatch(actions.setIsLoading(true));
    const body = {
      orderId: +billDetailId,
      userId: user.id,
      type: !productsList.length ? 'TOTAL' : 'PARCIAL',
      details: devolutions.map(e => ({ id: +e.id, quantity: +e.quantity }))
    };
    fetchCreateDevolution(body)
      .then(() => {
        dispatch(globalActions.notification({
          show: true, state: 'success', message: 'Devolución registrada con éxito'
        }));
        dispatch(actions.setMustRequestBills(true));
        clevertap.pushEvent(BILLS_DEVOLUTION, { invoiceId: body.orderId })
        navigate(BILLS_PATH);
      })
      .catch(() => dispatch(globalActions.notification({
        show: true, state: 'error', message: 'Lo sentimos, no hemos podido registrar su devolución'
      })))
      .finally(() => dispatch(actions.setIsLoading(false)));

  }, [billDetailId, devolutions, dispatch, navigate, productsList.length, user.id]);

  return {
    productsList,
    addProductToDevolution,
    devolutionsList,
    removeProductToDevolution,
    totalToDevolution,
    createNewDevolution,
  };
}

export default useDevolution;

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import image from '@img/stop-barrier.png';
import { actions, inventoryUiSelector, productDetailSelector } from '@modules/inventory/store';
import styles from './product-disable-notification-mobile.module.sass';

interface ProductDisableNotificationMobileProps { }

const ProductDisableNotificationMobile: FC<ProductDisableNotificationMobileProps> = () => {
  const product = useSelector(productDetailSelector);
  const dispatch = useDispatch();
  const { sku } = useParams();
  const navigate = useNavigate();
  const { showMobileDisableNotification } = useSelector(inventoryUiSelector);

  const handleCloseClick = (): void => {
    if (showMobileDisableNotification) dispatch(actions.setShowMobileDisableNotification(false))
    else navigate(`/product/${sku}`)
  };

  return (
    <div className={styles.container}>
      <span
        className={`icon-close ${styles.icon}`}
        onClick={handleCloseClick}
        data-testid='close-icon'
      />
      <div className={styles.wrapper}>
        <img src={image} alt='stop' className={styles.image} />
        <h1 className={styles.title}>
          Producto inhabilitado
        </h1>
        <span className={styles.description}>
          Puedes reactivar el producto {product.name} en cualquier momento.
        </span>
      </div>
    </div>
  );
}

export default ProductDisableNotificationMobile;

import {
  InventoryItem, Product, ProductCategory, ProductDiscountTableItem,
  ProductDiscount, InventoryFileObject, ComboTableItem, SearchedProduct, Combo, ComboProduct
} from "../entities";

export interface InventoryState {
  ui: InventoryUiState;
  data: InventoryDataState;
  detail: ProductDetailState;
  resume: InventoryResumeState;
  pagination: InventoryPaginationState;
  search: InventorySearchState;
  errors: InventoryErrorsState;
  uploadedFile: InventoryUploadFileState;
  combos: CombosDataState;
  currentCombo: CurrentComboState;
}

export interface InventoryUiState {
  isLoading: boolean;
  hasNoSearchResult: boolean;
  showDatePickers: boolean;
  hasRequestedDiscounts: boolean;
  isLoadingGetDiscount: boolean;
  isUpdatingDiscount: boolean;
  isUploadingInventory: boolean;
  showPreviewTable: boolean;
  isRepeatedFile: boolean;
  isReadingFile: boolean;
  hasNoComboSearchResult: boolean;
  hasNoComboProductSearchResult: boolean;
  showComboDatePickers: boolean;
  showMobileDisableNotification: boolean;
  isFromDashboardMobile: boolean;
}

export interface InventoryDataState {
  inventory: InventoryItem[];
}

export interface ProductDetailState {
  product: Product;
  discounts: ProductDiscountTableItem[];
  categories: ProductCategory[]
  currentDiscount: ProductDiscount;
}

export interface InventoryPaginationState {
  totalPages: number;
  currentPage: number;
}

export interface InventorySearchState {
  currentSearchedValue: string;
  currentComboSearchedValue: string;
}

export interface InventoryResumeState {
  totalSkus: number;
  totalCost: number | string;
}

export interface InventoryErrorsState {
  getInventory: boolean;
  getProductDetail: boolean;
  getProductDiscounts: boolean;
  getCombos: boolean;
}

export interface InventoryUploadFileState {
  name: string;
  data: InventoryFileObject[];
}

export interface CombosDataState {
  data: ComboTableItem[];
  pagination: InventoryPaginationState;
  totalActive: number;
}

export interface CurrentComboState {
  combo: Combo;
  products: ComboProduct[],
  searchedProducts: SearchedProduct[];
  customerPrice: number
}

export const initialState: InventoryState = {
  ui: {
    isLoading: false,
    hasNoSearchResult: false,
    showDatePickers: false,
    hasRequestedDiscounts: false,
    isLoadingGetDiscount: false,
    isUpdatingDiscount: false,
    isRepeatedFile: false,
    showPreviewTable: false,
    isUploadingInventory: false,
    isReadingFile: false,
    hasNoComboSearchResult: false,
    hasNoComboProductSearchResult: false,
    showComboDatePickers: false,
    showMobileDisableNotification: false,
    isFromDashboardMobile: false,
  },
  data: {
    inventory: [] as InventoryItem[]
  },
  resume: {
    totalSkus: 0,
    totalCost: 0
  },
  pagination: {
    totalPages: 0,
    currentPage: 1,
  },
  search: {
    currentSearchedValue: '',
    currentComboSearchedValue: ''
  },
  detail: {
    product: {} as Product,
    discounts: [] as ProductDiscountTableItem[],
    categories: [] as ProductCategory[],
    currentDiscount: {} as ProductDiscount,
  },
  errors: {
    getInventory: false,
    getProductDetail: false,
    getProductDiscounts: false,
    getCombos: false,
  },
  uploadedFile: {
    name: '',
    data: [] as InventoryFileObject[]
  },
  combos: {
    data: [] as ComboTableItem[],
    pagination: {
      currentPage: 1,
      totalPages: 0
    },
    totalActive: 0,
  },
  currentCombo: {
    combo: {} as Combo,
    products: [] as ComboProduct[],
    searchedProducts: [] as SearchedProduct[],
    customerPrice: 0
  }
}

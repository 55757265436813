import { useDispatch, useSelector } from 'react-redux';
import { InventoryFileItem } from '@modules/inventory/entities';
import { actions, inventorySearchSelector, inventoryUploadFileSelector } from '@modules/inventory/store';
import { actions as globalActions, currentUserSelector } from '@config/store';
import { useCallback } from 'react';
import {
  fetchGetInventory, fetchGetLastDocumentName, fetchGetPage,
  fetchSearchProduct, fetchSetNewDocument, fetchSetNewInventory
} from '@modules/inventory/services';
import { getInventoryToExport } from '@modules/inventory/utils';
import Clevertap from '@utils/clevertap';
import { INVENTORY_GET_DATA, INVENTORY_SEARCH, INVENTORY_UPLOAD } from '@constants';

const useInventoryFetch = () => {
  const user = useSelector(currentUserSelector);
  const uploadFile = useSelector(inventoryUploadFileSelector);
  const dispatch = useDispatch();
  const currentSearchedValue = useSelector(inventorySearchSelector);

  const getInventory = useCallback((sort?: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetInventory(sort)
      .then((data) => {
        dispatch(actions.setInventory(data.inventory));
        dispatch(actions.setResume({ totalSkus: data.totalSkus, totalCost: data.totalCount }));
        dispatch(actions.setTotalPages(data.totalPages));
        dispatch(actions.setGetInventoryError(false));
        Clevertap.pushCheckSuccessEvent(INVENTORY_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setInventory([]));
        dispatch(actions.setGetInventoryError(true));
        Clevertap.pushCheckSuccessEvent(INVENTORY_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);


  const searchProduct = useCallback((value: string): void => {
    dispatch(actions.setIsLoading(true));
    fetchSearchProduct(value)
      .then((data) => {
        dispatch(actions.setInventory(data.inventory));
        dispatch(actions.setGetInventoryError(false));
        dispatch(actions.setNoSearchReasults(!data.inventory.length));
        dispatch(actions.setTotalPages(data.totalPages));
        dispatch(actions.setCurrentPage(1));
        dispatch(actions.setCurrentSearchedValue(value));
        if (value) Clevertap.pushEvent(INVENTORY_SEARCH, { key: value, coincidenceQuantity: data.inventory.length });
      })
      .catch((error) => {
        dispatch(actions.setGetInventoryError(true));
        if (value) Clevertap.pushEvent(INVENTORY_SEARCH, { key: value }, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch]);

  const getPage = useCallback((page: number = 1): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetPage(page - 1, currentSearchedValue)
      .then((data) => {
        dispatch(actions.setInventory(data));
        dispatch(actions.setGetInventoryError(false));
        dispatch(actions.setCurrentPage(page));
      })
      .catch(() => {
        dispatch(actions.setInventory([]));
        dispatch(actions.setGetInventoryError(true));
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [currentSearchedValue, dispatch]);

  const isValidFile = (fileRows: InventoryFileItem[]): boolean => {
    const rejectFile = (message: string, line: number): void => {
      dispatch(actions.setIsReadingdFile(false));
      dispatch(actions.setIsLoading(false));
      dispatch(actions.setIsUploadingInventory(false));
      dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: `${message}. Fila: ${line + 2}`
      }));
    };
    for (let i = 0; i < fileRows.length; i++) {
      const row = fileRows[i];
      if ((!row.CANTIDAD && +row.CANTIDAD !== 0) || (!row.PRECIO_DE_COMPRA && +row.PRECIO_DE_COMPRA !== 0)
        || (!row.PRECIO_DE_VENTA && +row.PRECIO_DE_VENTA !== 0) || !row.EAN
        || !String(row.EAN).trim() || !row.DESCRIPCION || !String(row.DESCRIPCION).trim()) {
        rejectFile('Lo sentimos. El documento posee campos relevantes sin información', i);
        return false;
      }
      if (isNaN(+row.CANTIDAD) || isNaN(+row.PRECIO_DE_COMPRA)
        || isNaN(+row.PRECIO_DE_VENTA) || isNaN(+row.EAN)
        || (!!row.IVA && isNaN(+row.IVA))) {
        rejectFile('Lo sentimos. El documento posee campos numéricos con datos invalidos', i);
        return false;
      }
      if (+row.CANTIDAD <= 0 || +row.PRECIO_DE_COMPRA <= 0 || +row.PRECIO_DE_VENTA <= 0 || +row.IVA < 0) {
        rejectFile('Lo sentimos. El documento debe contener valores numéricos mayores a cero', i);
        return false;
      }
    }
    return true
  };

  const setFile = (fileRows: InventoryFileItem[], fileName: string) => {
    if (isValidFile(fileRows)) {
      fetchGetLastDocumentName(user.storeId)
        .then((name) => {
          if (name === fileName) {
            dispatch(actions.setIsRepeatedFile(true));
          } else {
            dispatch(actions.setUploadedFileName(fileName));
            dispatch(actions.setIsRepeatedFile(false));
            dispatch(actions.setUploadedFileData(getInventoryToExport(fileRows)));
            dispatch(actions.setShowPreviewTable(true));
          }
        })
        .catch(() => {
          dispatch(actions.setUploadedFileData([]));
          dispatch(globalActions.notification({
            show: true,
            state: 'error',
            message: 'Lo sentimos. Ha ocurrido un error y no pudimos procesar su archivo'
          }));
        })
        .finally(() => {
          dispatch(actions.setIsLoading(false));
          dispatch(actions.setIsUploadingInventory(false));
          dispatch(actions.setIsReadingdFile(false));
        })
    }
  };

  const uploadInventoryFile = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchSetNewDocument(uploadFile.name, user.storeId)
      .then(() => fetchSetNewInventory(uploadFile.data))
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Sus productos han sido cargados con éxito'
        }));
        Clevertap.pushCheckSuccessEvent(INVENTORY_UPLOAD);
        dispatch(actions.setShowPreviewTable(false));
        dispatch(actions.setUploadedFileData([]));
        setTimeout(() => getInventory(), 1000);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos cargar sus productos'
        }));
        Clevertap.pushCheckSuccessEvent(INVENTORY_UPLOAD, error?.message);
        dispatch(actions.setIsLoading(false));
      })
      .finally(() => {
        dispatch(actions.setIsRepeatedFile(false));
      });
  }, [dispatch, getInventory, uploadFile.data, uploadFile.name, user.storeId]);

  return {
    getInventory,
    searchProduct,
    getPage,
    uploadInventoryFile,
    setFile,
  }
}

export default useInventoryFetch;

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@config/store";
import {
  CombosDataState,
  CurrentComboState,
  InventoryDataState, InventoryErrorsState, InventoryPaginationState, InventoryResumeState,
  InventorySearchState,
  InventoryUiState, InventoryUploadFileState, ProductDetailState
} from ".";

export const inventoryUiSelector = createSelector(
  (state: RootState) => state.inventory.ui,
  (state: InventoryUiState) => state
);

export const inventoryPaginationSelector = createSelector(
  (state: RootState) => state.inventory.pagination,
  (state: InventoryPaginationState) => state
);

export const inventorySearchSelector = createSelector(
  (state: RootState) => state.inventory.search,
  (state: InventorySearchState) => state.currentSearchedValue
);

export const inventoryDataSelector = createSelector(
  (state: RootState) => state.inventory.data,
  (state: InventoryDataState) => state.inventory
);

export const inventoryResumeSelector = createSelector(
  (state: RootState) => state.inventory.resume,
  (state: InventoryResumeState) => state
);

export const inventoryErrorsSelector = createSelector(
  (state: RootState) => state.inventory.errors,
  (state: InventoryErrorsState) => state
);

export const productDetailSelector = createSelector(
  (state: RootState) => state.inventory.detail,
  (state: ProductDetailState) => state.product
);

export const productDiscountsSelector = createSelector(
  (state: RootState) => state.inventory.detail,
  (state: ProductDetailState) => state.discounts
);

export const currentDiscountSelector = createSelector(
  (state: RootState) => state.inventory.detail,
  (state: ProductDetailState) => state.currentDiscount
);

export const productCategoriesSelector = createSelector(
  (state: RootState) => state.inventory.detail,
  (state: ProductDetailState) => state.categories
);

export const inventoryUploadFileSelector = createSelector(
  (state: RootState) => state.inventory.uploadedFile,
  (state: InventoryUploadFileState) => state
);

export const combosDataSelector = createSelector(
  (state: RootState) => state.inventory.combos,
  (state: CombosDataState) => state.data
);

export const combosPaginationSelector = createSelector(
  (state: RootState) => state.inventory.combos,
  (state: CombosDataState) => state.pagination
);

export const combosResumeSelector = createSelector(
  (state: RootState) => state.inventory.combos,
  (state: CombosDataState) => state.totalActive
);

export const combosSearchSelector = createSelector(
  (state: RootState) => state.inventory.search,
  (state: InventorySearchState) => state.currentComboSearchedValue
);

export const currentComboSelector = createSelector(
  (state: RootState) => state.inventory.currentCombo,
  (state: CurrentComboState) => state.combo
);

export const combosSearchProductSelector = createSelector(
  (state: RootState) => state.inventory.currentCombo,
  (state: CurrentComboState) => state.searchedProducts
);

export const currentComboProductsSelector = createSelector(
  (state: RootState) => state.inventory.currentCombo,
  (state: CurrentComboState) => state.products
);

export const currentComboPriceSelector = createSelector(
  (state: RootState) => state.inventory.currentCombo,
  (state: CurrentComboState) => state.customerPrice
);
import React, { FC } from "react";
import styles from "./closures-mobile-history-paginator-button.module.sass";
import useClosureHistory from "@modules/closures/hooks/use-closure-history";
import { ChiperButton } from "@chiper-inc/sb-web-chiper";

interface ClosuresMobileHistoryPaginatorButtonProps {}

const ClosuresMobileHistoryPaginatorButton: FC<ClosuresMobileHistoryPaginatorButtonProps> = () => {
  const { changePage, pagination } = useClosureHistory();

    return (
      <div className={styles.button}>
        <ChiperButton text="Ver más" onClick={() => changePage(pagination.current + 1, true)} uiType="outlined" />
      </div>
    );
  };

export default ClosuresMobileHistoryPaginatorButton;

import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { profileErrorsSelector, profileOptionsSelector, profileUiSelector, storeInformationDataSelector } from '@modules/profile/store';
import styles from './business-information.module.sass';
import useProfileFetch from '@modules/profile/hooks/use-profile-fetch';
import UiNoDataMessage from '@ui/ui-no-data-message';
import { getStoreInfotmationInput } from '@modules/profile/utils';
import ProfileForm from '@modules/profile/components/profile-form';
import { storeInformationSchema } from '@modules/profile/schemas';

interface BusinessInformationProps { }

const BusinessInformation: FC<BusinessInformationProps> = () => {
  const errors = useSelector(profileErrorsSelector);
  const storeInformation = useSelector(storeInformationDataSelector);
  const { hasFieldOptions, isRequestingProfile, hasProfileData } = useSelector(profileUiSelector);
  const { dailySales, municipalities, storeTypes } = useSelector(profileOptionsSelector);
  const { getProfileData, getFieldOptions, updateStoreInformation } = useProfileFetch();

  useEffect(() => {
    if (!hasFieldOptions) getFieldOptions();
  }, [getFieldOptions, hasFieldOptions]);

  useEffect(() => {
    if (!hasProfileData) getProfileData();
  }, [getProfileData, hasProfileData]);

  if (errors.getProfileData && !hasProfileData) return (
    <UiNoDataMessage message='No pudimos obetener sus datos de negocio' />
  );

  if (isRequestingProfile) return <span className={styles.loading}>Cargando...</span>

  return (
    <>
      <h1 className={styles.title}>Datos del negocio</h1>
      <ProfileForm
        image={storeInformation.storeImageUrl}
        inputs={getStoreInfotmationInput(storeTypes, municipalities, dailySales)}
        values={storeInformation}
        onSubmit={updateStoreInformation}
        schema={storeInformationSchema}
      />
    </>
  );
}

export default BusinessInformation;

import React, { FC, useCallback } from 'react';
import styles from './repeated-file.module.sass';
import UiModal from '@ui/ui-modal';
import stopImage from '../../../../assets/img/stop-barrier.png';
import UiButton from '@ui/ui-button';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/inventory/store';

interface RepeatedFileProps { }

const RepeatedFile: FC<RepeatedFileProps> = () => {
  const dispatch = useDispatch();

  const handleCloseModal = useCallback((): void => {
    dispatch(actions.setIsRepeatedFile(false));
  }, [dispatch]);

  const handleUploadOtherFile = useCallback((): void => {
    dispatch(actions.setIsRepeatedFile(false));
    dispatch(actions.setIsUploadingInventory(true));
  }, [dispatch]);

  return (
    <UiModal onCloseClick={handleCloseModal}>
      <div className={styles.wrapper}>
        <img src={stopImage} alt='stop' className={styles.image} />

        <span className={styles.title}>
          Archivo repetido
        </span>

        <span className={styles.description}>
          Ya has realizado una carga de inventario con este archivo,
          cambia el nombre del archivo si se trata de una nueva lista de productos
          o intenta con otro archivo
        </span>

        <UiButton text='Subir otro archivo' leftIcon='icon-upload' onClick={handleUploadOtherFile} />
      </div>
    </UiModal>
  )
}

export default RepeatedFile;

import * as yup from 'yup';
import { t } from 'i18next'
import { PASSWORD_LENGTH, PHONE_LENGTH, REG_EXP_ONLY_NUMBERS } from '@constants';

export const changePasswordSchema = yup.object().shape({
  phone: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  password: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PASSWORD_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PASSWORD_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  confirmationPassword: yup.string()
    .required(t('errors.required'))
    .oneOf([yup.ref('password'), null], t('errors.passwords_no_match')),
});

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ACCESS_ADMIN, MIN_WIDTH_DESKTOP } from '@constants';
import { store } from '@config/store';
import DesktopRouter from './apps/desktop/router/desktop.router';
import MobileRouter from './apps/mobile/router/mobile-router.router';
import Clevertap from '@utils/clevertap';

function App() {

  useEffect(() => {
    Clevertap.pushSimpleEvent(ACCESS_ADMIN);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter basename='/'>
        {window.innerWidth > MIN_WIDTH_DESKTOP && <DesktopRouter />}
        {window.innerWidth <= MIN_WIDTH_DESKTOP && <MobileRouter />}
      </BrowserRouter>
    </Provider>
  );
}

export default App;

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions } from "@config/store";
import { fetchGetClosuresHistory } from "../services";
import { DEFAULT_PAGE_HISTORY } from "../constants";
import { actions, closuresHistorySelector } from "../store";

const useClosureHistory = () => {
  const dispatch = useDispatch();
  const history = useSelector(closuresHistorySelector);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 0, total: 0 });
  const isMounted = useRef(false);

  const getPage = useCallback((page: number, isMobile?: boolean) => {
    setIsLoading(true);
    fetchGetClosuresHistory({ page })
      .then((data) => {
        const totalPages = data.slice(-1)[0].pages ?? 0;
        if (isMobile) { 
          let currentHistoric = [...history];
          let newHistorics = [...data.slice(0, -1)];
          dispatch(actions.setClosureHistory([...currentHistoric, ...newHistorics]))
        };
        if (!isMobile) {
          dispatch(actions.setClosureHistory(data.slice(0, -1)))
        };
        setPagination({ current: page, total: totalPages })
        dispatch(actions.setIsEmptyClosures(page === DEFAULT_PAGE_HISTORY && !totalPages))
      })
      .catch(() => dispatch(globalActions.notification({
        show: true,
        message: "Lo sentimos. No hemos podido acceder a su historial de cierres",
        state: "error",
      })))
      .finally(() => setIsLoading(false));
  }, [dispatch, history]);

  useEffect(() => {
    if (!isMounted.current) {
      getPage(DEFAULT_PAGE_HISTORY);
      isMounted.current = true
    }
  }, [getPage]);

  const changePage = (page: number, isMobile?: boolean) => {
    if (!isLoading) getPage(page, isMobile)
  }

  return {
    isLoading,
    pagination,
    changePage,
    getPage
  };
}

export default useClosureHistory;

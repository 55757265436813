import React, { FC, useCallback, useEffect } from 'react';
import UiModal from '@ui/ui-modal';
import styles from './providers-form.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { actions, providersUiSelector, providersUpdateSelector } from '@modules/providers/store';
import UiInputField from '@ui/ui-input-field';
import { useFormik } from 'formik';
import { getForm } from '@modules/providers/utils';
import { currentUserSelector } from '@config/store';
import { providersSchema } from '@modules/providers/schemas';
import UiButton from '@ui/ui-button';
import { Provider, ProvidersForm as ProvidersFormInterface } from '@modules/providers/entities';
import useProvidersFetch from '@modules/providers/hooks/use-providers-fetch';

interface ProvidersFormProps { }

const ProvidersForm: FC<ProvidersFormProps> = () => {
  const user = useSelector(currentUserSelector);
  const { showFirstInputs } = useSelector(providersUiSelector);
  const provider = useSelector(providersUpdateSelector);
  const dispatch = useDispatch();
  const { createProvider, updateProvider } = useProvidersFetch();

  useEffect(() => {
    dispatch(actions.setShowFirstInputs(true));
  }, [dispatch]);

  const handleCloseModal = useCallback((): void => {
    dispatch(actions.setProviderToUpdate({} as Provider));
    dispatch(actions.setShowModal(false));
  }, [dispatch]);

  const handleSubmit = useCallback((values: ProvidersFormInterface): void => {
    if (provider.id) updateProvider(values);
    else createProvider(values);
  }, [createProvider, provider.id, updateProvider]);

  const form = useFormik({
    initialValues: getForm(user.storeId, provider),
    onSubmit: handleSubmit,
    validationSchema: providersSchema,
  });

  const handleNextClick = useCallback((): void => {
    dispatch(actions.setShowFirstInputs(false));
  }, [dispatch]);

  const isNextButtonDisabled = (): boolean => {
    return !!form.errors.nameProvider || !form.values.nameProvider
      || !!form.errors.nit || !form.values.nit
  };

  return (
    <UiModal onCloseClick={handleCloseModal}>
      <form className={styles.wrapper} onSubmit={form.handleSubmit}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>
            {`${provider.id ? 'Editar' : 'Nuevo'} proveedor`}
          </span>
          {provider.codeProvider && (
            <span className={styles.code}>
              Codigo:
              <span className={styles.numberCode}> {provider.codeProvider}</span>
            </span>
          )}
        </div>
        {showFirstInputs ? (
          <>
            <div className={styles.inputs}>
              <UiInputField
                type='text'
                name='nameProvider'
                label='Nombre'
                value={form.values.nameProvider}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.errors.nameProvider}
                hasError={!!form.errors.nameProvider && !!form.touched.nameProvider}
              />
              <UiInputField
                type='text'
                name='nit'
                label='NIT'
                value={form.values.nit}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.errors.nit}
                hasError={!!form.errors.nit && !!form.touched.nit}
              />
            </div>
            <UiButton text='Siguiente' isFullWidth onClick={handleNextClick} isDisabled={isNextButtonDisabled()} />
          </>
        ) : (
          <>
            <div className={styles.inputs}>
              <UiInputField
                type='text'
                name='contactName'
                label='Nombre del contacto'
                value={form.values.contactName}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.errors.contactName}
                hasError={!!form.errors.contactName && !!form.touched.contactName}
              />
              <UiInputField
                type='text'
                name='phone'
                label='Celular'
                value={form.values.phone}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.errors.phone}
                hasError={!!form.errors.phone && !!form.touched.phone}
              />
              <UiInputField
                type='text'
                name='email'
                label='Correo'
                value={form.values.email}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.errors.email}
                hasError={!!form.errors.email && !!form.touched.email}
              />
            </div>
            <UiButton text='Guardar' isFullWidth type='submit' key='submit' />
          </>
        )}
      </form>
    </UiModal>
  )
}

export default ProvidersForm;

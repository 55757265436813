import React, { FC, memo, MouseEvent, ReactElement } from 'react';
import styles from './ui-modal.module.sass';

interface UiModalProps {
  onCloseClick: () => void,
  children?: ReactElement | string,
}

const UiModal: FC<UiModalProps> = ({ onCloseClick, children }) => {

  const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
    if (((e.target as HTMLElement).id) === 'uiModal') onCloseClick();
  };

  return (
    <div className={styles.container} id="uiModal" onClick={handleBackgroundClick}>
      <div className={styles.modal}>
        <div className={styles.icon} onClick={onCloseClick} data-testid='close-modal-icon'>
          <span className='icon-close' />
        </div>
        {children}
      </div>
    </div>
  );
};

export default memo(UiModal);

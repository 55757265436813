import React, { FC, useCallback } from 'react'
import styles from './collaborators-delete-modal.module.sass';
import { actions } from '@modules/collaborators/store';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UiModal from '../../../../ui/ui-modal';
import UiButton from '../../../../ui/ui-button';
import useCollaboratorsFetch from '@modules/collaborators/hooks/use-collaborators-fetch';

interface CollaboratorsDeleteModalProps { }

const CollaboratorsDeleteModal: FC<CollaboratorsDeleteModalProps> = () => {
  const { collaboratorId } = useParams();
  const dispatch = useDispatch();
  const { deleteCollaborator } = useCollaboratorsFetch();

  const handleDeleteClick = useCallback((): void => {
    if (collaboratorId) deleteCollaborator(collaboratorId);
  }, [collaboratorId, deleteCollaborator]);

  const onCancelDelete = useCallback((): void => {
    dispatch(actions.setShowDeleteCollaboratorModal(false));
  }, [dispatch]);

  return (
    <UiModal onCloseClick={onCancelDelete}>
      <div className={styles.conatiner}>
        <h1 className={styles.title}>Eliminar colaborador</h1>

        <span className={styles.description}>
          ¿Estás seguro de eliminar a este colaborador?
        </span>

        <div className={styles.buttons}>
          <UiButton onClick={handleDeleteClick} text='Eliminar' isFullWidth />

          <UiButton onClick={onCancelDelete} text='Cancelar' isSecondary isFullWidth />
        </div>
      </div>
    </UiModal>
  );
}

export default CollaboratorsDeleteModal;

import { createSelector } from "@reduxjs/toolkit";
import { GlobalDataState, RootState } from "@config/store";
import { Toast, Notification } from "@entities";
import { GlobalUiState } from ".";

export const globalUiSelector = createSelector(
  (state: RootState) => state.global.ui,
  (state: GlobalUiState) => state
);

export const globalUiNotficationSelector = createSelector(
  (state: RootState) => state.global.ui.notification,
  (state: Notification) => state
);

export const globalUiToastSelector = createSelector(
  (state: RootState) => state.global.ui.toast,
  (state: Toast) => state
);

export const currentUserSelector = createSelector(
  (state: RootState) => state.global.data,
  (state: GlobalDataState) => state.currentUser
);

export const currentUserPermissionsSelector = createSelector(
  (state: RootState) => state.global.data,
  (state: GlobalDataState) => state.permissions
);

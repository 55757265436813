import React, { FC } from 'react'
import { useSelector } from 'react-redux';
import { customersResumeSelector } from '@modules/customers/store';
import { formatMoney } from '@utils/format-money';
import styles from './customers-resume-mobile.module.sass';

interface CustomersResumeMobileProps { }

const CustomersResumeMobile: FC<CustomersResumeMobileProps> = () => {
  const resume = useSelector(customersResumeSelector);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Deuda total</h1>
      <span className={styles.total}>
        {formatMoney(resume.totalLoan, 0)}
      </span>
      <h1 className={styles.title}>Clientes</h1>

      <div className={styles.boxes}>
        <div className={styles.box}>
          Con deuda:
          <span className={styles.value}>{resume.totalCustomerLoan}</span>
        </div>
        <div className={styles.box}>
          Registrados:
          <span className={styles.value}>{resume.totalCustomers}</span>
        </div>
      </div>
    </div>
  )
}

export default CustomersResumeMobile;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState, PhoneInterface } from './auth.state';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoadingLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingLogin = payload;
    },
    setShowUserInputs: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showUserInputs = payload;
    },
    setShowPasswordInputs: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showPasswordInputs = payload;
    },
    setPasswordRecuperationPage: (state, { payload }: PayloadAction<string>) => {
      state.ui.passwordRecuperationPage = payload;
    },
    setPhoneNumber: (state, { payload }: PayloadAction<PhoneInterface>) => {
      state.data.phoneNumber = payload;
    },
    setValidationCode: (state, { payload }: PayloadAction<string>) => {
      state.data.validationCode = payload;
    }
  },
});

export const actions = authSlice.actions;
export default authSlice.reducer;

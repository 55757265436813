import { useDispatch, useSelector } from 'react-redux';
import { ProductDiscount, ProductDiscountForm } from '@modules/inventory/entities';
import { actions, productDetailSelector } from '@modules/inventory/store';
import { actions as globalActions } from '@config/store';
import { useCallback } from 'react';
import { fetchCreateDiscount, fetchGetDiscount, fetchGetProductDiscounts, fetchUpdateDiscount } from '@modules/inventory/services';
import useInvetory from '../use-invetory';
import Clevertap from '@utils/clevertap';
import { DISCOUNTS_GET_DATA, DISCOUNT_CREATION, DISCOUNT_EDITION } from '@constants';

const useDiscountsFetch = () => {
  const product = useSelector(productDetailSelector);
  const dispatch = useDispatch();
  const { setUpdatedDiscount } = useInvetory();

  const getDiscounts = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetProductDiscounts(product.inventoryId)
      .then((data) => {
        dispatch(actions.setProductDiscounts(data));
        dispatch(actions.setGetProductDiscountsError(false));
        dispatch(actions.setHasRequestedDiscounts(true));
        dispatch(actions.setCurrentDiscount({} as ProductDiscount));
        Clevertap.pushCheckSuccessEvent(DISCOUNTS_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setGetProductDiscountsError(true));
        Clevertap.pushCheckSuccessEvent(DISCOUNTS_GET_DATA, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)))
  }, [dispatch, product.inventoryId]);

  const createDiscount = useCallback((values: ProductDiscountForm): void => {
    if (!values.quantity) values = { ...values, quantity: '0' };
    dispatch(actions.setIsLoading(true));
    fetchCreateDiscount(values)
      .then((isRepeteadRecurrence) => {
        dispatch(globalActions.notification({
          show: true,
          state: isRepeteadRecurrence ? 'error' : 'success',
          message: isRepeteadRecurrence
            ? 'Este producto ya tiene esa recurrencia'
            : 'Descuento creado con éxito'
        }));
        if (!isRepeteadRecurrence) {
          getDiscounts();
          Clevertap.pushEvent(DISCOUNT_CREATION, { sku: product.sku, isRecurrent: !!values.recurrency.length });
        } else Clevertap.pushCheckSuccessEvent(DISCOUNT_CREATION, 'Has repeated recurrency');
        dispatch(actions.setShowDatePickers(false));
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos crear el descuento'
        }));
        Clevertap.pushCheckSuccessEvent(DISCOUNT_CREATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, getDiscounts, product.sku]);

  const getDiscount = useCallback((id: string): void => {
    dispatch(actions.setIsLoadingGetDiscount(true));
    fetchGetDiscount(id)
      .then((data) => {
        dispatch(actions.setCurrentDiscount(data))
        dispatch(actions.setIsUpdatingDiscount(true))
      })
      .catch(() => dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Lo sentimos. Momentáneamente no puede editar este descuento'
      })))
      .finally(() => dispatch(actions.setIsLoadingGetDiscount(false)))
  }, [dispatch]);

  const updateDiscount = useCallback((values: ProductDiscountForm): void => {
    if (!values.quantity) values = { ...values, quantity: '0' };
    dispatch(actions.setIsLoading(true));
    fetchUpdateDiscount(values)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Descuento actualizado con éxito'
        }));
        dispatch(actions.setShowDatePickers(false));
        dispatch(actions.setIsUpdatingDiscount(false));
        setUpdatedDiscount(values);
        Clevertap.pushCheckSuccessEvent(DISCOUNT_EDITION);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. No pudimos actualizar el descuento'
        }));
        Clevertap.pushCheckSuccessEvent(DISCOUNT_CREATION, error?.message);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, setUpdatedDiscount]);

  return {
    getDiscounts,
    createDiscount,
    getDiscount,
    updateDiscount
  }
}

export default useDiscountsFetch;

import React, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback } from 'react'
import { actions as globalActions } from '@config/store';
import { PERMISSIONS_DESCRIPTION, PERMISSIONS_FORM } from '@modules/collaborators/constants';
import { getNewPermissions } from '@modules/collaborators/utils';
import UiSwitch from '@ui/ui-switch';
import { useDispatch } from 'react-redux';
import styles from './collaborators-form-permissions.module.sass';

interface CollaboratorsFormPermissionsProps {
  permissions: string[];
  setPermissions: Dispatch<SetStateAction<string[]>>;
  hasPermissionsBlocked: boolean;
}

const CollaboratorsFormPermissions: FC<CollaboratorsFormPermissionsProps> = ({
  permissions, setPermissions, hasPermissionsBlocked
}) => {
  const dispatch = useDispatch();

  const handleToggleChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    setPermissions(getNewPermissions(permissions, target.value))
  }, [permissions, setPermissions]);

  const isChecked = (id: string): boolean => !!permissions.find((p) => p === id);

  const handleBlockedClick = useCallback(() => {
    dispatch(globalActions.notification({
      show: true,
      state: 'normal',
      message: 'No puedes modificar los permisos de un Dueño'
    }))
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <p className={styles.formDescription}>{PERMISSIONS_DESCRIPTION}</p>

      <div className={styles.permissions}>

        {PERMISSIONS_FORM.map((permission, i) => (
          <div className={styles.permission} key={`permissions-form-item-${String(i)}`}>

            <div className={styles.information}>
              <p className={styles.title}>
                {permission.title}
              </p>
              <p className={styles.description}>
                {permission.description[0]}
                {!isChecked(permission.id) &&
                  <span className={styles.noChecked}>
                    no
                  </span>
                }
                {permission.description[1]}
              </p>
            </div>

            <UiSwitch
              onChange={handleToggleChange}
              name={permission.name}
              value={permission.id}
              isChecked={isChecked(permission.id)}
              isBlocked={hasPermissionsBlocked}
              onBlockedClick={handleBlockedClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollaboratorsFormPermissions;

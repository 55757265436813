import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from './reports.state';
import { ReportsTabs } from '../constants';
import { ResumeData } from '../entities/resume-data.entity';
import { DatesFilter, SalesData } from '../entities';
import { TransactionsData } from '../entities/transaction.entities';
import { SkusData } from '../entities/skus.entities';
import { SlowTurnoverData } from '../entities/slow-turnover.entities';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setIsLoadingReports: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingReports = payload;
    },
    setIsLoadingSalesData: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingSales = payload;
    },
    setIsLoadingTransactionsData: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingTransactions = payload;
    },
    setIsLoadingSkusData: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingSkus = payload;
    },
    setIsLoadingSlowTurnoverData: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingSlowTurnover = payload;
    },
    setTab: (state, action: PayloadAction<ReportsTabs>) => {
      state.ui.tab = action.payload;
    },
    setResumeData: (state, action: PayloadAction<ResumeData>) => {
      state.data.resumeData = action.payload;
    },
    setDatesFilter: (state, action: PayloadAction<DatesFilter>) => {
      state.data.dates = action.payload;
    },
    setSalesData: (state, action: PayloadAction<SalesData>) => {
      state.data.salesData = action.payload;
    },
    setTransactionsData: (state, action: PayloadAction<TransactionsData>) => {
      state.data.transactionsData = action.payload;
    },
    setSkusData: (state, action: PayloadAction<SkusData>) => {
      state.data.skusData = action.payload;
    },
    setSlowTurnoverData: (state, action: PayloadAction<SlowTurnoverData>) => {
      state.data.slowTurnoverData = action.payload;
    },
  },
});

export const actions = reportsSlice.actions;
export default reportsSlice.reducer;

import axios from 'axios';
import { CashHistoryItem, Closure } from '../entities';
import { DEFAULT_LIMIT_HISTORY, DEFAULT_PAGE_HISTORY } from '../constants';

export const fetchGetClosureDetail = (id: number): Promise<Closure> => (
  axios.get<Closure>(`/cash/detail-close-box/${id}`)
    .then((response) => response.data)
);

export const fetchGetClosuresHistory = ({
  limit = DEFAULT_LIMIT_HISTORY,
  page = DEFAULT_PAGE_HISTORY,
}): Promise<CashHistoryItem[]> => (
  axios.get<CashHistoryItem[]>(`cash/history?limit=${limit}&skip=${limit * (page - 1)}&getCount=true`)
    .then((response) => response.data)
);

export const fetchDownloadClosure = (cashId: string | number, storeId: string | number): Window | null => (
  window.open(`${process.env.REACT_APP_URL_POS_API}/cash/download-inform/${storeId}/${cashId}`, 'blank')
);

import React, { FC } from "react";
import styles from "./ui-skeleton.module.sass";

interface UiSkeletonProps {
  width?: string;
  height?: string;
  className?: string;
}

const UiSkeleton: FC<UiSkeletonProps> = ({ width, height, className = "" }) => {

  return (
    <div
      className={`${styles.skeleton} ${className}`} style={{ width, height }}
      data-testid="ui-skeleton"
    />
  );
}

export default UiSkeleton;

import React, { FC, memo } from 'react';
import { formatIndex } from '@modules/bills/utils';
import { formatMoney } from '@utils/format-money';
import styles from './bill-devolution-card.module.sass';

interface BillDevolutionCardProps {
  index: number;
  name: string;
  total: string
}

const BillDevolutionCard: FC<BillDevolutionCardProps> = ({ index, name, total }) => {

  return (
    <div className={styles.wrapper}>
      <span className={styles.index}>{formatIndex(index)}</span>
      <span className={styles.name}>
        {name}
        <p className={styles.subtitle}>Unidad</p>
      </span>
      <span className={styles.total}>{formatMoney(+total)}</span>
    </div>
  );
}

export default memo(BillDevolutionCard);

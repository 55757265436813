import * as yup from 'yup';
import { t } from 'i18next'
import { PHONE_LENGTH, REG_EXP_ONLY_LETTERS, REG_EXP_ONLY_NUMBERS } from '@constants';

export const businessDataSchema = yup.object().shape({
  storeName: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters')),
  numberStore: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
});

export const newConsecutiveSchema = yup.object().shape({
  dianAuthNumber: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers')),
  prefix: yup.string()
    .required(t('errors.required')),
  initConsecutive: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers')),
  endConsecutive: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers')),
    dianAuthDateExp: yup.date()
    .required(t('errors.required')),
    dianAuthDate: yup.date()
    .required(t('errors.required'))
    .min(yup.ref('dianAuthDateExp')),
});

import React, { FC, memo } from 'react';
import { customerDetailSelector } from '@modules/customers/store';
import { getCustomerDetailResumeItems } from '@modules/customers/utils';
import { useSelector } from 'react-redux';
import styles from './customer-detail-resume.module.sass';

interface CustomerDetailResumeProps {}

const CustomerDetailResume: FC<CustomerDetailResumeProps> = () => {
  const customer = useSelector(customerDetailSelector);
  return (
    <div className={styles.wrapper}>
      {getCustomerDetailResumeItems(customer).map((item, i) => (
        <div className={styles.item} key={`customers-item-resume-${String(i)}`}>
          {item.title}
          <span className={`${styles.value} ${item.isRed && styles.red}`}>
            {item.value}
          </span>
        </div>
      ))}
    </div>
  )
}

export default memo(CustomerDetailResume);

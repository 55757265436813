import { UiSelectOption } from "@entities";

export interface InventoryItem {
  id: string;
  ean: string;
  image: string;
  own: boolean;
  iva: number;
  ico: number;
  categoryId: number;
  isActive: boolean;
  deletedat?: Date;
  packagingType: string;
  measurementUnit: string;
  brandName: string;
  brandId?: any;
  name: string;
  customerPrice: number;
  managerPrice: number;
  discount?: boolean;
  margin: string;
  stock: number;
  total: number;
  inventoryDay: number;
  inventoryItemDays: number;
}

export interface Inventory {
  inventory: InventoryItem[];
  totalPages: number;
  totalSkus: number;
  totalCount: string;
}

export interface Product {
  inventoryId: string;
  referenceTypeId: string;
  sku: string;
  imageUrl: string;
  name: string;
  brandName: string;
  totalPrice: number;
  subtotalPrice: number;
  totalTax: number;
  managerTotal: number;
  tax: number;
  stock: number;
  discount: number;
  packagingType: string;
  operator: number;
  soldByWeight: boolean;
  salesUnit: string;
  categoryId: string;
  isActive: boolean;
  iva: number;
  ico: number;
  own: boolean;
  withOutSku: boolean;
  managerPrice?: string | number;
  createdAt: Date | string;
  updatedAt: Date | string;
  action?: string;
}

export interface ProductDetail {
  status: number;
  ifExist: boolean;
  product: Product;
}

export interface ProductCategory {
  id: string;
  categoryId: string;
  chiperId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface ProductDiscountTableItem {
  id: string;
  inventoryId: string;
  customerTotal: string;
  discountPrice: string;
  initAt: string;
  isActive: boolean;
  recurrency: boolean;
}

export interface Tab {
  title: string;
  path: string;
}

export interface InventoryTabs {
  products: string;
  providers: string;
  combos: string;
  productDetail: string;
  productDiscounts: string;
}

export interface ProductInput {
  name: string;
  title: string;
  isSelect: boolean;
  options: UiSelectOption[];
}

export interface ProductsCategories {
  id: string;
  categoryId: string;
  chiperId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface ProductDiscount {
  customerTotal?: string;
  id: string;
  inventoryId: string;
  customerDiscountPrice: string;
  quantity: string;
  initAt: Date;
  endAt: Date;
  image: string;
  name: string;
  recurrency: string[];
}

export interface DiscountRecurrency {
  scheduleId: string;
}

export interface ProductDiscountForm {
  id?: string;
  customerSubTotalPrice: number;
  customerDiscountPrice: string;
  quantity: string;
  initAt: Date;
  endAt?: Date;
  inventoryId: string;
  isActive: boolean;
  recurrency: DiscountRecurrency[];
}

export interface InventoryFileItem {
  EAN: string;
  IMAGEN: string;
  DESCRIPCION: string;
  MARCA: string;
  TIPO_DE_UNIDAD: string;
  PRECIO_DE_COMPRA: string;
  PRECIO_DE_VENTA: string;
  CANTIDAD: string;
  IVA: string;
  ICO: string;
}

export interface InventoryFileObject {
  brandId: string | null;
  brandName: string;
  managerPrice: number;
  ean: string;
  id: string;
  image: string;
  inventoryItemDays: number;
  isActive: number;
  iva: string;
  ico: string;
  customerPrice: number;
  margin: string;
  measurementUnit: string;
  name: string;
  own: boolean;
  packagingType: string;
  stock: number;
  failed: boolean;
  total: number;
  upload: boolean;
};

export interface ProductDetailList {
  title: string;
  value: string;
};

export enum ProductUpdateProperty {
  empty = '',
  stock = 'stock',
  managerTotal = 'managerTotal',
  totalPrice = 'totalPrice'
};
import { CashHistoryItem, Closure } from "../entities";

export interface ClosuresState {
  ui: ClosuresUiState;
  data: ClosuresDataState;
}

export interface ClosuresUiState {
  isLoadingClosure: boolean;
  isEmptyClosures: boolean;
}

export interface ClosureHistoryPaginationState {
  totalPages: number;
  currentPage: number;
}

export interface ClosureHistoryFiltersState {
  initDate: string;
  endDate: string;
}

export interface ClosureHistoryState {
  pagination: ClosureHistoryPaginationState;
  closures: CashHistoryItem[];
  filters: ClosureHistoryFiltersState;
  closureSelected: number;
}

export interface ClosuresDataState {
  history: ClosureHistoryState;
  closure: Closure;
}

export const initialState: ClosuresState = {
  ui: {
    isLoadingClosure: false,
    isEmptyClosures: false,
  },
  data: {
    history: {
      pagination: { currentPage: 0, totalPages: 0 },
      closures: [],
      filters: {
        initDate: "",
        endDate: ""
      },
      closureSelected: 0,
    },
    closure: {} as Closure
  }
}

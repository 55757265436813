import React, { FC, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions, globalUiSelector } from '@config/store';
import { STORAGE_TOKEN } from '@constants';
import UiNotification from '@ui/ui-notification/ui-notification';
import UiMobileHeader from '@ui/ui-mobile-header';
import UiLoader from '@ui/ui-loader';
import Login from '@modules/auth/pages/login-v2';
import DashboardMobile from '@modules/dashboard/pages/dashboard-mobile';
import CollaboratorCreatePassword from '@modules/collaborators/pages/collaborator-create-password';
import Customers from '@modules/customers';
import useUserPermissions from '@utils/use-user-permissions';
import ProductsSearchMobile from '@modules/dashboard/pages/products-search-mobile';
import ProductDetailMobile from '@modules/inventory/pages/product-detail-mobile';
import ProductUpdateMobile from '@modules/inventory/pages/product-update-mobile';
import LoginPasswordRecuperation from '@modules/auth/pages/login-password-recuperation';
import Closures from '@modules/closures/closures';
import Clevertap from '@utils/clevertap';

interface MobileRouterProps { }

const HeaderOutlet = () => {
  const navigate = useNavigate();
  return (
    <>
      <UiMobileHeader navigate={navigate} />
      <Outlet />
    </>
  )
};

const PrivateOutlet = () => {
  const getPermissions = useUserPermissions();

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  useEffect(() => Clevertap.identifyUser(), []);

  return !localStorage.getItem(STORAGE_TOKEN) ? <Navigate to="/login" /> : <Outlet />
};

const MobileRouter: FC<MobileRouterProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hasCurrentUser } = useSelector(globalUiSelector);

  useEffect(() => {
    const user = localStorage.getItem(STORAGE_TOKEN);
    if (!!user) {
      dispatch(actions.saveCurrentUser(JSON.parse(user)));
      dispatch(actions.setHasCurrentUser(true));
    }
  }, [dispatch, navigate]);

  return (
    <>
      <UiNotification />
      <Routes>
        {!localStorage.getItem(STORAGE_TOKEN) ? (
          <>
            <Route path="login" element={<Login />} />
            <Route path="/:moduleToRedirection" element={<Login />} />
            <Route path="collaborator/change-password/:userPhone" element={<CollaboratorCreatePassword />} />
            <Route path="/login/password-recuperation" element={<LoginPasswordRecuperation />} />
            <Route path="*" element={<Navigate replace to="login" />} />
          </>
        ) : (
          <>
            <Route path="/" element={hasCurrentUser ? <PrivateOutlet /> : <UiLoader />}>
              <Route index element={ <DashboardMobile /> } />
              <Route path="products-search" element={<ProductsSearchMobile />} />
              <Route path="/*" element={<HeaderOutlet />}>
                <Route path="customers/*" element={<Customers />} />
                <Route path="product/:sku" element={<ProductDetailMobile />} />
                <Route path="product/:sku/update/:property" element={<ProductUpdateMobile />} />
                <Route path="closure/*" element={<Closures />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Route>
            </Route>
          </>
        )}
      </Routes>
    </>
  );
}

export default MobileRouter;

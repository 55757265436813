import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TABS } from '../constants';
import CustomersHomeDesktop from '../pages/customers-home-desktop';
import CustomersCreation from '../pages/customers-creation';
import CustomersUpdate from '../pages/customers-update';
import CustomerDetailDesktop from '../pages/customer-detail-desktop';
import CustomerSaleDetailDesktop from '../pages/customer-sale-detail-desktop';

interface CustomersDektopRouterProps {}

const CustomersDektopRouter: FC<CustomersDektopRouterProps> = () => {
  return (
    <Routes>
      <Route path={`/:customerId/${TABS.transaction}/:transactionId`} element={<CustomerSaleDetailDesktop />} />
      <Route path={`/${TABS.creation}`} element={<CustomersCreation />} />
      <Route path={`/${TABS.customers}:customerId/${TABS.update}`} element={<CustomersUpdate />} />
      <Route path={`/${TABS.customers}:customerId`} element={<CustomerDetailDesktop />} />
      <Route path={`/${TABS.customers}`} element={<CustomersHomeDesktop />} />
    </Routes>
  );
}

export default CustomersDektopRouter;

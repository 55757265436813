import React, { FC, memo } from 'react';

import styles from './ui-square-button.module.sass';

interface UiSquareButtonProps {
  icon: string
  onClick?: () => void;
}

const UiSquareButton: FC<UiSquareButtonProps> = ({ icon, onClick }) => {
  return (
    <button
      className={styles.button}
      onClick={onClick}
    >
      <span className={`${icon} ${styles.icon}`} />
    </button>
  );
}

export default memo(UiSquareButton);

import { Provider } from "../entities";

export interface ProvidersState {
  ui: ProvidersUiState;
  data: ProvidersDataState;
  update: ProvidersUpdateState;
  resume: ProvidersResumeState;
  pagination: ProvidersPaginationState;
  errors: ProvidersErrorsState;
}

export interface ProvidersUiState {
  isLoading: boolean;
  hasNoSearchResult: boolean;
  showProvidersFormModal: boolean;
  showFirstInputs: boolean;
}

export interface ProvidersDataState {
  providers: Provider[];
}

export interface ProvidersUpdateState {
  provider: Provider;
}

export interface ProvidersPaginationState {
  totalPages: number;
  currentPage: number;
}

export interface ProvidersResumeState {
  total: number;
}

export interface ProvidersErrorsState {
  getProviders: boolean;
}

export const initialState: ProvidersState = {
  ui: {
    isLoading: false,
    hasNoSearchResult: false,
    showProvidersFormModal: false,
    showFirstInputs: true,
  },
  data: {
    providers: [] as Provider[]
  },
  update: {
    provider: {} as Provider
  },
  resume: {
    total: 0
  },
  pagination: {
    totalPages: 0,
    currentPage: 1,
  },
  errors: {
    getProviders: false
  }
}

import { Municipality, ProfileInput, StoreType } from "../entities";

export const getStoreInfotmationInput = (
  storeTypes: StoreType[],
  municipalities: Municipality[],
  dailySales: string[]
): ProfileInput[] => [
    {
      label: 'Tipo de negocio',
      name: 'storeTypeId',
      isDisabled: false,
      isSelect: true,
      options: storeTypes.map(e => ({ title: e.name, value: e.id })),
    },
    {
      label: 'NIT',
      name: 'nit',
      isDisabled: false,
      isSelect: false,
    },
    {
      label: 'Ciudad',
      name: 'municipalityId',
      isDisabled: false,
      isSelect: true,
      options: municipalities.map(e => ({ title: e.name, value: e.id })),
    },
    {
      label: 'Razón Social',
      name: 'storeName',
      isDisabled: false,
      isSelect: false,
    },
    {
      label: 'Dirección',
      name: 'address',
      isDisabled: true,
      isSelect: false,
    },
    {
      label: '¿Cuánto vende tu negocio?',
      name: 'dailySales',
      isDisabled: false,
      isSelect: true,
      options: dailySales.map(e => ({ title: e, value: e })),
    },
    {
      label: 'Telefono de la tienda',
      name: 'alternativePhone',
      isDisabled: false,
      isSelect: false,
    },
    {
      label: 'Número de empleados',
      name: 'numberOfEmployees',
      isDisabled: false,
      isSelect: false,
    },
    {
      label: '¿Métodos de pago en tu negocio?',
      name: 'paymentMethod',
      isDisabled: false,
      isSelect: true,
      options: [
        { title: "Efectivo", value: 'Efectivo' },
        { title: "Tarjeta", value: 'Tarjeta' },
        { title: "Efectivo y tarjeta", value: 'Efectivo y tarjeta' },
        { title: "Otros", value: 'Otros' },
      ],
    },
    {
      label: '¿En qué año abriste tu negocio?',
      name: 'openingTime',
      isDisabled: false,
      isSelect: false,
    },
  ];
  
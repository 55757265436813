import React, { FC, useCallback, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addDays } from 'date-fns';
import { actions } from '@modules/bills/store';
import useOutsideClickDetector from '../../../../hooks/use-outside-click-detector';
import Clevertap, { BILLS_SEARCH_DATE_PICKER } from '@config/clevertap';
import styles from './bill-date-picker.module.sass';
import UiDatePicker from '@ui/ui-date-picker-v2/ui-date-picker';

interface BillDatePickerProps { }

const BillDatePicker: FC<BillDatePickerProps> = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const dispatch = useDispatch();
  const datePickerRef = useRef<HTMLDivElement>(null);
  useOutsideClickDetector(datePickerRef, () => setShowDatePicker(false));

  const handleShowDatePicker = useCallback(() => {
    setShowDatePicker(show => !show)
  }, []);

  const handleSubmitDates = useCallback((initDate?: Date, endDate?: Date) => {
    dispatch(actions.setFilter({
      initDate: initDate?.toDateString(),
      endDate: endDate?.toDateString(),
    }));
    Clevertap.pushEvent(BILLS_SEARCH_DATE_PICKER, { initDate, endDate })
    setShowDatePicker(false);
  }, [dispatch]);

  return (
    <div ref={datePickerRef}>
      <div className={styles.calendar} onClick={handleShowDatePicker} data-testid='bill-date-picker'>
        <span className={`icon-calendar-2 ${styles.icon}`} />
      </div>
      {showDatePicker && (
        <div className={styles.datePicker}>
          <UiDatePicker
            maxDate={addDays(new Date(), 1)}
            onApply={handleSubmitDates}
            onCancel={handleShowDatePicker}
          />
        </div>
      )}
    </div>
  );
}

export default BillDatePicker;

import { format } from "date-fns";

export const formatTime = (date: string, time: string) => {
  const [day, month, year] = date.split('/');
  const [hour, minutes] = time.split(':')
  return format(new Date(+year, +month, +day, +hour, +minutes), 'p')
}

export const formatIndex = (num: number) => {
  return String(num).padStart(2, '0');
}

export const isLoan = (type: string) => type === 'Fiado';

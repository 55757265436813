import React, { FC } from "react";
import { useSelector } from "react-redux";
import { closuresUiEmptySelector, closuresUiLoadingSelector } from "@modules/closures/store";
import useLastClosure from "@modules/closures/hooks/use-last-closure";
import ClosuresHomeDetailTopInfo from "../closures-home-detail-top-info";
import ClosuresHomeDetailSkeleton from "../closures-home-detail-skeleton";
import ClosuresHomeDetailCenterInfo from "../closures-home-detail-center-info";
import ClosuresHomeDetailBottomInfo from "../closures-home-detail-bottom-info";
import styles from "./closures-home-detail.module.sass";

interface ClosuresHomeDetailProps { }

const ClosuresHomeDetail: FC<ClosuresHomeDetailProps> = () => {
  const isLoadingClosure = useSelector(closuresUiLoadingSelector);
  const isEmptyClosures = useSelector(closuresUiEmptySelector);
  useLastClosure();

  if (isEmptyClosures) return (
    <div className={styles.container}>
      <div className={styles.empty} data-testid="closures-empty-message">
        Su historial de cierres de caja se encuentra vacío. Cierre su caja actual y podrá consultar aquí todos los detalles
      </div>
    </div>
  );

  if (isLoadingClosure) return (
    <div className={styles.container}>
      <ClosuresHomeDetailSkeleton />
    </div>
  );

  return (
    <div className={styles.container}>
      <ClosuresHomeDetailTopInfo />

      <ClosuresHomeDetailCenterInfo />

      <ClosuresHomeDetailBottomInfo />
    </div>
  );
}

export default ClosuresHomeDetail;

import { createSelector } from "@reduxjs/toolkit";

import { DashboardDataState, DashboardErrorsState, DashboardUiState, ProductsSearchDataState } from ".";
import { RootState } from "@config/store";

export const dashboardUiSelector = createSelector(
  (state: RootState) => state.dashboard.ui,
  (state: DashboardUiState) => state
);

export const dashboardDataSelector = createSelector(
  (state: RootState) => state.dashboard.data,
  (state: DashboardDataState) => state.dashboard
);

export const productsSearchDataSelector = createSelector(
  (state: RootState) => state.dashboard.data.productsSearch,
  (state: ProductsSearchDataState) => state.products
);

export const searchHistorySelector = createSelector(
  (state: RootState) => state.dashboard.data.productsSearch,
  (state: ProductsSearchDataState) => state.searchHistory
);

export const historyOptionSelector = createSelector(
  (state: RootState) => state.dashboard.data.productsSearch,
  (state: ProductsSearchDataState) => state.historyOptionSelected
);


export const dashboardErrorsSelector = createSelector(
  (state: RootState) => state.dashboard.errors,
  (state: DashboardErrorsState) => state
);

import { loginAsNormalUser, loginWithToken } from "@modules/auth/auth.services";
import { actions as globalActions } from "@config/store";
import { actions } from "@modules/auth/store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ACCESS_POS_WEB_PERMISSION_ID, OWNER_ROLE_ID, STORAGE_TOKEN } from "@constants";
import { LoginResponse } from "@modules/auth/entities";
import { useCallback } from "react";
import { sleep } from "@utils/sleep";
import Clevertap, { LOGIN } from "@config/clevertap";

const useLogin = (routeToRedirection: string = '') => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setUser = useCallback((loginResponse: LoginResponse): void => {
    const isOwner: boolean = loginResponse.roleId === OWNER_ROLE_ID;

    if (!loginResponse.permissionList.find(e => e.id === ACCESS_POS_WEB_PERMISSION_ID) && !isOwner) {
      dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Lo sentimos, no tenes autorización para ingresar al administrador'
      }))
    } else {
      dispatch(globalActions.saveCurrentUser(loginResponse));
      dispatch(globalActions.setHasCurrentUser(true));
      localStorage.setItem(STORAGE_TOKEN, JSON.stringify(loginResponse));
      Clevertap.pushEvent(LOGIN, { storeId: loginResponse.storeId })
    }
  }, [dispatch]);

  const normalUserLogin = (user: string, password: string) => {
    dispatch(actions.setIsLoadingLogin(true));
    loginAsNormalUser(user, password)
      .then((setUser))
      .then(() => sleep(1300))
      .then(() => navigate(`/${routeToRedirection}`, { replace: true }))
      .catch(() => dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Lo sentimos, el número de teléfono o contraseña es invalido, intentalo de nuevo',
      })))
      .finally(() => dispatch(actions.setIsLoadingLogin(false)));
  };

  const loginFromPOS = useCallback((authWeb: string): void => {
    dispatch(actions.setIsLoadingLogin(true));
    loginWithToken(authWeb)
      .then(setUser)
      .then(() => sleep(1300))
      .then(() => navigate(`/${routeToRedirection}`, { replace: true }))
      .catch(() => dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'Lo sentimos, ha ocurrido un error. Ingrese sus datos manualmente o inténtelo más tarde',
      })))
      .finally(() => dispatch(actions.setIsLoadingLogin(false)));
  }, [dispatch, navigate, routeToRedirection, setUser]);

  return {
    normalUserLogin,
    loginFromPOS
  };
};

export default useLogin;

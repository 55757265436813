import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '@config/store';
import { profileErrorsSelector, profileUiSelector, userInformationDataSelector } from '@modules/profile/store';
import { getUserInfotmationInput } from '@modules/profile/utils';
import styles from './user-information.module.sass';
import useProfileFetch from '@modules/profile/hooks/use-profile-fetch';
import UiNoDataMessage from '@ui/ui-no-data-message';
import ProfileForm from '@modules/profile/components/profile-form';
import { userInformationSchema } from '@modules/profile/schemas';

interface UserInformationProps { }

const UserInformation: FC<UserInformationProps> = () => {
  const user = useSelector(currentUserSelector);
  const errors = useSelector(profileErrorsSelector);
  const userInformation = useSelector(userInformationDataSelector);
  const { isRequestingProfile, hasProfileData } = useSelector(profileUiSelector);
  const { getProfileData, updateUserInformation } = useProfileFetch();

  useEffect(() => {
    if (!hasProfileData) getProfileData();
  }, [getProfileData, hasProfileData]);

  if (errors.getProfileData && !hasProfileData) return (
    <UiNoDataMessage message='No pudimos obetener sus datos personales' />
  );

  if (isRequestingProfile) return <span className={styles.loading}>Cargando...</span>

  return (
    <>
      <h1 className={styles.title}>Datos personales</h1>
      <ProfileForm
        image={userInformation.userImageUrl}
        inputs={getUserInfotmationInput(user.country, userInformation.contactChannels)}
        values={userInformation}
        onSubmit={updateUserInformation}
        schema={userInformationSchema}
      />
    </>
  );
}

export default UserInformation;

import * as yup from 'yup';
import { t } from 'i18next'
import { COLLABORATOR_NAME_LENGTH, PHONE_LENGTH, REG_EXP_ONLY_LETTERS, REG_EXP_ONLY_NUMBERS } from '@constants';

export const customerSchema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters'))
    .max(COLLABORATOR_NAME_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  phone: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  document: yup.string()
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers')),
});

export const customerUpdateNameSchema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters'))
    .max(COLLABORATOR_NAME_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
});

export const customerUpdatePhoneSchema = yup.object().shape({
  phone: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
});

import React, { FC, useCallback, useEffect } from 'react';
import CustomersForm from '../../components/customers-form';
import { actions as globalActions } from '@config/store';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@modules/customers/constants';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import { useDispatch } from 'react-redux';

interface CustomersCreationProps { }

const CustomersCreation: FC<CustomersCreationProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createCustomer } = useCustomersFetch();

  const goBack = useCallback((): void => {
    navigate(`/${PATH}`)
  }, [navigate]);

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({ section: 'Nuevo Cliente', routeTo: `/${PATH}` }));
  }, [dispatch]);

  return (
    <CustomersForm
      title='Crear cliente'
      routes={[{ name: 'Clientes', path: `/${PATH}` }]}
      onCancel={goBack}
      onSubmit={createCustomer}
    />
  )
}

export default CustomersCreation;

import { PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import { CustomersTabs, CustomersTableColumn, CustomersFormBase, CustomerFormInput, SwitchOption } from "../entities";

export const LIMIT_GET_CUSTOMERS = 20;

export const PATH = 'customers';

export const TABS: CustomersTabs = {
  customers: '',
  creation: 'creation',
  update: 'update',
  transaction: 'transaction',
  paymentTransaction: 'payment',
  loanTransaction: 'loan',
  updateName: 'update/name',
  updatePhone: 'update/phone'
}

export const COLUMNS_TABLE: { title: string, icon: keyof typeof PhosphorIcons }[] = [
  { title: 'Nombre', icon: 'ArrowsDownUp' },
  { title: 'Cédula', icon: 'ArrowsDownUp' },
  { title: 'Dirección', icon: 'ArrowsDownUp' },
  { title: 'Celular', icon: 'ArrowsDownUp' },
  { title: 'Transacciones', icon: 'ArrowsDownUp' },
  { title: 'Deuda actual', icon: 'ArrowsDownUp' },
  { title: 'Último abono', icon: 'ArrowsDownUp' }
];

export const NEW_CUSTOMER_FORM: CustomersFormBase = {
  name: '',
  address: '',
  document: '',
  phone: ''
};

export const CUSTOMER_FORM_INPUTS: CustomerFormInput[] = [
  { name: 'name', title: 'Nombre' },
  { name: 'phone', title: 'Celular' },
  { name: 'address', title: 'Dirección (Opcional)' },
  { name: 'document', title: 'Cédula (Opcional)' }
]

export const SWITCH_OPTIONS: SwitchOption[] = [
  { name: 'sales', title: 'Ventas' },
  { name: 'balances', title: 'Saldos' }
]

export const COLUMNS_SALES_TABLE: CustomersTableColumn[] = [
  { title: 'Número de factura' },
  { title: 'Fecha', icon: 'calendar' },
  { title: 'SKU\'s', icon: '' },
  { title: 'Valor', icon: '' },
  { title: 'Método de pago', icon: '' }
];

export const COLUMNS_BALANCES_TABLE: CustomersTableColumn[] = [
  { title: 'Número' },
  { title: 'Fecha', icon: 'calendar' },
  { title: 'Tipo' },
  { title: 'Valor' },
];

export const TRANSACTION_INCOME = 'INCOME';
export const TRANSACTION_INCOME_CARD = 'INCOME_CARD';
export const TRANSACTION_LOAN = 'LOAN';
export const TRANSACTION_PAY_LOAN = 'PAY_LOAN';
export const TRANSACTION_EGRESS = 'EGRESS';

export const PAYMENT_TRANSACTION_TYPE = 3;
export const LOAN_TRANSACTION_TYPE = 2;

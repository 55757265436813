import React, { FC } from "react";
import styles from "./closures-mobile-home-detail-skeleton.module.sass";
import UiSkeleton from "@ui/ui-skeleton/ui-skeleton";

interface ClosuresHomeDetailMobileSkeletonProps { }

const ClosuresHomeDetailMobileSkeleton: FC<ClosuresHomeDetailMobileSkeletonProps> = () => {

  return (
    <div className={styles.wrapper}>
      {Array(25).fill("").map((_, i) => (
        <UiSkeleton
          className={styles[`element-${i + 1}`]}
          key={`closure-detail-skeleton-${_ + i}`}
        />
      ))}
    </div>
  );
}

export default ClosuresHomeDetailMobileSkeleton;
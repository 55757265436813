import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StoreInformation, BussiessData, Consecutive,
  Municipality, StoreType, UserInformation
} from "../entities";
import { initialState } from './profile.state';

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setShowChangePasswordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showChangePasswordModal = payload;
    },
    setShowNewConsecutiveModal: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showNewConsecutiveModal = payload;
    },
    setHasFieldOptions: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasFieldOptions = payload;
    },
    setIsRequestingProfile: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isRequestingProfile = payload;
    },
    setHasProfileData: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasProfileData = payload;
    },
    setBusinessData: (state, { payload }: PayloadAction<BussiessData>) => {
      state.inovicingInformation.businessData = payload;
    },
    setConsecutives: (state, { payload }: PayloadAction<Consecutive[]>) => {
      state.inovicingInformation.consecutives = payload;
    },
    setUserInformationData: (state, { payload }: PayloadAction<UserInformation>) => {
      state.profile.userInformation = payload;
    },
    setStoreInformationData: (state, { payload }: PayloadAction<StoreInformation>) => {
      state.profile.storeInformation = payload;
    },
    setStoreTypes: (state, { payload }: PayloadAction<StoreType[]>) => {
      state.options.storeTypes = payload;
    },
    setMunicipalities: (state, { payload }: PayloadAction<Municipality[]>) => {
      state.options.municipalities = payload;
    },
    setDailySales: (state, { payload }: PayloadAction<string[]>) => {
      state.options.dailySales = payload;
    },
    setGetInvoicingInformationError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getInvoicingInformation = payload;
    },
    setGetProfileDataError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getProfileData = payload;
    },
  },
});

export const actions = profileSlice.actions;
export default profileSlice.reducer;

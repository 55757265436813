import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import OrdersDetail from "@modules/orders/pages/order-detail";
import OrdersHome from "@modules/orders/pages/order-home";
import { TABS } from "@modules/orders/constants";

interface OrdersRouterProps {}

const OrdersRouter: FC<OrdersRouterProps> = () => {
  return (
    <Routes>
      <Route index element={<OrdersHome />} />
      <Route path={`${TABS.ordersDetail}/:id`} element={<OrdersDetail />} />
      <Route path="*" element={<Navigate to={`/${TABS.orders}/`} />} />
    </Routes>
  );
};

export default OrdersRouter;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Collaborator } from "../entities";
import { initialState } from './collaborators.state';

export const collaboratorsSlice = createSlice({
  name: 'collaborators',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setShowDeleteCollaboratorModal: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showDeleteCollaboratorModal = payload;
    },
    setHasRequestedCollaborators: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasRequestedCollaborators = payload;
    },
    setShowSuccessPasswordCreationScreen: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showSuccessPasswordCreationScreen = payload;
    },
    setCollaborators: (state, { payload }: PayloadAction<Collaborator[]>) => {
      state.data.collaborators = payload;
    },
    setTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.pagination.totalPages = payload;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    },
    setGetCollaboratorError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getCollaborators = payload;
    },
  },
});

export const actions = collaboratorsSlice.actions;
export default collaboratorsSlice.reducer;

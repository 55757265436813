import { Bill, BillPreview } from "../entities";

export interface BillsState {
  ui: BillsUiState;
  data: BillsDataState;
  errors: BillsErrorsState;
};

export interface BillsUiState {
  isLoading: boolean;
  hasRequestedBills: boolean;
  hasNoSearchResults: boolean;
  isFirstTimeEdit: boolean;
  fieldOnFocus: string;
  mustRequestBills: boolean;
};

export interface BillsDetailState {
  id: string;
};

export interface BillsFiltersState {
  initDate: string;
  endDate: string | undefined;
  search: string | undefined;
};

export interface BillsDataState {
  bills: Bill[];
  detail: BillsDetailState;
  filters: BillsFiltersState;
  form: BillPreview;
};

export interface BillsErrorsState {
  getBillsError: boolean;
};

export const initialState: BillsState = {
  ui: {
    isLoading: false,
    hasRequestedBills: false,
    hasNoSearchResults: false,
    isFirstTimeEdit: true,
    fieldOnFocus: '',
    mustRequestBills: false,
  },
  data: {
    bills: [],
    detail: { id: '' },
    form: {
      name: '',
      address: '',
      phone: '',
      nit: '',
      rfc: '',
      message: '',
    },
    filters: {
      initDate: new Date().toDateString(),
      endDate: undefined,
      search: undefined,
    }
  },
  errors: {
    getBillsError: false,
  }
};

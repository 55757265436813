import React, { FC } from "react";
import styles from "./closures-mobile-home-detail.module.sass";
import { useSelector } from "react-redux";
import { closuresDetailSelector } from "@modules/closures/store";
import { IPhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import { Theme } from "@chiper-inc/sb-web-chiper";
import ClosuresMobileHomeBoxes from "../closures-mobile-home-boxes";
import { formatMoney } from "@utils/format-money";

interface ClosureMobileHomeDetailProps { }

interface Item {
  icon: IPhosphorIcons;
  title: string;
  total: number;
  isPlain?: boolean;
}

const ClosuresMobileHomeDetail: FC<ClosureMobileHomeDetailProps> = () => {
  const closure = useSelector(closuresDetailSelector);

  const firstRow: Item[] = [
    {
      icon: { name: "Coins", color: Theme.colors.secondary.cercana },
      title: "Balance",
      total: closure.balance
    },
    {
      icon: { name: "Flag", color: Theme.colors.secondary.blueJeans },
      title: "Saldo inicial caja",
      total: closure.startBase
    },
  ];

  const secondRow: Item[] = [
    {
      icon: { name: "Handshake", color: Theme.colors.primary.chiperBrandRed },
      title: "Fiados",
      total: closure.loan
    },
    {
      icon: { name: "Calculator", color: Theme.colors.secondary.brightYellow },
      title: "Total transacciones",
      total: closure.numberOfSales,
    },
  ];

  return (
    <div className={styles.wrapper}>

      <div className={styles.balance}>
        <div className={styles.title}>Este fue el balance de tu tienda</div>
        <div className={styles.total}>
          <p className={styles.text}>Total de ingresos</p>
          <p className={styles.value}>{formatMoney(closure.balance)}</p>
        </div>
      </div>

      <div className={styles.firstRow}>
        {firstRow.map(box => (
          <ClosuresMobileHomeBoxes icon={box.icon} title={box.title} value={box.total} key={`${box.title}-list`}/>
        ))}
      </div>
      <div className={styles.secondRow}>
        {secondRow.map(box => (
          <ClosuresMobileHomeBoxes icon={box.icon} title={box.title} value={box.total} key={`${box.title}-list`}/>
        ))}
      </div>

    </div>
  )
}

export default ClosuresMobileHomeDetail;
import axios from 'axios';
import { DatesFilter, ResumeData, SalesByDate, SalesByDay, SalesByHour, SkusByReference, SlowTurnoverProduct } from '../entities';

export const fetchSalesByDates = ({ startDate, finishDate }: DatesFilter ): Promise<SalesByDate[]> => {
  return axios.post('my-sales-by-date', { startDate, endDate: finishDate })
    .then((response) => response.data);
};

export const fetchSalesByDays = ({ startDate, finishDate }: DatesFilter): Promise<SalesByDay[]> => {
  return axios.post('sales-by-day', { startDate, endDate: finishDate })
    .then((resp) => resp.data);
};

export const fetchSalesByHour = ({ startDate, finishDate }: DatesFilter): Promise<SalesByHour[]> => {
  return axios.post('sales-by-hour', { startDate, endDate: finishDate })
    .then((resp) => resp.data);
};

export const fetchProductsWithLowTurnover = ({ startDate, finishDate }: DatesFilter): Promise<SlowTurnoverProduct[]> => {
  return axios.post('order-low-turnover', { startDate, endDate: finishDate })
    .then((resp) => resp.data);
};

export const fetchSkusReferences = ({ startDate, finishDate }: DatesFilter): Promise<SkusByReference[]> => {
  return axios.post('order-my-references', { startDate, endDate: finishDate })
    .then((resp) => resp.data);
};

export const fetchResumeData = ({ startDate, finishDate }: DatesFilter): Promise<ResumeData> => {
  return axios.get(`sales-reports?startDate=${startDate}&endDate=${finishDate}`)
    .then((response) => response.data).catch(() => null);
};

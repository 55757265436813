import { useDispatch, useSelector } from 'react-redux';
import {
  InventoryItem, Product,
  ProductDiscountForm, ProductDiscountTableItem
} from '@modules/inventory/entities';
import {
  actions, inventoryDataSelector,
  productDetailSelector, productDiscountsSelector
} from '@modules/inventory/store';
import { useCallback } from 'react';
import { format } from 'date-fns';
import { es } from "date-fns/locale";

const useInventory = () => {
  const inventory = useSelector(inventoryDataSelector);
  const product = useSelector(productDetailSelector);
  const discounts = useSelector(productDiscountsSelector);
  const dispatch = useDispatch();

  const setUpdatedProduct = useCallback((updatedProduct: Product): void => {
    const items: InventoryItem[] = inventory.map((item) => {
      return (item.id === updatedProduct.inventoryId) ? {
        ...item,
        managerPrice: Number(updatedProduct.managerTotal),
        customerPrice: Number(updatedProduct.totalPrice),
        brandName: updatedProduct.brandName,
        packagingType: updatedProduct.packagingType,
        stock: Number(updatedProduct.stock),
        total: +updatedProduct.stock * +updatedProduct.managerTotal
      } : item
    });
    dispatch(actions.setInventory(items));
    dispatch(actions.setProduct({
      ...updatedProduct,
      categoryId: String(updatedProduct.categoryId),
      isActive: product.isActive,
      totalPrice: Number(updatedProduct.totalPrice)
    }));
  }, [dispatch, inventory, product.isActive]);

  const setChangeProductState = useCallback((): void => {
    dispatch(actions.setProduct({
      ...product,
      isActive: !product.isActive
    }));
  }, [dispatch, product]);

  const setUpdatedDiscount = useCallback((updatedDiscount: ProductDiscountForm): void => {
    const items: ProductDiscountTableItem[] = discounts.map((item) => {
      return (item.id === updatedDiscount.id) ? {
        id: updatedDiscount.id,
        customerTotal: String(updatedDiscount.customerSubTotalPrice),
        discountPrice: updatedDiscount.customerDiscountPrice,
        initAt: format(updatedDiscount.initAt, 'dd MMM. yyyy', { locale: es }),
        isActive: updatedDiscount.isActive,
        recurrency: !!updatedDiscount.recurrency.length,
        inventoryId: updatedDiscount.inventoryId
      } : item;
    });
    dispatch(actions.setProductDiscounts(items));
  }, [discounts, dispatch]);

  return {
    setUpdatedProduct,
    setChangeProductState,
    setUpdatedDiscount
  }
}

export default useInventory;

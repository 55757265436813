import React, { FC } from 'react';

import styles from './dashboard-top-products.module.sass';
import { formatMoney } from '@utils/format-money';
import { DashboardProduct } from '@modules/dashboard/entities';

interface DashboardTopProductsProps {
  title: string;
  products: DashboardProduct[];
}

const DashboardTopProducts: FC<DashboardTopProductsProps> = ({ title, products = [] }) => {
  return (
    <div className={styles.wrapper}>
      {
        !products.length 
          ? (
            <div className="noProducts">No hay productos aún</div>
          )
          : (
            <>
              <div className={styles.title}>{title}</div>
              <div className={styles.table}>
                <div className={styles.titles}>
                  <div className={styles.space} />
                  <div className={styles.units}>Unidades</div>
                  <div className={styles.values}>Valor</div>
                </div>
                <div className={styles.items}>
                  {
                    products.map((p, i) => (
                      <React.Fragment key={`${title}-top-product-${String(i)}`}>
                        <div className={styles.number}>{`0${i + 1}`}</div>
                        <div className={styles.name}>{p.referencename}</div>
                        <div className={styles.units}>{p.quantity}</div>
                        <div className={styles.value}>{formatMoney(+p.salestotal)}</div>
                      </React.Fragment>
                    ))
                  }
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};
 
export default DashboardTopProducts;

import React, { FC, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TABS } from '@modules/collaborators/constants';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from '@config/store';
import { fetchDownloadCustomers } from '@modules/customers/services';
import { actions, customersUiSelector } from '@modules/customers/store';
import { Customer } from '@modules/customers/entities';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import Clevertap from '@utils/clevertap';
import { CUSTOMERS_DOWNLOAD } from '@constants';
import styles from './customers-home-desktop.module.sass';
import CustomersTable from '../../components/customers-table';
import CustomersResume from '../../components/customers-resume-desktop';
import UiButton from '@ui/ui-button';
import UiTopSection from '@ui/ui-top-section';

interface CustomersHomeDeskptopProps { }

const CustomersHomeDeskptop: FC<CustomersHomeDeskptopProps> = () => {
  const user = useSelector(currentUserSelector);
  const { hasRequestedCustomers } = useSelector(customersUiSelector);
  const dispatch = useDispatch();
  const { getCustomers } = useCustomersFetch();

  const handleDownload = useCallback((): void => {
    fetchDownloadCustomers(user.storeId, user.country);
    Clevertap.pushSimpleEvent(CUSTOMERS_DOWNLOAD);
  }, [user.country, user.storeId]);

  useEffect(() => {
    if (!hasRequestedCustomers) getCustomers();
    dispatch(actions.setCustomerDetail({} as Customer));
  }, [dispatch, getCustomers, hasRequestedCustomers]);

  return (
    <div className={styles.container}>
      <UiTopSection
        title='Clientes'
        leftButton={
          <UiButton text='Descargar' leftIcon='icon-download' onClick={handleDownload} isSecondary />
        }
        rightButton={
          <Link to={TABS.creation} className={styles.link}>
            <UiButton text='Crear cliente' leftIcon='icon-add' />
          </Link>
        }
      />
      <CustomersResume />

      <CustomersTable />
    </div>
  )
}

export default CustomersHomeDeskptop;

import { MIN_WIDTH_DESKTOP, STORAGE_TOKEN } from "@constants";
import { ClevertapEventPayload, ClevertapGeneralEvent, ClevertapIdentify, User } from "@entities";
import { formatDistanceToNowStrict } from "date-fns";

interface ClevertapPush {
  push: (name: string, data: ClevertapGeneralEvent) => void;
}

interface ClevertapIdentifyPush {
  push: (user: ClevertapIdentify) => void;
}

interface ClevertapGlobal {
  event: ClevertapPush;
  onUserLogin: ClevertapIdentifyPush;
}

declare global {
  interface Window { clevertap: ClevertapGlobal }
}

const clevertap = window.clevertap;
const item = localStorage.getItem(STORAGE_TOKEN) || ''
const user: User = item ? JSON.parse(item) : {} as User;

const identifyUser = (): void => {
  if (user.id) {
    clevertap.onUserLogin.push({
      Site: {
        Name: `${user.firstName} ${user.lastName || ''}`,
        Identity: user.id,
        Email: user.email,
      }
    })
  }
};

const pushSimpleEvent = (eventName: string, payload?: ClevertapEventPayload): void => {
  try {
    clevertap.event.push(eventName, {
      storeId: user.storeId,
      isMobile: window.innerWidth < MIN_WIDTH_DESKTOP,
      ...payload,
    });
  } catch { }
};

const pushEvent = (eventName: string, payload: ClevertapEventPayload, errorInfo?: string): void => {
  pushSimpleEvent(eventName, {
    isSuccess: !errorInfo,
    errorInfo,
    ...payload
  });
};

const pushCheckSuccessEvent = (eventName: string, errorInfo?: string): void => {
  pushSimpleEvent(eventName, { isSuccess: !errorInfo, errorInfo });
};

const pushDurationEvent = (eventName: string, initialTime: Date): void => {
  pushSimpleEvent(eventName, {
    duration: formatDistanceToNowStrict(initialTime, { unit: 'second' })
  });
};

const Clevertap = {
  pushEvent,
  pushSimpleEvent,
  pushDurationEvent,
  pushCheckSuccessEvent,
  identifyUser,
};

export default Clevertap;
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { billsDataSelector, billsDetailSelector } from '@modules/bills/store';
import BillDetailHeader from '../bill-detail-header';
import BillDetailList from '../bill-detail-list';
import styles from './bill-detail.module.sass';
import BillDetailFooter from '../bill-detail-footer';
import { isLoan } from '@modules/bills/utils';

interface BillDetailProps { }

const BillDetail: FC<BillDetailProps> = () => {
  const id = useSelector(billsDetailSelector);
  const bills = useSelector(billsDataSelector);

  const bill = useMemo(() => bills.find(e => e.id === id), [id, bills])

  const getType = () => {
    if (bill?.deletedAt) return 'Devolución';
    return isLoan(bill!.type) ? 'Fiado' : 'Venta';
  };

  if (!bill) return <div />

  return (
    <div className={styles.wrapper}>
      <p className={styles.transactionType}>
        <span className={`${styles.point} ${bill.deletedAt && styles.deleted}`} />
        {getType()}
      </p>

      <BillDetailHeader bill={bill} />

      <BillDetailList bill={bill} />

      <BillDetailFooter bill={bill} />
    </div>
  );
}

export default BillDetail;

import React, { FC, memo } from 'react'
import styles from './tabs.module.sass';
import { Tab } from '@modules/inventory/entities';
import { NavLink } from 'react-router-dom';

interface TabsProps {
  options: Tab[],
  title?: string;
}

const Tabs: FC<TabsProps> = ({ options, title }) => {
  return (
    <>
    {title && <span className={styles.title}>{title}</span>}
      <div className={styles.wrapper}>
        {options.map((tab) => (
          <NavLink
            end to={tab.path}
            className={({ isActive }) => isActive ? styles.active : styles.inactive}
            key={`tab-option-${tab.title}`}
          >
            {tab.title}
          </NavLink>
        ))}
      </div>
    </>
  )
}

export default memo(Tabs);

import axios from "axios";
import { LIMIT_GET_CUSTOMERS } from "../constants";
import { Customer, CustomerBalance, CustomerBalances, Customers, CustomerSale, CustomersFormBase, TransactionDetailItem, TransactionForm } from "../entities";

export const fetchDownloadCustomers = (storeId: number, isoCode: string): Window | null =>
  window.open(`${process.env.REACT_APP_URL_POS_API}/download-customer/${storeId}/${isoCode}`, 'blank');

export const fetchGetCustomers = (skip: number = 0, sort?: string): Promise<Customers> =>
  axios.get<Customers>(`customers?limit=${LIMIT_GET_CUSTOMERS}&skip=${skip * LIMIT_GET_CUSTOMERS}${!!sort ? `&sort=${sort}`: ''}`)
    .then(({ data }) => data);

export const fetchSearchCustomers = (value: string): Promise<Customer[]> =>
  axios.get<Customer[]>(`search-customer?search=${value}`)
    .then(({ data }) => data);

export const fetchCreateCustomer = (newCustomer: CustomersFormBase): Promise<boolean> =>
  axios.post('customer/create', { name: newCustomer.name, phone: newCustomer.phone })
    .then(() => true);

export const fetchUpdateCustomer = (editedCustomer: CustomersFormBase): Promise<boolean> =>
  axios.put('update-customer', [editedCustomer])
    .then(() => true);

export const fetchGetCustomerDetail = (id: string): Promise<Customer> =>
  axios.get<Customer[]>(`customer/${id}`)
    .then(({ data }) => data[0]);

export const fetchGetCustomerSales = (id: string): Promise<CustomerSale[]> =>
  axios.get<CustomerSale[]>(`customer/transactions/${id}`)
    .then(({ data }) => data);

export const fetchGetCustomerBalances = (id: string): Promise<CustomerBalances> =>
  axios.get<CustomerBalances>(`detailCustomer/${id}`)
    .then(({ data }) => data);

export const fetchGetTransactionDetail = (transactionId: string): Promise<TransactionDetailItem[]> =>
  axios.get<TransactionDetailItem[]>(`customer/transaction-detail/${transactionId}`)
    .then(({ data }) => data);

export const fetchSendWhatsApp = (phone: string, message: string): Window | null =>
  window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`, '_blank');

export const fetchGetCustomerUrlResume = (id: string): Promise<string> =>
  axios.post(`send-pdf-resume/${id}`)
    .then(({ data }) => data.url);

export const fetchNewTransaction = (transaction: TransactionForm): Promise<CustomerBalance> =>
  axios.post('transaction', transaction)
    .then(({ data }) => data.body);
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions } from "@config/store";
import { actions, closuresHistorySelector } from "../store";
import { fetchGetClosureDetail } from "../services";
import Clevertap, { CLOSURE_DETAIL } from "@config/clevertap";

const useGetClosure = () => {
  const dispatch = useDispatch();
  const history = useSelector(closuresHistorySelector);

  const getClosure = useCallback((id?: number) => {
    dispatch(actions.setIsLoadingClosure(true));
    fetchGetClosureDetail(!id ? +history[0]!.balanceId : id)
      .then((data) => {
        dispatch(actions.setClosure(data));
        Clevertap.pushEvent(CLOSURE_DETAIL, { closureId: id ? +history[0]!.balanceId : id! })
      })
      .catch(() => dispatch(globalActions.notification({
        show: true,
        message: "Lo sentimos. No hemos podido obtener su cierre",
        state: "error"
      })))
      .finally(() => dispatch(actions.setIsLoadingClosure(false)));
  }, [dispatch, history]);

  return {
    getClosure
  }
}

export default useGetClosure;

import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as globalActions } from "@config/store";
import useClosureHistory from "@modules/closures/hooks/use-closure-history";
import { TABS } from "@modules/closures/constants";
import styles from "./closure-mobile-history.module.sass";
import ClosuresMobileHistoryContent from "@modules/closures/components/closures-mobile-history-content";
import ClosuresMobileHistorySkeleton from "@modules/closures/components/closures-mobile-history-skeleton";

interface ClosureMobileHistoryProps {}

const ClosureMobileHistory: FC<ClosureMobileHistoryProps> = () => {
  const dispatch = useDispatch();
  const { isLoading } = useClosureHistory();

  useEffect(() => {
    dispatch(
      globalActions.setMobileHeaderSection({
        section: "Cierre de Caja",
        routeTo: `closure/${TABS.home}`,
      })
    );
  }, [dispatch]);

  if (isLoading) return <ClosuresMobileHistorySkeleton />

  return (
    <div className={styles.container}>
      <ClosuresMobileHistoryContent />
    </div>
  )
};

export default ClosureMobileHistory;

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@config/store';
import { AuthDataState, AuthUiState } from '.';

export const authUiSelector = createSelector(
  (state: RootState) => state.auth.ui,
  (state: AuthUiState) => state
);

export const authDataSelector = createSelector(
  (state: RootState) => state.auth.data,
  (state: AuthDataState) => state
)

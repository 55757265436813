import { PATHS } from "@constants";
import { EditFormField } from "../entities";

export const BILLS_PATH = PATHS.bills;

export const BILLS_DEVOLUTION_PATH = 'devolution';
export const BILLS_EDITION_PATH = 'edition';

export const BILL_PREVIEW_FORMAT_DATE = 'EEE, dd MMM yyy HH:mm z';

export const BILL_PREVIEW_FIELDS: EditFormField[] = [
  {
    name: 'name',
    icon: 'store-v2',
    placeholder: 'Nombre comercial',
  },
  {
    name: 'address',
    icon: 'location',
    placeholder: 'Dirección',
  },
  {
    name: 'phone',
    icon: 'phone',
    placeholder: 'Teléfono',
  },
];

export const BILL_EDIT_FORM_FIELDS: EditFormField[] = [
  ...BILL_PREVIEW_FIELDS,
];

export const FIELD_NIT: EditFormField = {
  name: 'nit',
  icon: 'dni',
  placeholder: 'NIT',
}

export const FIELD_RFC: EditFormField = {
  name: 'rfc',
  icon: 'dni',
  placeholder: 'RFC',
}

import axios from 'axios'

import { Provider } from '@modules/providers/entities'
import { Order, OrderDetail } from '../entities/orders.entities'

export const fetchOrderProviders = (
  query: string = '', 
  limit: number = 100, 
  skip: number = 0
): Promise<{ providers: Provider[], total: number, totalPages: number }> => {
  return axios.get('providers', { params: { search: query, limit, skip }})
    .then(r => r.data);
}

export const fetchOrders = async (
  query: string = '', 
  page: number = 1,
  providerId?: string,
  isPaid?: boolean,
  sort?: string
): Promise<{ getOrders: Order[], total: number, totalPages: number }> => {
  const limit = 20;
  const skip = page === 1 ? 0 : (page - 1) * 20;
  return axios.get('/orders-providers', { 
    params: { 
      search: query, limit, skip, idProvider: providerId, paid: isPaid, sort: sort
    }
  })
    .then(r => r.data);
}

export const fetchOrderDetails = (orderId: string): Promise<OrderDetail> => {
  return axios.get(`/orders-providers-detail/${orderId}`).then(r => r.data);
}

export const setPaidOutOrder = (orderId: string): Promise<{ message: string }> => {
  return axios.patch(`/paid-out-order-provider`, { providerOrderId: orderId }).then(r => r.data);
}

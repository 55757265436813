import React, { FC, memo } from 'react';
import { MENU_OPTIONS } from '@modules/profile/constants';
import { NavLink } from 'react-router-dom';
import styles from './menu.module.sass';

interface ProfileMenuProps { }

const ProfileMenu: FC<ProfileMenuProps> = () => {
  return (
    <div className={styles.wrapper}>
      {MENU_OPTIONS.map(item => (
        <NavLink
          key={`profile-menu-option-${item.title}`}
          end to={item.path}
          className={({ isActive }) => isActive ? styles.active : styles.inactive}
        >
          <span className={`${item.icon} ${styles.icon}`} />
          {item.title}
        </NavLink>
      ))}
    </div>
  );
}

export default memo(ProfileMenu);

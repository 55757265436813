import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { actions, productDetailSelector } from '@modules/inventory/store';
import { productSchema } from '@modules/inventory/schemas';
import { Product } from '@modules/inventory/entities';
import { actions as globalActions } from '@config/store';
import useProductFetch from '@modules/inventory/hooks/use-product-fetch';
import ProductDetailCard from '../product-detail-card';
import ProductDetailInputs from '../product-detail-inputs';
import { fetchSetNewImage } from '@modules/inventory/services';
import Clevertap from '@config/clevertap/clevertap';
import { PRODUCT_UPDATE } from '@constants';

interface ProductFormProps { }

const ProductForm: FC<ProductFormProps> = () => {
  const product = useSelector(productDetailSelector);
  const { updateProduct } = useProductFetch();
  const dispatch = useDispatch();
  const [newImage, setNewImage] = useState<File | null>(null)

  const handleSubmit = async (values: Product) => {
    if (newImage) {
      dispatch(actions.setIsLoading(true));
      const formData = new FormData();
      formData.append('images', newImage);
      await fetchSetNewImage(formData)
        .then((data) => { 
          values.imageUrl = data;
          Clevertap.pushEvent(PRODUCT_UPDATE, { value: values.name })
        })
        .catch(() => {
          dispatch(globalActions.notification({
            show: true,
            state: 'error',
            message: 'Lo sentimos. No pudimos cargar su imagen'
          }));
          values.imageUrl = product.imageUrl
        })
    }
    updateProduct(values);
    form.resetForm({ values });
  };

  const form = useFormik({
    initialValues: product,
    validationSchema: productSchema,
    onSubmit: handleSubmit,
    validateOnMount: true
  });

  return (
    <>
      <ProductDetailCard form={form} setNewImage={setNewImage} />

      <ProductDetailInputs form={form} />
    </>

  );
};

export default ProductForm;

import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './profile.module.sass';
import { profileUiSelector } from './store';
import UiLoader from '@ui/ui-loader';
import ProfileRouter from './router';
import ProfileMenu from './components/menu';
import Clevertap from '@utils/clevertap';
import { PROFILE_DURATION, PROFILE_OPEN } from '@constants';

interface ProfileProps { }

const Profile: FC<ProfileProps> = () => {
  const { isLoading } = useSelector(profileUiSelector);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(PROFILE_OPEN);
    return () => Clevertap.pushDurationEvent(PROFILE_DURATION, openedAt);
  }, []);

  return (
    <div className={styles.container}>
      <ProfileMenu />
      <div className={styles.sections}>
        <ProfileRouter />
      </div>
      {isLoading && <UiLoader />}
    </div>
  );
}

export default Profile;

export const REG_EXP_NO_ONLY_SPACES = RegExp(/^\S/);
export const REG_EXP_ONLY_LETTERS = RegExp(/^\S[a-z|A-ZA-ÿ\s]+$/);
export const REG_EXP_ONLY_NUMBERS = RegExp(/^[0-9]+$/);
export const REG_EXP_ONLY_LETTERS_AND_NUMBERS = RegExp(/^\S[a-z|A-ZA-ÿ\s|0-9]+$/);

export const PHONE_LENGTH = 10;
export const IDENTIFICATION_LENGTH = 18;
export const PASSWORD_LENGTH = 10;
export const COLLABORATOR_NAME_LENGTH = 20;
export const PRODUCT_NAME_LENGTH = 80;
export const USER_NUMBER_LENGTH = PHONE_LENGTH;
export const PROVIDER_NAME_LENGTH = 20;
export const BILL_EDIT_FIELD_MAX_LENGTH = 40;
export const BILL_NAME_MAX_LENGTH = BILL_EDIT_FIELD_MAX_LENGTH;
export const BILL_NIT_MAX_LENGTH = 10;
export const BILL_RFC_LENGTH = 13;
export const BILL_ADDRESS_MAX_LENGTH = BILL_EDIT_FIELD_MAX_LENGTH;
export const BILL_MESSAGE_MAX_LENGTH = 200;

export const MAX_MONEY_VALUE = 99999999;
export const MAX_STOCK_VALUE = MAX_MONEY_VALUE;

import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import styles from './customers-form.module.sass';
import { UiTopSectionRoutes } from '@entities';
import { CUSTOMER_FORM_INPUTS } from '@modules/customers/constants';
import { customerSchema } from '@modules/customers/schemas';
import { CustomersFormBase } from '@modules/customers/entities';
import { getIntialValues } from '@modules/customers/utils';
import { customerDetailSelector } from '@modules/customers/store';
import UiButton from '@ui/ui-button';
import UiTopSection from '@ui/ui-top-section';
import UiInputField from '@ui/ui-input-field';

interface CustomersCreationProps {
  title: string;
  routes: UiTopSectionRoutes[];
  onSubmit: (form: CustomersFormBase) => void;
  onCancel: () => void;
}

const CustomersForm: FC<CustomersCreationProps> = ({ title, routes, onSubmit, onCancel }) => {
  const customer = useSelector(customerDetailSelector);

  const form = useFormik({
    initialValues: getIntialValues(customer),
    onSubmit: onSubmit,
    validationSchema: customerSchema,
  });

  return (
    <form className={styles.container} onSubmit={form.handleSubmit}>
      <div className={styles.top}>
        <UiTopSection
          title={title}
          routes={routes}
          leftButton={<UiButton text='Cancelar' leftIcon='icon-cancel' onClick={onCancel} isSecondary />}
          rightButton={<UiButton text='Guardar' leftIcon='icon-save' type='submit' isDisabled={!form.isValid} />}
          hasShadow
        />
      </div>

      <div className={styles.inputsWrapper}>
        {CUSTOMER_FORM_INPUTS.map((input) => (
          <div className={styles.input} key={`customer-form-input-${input.name}`}>
            <UiInputField
              type='text'
              name={input.name}
              label={input.title}
              onChange={form.handleChange}
              value={form.values[input.name as keyof object]}
              hasError={form.errors[input.name as keyof object] && form.touched[input.name as keyof object]}
              error={form.errors[input.name as keyof object]}
              onBlur={form.handleBlur}
            />
          </div>
        ))}
      </div>

      <div className={styles.button}>
        <UiButton text='Guardar' type='submit' isFullWidth isDisabled={!form.isValid || !form.dirty} />
      </div>
    </form>
  )
}

export default CustomersForm;

import { NewConsecutiveForm } from "../entities";

export const getNewConsecutiveForm = (numberStore: string, storeName: string): NewConsecutiveForm => {
  return {
    numberStore,
    storeName,
    dianAuthDate: undefined,
    dianAuthDateExp: undefined,
    personType: 'natural',
    dianAuthNumber: '',
    endConsecutive: '',
    initConsecutive: '',
    prefix: '',
  };
};

import { SecurityForm } from "../entities";

export const getChangePasswordForm = (userId: string | number): SecurityForm => {
  return {
    userId,
    phone: '',
    password: '',
    confirmationPassword: '',
  };
};

import React, { ChangeEvent, FC, FormEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions } from "@modules/auth/store";
import { COUNTRY_CODES } from "@constants";
import useSendPhone from "@modules/auth/hooks/use-send-phone";
import styles from "./password-recuperation-phone.module.sass"; 
import UiPhoneInput from "@ui/ui-phone-input";
import UiButton from "@ui/ui-button-v2/ui-button";

interface PasswordRecuperationPhoneProps { }

const PasswordRecuperationPhone: FC<PasswordRecuperationPhoneProps> = () => {
  const [ areaCode, setAreaCode ] = useState<number>(57);
  const [ inputNumber, setInputNumber ] = useState<string>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sendUserPhone } = useSendPhone();

  const handleCodeAreaChange = (phoneCode: number) => {
    setAreaCode(phoneCode);
    dispatch(actions.setPhoneNumber({
      phoneCode: phoneCode,
      phone: inputNumber
    }));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputNumber(e.target.value);
    dispatch(actions.setPhoneNumber({
      phoneCode: areaCode,
      phone: e.target.value
    }))
  };

  const handlePlaceholder = useMemo(() => {
    if (areaCode === 55) {
      return '00000 0000'
    } else return '000 000 0000'
  }, [areaCode]);

  const handleMaxLength = useMemo(() => {
    if (areaCode === 55) {
      return 11
    } else return 10
  }, [areaCode]);

  const handleDisableButton = () => {
    if ((areaCode !== 55 && inputNumber.length < 10) || (areaCode === 55 && inputNumber.length < 11)) {
      return true
    } return false
  };
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    sendUserPhone(inputNumber, areaCode, true)
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <div className={styles.messageWrapper}>
        <span className={styles.message}> {t('Para restablecer tu contraseña ingresa el celular asociado a tu cuenta.')} </span>
      </div>

        <div className={styles.input}>
          <UiPhoneInput
            name={"phoneInput"}
            value={inputNumber}
            placeholder={handlePlaceholder}
            type="number"
            maxLength={handleMaxLength}
            countrySelected={areaCode}
            countries={COUNTRY_CODES}
            onChangePhoneCode={handleCodeAreaChange}
            onChange={onChange}
          />
        </div>

      <UiButton 
        text="Enviar código"
        type="submit"
        isDisabled={handleDisableButton()}
      />

      <button className={styles.back} onClick={() => navigate(-1)}>
        {t('Volver a inicar sesión')}
      </button>
    </form>
  )
}

export default PasswordRecuperationPhone;
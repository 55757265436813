import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COLUMNS_TABLE, PERMISSIONS_COLUMNS, ROLE_OPTIONS, TABS } from '@modules/collaborators/constants';
import UiSwitch from '@ui/ui-switch';
import styles from './collaborators-table.module.sass';
import { collaboratorsDataSelector, collaboratorsErrorsSelector, collaboratorsPaginationSelector, collaboratorsUiSelector } from '@modules/collaborators/store';
import { Collaborator } from '@modules/collaborators/entities';
import { actions as globalActions } from '@config/store';
import { hasOwnerRole } from '@modules/collaborators/utils';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiPaginator from '@ui/ui-paginator';
import useCollaboratorsFetch from '@modules/collaborators/hooks/use-collaborators-fetch';
import { Icon } from '@chiper-inc/sb-web-chiper';

interface CollaboratorsTableProps { }

const CollaboratorsTable: FC<CollaboratorsTableProps> = () => {
  const [ activeColumn, setActiveColumn ] = useState<string>('');
  const [ sortType, setSortType ] = useState<boolean>(false);
  
  const navigate = useNavigate();
  const collaborators = useSelector(collaboratorsDataSelector);
  const { hasRequestedCollaborators } = useSelector(collaboratorsUiSelector);
  const pagination = useSelector(collaboratorsPaginationSelector);
  const errors = useSelector(collaboratorsErrorsSelector);
  const dispatch = useDispatch();
  const { getCollaborators, changePermissionState } = useCollaboratorsFetch();

  const handleSwitchChange = useCallback(({ target }): void => {
    changePermissionState(target.id, target.value, target.checked);
  }, [changePermissionState]);

  const getRoleById = (roleId: string): string =>
    ROLE_OPTIONS.find((role) => role.id === roleId)?.title || '';

  const hasPermission = (collaborator: Collaborator, permission: string): boolean =>
    !!collaborator.employeePermission.find((e) => e.id === permission);

  const handleBlockedClick = useCallback(() =>
    dispatch(globalActions.notification({
      show: true,
      state: 'error',
      message: 'No puedes quitarle permisos a un colaborador con rol de Dueño.',
    })), [dispatch]);

  const handleClick = (title: string, icon?: string) => {
    if (!!icon) {
      setActiveColumn(title);
      setSortType(!sortType);
      const sort = JSON.stringify({
        field: title,
        type: !sortType ? 'ASC' : 'DESC'
      })
      getCollaborators(1, sort)
    }
  };

  if (errors.getCollaborators) return <UiNoDataMessage message='No pudimos obetener su lista de colaboradores' />

  if (hasRequestedCollaborators && !collaborators.length) return (
    <p className={styles.loading}>Parece que aún no tienes colaboradores.</p>
  );

  if (!collaborators.length) return <p className={styles.loading}>Cargando...</p>

  return (
    <>
      <table className={styles.table} cellSpacing="0">
        <thead>
          <tr className={styles.rowHeader}>
            {COLUMNS_TABLE.map((item, i) =>
              <td className={`${styles.header} ${activeColumn === item.title && styles.active} ${!item.icon && styles.buttons}`} key={`collaborators-table-column-${String(i)}`}>
                <div className={styles.headerWrapper}>
                  <div className={styles.title} onClick={() => handleClick(item.title, item.icon)}>{item.title}</div>
                  {!!item.icon && (
                  <div className={styles.icon} onClick={() => handleClick(item.title, item.icon)} data-testid="table-head-item">
                    <Icon size="12px" weight="bold" name={item.icon} />
                  </div>
                )}
                </div>
              </td>)}
          </tr>
        </thead>
        <tbody>
          {collaborators.map((collaborator, i) => (
            <tr key={`collaborators-table-item-${String(i)}`} className={styles.row}>

              <td className={styles.cell} onClick={() => navigate(`${TABS.update}/${collaborator.id}`)}>
                <div className={styles.nameCell}>
                  <span className={`icon-edit ${styles.icon}`} />
                  {collaborator.name}
                </div>
              </td>

              <td className={styles.cell}>{collaborator.document}</td>
              <td className={styles.cell}>{collaborator.phone}</td>
              <td className={styles.cell}>{getRoleById(collaborator.roleId)}</td>

              {PERMISSIONS_COLUMNS.map((permission, index) => (
                <td key={`collaborator-permission-item-${String(index + i)}`} className={styles.cell}>
                  <div className={styles.switchWrapper}>
                    <UiSwitch
                      onChange={handleSwitchChange}
                      isChecked={hasPermission(collaborator, permission)}
                      value={permission}
                      id={collaborator.id}
                      isBlocked={hasOwnerRole(collaborator.roleId)}
                      onBlockedClick={handleBlockedClick}
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <UiPaginator onChangePage={getCollaborators} pagesQuantity={pagination.totalPages} currentPage={pagination.currentPage} />
    </>
  );
}

export default CollaboratorsTable;

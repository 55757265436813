import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_TABLE_COLUMNS, TABS } from '@modules/inventory/constants';
import { inventoryDataSelector, inventoryPaginationSelector, inventoryUiSelector } from '@modules/inventory/store';
import { formatMoney } from '@utils/format-money';
import styles from './inventory-table.module.sass';
import UiPaginator from '@ui/ui-paginator';
import useInventoryFetch from '@modules/inventory/hooks/use-inventory-fetch';
import Clevertap from '@config/clevertap/clevertap';
import { INVENTORY_DETAIL } from '@constants';
import { Icon } from '@chiper-inc/sb-web-chiper';

interface InventoryTableProps { }

const InventoryTable: FC<InventoryTableProps> = () => {
  const [ activeColumn, setActiveColumn ] = useState<string>('');
  const [ sortType, setSortType ] = useState<boolean>(false);
  
  const navigate = useNavigate();
  const inventory = useSelector(inventoryDataSelector);
  const { isLoading } = useSelector(inventoryUiSelector);
  const { hasNoSearchResult } = useSelector(inventoryUiSelector);
  const pagination = useSelector(inventoryPaginationSelector);
  const { getPage } = useInventoryFetch();
  const { getInventory } = useInventoryFetch();

  if (!inventory.length && isLoading) return <div className={styles.loading}>Cargando...</div>;

  if (hasNoSearchResult) return (
    <p className={styles.noResults}>
      No se encontraron coincidencias. Inteta nuevamente.
    </p>
  );

  const handleRowClick = (ean: string): void => {
    Clevertap.pushEvent(INVENTORY_DETAIL, { value: ean })
    navigate(`${TABS.productDetail}/${ean}`)
  };

  const handleClick = (title: string) => {
    setActiveColumn(title);
    setSortType(!sortType);
    const sort = JSON.stringify({
      field: activeColumn,
      type: !sortType ? 'ASC' : 'DESC'
    })
    getInventory(sort)
  };

  return (
    <>
      <div className={styles.table}>
        <div className={styles.header}>
          {INVENTORY_TABLE_COLUMNS.map((item) => (
            <div className={`${styles.cell} ${activeColumn === item.title && styles.active}`} key={`inventory-table-column-${item.title}`}>
            <div className={styles.title} onClick={() => handleClick(item.title)}>{item.title}</div>
            {!!item.icon && (
            <div className={styles.icon} onClick={() => handleClick(item.title)} data-testid="table-head-item">
              <Icon size="12px" weight="bold" name={item.icon} />
            </div>
          )}
          </div>
          ))}
        </div>
        {inventory.map((item, i) => (
          <div key={`inventory-table-item-${item.ean}-${i}`} className={styles.body} onClick={() => handleRowClick(item.ean)}>
            <div className={styles.cell}>{item.ean}</div>
            <div className={styles.cell}>{item.brandName}</div>
            <div className={styles.cell}>{item.name}</div>
            <div className={styles.cell}>{formatMoney(item.managerPrice, 0)}</div>
            <div className={styles.cell}>{formatMoney(item.customerPrice, 0)}</div>
            <div className={styles.cell}>{item.margin}</div>
            <div className={styles.cell}> {item.total ? formatMoney(item.total, 0) : 0}</div>
            <div className={styles.cell}>{item.packagingType}</div>
            <div className={styles.cell}>{item.stock ? item.stock : 0} </div>
          </div>
        ))}
      </div>

      {!pagination.totalPages
        ? <div className={styles.loading}>Aún no tienes productos cargados en tu inventario</div>
        : <UiPaginator pagesQuantity={pagination.totalPages} onChangePage={getPage} currentPage={pagination.currentPage} />
      }
    </>
  )
}

export default InventoryTable;

import {
  ACCESS_POS_WEB_PERMISSION_ID, COLOMBIA_IDENTIFICATIONS, COLOMBIA_ISO_CODE,
  EDIT_INVENTORY_PERMISSION_ID, EDIT_PURCHASE_PRICE_PERMISSION_ID, EDIT_SALE_PRICE_PERMISSION_ID,
  MEXICO_IDENTIFICATIONS, OWNER_ROLE_ID, VIEW_CASH_DECK_CONTROL_PERMISSION_ID
} from "@constants";
import { NEW_COLLABORATOR_FORM_BASE, ROLE_OPTIONS, } from "../constants";
import { Collaborator, CollaboratorForm, CollaboratorInput } from "../entities";

export const hasOwnerRole = (roleId: string): boolean => roleId === OWNER_ROLE_ID;

export const getNewPermissions = (permissions: string[], permissionId: string): string[] => {
  const currentPermissions = permissions.filter((permission) => permission !== permissionId);

  return currentPermissions.length === permissions.length
    ? [...permissions, permissionId]
    : currentPermissions;
};

export const getCollaboratorPermissions = (collaborator?: Collaborator): string[] => {
  if (!collaborator) return [];
  return collaborator.employeePermission.map((e) => e.id);
};

export const getPermissionToUpdate = (collaborator: Collaborator, permissionId: string): string[] => {
  const permissions = getCollaboratorPermissions(collaborator);
  return getNewPermissions(permissions, permissionId);
};

export const getNotificationMessage = (name: string, permissionId: string, isActivation = true): string => {
  const firstPart = () =>
    `Ahora ${name} ${!isActivation ? 'ya no' : ''} puede`;

  switch (permissionId) {
    case ACCESS_POS_WEB_PERMISSION_ID:
      return `${firstPart()} ingresar al Admin`;

    case VIEW_CASH_DECK_CONTROL_PERMISSION_ID:
      return `${firstPart()} ver transacciones en cierre`;

    case EDIT_INVENTORY_PERMISSION_ID:
      return `${firstPart()} editar inventario`;

    case EDIT_SALE_PRICE_PERMISSION_ID:
      return `${firstPart()} editar precios de venta`;

    case EDIT_PURCHASE_PRICE_PERMISSION_ID:
      return `${firstPart()} editar precios de compra`;

    default:
      return `${firstPart()} recibir caja`;
  }
};

export const getCollaboratorToForm = (
  storeId: number,
  storeOwnerId: string | number,
  isoCode?: string,
  collaborator?: Collaborator
): CollaboratorForm => {
  const form = !collaborator
    ? NEW_COLLABORATOR_FORM_BASE
    : {
      id: collaborator.id,
      name: collaborator.name,
      document: collaborator.document,
      documentTypeId: collaborator.documentTypeId,
      phone: collaborator.phone,
      roleId: collaborator.roleId,
    }

  return {
    ...form,
    storeId,
    storeOwnerId,
    isoCode,
  };
};

export const getInputs = (isoCode: string): CollaboratorInput[] => [
  {
    name: 'name',
    title: 'Nombre',
    isSelect: false,
  },
  {
    name: 'roleId',
    title: 'Rol',
    isSelect: true,
    options: ROLE_OPTIONS,
  },
  {
    name: 'phone',
    title: 'Celular',
    isSelect: false,
  },
  {
    name: 'documentTypeId',
    title: 'Tipo de identificación',
    isSelect: true,
    options: isoCode === COLOMBIA_ISO_CODE ? COLOMBIA_IDENTIFICATIONS : MEXICO_IDENTIFICATIONS,
  },
  {
    name: 'document',
    title: 'Identificación',
    isSelect: false,
  },
];
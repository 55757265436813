import { sendOtp } from "@modules/auth/auth.services";
import { actions as globalActions } from "@config/store";
import { actions } from "@modules/auth/store";
import { useDispatch } from "react-redux";
import { NEW_PASSWORD } from "../constants";
import { OTP } from "../entities";

const useSendOtp = () => {
  const dispatch = useDispatch();

  const sendUserOtp = ( otp: string, phone: string, phoneCode: number ) => {
    const args: OTP = {
      phone: phone,
      phoneCode: phoneCode,
      otp: otp
    };
    dispatch(actions.setIsLoadingLogin(true));
    sendOtp(args)
      .then(() => dispatch(actions.setPasswordRecuperationPage(NEW_PASSWORD)))
      .catch(() => dispatch(globalActions.notification({
        show: true,
        state: 'error',
        message: 'El código no coincide.',
      })))
      .finally(() => dispatch(actions.setIsLoadingLogin(false)));
  };

  return {
    sendUserOtp,
  };
};

export default useSendOtp;
import React, { FC, memo } from 'react';

import styles from './reports-resume.module.sass';
import { ResumeData } from '@modules/reports/entities';
import { formatMoney } from '@utils/format-money';

interface ReportsResumeProps {
  data: ResumeData;
}

const ReportsResume: FC<ReportsResumeProps> = ({ data }) => {
  const DEFAULT_PERCENTAGE = 0?.toFixed(2);
  return (
    <div className={styles.wrapper}>
      <div className={styles.texts}>
        <div className={styles.title}>
          <span className={styles.label}>Ventas</span>
          <span className={styles.value}>
            {formatMoney(data?.totalSales || 0)}
          </span>
        </div>
        <div className={styles.subTitle}>
          <span className={styles.percentage}>
            {`${data?.averageSalesVsTop?.toFixed(2) || DEFAULT_PERCENTAGE}%`}
          </span>
          <span className={styles.text}>vs. tiendas más exitosas</span>
        </div>
      </div>
      <div className={styles.texts}>
        <div className={styles.title}>
          <span className={styles.label}>Transacciones</span>
          <span className={styles.value}>{data?.totalTransactions || 0}</span>
        </div>
        <div className={styles.subTitle}>
          <span className={styles.percentage}>
            {`${data?.averageTransactionsVsTop?.toFixed(2) || DEFAULT_PERCENTAGE}%`}
          </span>
          <span className={styles.text}>vs. tiendas más exitosas</span>
        </div>
      </div>
      <div className={styles.texts}>
        <div className={styles.title}>
          <span className={styles.label}>SKUs</span>
          <span className={styles.value}>{data?.totalReferences || 0}</span>
        </div>
        <div className={styles.subTitle}>
          <span className={styles.percentage}>
            {`${data?.averageReferencesVsTop?.toFixed(2) || 0?.toFixed(2)}%`}
          </span>
          <span className={styles.text}>vs. tiendas más exitosas</span>
        </div>
      </div>
      <div className={styles.texts}>
        <div className={styles.title}>
          <span className={styles.label}>Margen</span>
          <span className={styles.value}>{`${data?.totalMargin?.toFixed(2) || 0}%`}</span>
        </div>
        <div className={styles.subTitle}>
          <span className={styles.percentage}>{`${data?.averageMarginVsTop?.toFixed(2) || DEFAULT_PERCENTAGE}%`}</span>
          <span className={styles.text}>vs. tiendas más exitosas</span>
        </div>
      </div>
    </div>
  );
};
 
export default memo(ReportsResume);

import React, { FC } from 'react';
import { customerTransactionDetailSelector } from '@modules/customers/store';
import { useSelector } from 'react-redux';
import styles from './customer-transaction-detail-table.module.sass';

interface CustomerTransactionDetailTableProps { }

const CustomerTransactionDetailTable: FC<CustomerTransactionDetailTableProps> = () => {
  const transactions = useSelector(customerTransactionDetailSelector);

  return (
    <div className={styles.container}>
      <table className={styles.table} cellSpacing="0">
        <thead>
          <tr>
            <th className={styles.head}>Cantidad</th>
            <th className={styles.head}>SKU</th>
            <th className={styles.head}>Valor</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) =>
            <tr key={`sale-detail-table-item${transaction.name}`}>
              <td className={styles.cell}>
                {transaction.quantity}
              </td>
              <td className={styles.cell}>
                {transaction.name}
              </td>
              <td className={styles.cell}>
                {transaction.total}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default CustomerTransactionDetailTable;

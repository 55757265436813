import axios from "axios";
import { LIMIT_GET_PROVIDERS } from "../constants";
import { Provider, Providers, ProvidersForm } from "../entities";

export const fetchGetProviders = (skip: number = 0, sort?: string): Promise<Providers> =>
  axios.get<Providers>(`providers?limit=${LIMIT_GET_PROVIDERS}&skip=${0 * LIMIT_GET_PROVIDERS}&search=${!!sort ? `&sort=${sort}` : ''}`)
    .then(({ data }) => data);

export const fetchSearchProviders = (value: string): Promise<Provider[]> =>
  axios.get<Providers>(`providers?limit=&search=${value}&skip=0`)
    .then(({ data }) => data.providers);

export const fetchDownloadProviders = (storeId: number): Window | null =>
  window.open(`${process.env.REACT_APP_URL_POS_API}/download-providers-pos/${storeId}`, '_blank');

export const fetchCreateProvider = (provider: ProvidersForm): Promise<boolean> =>
  axios.post('create-provider', provider)
    .then(() => true);

export const fetchUpdateProvider = (provider: ProvidersForm): Promise<boolean> =>
  axios.patch('edit-provider', provider)
    .then(() => true);

export const fetchGetNewProviderCode = (): Promise<string> =>
  axios.get('code-provider')
    .then(({ data }) => data.code);

import axios from 'axios';
import { sleep } from "@utils/sleep";
import { LoginResponse, OTP, NewPassword } from "./entities";
import { PhoneInterface } from './store';

export const loginAsNormalUser = async (user: string, password: string): Promise<LoginResponse> => {
  return axios.post('/auth/pos-login', { login: user, password })
    .then(response => ({ ...response.data, roleId: String(response.data.roleId) }));
};

export const loginWithToken = async (authWeb: string): Promise<LoginResponse> => {
  return axios.post('/auth/pos-login', { authWeb })
    .then(response => ({ ...response.data, roleId: String(response.data.roleId) }));
};

export const loginAsSupportUser = () => {
  return sleep(200);
};

export const sendPhone = async (phone: PhoneInterface): Promise<PhoneInterface> => {
  return axios.post('/auth/send-otp', phone)
    .then(resp => resp.data)
};

export const sendNewPassword = async (password: NewPassword): Promise<string> => {
  return axios.put('/auth/change-password', password)
    .then(resp => resp.data)
};

export const sendOtp = async (otp: OTP): Promise<OTP> => {
  return axios.post('/auth/check-otp', otp)
    .then(resp => resp.data)
};
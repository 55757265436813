import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import styles from './reports.module.sass';
import { REPORTS_PATH, ReportsTabs } from './constants';
import { fetchResumeData } from './services/reports.services';
import { DatesFilter } from './entities';
import { useIsMounted } from '@utils/use-is-mounted';
import { currentUserSelector } from '@config/store';
import { actions, reportsDatesFilterSelector, reportsResumeDataSelector, reportsUiSelector } from './store';
import ReportsFilters from './components/reports-filters';
import ReportsResume from './components/reports-resume';
import Clevertap from '@utils/clevertap';
import {
  REPORTS_CHANGE_DATES, REPORTS_DOWNLOAD_INVOICE,
  REPORTS_DOWNLOAD_RESUME, REPORTS_DURATION, REPORTS_OPEN
} from '@constants';
import ReportsRouter from './routers/reports.router';

interface ReportsProps { }

const Reports: FC<ReportsProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { tab } = useSelector(reportsUiSelector);
  const user = useSelector(currentUserSelector);
  const resumeData = useSelector(reportsResumeDataSelector);
  const datesFilter = useSelector(reportsDatesFilterSelector);

  const getResumeData = useCallback(({ startDate, finishDate }: DatesFilter): void => {
    fetchResumeData({ startDate, finishDate })
      .then((response) => {
        if (isMounted.current) {
          dispatch(actions.setResumeData(response));
        }
      })
  }, [dispatch, isMounted]);

  const navigateToTab = useCallback((selectedTab?: ReportsTabs) => {
    navigate(`/${REPORTS_PATH}/${selectedTab || tab}`, { replace: true });
  }, [navigate, tab]);

  const handleChangeTab = useCallback((tabInfo: ReportsTabs) => {
    dispatch(actions.setTab(tabInfo));
    navigateToTab(tabInfo);
  }, [dispatch, navigateToTab]);


  const handleChangeDates = useCallback((dates: DatesFilter) => {
    const start = format(new Date(dates.startDate), 'MM-dd-yyyy');
    const end = format(new Date(dates.finishDate), 'MM-dd-yyyy');
    dispatch(actions.setDatesFilter({ startDate: start, finishDate: end, }));
    getResumeData({ startDate: start, finishDate: end, });

    Clevertap.pushEvent(REPORTS_CHANGE_DATES, {
      initFilterAt: dates.startDate,
      endFilterAt: dates.finishDate,
      changedFrom: tab
    });
  }, [dispatch, getResumeData, tab]);


  const handleClickBillsButton = useCallback(() => {
    window.open(`${process.env.REACT_APP_URL_POS_API}/download-invoice/${user.storeId}/${
      user.country}/${datesFilter.startDate}/${datesFilter.finishDate}`, '_blank'
    );
    Clevertap.pushEvent(REPORTS_DOWNLOAD_INVOICE, { initFilterAt: datesFilter.startDate, endFilterAt: datesFilter.finishDate });
  }, [user.storeId, user.country, datesFilter]);

  const handleClickResumeButton = useCallback(() => {
    window.open(`${process.env.REACT_APP_URL_POS_API}/download-inform/${user.storeId}/${
      user.country}/${datesFilter.startDate}/${datesFilter.finishDate}`, '_blank'
    );
    Clevertap.pushEvent(REPORTS_DOWNLOAD_RESUME, { initFilterAt: datesFilter.startDate, endFilterAt: datesFilter.finishDate });
  }, [user.storeId, user.country, datesFilter]);

  useEffect(() => {
    getResumeData({ startDate: datesFilter.startDate, finishDate: datesFilter.finishDate });
  }, [datesFilter.startDate, datesFilter.finishDate, getResumeData]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(REPORTS_OPEN);
    return () => Clevertap.pushDurationEvent(REPORTS_DURATION, openedAt);
  }, []);

  const getTabPathFromUrl = useCallback((): ReportsTabs => {
    const getPath: string = window.location.pathname;
    return (getPath.split("/").pop() as ReportsTabs) || ReportsTabs.Sales
  }, []);

  useEffect(() => {
    handleChangeTab(getTabPathFromUrl());
  }, [handleChangeTab, getTabPathFromUrl]);

  return (
    <div className={styles.wrapper}>
      <ReportsFilters
        currentTab={tab}
        initDate={datesFilter.startDate}
        endDate={datesFilter.finishDate}
        onChangeDates={handleChangeDates}
        onChangeTab={handleChangeTab}
        onClickBillsButton={handleClickBillsButton}
        onClickResumeButton={handleClickResumeButton}
      />
      
      <ReportsResume data={resumeData} />

      <ReportsRouter />
    </div>
  );
};

export default Reports;

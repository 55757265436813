import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom';
import { PATH, TABS } from '@modules/collaborators/constants';
import CollaboratorsForm from '@modules/collaborators/components/form/collaborators-form';
import UiButton from '@ui/ui-button';
import useCollaboratorsFetch from '@modules/collaborators/hooks/use-collaborators-fetch';

interface CollaboratorsCreationProps { }

const CollaboratorsCreation: FC<CollaboratorsCreationProps> = () => {
  const navigate = useNavigate();
  const { createCollaborator } = useCollaboratorsFetch();

  const goBack = (): void => navigate(`/${PATH}/${TABS.home}`);

  return (
    <CollaboratorsForm
      title='Crear colaborador'
      onSubmit={createCollaborator}
      leftButton={
        <UiButton text='Cancelar' leftIcon='icon-cancel' onClick={goBack} isSecondary />
      }
    />
  );
}

export default CollaboratorsCreation;
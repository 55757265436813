import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export default i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: {
          "errors": {
            "required": "Campo requerido.",
            "min_digits": "Mínimo {{digits}} dígitos.",
            "max_digits": "Máximo {{digits}} dígitos.",
            "max_characters": "Máximo {{digits}} caracteres",
            "only_letters": "Solo se permiten caracteres de la A a la Z",
            "only_numbers": "Solo se permiten números",
            "only_letters_and_numbers": 'Solo se permiten caracteres de la A a la Z y números',
            "invalid_phone": "Número de celular invalido",
            "invalid_description": "Descripción invalida",
            "passwords_no_match": "Las contraseñas no coinciden",
            "end_date": "La fecha de finalización debe ser posterior a la fecha de inicio",
            "invalid_date": "Fecha invalida",
            "invalid_email": "Email invalido",
            "overpay": "Está intentando abonar un valor mayor al de la deuda",
            "max_purchase_price": "El precio de compra no debe superar al precio de venta",
            "min_sale_price": "El precio de venta no debe ser inferior al precio de compra",
            "not_past_dates": "No se permiten fechas pasadas",
            "end_date_not_equal_init_date": "La fecha final no puede ser anterior a la inicial",
            "zero_amount": "El monto debe ser mayor a 0",
            "max_discount_price": "El precio con descuento no debe superar al precio del producto",
            "max_money_value": "El valor máximo permitido es {{max}}"
          },
          "auth": {
            "title": "Bienvenido",
            "text": "Ingresa tu número de celular y contraseña para iniciar sesión",
            "phone": "Número de celular",
            "password": "Contraseña",
            "footer_text": "¡El futuro de tu negocio en tus manos!"
          }
        }
      }
    },
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: true
    }
  });

import * as yup from 'yup';
import { t } from 'i18next'
import { MAX_MONEY_VALUE, MAX_STOCK_VALUE } from '@constants';
import { formatMoney } from '@utils/format-money';

export const productSalePriceUpdateMobileSchema = yup.object().shape({
  totalPrice: yup.number()
    .required(t('errors.required'))
    .min(yup.ref('managerTotal'), t('errors.min_sale_price'))
    .test('test', t('errors.zero_amount'), (total) => Number(total) > 0)
    .max(MAX_MONEY_VALUE, t('errors.max_money_value', { max: formatMoney(MAX_MONEY_VALUE) })),
});

export const productStockUpdateMobileSchema = yup.object().shape({
  stock: yup.number()
    .required(t('errors.required'))
    .typeError(t('errors.only_numbers'))
    .min(0, t('errors.zero_amount'))
    .max(MAX_STOCK_VALUE, t('errors.max_money_value', { max: MAX_STOCK_VALUE })),
});

export const productPurchasePriceUpdateMobileSchema = yup.object().shape({
  managerTotal: yup.number()
    .required(t('errors.required'))
    .max(yup.ref('totalPrice'), t('errors.max_purchase_price'))
    .test('test', t('errors.zero_amount'), (total) => Number(total) > 0)
    .min(0, t('errors.zero_amount'))
});

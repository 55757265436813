import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InventoryResumeState } from ".";
import {
  InventoryItem, InventoryFileObject, Product,
  ProductCategory, ProductDiscountTableItem, ProductDiscount,
  Combo, SearchedProduct, ComboTableItem, ComboProduct
} from "../entities";

import { initialState } from './inventory.state';

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setIsLoadingGetDiscount: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoadingGetDiscount = payload;
    },
    setIsUpdatingDiscount: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isUpdatingDiscount = payload;
    },
    setIsUploadingInventory: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isUploadingInventory = payload;
    },
    setShowPreviewTable: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showPreviewTable = payload;
    },
    setIsRepeatedFile: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isRepeatedFile = payload;
    },
    setIsReadingdFile: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isReadingFile = payload;
    },
    setNoSearchReasults: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasNoSearchResult = payload;
    },
    setNoComboSearchReasults: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasNoComboSearchResult = payload;
    },
    setNoComboProductSearchReasults: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasNoComboProductSearchResult = payload;
    },
    setShowComboDatePickers: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showComboDatePickers = payload;
    },
    setShowMobileDisableNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showMobileDisableNotification = payload;
    },
    setCurrentComboSearchedValue: (state, { payload }: PayloadAction<string>) => {
      state.search.currentComboSearchedValue = payload;
    },
    setCurrentSearchedValue: (state, { payload }: PayloadAction<string>) => {
      state.search.currentSearchedValue = payload;
    },
    setShowDatePickers: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showDatePickers = payload;
    },
    setHasRequestedDiscounts: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasRequestedDiscounts = payload;
    },
    setIsFromDashboardMobile: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isFromDashboardMobile = payload;
    },
    setInventory: (state, { payload }: PayloadAction<InventoryItem[]>) => {
      state.data.inventory = payload;
    },
    setTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.pagination.totalPages = payload;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    },
    setResume: (state, { payload }: PayloadAction<InventoryResumeState>) => {
      state.resume = payload;
    },
    setProduct: (state, { payload }: PayloadAction<Product>) => {
      state.detail.product = payload;
    },
    setProductDiscounts: (state, { payload }: PayloadAction<ProductDiscountTableItem[]>) => {
      state.detail.discounts = payload;
    },
    setCurrentDiscount: (state, { payload }: PayloadAction<ProductDiscount>) => {
      state.detail.currentDiscount = payload;
    },
    setGetInventoryError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getInventory = payload;
    },
    setGetProductDetailError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getProductDetail = payload;
    },
    setGetProductDiscountsError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getProductDiscounts = payload;
    },
    setGetCombosError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getCombos = payload;
    },
    setProductCategories: (state, { payload }: PayloadAction<ProductCategory[]>) => {
      state.detail.categories = payload;
    },
    setUploadedFileName: (state, { payload }: PayloadAction<string>) => {
      state.uploadedFile.name = payload;
    },
    setUploadedFileData: (state, { payload }: PayloadAction<InventoryFileObject[]>) => {
      state.uploadedFile.data = payload;
    },
    setCombos: (state, { payload }: PayloadAction<ComboTableItem[]>) => {
      state.combos.data = payload;
    },
    setCombosTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.combos.pagination.totalPages = payload;
    },
    setCombosCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.combos.pagination.currentPage = payload;
    },
    setTotalActiveCombos: (state, { payload }: PayloadAction<number>) => {
      state.combos.totalActive = payload;
    },
    setCurrentCombo: (state, { payload }: PayloadAction<Combo>) => {
      state.currentCombo.combo = payload;
    },
    setComboProducts: (state, { payload }: PayloadAction<ComboProduct[]>) => {
      state.currentCombo.products = payload;
    },
    setSearchedProductsToCombo: (state, { payload }: PayloadAction<SearchedProduct[]>) => {
      state.currentCombo.searchedProducts = payload;
    },
    setCustomerComboPrice: (state, { payload }: PayloadAction<number>) => {
      state.currentCombo.customerPrice = payload;
    }
  },
});

export const actions = inventorySlice.actions;
export default inventorySlice.reducer;

import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction, NavLink, Link } from 'react-router-dom';
import { MENU_ITEMS, STORAGE_TOKEN } from '@constants';
import { currentUserSelector } from '@config/store';
import LogoPosImg from '@img/logo-chiper-pos-white.png'
import styles from './ui-menu.module.sass';
import Clevertap, { OPEN_BILLS, OPEN_CLOSURES, OPEN_CUSTOMERS, OPEN_IVENTORY, OPEN_PROVIDERS, OPEN_REPORTS, OPEN_REQUESTS } from '@config/clevertap';

interface UiMenuProps {
  navigate: NavigateFunction;
  closeMenu: () => void;
  isOpen: boolean;
}

const UiMenu: FC<UiMenuProps> = ({ navigate, closeMenu, isOpen }) => {
  const user = useSelector(currentUserSelector);

  const handleLogout = () => {
    localStorage.removeItem((STORAGE_TOKEN));
    navigate('/login', { replace: true });
  };

  const handleClevertapEvent = (name: string) => {
    switch (name) {
      case "Informes": return OPEN_REPORTS;
      case "Inventario": return OPEN_IVENTORY;
      case "Cierres": return OPEN_CLOSURES;
      case "Proveedores": return OPEN_PROVIDERS;
      case "Pedidos": return OPEN_REQUESTS;
      case "Clientes": return OPEN_CUSTOMERS;
      case "Facturas": return OPEN_BILLS;
      default: return ''
    }
  };

  const handleItemClick = (name: string) => {
    Clevertap.pushEvent(handleClevertapEvent(name), { from: 'Menu', locationId: user.locationId })
    closeMenu();
  };


  return (
    <div className={`${styles.wrapper} ${isOpen && styles.open}`}>
      <div className={styles.overlay} onClick={closeMenu} />

      <div className={styles.menuTop}>
        <p className={styles.store}>{user.storeName}</p>
        <NavLink className={styles.name} to='/profile' onClick={() => handleItemClick('Perfil')} data-testid='user'>
          <span className={styles.status} />
          {user.firstName} {user.lastName}
        </NavLink>
      </div>

      <div className={styles.items}>
        {MENU_ITEMS.map(item => (
          <NavLink
            key={`dashboard-menu-item-${item.name}`}
            className={(navData) => `${styles.item} ${navData.isActive ? styles.active : ''}`}
            to={item.routeTo}
            onClick={() => handleItemClick(item.name)}
          >
            <span className={`icon-${item.icon} ${styles.icon}`} />
            <span className={styles.name}>{item.name}</span>
          </NavLink>
        ))}
      </div>

      <div className={styles.bottom}>
        <Link to='/' onClick={closeMenu} data-testid='logo'>
          <img src={LogoPosImg} alt='logo' className={styles.logo} />
        </Link>
        <div className={styles.logoutWrapper} onClick={handleLogout}>
          <span className={`icon-logout-2 ${styles.door}`} />
          <span className={styles.closeSection}>Cerrar Sesión</span>
        </div>
      </div>
    </div>
  )
}

export default memo(UiMenu);
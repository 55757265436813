import axios from "axios";
import { BussiessData, Consecutive, NewConsecutiveForm } from "../entities";

export const fetchGetInvoicingInformation = (storeId: number): Promise<Consecutive[]> =>
  axios.get<Consecutive[]>(`storeprefix/${storeId}`)
    .then(({ data }) => data);

export const fetchUpdateBusinessData = (body: BussiessData, storeId: number): Promise<boolean> =>
  axios.put(`storeprefix/update/${storeId}`, body)
    .then(() => true);

export const fetchUpdateShowNit = (isActive: boolean): Promise<boolean> =>
  axios.put('store-prefix/update-nit', { value: isActive })
    .then(() => true);

export const fetchCreateConsecutive = (body: NewConsecutiveForm): Promise<Consecutive> =>
  axios.post<Consecutive>('storeprefix', body)
    .then(({ data }) => data);

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { billsDataSelector, billsDetailSelector, billsErrorsSelector, billsUiSelector } from '@modules/bills/store';
import billImage from '@img/bill.png';
import styles from './bill-detail-wrapper.module.sass';
import BillDetail from '../bill-detail';
import UiNoDataMessage from '@ui/ui-no-data-message-v2';
import UiUnexpectedError from '@ui/ui-unexpected-error';

interface BillDetailWrapperProps { }

const BillDetailWrapper: FC<BillDetailWrapperProps> = () => {
  const id = useSelector(billsDetailSelector);
  const { hasRequestedBills, hasNoSearchResults } = useSelector(billsUiSelector);
  const bills = useSelector(billsDataSelector);
  const { getBillsError } = useSelector(billsErrorsSelector);

  if (getBillsError) return (
    <div className={styles.empty}>
      <UiUnexpectedError />
    </div>
  );

  if (hasNoSearchResults) return (
    <div className={styles.empty}>
      <UiNoDataMessage
        title='No hay resultados para tu búsqueda'
        message='Verifica rus criterios de búsqueda'
      />
    </div>
  );
  
  if (!bills.length && hasRequestedBills) return (
    <div className={styles.empty}>
      <UiNoDataMessage
        title='No hay facturas para ver!'
        message='Debes realizar ventas para ver tus facturas'
      />
    </div>
  );

  if (!id) return (
    <div className={styles.empty}>
      <p className={styles.title}>Elige una factura para ver el detalle</p>
      <figure className={styles.img} >
        <img src={billImage} alt='bill' className={styles.img} />
      </figure>
    </div>
  );

  return (
    <div className={styles.container} data-testid="bill-detail-wrapper">
      <BillDetail />
    </div>
  );
}

export default BillDetailWrapper;

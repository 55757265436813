import React, { FC } from "react";
import { useSelector } from "react-redux";
import { closuresDetailSelector } from "@modules/closures/store";
import { currentUserSelector } from "@config/store";
import { COLOMBIA_ISO_CODE } from "@constants";
import { formatMoney } from "@utils/format-money";
import ClosuresHomeDetailBox from "../closures-home-detail-box";
import styles from "./closures-home-detail-bottom-info.module.sass";
import UiDivider from "@ui/ui-divider";

interface ClosuresHomeDetailBottomInfoProps { }

const ClosuresHomeDetailBottomInfo: FC<ClosuresHomeDetailBottomInfoProps> = () => {
  const closure = useSelector(closuresDetailSelector);
  const userCountry = useSelector(currentUserSelector).country;

  const boxes = [
    {
      title: "Ingresos por método de pago",
      list: [
        { title: "Ventas en Efectivo", value: closure.income },
        { title: "Ventas en Tarjeta", value: closure.income_card },
        ...userCountry === COLOMBIA_ISO_CODE
          ? [
            { title: "Ventas en Daviplata", value: closure.daviplata },
            { title: "Ventas en Nequi", value: closure.nequi },
          ]
          : [{ title: "Ventas en Codi", value: closure.codi }],
          { title: "Abonos", value: closure.pay_loan },
          { title: "Adicionales", value: closure.entry },
      ]
    },
    {
      title: "Devoluciones y egresos",
      list: [
        { title: "Pago a proveedores", value: closure.provider },
        { title: "Pago de servicios", value: closure.services },
        { title: "Personal", value: closure.personal },
        { title: "Otros", value: closure.others },
        { title: "Devoluciones", value: closure.devolutions },
      ]
    },
    {
      title: "Ajustes o notas",
      list: []
    },
  ];

  return (
    <div className={styles.wrapper}>
      {boxes.map(e => (
        <ClosuresHomeDetailBox className={styles.box} key={`closure-info-${e.title}`}>
          <p className={styles.title}>{e.title}</p>

          <UiDivider />

          <div className={styles.items}>
            {!e.list.length && (
              <p className={styles.empty}>Sin novedades</p>
            )}
            {e.list.map(el => (
              <p className={styles.item} key={`closure-bottom-item-${el.title}`}>
                {el.title}
                <span className={styles.value}>
                  {formatMoney(el.value)}
                </span>
              </p>
            ))}
          </div>

        </ClosuresHomeDetailBox>
      ))}
    </div>
  );
}

export default ClosuresHomeDetailBottomInfo;

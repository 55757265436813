import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions as globalActions } from '@config/store';
import {
  inventoryErrorsSelector, inventoryUiSelector,
  productCategoriesSelector, productDetailSelector
} from '@modules/inventory/store';
import useProductFetch from '@modules/inventory/hooks/use-product-fetch';
import UiNoDataMessage from '@ui/ui-no-data-message';
import styles from './product-detail-mobile.module.sass';
import { getProductDetailList } from '@modules/inventory/utils';
import UiSwitch from '@ui/ui-switch';
import UiLoader from '@ui/ui-loader';
import ProductDetailMobileInputs from '@modules/inventory/components/product-update-mobile-inputs';
import ProductDisableNotificationMobile from '@modules/inventory/components/product-disable-notification';
import Clevertap from '@utils/clevertap';
import { PRODUCT_DETAIL_OPEN, PRODUCT_DURATION } from '@constants';

interface ProductDetailMobileProps { }

const ProductDetailMobile: FC<ProductDetailMobileProps> = () => {
  const { sku } = useParams();
  const dispatch = useDispatch();
  const product = useSelector(productDetailSelector);
  const categories = useSelector(productCategoriesSelector);
  const errors = useSelector(inventoryErrorsSelector);
  const { isLoading, showMobileDisableNotification, isFromDashboardMobile } = useSelector(inventoryUiSelector);
  const { getProduct, getCategories, changeState } = useProductFetch();

  useEffect(() => {
    dispatch(globalActions.setMobileHeaderSection({
      section: product.name, routeTo: isFromDashboardMobile ? '/' : '/products-search'
    }));
  }, [dispatch, isFromDashboardMobile, product]);

  useEffect(() => {
    if (!product.sku && sku) getProduct(sku);
  }, [getProduct, product.sku, sku]);

  useEffect(() => {
    if (!categories.length) {
      getCategories()
    }
  }, [categories.length, getCategories]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(PRODUCT_DETAIL_OPEN, { sku: String(sku) });
    return () => Clevertap.pushDurationEvent(PRODUCT_DURATION, openedAt);
  }, [sku]);

  if (errors.getProductDetail && !isLoading) return <UiNoDataMessage message='No pudimos hallar este producto' />;

  if ((!product.sku || !categories.length) && isLoading) return <UiLoader />;

  return (
    <>
      {isLoading && <UiLoader />}

      {showMobileDisableNotification && <ProductDisableNotificationMobile />}

      <div className={`${styles.container} ${!product.isActive && styles.disabled}`}>
        <div className={styles.infoWrapper}>
          <img src={product.imageUrl} alt='product' className={styles.image} />
          <div className={styles.list}>
            {getProductDetailList(product, categories).map(item => (
              <div className={styles.item} key={`product-detail-info-divst-${item.title}`}>
                {item.title}
                <span className={styles.value}>{item.value}</span>
              </div>
            ))}
          </div>
          <UiSwitch isChecked={product.isActive} onChange={changeState} />
          <span className={styles.state}>
            {product.isActive ? 'Activo' : 'Inactivo'}
          </span>
        </div>
        <ProductDetailMobileInputs />
      </div>
    </>
  );
}

export default ProductDetailMobile;

import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import useNewPassword from "@modules/auth/hooks/use-send-new-password";
import styles from "./password-recuperation-new-password.module.sass"; 
import UiButton from "@ui/ui-button-v2/ui-button";
import UiInputField from "@ui/ui-input-field-v2";
import { newPasswordSchema } from "@modules/auth/auth.schemas";
import { useFormik } from "formik";

interface PasswordRecuperationNewPasswordProps { }

const PasswordRecuperationNewPassword: FC<PasswordRecuperationNewPasswordProps> = () => {
  const [ firstIcon, setFirstIcon ] = useState<keyof typeof PhosphorIcons>('EyeClosed');
  const [ secondIcon, setSecondIcon ] = useState<keyof typeof PhosphorIcons>('EyeClosed');
  const [ firstInputType, setFirstInputType ] = useState<string>('password');
  const [ secondInputType, setSecondInputType ] = useState<string>('password');
  const { t } = useTranslation();
  const { sendUserNewPassword } = useNewPassword();

  const form = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: newPasswordSchema,
    onSubmit: (v) => handleSubmit(v.passwordConfirmation)
  });

  const handleFirstIconClick = useCallback(() => {
    if (firstIcon === 'EyeClosed') {
      setFirstInputType('text')
      setFirstIcon('Eye')
    } else {
      setFirstInputType('password')
      setFirstIcon('EyeClosed')
    }
  }, [firstIcon]);

  const handleSecondIconClick = useCallback(() => {
    if (secondIcon === 'EyeClosed') {
      setSecondInputType('text')
      setSecondIcon('Eye')
    } else {
      setSecondInputType('password')
      setSecondIcon('EyeClosed')
    }
  }, [secondIcon]);

  const handleDisableButton = useCallback(() => {
    if (form.values.password !== '' && form.values.passwordConfirmation !== '' && !form.errors.password && !form.errors.passwordConfirmation) {
      return false
    } else return true
  }, [form]);

  const handleSubmit = (newPassword: string) => {
    console.log('submit')
    sendUserNewPassword(newPassword);
  };

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      <div className={styles.messageWrapper}>
        <span className={styles.message}> {t('Crea y confirma tu nueva contraseña')} </span>
      </div>

      <div className={styles.input}>
        <UiInputField
          name="password"
          placeholder="Nueva contraseña"
          type={firstInputType}
          error={form.errors.password}
          hasError={!!form.touched.password && !!form.errors.password}
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          iconRight={firstIcon}
          onIconClick={handleFirstIconClick}
        />
      </div>

      <div className={styles.input}>
        <UiInputField
          name="passwordConfirmation"
          placeholder="Confirmar nueva contraseña"
          error={form.errors.passwordConfirmation}
          type={secondInputType}
          hasError={!!form.touched.passwordConfirmation && !!form.errors.passwordConfirmation}
          value={form.values.passwordConfirmation}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          iconRight={secondIcon}
          onIconClick={handleSecondIconClick}
        />
      </div>

      <UiButton 
        text="Confirmar"
        type="submit"
        isDisabled={handleDisableButton()}
      />
    </form>
  )
}

export default PasswordRecuperationNewPassword;
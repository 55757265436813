import React, { FC, memo, useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions, currentUserSelector, globalUiSelector } from "@config/store";
import { OWNER_ROLE_ID, STORAGE_TOKEN, PATHS } from "@constants";
import DashboardDesktop from "@modules/dashboard/pages/dashboard-desktop";
import UiNotification from "@ui/ui-notification";
import UiToast from "@ui/ui-toast";
import UiDesktopHeader from "@ui/ui-desktop-header-v2";
import Login from "@modules/auth/pages/login-v2";
import LoginPasswordRecuperation from "@modules/auth/pages/login-password-recuperation";
import Collaborators from "@modules/collaborators/collaborators";
import Customers from "@modules/customers";
import Closures from "@modules/closures";
import Reports from "@modules/reports";
import Inventory from "@modules/inventory";
import Providers from "@modules/providers";
import Profile from "@modules/profile";
import UiLoader from "@ui/ui-loader";
import UiAccessDeniedMessage from "@ui/ui-access-denied-message";
import useUserPermissions from "@utils/use-user-permissions";
import Clevertap from "@utils/clevertap";
import Orders from "@modules/orders";
import Bills from "@modules/bills";

const PrivateOutlet = () => {
  const navigate = useNavigate();
  const getPermissions = useUserPermissions();

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  useEffect(() => Clevertap.identifyUser(), []);

  if (!localStorage.getItem("t")) return <Navigate to="/login" />;
  return (
    <>
      <UiDesktopHeader navigate={navigate} />
      <Outlet />
    </>
  )
};

interface DesktopRouterProps { }

const DesktopRouter: FC<DesktopRouterProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const { hasCurrentUser } = useSelector(globalUiSelector);

  useEffect(() => {
    const from = new URLSearchParams(window.location.search).get("from");
    if (from === "pos") dispatch(actions.setIsFromPOS());
  }, [dispatch]);

  useEffect(() => {
    const userToken = localStorage.getItem(STORAGE_TOKEN);
    if (!!userToken) {
      dispatch(actions.saveCurrentUser(JSON.parse(userToken)));
      dispatch(actions.setHasCurrentUser(true));
    }
  }, [dispatch, navigate]);

  const isOwner = (): boolean => user.roleId === OWNER_ROLE_ID;

  return (
    <>
      <UiToast />
      <UiNotification />
      <Routes>
        {!localStorage.getItem(STORAGE_TOKEN) ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/:authToken" element={<Login />} />
            <Route path="/:moduleToRedirection/:authToken" element={<Login />} />
            <Route path="/login/password-recuperation" element={<LoginPasswordRecuperation />} />
            <Route path="*" element={<Navigate replace to="login" />} />
          </>
        ) : (
          <Route path="/" element={hasCurrentUser ? <PrivateOutlet /> : <UiLoader />}>
            <Route index element={<DashboardDesktop />} />
            <Route path="collaborators/*" element={isOwner() ? <Collaborators /> : <UiAccessDeniedMessage navigate={navigate} />} />
            <Route path="customers/*" element={<Customers />} />
            <Route path="inventory/*" element={<Inventory />} />
            <Route path="providers" element={<Providers />} />
            <Route path="closures" element={<Closures />} />
            <Route path="reports/*" element={<Reports />} />
            <Route path="profile/*" element={<Profile />} />
            <Route path="orders/*" element={<Orders />} />
            <Route path={`${PATHS.bills}/*`} element={<Bills />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        )}
      </Routes>
    </>
  );
}

export default memo(DesktopRouter);

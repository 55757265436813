import React, { FC } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import imageDefaultUser from '@img/default-profile.svg';
import { ProfileInput, StoreInformation, UserInformation } from '@modules/profile/entities';
import styles from './profile-form.module.sass';
import UiInputField from '@ui/ui-input-field';
import UiSelect from '@ui/ui-select';
import UiButton from '@ui/ui-button';

interface ProfileFormProps {
  values: UserInformation | StoreInformation;
  inputs: ProfileInput[];
  image: string;
  onSubmit: (values: any) => void;
  schema?: yup.AnySchema;
}

const ProfileForm: FC<ProfileFormProps> = ({ inputs, image, onSubmit, values, schema }) => {
  const form = useFormik({
    initialValues: values,
    onSubmit: onSubmit,
    validationSchema: schema,
    validateOnMount: true
  });

  return (
    <>
      <div className={styles.imageWrapper}>
        {/* <span className={`icon-add ${styles.icon}`} /> */}
        <img src={image || imageDefaultUser} alt="user" className={styles.image} />
      </div>

      <form className={styles.inputs} onSubmit={form.handleSubmit}>
        <div className={styles.inputsWrapper}>
          {inputs.map(input =>
            <div className={styles.input} key={`profile-input-${input.name}`}>
              {input.isSelect ? (
                <UiSelect
                  label={input.label}
                  name={input.name}
                  options={input.options || []}
                  value={form.values[input.name as keyof object]}
                  disabled={input.isDisabled}
                  defaultValue={input.label}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  error={form.errors[input.name as keyof object]}
                  hasError={!!form.errors[input.name as keyof object]}
                />
              ) : (
                <UiInputField
                  type={input.type}
                  label={input.label}
                  value={form.values[input.name as keyof object]}
                  disabled={input.isDisabled}
                  name={input.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  error={form.errors[input.name as keyof object]}
                  hasError={!!form.errors[input.name as keyof object]}
                />
              )}
            </div>
          )}
        </div>

        <div className={styles.button}>
          <UiButton
            text='Actualizar'
            type='submit'
            isFullWidth
            isDisabled={!form.isValid || !form.dirty}
            key={`${inputs[0].name}`}
          />
        </div>
      </form>
    </>
  );
}

export default ProfileForm;

import React, { FC, useEffect } from 'react';
import { PATH } from '@modules/customers/constants';
import { customersErrorsSelector, customerTransactionDetailSelector } from '@modules/customers/store';
import UiTopSection from '@ui/ui-top-section';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './customer-sale-detail.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiLoader from '@ui/ui-loader';
import CustomerTransactionDetailTable from '@modules/customers/components/customer-transaction-detail-table';
import CustomerTransactionDetailFooter from '@modules/customers/components/customer-transaction-detail-footer';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import Clevertap from '@utils/clevertap';
import { CUSTOMER_TRANSACTION_DETAIL } from '@constants';

interface CustomerSaleDetailProps { }

const CustomerSaleDetail: FC<CustomerSaleDetailProps> = () => {
  const { customerId, transactionId } = useParams();
  const [transaction] = useSelector(customerTransactionDetailSelector);
  const errors = useSelector(customersErrorsSelector);
  const { getTransactionDetail } = useCustomersFetch();

  useEffect(() => {
    Clevertap.pushSimpleEvent(CUSTOMER_TRANSACTION_DETAIL);
  }, []);

  useEffect(() => {
    if (transactionId) getTransactionDetail(transactionId);
  }, [getTransactionDetail, transactionId]);

  if (errors.getTransactionDetail) return <UiNoDataMessage message="No pudimos obtener el detalle de su factura" />;

  if (!transaction) return <UiLoader />;

  return (
    <div className={styles.container}>
      <UiTopSection
        title={`Factura ${transactionId}`}
        routes={[
          { name: 'Clientes', path: `/${PATH}` },
          { name: transaction.customerName, path: `/${PATH}/${customerId}` }
        ]}
        rightButton={transaction.date}
        hasShadow
      />
      <CustomerTransactionDetailTable />

      <CustomerTransactionDetailFooter />
    </div>
  )
}

export default CustomerSaleDetail;

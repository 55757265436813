import React, { FC } from 'react';
import { DISCOUNTS_TABLE_COLUMNS } from '@modules/inventory/constants';
import { productDiscountsSelector } from '@modules/inventory/store';
import { useSelector } from 'react-redux';
import styles from './product-discounts-table.module.sass';
import { formatMoney } from '@utils/format-money';
import useDiscountsFetch from '@modules/inventory/hooks/use-discounts-fetch';
import { ProductDiscountTableItem } from '@modules/inventory/entities';

interface ProductDiscountsTableProps { }

const ProductDiscountsTable: FC<ProductDiscountsTableProps> = () => {
  const discounts = useSelector(productDiscountsSelector);
  const { getDiscount } = useDiscountsFetch();

  const handleIcon = (discount: ProductDiscountTableItem) => {
    return discount.recurrency ? `check ${styles.checkIcon}` : `close ${styles.closeIcon}`
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Historial de descuentos</span>
      {discounts.length ? (
        <table className={styles.table} cellSpacing='0'>
          <thead>
            <tr>
              {DISCOUNTS_TABLE_COLUMNS.map((column) => (
                <th className={styles.head} key={`discounts-table-column-${column}`}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {discounts.map((discount) => (
              <tr
                className={styles.row}
                key={`discounts-table-item-${discount.id}`}
                onClick={() => getDiscount(discount.id)}
              >
                <td className={styles.cell}>
                  {discount.id}
                </td>
                <td className={styles.cell}>
                  {discount.initAt}
                </td>
                <td className={styles.cell}>
                  {formatMoney(Number(discount.customerTotal), 0)}
                </td>
                <td className={styles.cell}>
                  {formatMoney(Number(discount.customerTotal) - Number(discount.discountPrice), 0)}
                </td>
                <td className={styles.cell}>
                  {formatMoney(Number(discount.discountPrice), 0)}
                </td>
                <td className={styles.cell}>
                  <span
                    className={`icon-${handleIcon(discount)}`}
                  />
                </td>
                <td className={styles.cell}>
                  <div className={`${styles.state} ${!discount.isActive && styles.inactive}`}>
                    {discount.isActive ? 'Activo' : 'Inactivo'}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span className={styles.empty}>
          No hay descuentos asociados a este producto
        </span>
      )}
    </div >
  )
}

export default ProductDiscountsTable;

import React, { FC } from 'react';

import styles from './dashboard-sales-box-numbers.module.sass';

interface DashboardSalesBoxNumbersProps {
  formattedDate: string;
  label: string;
  formattedValue: string;
}
 
const DashboardSalesBoxNumbers: FC<DashboardSalesBoxNumbersProps> = ({
  formattedDate,
  label,
  formattedValue
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.texts}>
        <div className={styles.date}>{formattedDate}</div>
        <div className={styles.label}>{label}</div>
      </div>
      <div className={styles.value}>
        <div className={styles.number}>{formattedValue}</div>
      </div>
    </div>
  );
}
 
export default DashboardSalesBoxNumbers;
import { useDispatch, useSelector } from 'react-redux';
import { actions, ordersUiSelector } from '../store';
import { fetchOrders } from '../services/orders.services';
import Clevertap from '@utils/clevertap';
import { ORDERS_GET_DATA, ORDERS_SEARCH } from '@constants';

const UseGetOrders = () => {
  const dispatch = useDispatch();
  const { filters, page } = useSelector(ordersUiSelector);

  const getOrders = ( sort?: string ) => {
    const key = `${filters.query} provider: ${filters.providerId} isPaid: ${filters.isPaid}`

    dispatch(actions.setIsLoading(true));
    fetchOrders(filters.query, page, filters.providerId, filters.isPaid, sort)
      .then(r => {
        dispatch(actions.setOrdersData({ orders: r.getOrders, ordersQuantity: r.total, pages: r.totalPages }));
        dispatch(actions.setCurrentSearchedValue(filters.query));
        dispatch(actions.setIsLoading(false));
        if (filters.isPaid || filters.providerId || filters.query) Clevertap.pushEvent(ORDERS_SEARCH, { key })
        else Clevertap.pushCheckSuccessEvent(ORDERS_GET_DATA);
      })
      .catch((error) => {
        dispatch(actions.setIsLoading(false));
        if (filters.isPaid || filters.providerId || filters.query) Clevertap.pushEvent(ORDERS_SEARCH, { key }, error?.message)
        else Clevertap.pushCheckSuccessEvent(ORDERS_GET_DATA, error?.message);
      });
  };

  return {
    getOrders
  }
}

export default UseGetOrders;
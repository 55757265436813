import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './bill-devolution-modal.module.sass';
import UiButton from '@ui/ui-button-v2/ui-button';
import UiModal from '@ui/ui-modal';

interface BillDevolutionModalProps {
  onClose: () => void;
  onSubmit: (quantity: string) => void;
  maxQuantity: string;
  buttonText: string;
}

const BillDevolutionModal: FC<BillDevolutionModalProps> = ({ onClose, onSubmit, maxQuantity, buttonText }) => {
  const [quantity, setQuantity] = useState<string>('1');
  const [hasError, setHasError] = useState<boolean>(false);

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(+target.value)) setQuantity(target.value);
  }

  const handleModalClick = () => onSubmit(quantity);

  useEffect(() => {
    setHasError(!+quantity || +quantity > +maxQuantity)
  }, [maxQuantity, quantity]);

  return (
    <UiModal onCloseClick={onClose}>
      <form className={styles.modal} onSubmit={handleModalClick} data-testid='submit-modal'>
        <p className={styles.title}>Cantidad</p>
        <div className={`${styles.inputWrapper} ${hasError && styles.error}`}>
          <input
            type="tel"
            className={styles.input}
            autoFocus
            value={quantity}
            onChange={handleInputChange}
            data-testid='bill-input'
          />
        </div>
        <UiButton
          type='submit'
          text={buttonText}
          isDisabled={hasError}
        />
      </form>
    </UiModal>
  );
}

export default BillDevolutionModal;

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './products.module.sass';
import { actions, inventoryDataSelector, inventoryErrorsSelector, inventoryUiSelector } from '@modules/inventory/store';
import InventoryFilters from '@modules/inventory/components/inventory-filters';
import InventoryTable from '@modules/inventory/components/inventory-table';
import UiNoDataMessage from '@ui/ui-no-data-message';
import { Product } from '@modules/inventory/entities';
import UploadInventory from '@modules/inventory/components/upload-inventory';
import UploadPreviewTable from '@modules/inventory/components/upload-preview-table';
import RepeatedFile from '@modules/inventory/components/repeated-file';
import useInventoryFetch from '@modules/inventory/hooks/use-inventory-fetch';

interface ProductsProps { }

const Products: FC<ProductsProps> = () => {
  const inventory = useSelector(inventoryDataSelector);
  const errors = useSelector(inventoryErrorsSelector);
  const dispatch = useDispatch();
  const { isUploadingInventory, showPreviewTable, isRepeatedFile } = useSelector(inventoryUiSelector);
  const { getInventory } = useInventoryFetch();

  useEffect(() => {
    if (!inventory.length) getInventory();
    dispatch(actions.setProduct({} as Product))
  }, [dispatch, getInventory, inventory.length])

  return (
    <div className={styles.container}>

      {isUploadingInventory && <UploadInventory />}

      {showPreviewTable && <UploadPreviewTable />}

      {isRepeatedFile && <RepeatedFile />}

      <InventoryFilters />

      {errors.getInventory
        ? <UiNoDataMessage message='No pudimos cargar su lista de productos' />
        : <InventoryTable />
      }
    </div>
  );
}

export default Products;

import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COLUMNS_SALES_TABLE, TABS } from '@modules/customers/constants';
import { customerSalesSelector } from '@modules/customers/store';
import { formatMoney } from '@utils/format-money';
import styles from './customer-sales-table.module.sass';

interface CustomerSaleTableProps { }

const CustomerSaleTable: FC<CustomerSaleTableProps> = () => {
  const navigate = useNavigate();
  const sales = useSelector(customerSalesSelector);

  const handleRowClick = (orderId: string) => {
    navigate(`${TABS.transaction}/${orderId}`)
  };

  if (!sales.length) return <div className={styles.noSales}>Parece que aún no tiene ventas</div>;

  return (
    <div className={styles.table}>
      
      <div className={styles.header}>
        {COLUMNS_SALES_TABLE.map((column, i) => (
          <div className={styles.cell} key={`customers-column-table-${i}`}>
            {column.title}
            {column.icon && <span className={`icon-${column.icon} ${styles.icon}`} />}
          </div>
        ))}
      </div>

      {sales.map((sale, i) => (
        <div key={`sales-table-item-${i}`} className={styles.body} onClick={() => handleRowClick(sale.orderId)}>
          <div className={styles.cell}>{sale.orderId}</div>
          <div className={styles.cell}>{sale.date}</div>
          <div className={styles.cell}> {sale.countSku}</div>
          <div className={styles.cell}>{formatMoney(sale.total, 0)}</div>
          <div className={styles.cell}>{sale.transactionType}</div>
        </div>
      ))}
    </div>
  );
}

export default memo(CustomerSaleTable);

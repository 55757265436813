import React, { ChangeEvent, ChangeEventHandler, FC, memo, useRef, useState } from 'react';
import useDebounce from '@utils/use-debounce';
import styles from './ui-search-bar.module.sass';
import { MIN_WIDTH_DESKTOP } from '@constants';

interface UiSearchBarProps {
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string;
  hasError?: boolean;
}

const UiSearchBar: FC<UiSearchBarProps> = ({
  placeholder,
  onChange,
  defaultValue = '',
  hasError
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [showArrow, setShowArrow] = useState(false);
  const isMobile = useRef(window.innerWidth < MIN_WIDTH_DESKTOP).current
  const ref = useRef<HTMLInputElement>(null);

  const onClose = () => {
    if (!isMobile)
      handleInputChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
    else ref.current?.focus();
    setInputValue('');
    if (ref.current) ref.current.value = '';
  };

  const onArrowClick = () => {
    setShowArrow(false);
    setInputValue('');
    onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
    if (ref.current) ref.current.value = '';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowArrow(!!e.target.value);
    handleInputChange(e)
  };

  const { handleInputChange } = useDebounce(onChange, defaultValue);

  return (
    <div className={styles.wrapper}>
      {(showArrow && isMobile) && <span className={`icon-arrow-2 ${styles.arrow}`} onClick={onArrowClick} data-testid='icon-arrow' />}
      <div className={`${styles.inputWrapper} ${(hasError && !!inputValue) && styles.error}`}>
        <span className={`icon-search-2 ${styles.icon}`} />
        <input
          data-testid='ui-search-bar'
          type='text'
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          className={styles.input}
          ref={ref}
        />
        {(!!inputValue) && (
          <span
            className={`icon-close-2 ${styles.iconClose}`}
            onClick={onClose}
            data-testid='icon-close'
          />
        )}
      </div>
    </div>
  )
}

export default memo(UiSearchBar);
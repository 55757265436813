import React, { FC, memo } from 'react';
import noData from '@img/no-data.png';
import styles from './ui-no-data-message.module.sass';

interface UiNoDataMessageProps {
  title?: string;
  message?: string;
}

const UiNoDataMessage: FC<UiNoDataMessageProps> = ({ title, message }) => {
  return (
    <div className={styles.wrapper} data-testid='ui-no-data-message'>
      <img src={noData} alt="Stop" className={styles.image} />
      <p className={styles.title}>{title}</p>
      <p className={styles.message}>{message}</p>
    </div>
  );
}

export default memo(UiNoDataMessage);

import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { newConsecutiveSchema } from '@modules/profile/schemas';
import { actions, businessDataSelector } from '@modules/profile/store';
import { getNewConsecutiveForm } from '@modules/profile/utils';
import { DATE_FORMAT } from '@modules/profile/constants';
import useInvoicingInformationFetch from '@modules/profile/hooks/use-invoicing-information';
import UiModal from '@ui/ui-modal';
import UiInputField from '@ui/ui-input-field';
import UiDatePicker from '@ui/ui-date-picker';
import UiButton from '@ui/ui-button';
import styles from './new-consecutive-modal.module.sass';

interface NewConsecutiveModalProps { }

const NewConsecutiveModal: FC<NewConsecutiveModalProps> = () => {
  const dispatch = useDispatch();
  const businessData = useSelector(businessDataSelector);
  const { createNewConsecutive } = useInvoicingInformationFetch();

  const handleCloseModal = useCallback((): void => {
    dispatch(actions.setShowNewConsecutiveModal(false))
  }, [dispatch]);

  const form = useFormik({
    initialValues: getNewConsecutiveForm(businessData.numberStore, businessData.storeName),
    onSubmit: createNewConsecutive,
    validationSchema: newConsecutiveSchema,
  });

  const handleChangeExpeditionDate = useCallback((date: Date): void => {
    form.setFieldValue('dianAuthDateExp', date)
  }, [form]);

  const handleChangeValidityDate = useCallback((date: Date): void => {
    form.setFieldValue('dianAuthDate', date)
  }, [form]);

  return (
    <UiModal onCloseClick={handleCloseModal}>
      <form className={styles.wrapper} onSubmit={form.handleSubmit}>
        <h1 className={styles.title}>Nuevo consecutivo</h1>

        <div className={styles.inputs}>
          <UiInputField
            name='dianAuthNumber'
            label='Número de resolución de la DIAN'
            type='text'
            value={form.values.dianAuthNumber}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.errors.dianAuthNumber}
            hasError={!!(form.errors.dianAuthNumber && form.touched.dianAuthNumber)}
          />
          <UiInputField
            name='prefix'
            label='Prefijo'
            type='text'
            value={form.values.prefix}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.errors.prefix}
            hasError={!!(form.errors.prefix && form.touched.prefix)}
          />
          <UiDatePicker
            label='Fecha de expedición'
            onChange={handleChangeExpeditionDate}
            hasError={!!(form.errors.dianAuthDateExp && form.touched.dianAuthDateExp)}
            value={form.values.dianAuthDateExp}
            formatToShow={DATE_FORMAT}
          />
          <UiDatePicker
            label='Fecha de vigencia'
            onChange={handleChangeValidityDate}
            hasError={!!(form.errors.dianAuthDate && (form.values.dianAuthDate || form.touched.dianAuthDate))}
            value={form.values.dianAuthDate}
            formatToShow={DATE_FORMAT}
          />
          <div className={styles.range}>
            <UiInputField
              name='initConsecutive'
              label='Rango desde'
              type='text'
              value={form.values.initConsecutive}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.errors.initConsecutive}
              hasError={!!(form.errors.initConsecutive && form.touched.initConsecutive)}
            />
            <UiInputField
              name='endConsecutive'
              label='Rango hasta'
              type='text'
              value={form.values.endConsecutive}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.errors.endConsecutive}
              hasError={!!(form.errors.endConsecutive && form.touched.endConsecutive)}
            />
          </div>
        </div>
        <UiButton text='Agregar' type='submit' isFullWidth isDisabled={!form.isValid} />
      </form>
    </UiModal>
  );
}

export default NewConsecutiveModal;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { BillsUiState } from ".";

export const billsUiSelector = createSelector(
  (state: RootState) => state.bills.ui,
  (state: BillsUiState) => state
);

export const billsUiLoaderSelector = createSelector(
  (state: RootState) => state.bills.ui.isLoading,
  (state: boolean) => state
);

export const billsUiRequestSelector = createSelector(
  (state: RootState) => state.bills.ui.mustRequestBills,
  (state: boolean) => state
);

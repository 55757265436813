import * as yup from 'yup';
import { t } from 'i18next'
import {
  COLLABORATOR_NAME_LENGTH, IDENTIFICATION_LENGTH, PASSWORD_LENGTH, PHONE_LENGTH,
  REG_EXP_ONLY_LETTERS, REG_EXP_ONLY_LETTERS_AND_NUMBERS, REG_EXP_ONLY_NUMBERS
} from '@constants';

export const collaboratorSchema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters'))
    .max(COLLABORATOR_NAME_LENGTH, (max) => t('errors.max_characters', { digits: max.max })),
  phone: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  documentTypeId: yup.string()
    .required(t('errors.required')),
  roleId: yup.string()
    .required(t('errors.required')),
  document: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_LETTERS_AND_NUMBERS, t('errors.only_letters_and_numbers'))
    .max(IDENTIFICATION_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
});

export const createPasswordSchema = yup.object().shape({
  newPassword: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PASSWORD_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PASSWORD_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  confirmPassword: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PASSWORD_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PASSWORD_LENGTH, (max) => t('errors.max_digits', { digits: max.max }))
    .oneOf([yup.ref('newPassword'), null], t('errors.passwords_no_match')),
});

import { FC } from 'react';
import styles from './bill-edit.module.sass';
import BillEditPreview from '@modules/bills/components/bill-edit-preview';
import BillEditForm from '@modules/bills/components/bill-edit-form';
import useGetInfoBill from '@modules/bills/hooks/use-get-info-bill';
import { useSelector } from 'react-redux';
import { billsUiLoaderSelector } from '@modules/bills/store';
import UiLoader from '@ui/ui-loader';

interface BillEditProps { }

const BillEdit: FC<BillEditProps> = () => {
  const isLoading = useSelector(billsUiLoaderSelector);
  
  useGetInfoBill();

  if (isLoading) return <UiLoader />

  return (
    <div className={styles.container}>
      <BillEditPreview />

      <BillEditForm />
    </div>
  )
}

export default BillEdit;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { CollaboratorsUiState, CollaboratorsDataState, CollaboratorsPaginationState, CollaboratorsErrosState } from '.';

export const collaboratorsUiSelector = createSelector(
  (state: RootState) => state.collaborators.ui,
  (state: CollaboratorsUiState) => state
);

export const collaboratorsDataSelector = createSelector(
  (state: RootState) => state.collaborators.data,
  (state: CollaboratorsDataState) => state.collaborators
);

export const collaboratorsPaginationSelector = createSelector(
  (state: RootState) => state.collaborators.pagination,
  (state: CollaboratorsPaginationState) => state
);

export const collaboratorsErrorsSelector = createSelector(
  (state: RootState) => state.collaborators.errors,
  (state: CollaboratorsErrosState) => state
);
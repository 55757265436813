import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { closuresUiLoadingSelector, closuresUiEmptySelector } from '@modules/closures/store';
import styles from './closures-mobile-home-detail-content.module.sass';
import ClosuresMobileHomeTop from '@modules/closures/components/closures-mobile-home-top';
import ClosuresMobileHomeDetail from '../closures-mobile-home-detail';
import ClosuresMobileHomeContent from '../closures-mobile-home-content';
import ClosuresMobileHomeResponsable from '../closures-mobile-home-responsable';
import useLastClosure from '@modules/closures/hooks/use-last-closure';

interface ClosuresMobileHomeDetailContentProps { }

const ClosuresMobileHomeDetailContent: FC<ClosuresMobileHomeDetailContentProps> = () => {
  const isLoadingClosure = useSelector(closuresUiLoadingSelector);
  const isEmptyClosures = useSelector(closuresUiEmptySelector);
  useLastClosure();

  if (isEmptyClosures) return (
    <div className={styles.container}>
      <div className={styles.empty} data-testid="closures-empty-message">
        Su historial de cierres de caja se encuentra vacío. Cierre su caja actual y podrá consultar aquí todos los detalles
      </div>
    </div>
  );

  if (isLoadingClosure) return (
    <div>...Loading</div>
  );

  return (
    <div className={styles.container}>

      <ClosuresMobileHomeTop />

      <ClosuresMobileHomeDetail />

      <ClosuresMobileHomeContent />

      <ClosuresMobileHomeResponsable />

    </div>
  )
}

export default ClosuresMobileHomeDetailContent;
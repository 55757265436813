export const ACCESS_ADMIN = 'Access_Admin';

export const REPORTS_OPEN = 'Reports_Open';
export const REPORTS_DURATION = 'Reports_Duration';
export const REPORTS_CHANGE_DATES = 'Reports_Change_Dates';
export const REPORTS_TRANSACTION_SECTION = 'Reports_Transaction_Section';
export const REPORTS_TRANSACTION_DURATION = 'Reports_Transaction_Duration';
export const REPORTS_GET_TRANSACTION_DATA = 'Reports_Get_Transaction_Data';
export const REPORTS_SALES_SECTION = 'Reports_Sales_Section';
export const REPORTS_SALES_DURATION = 'Reports_Sales_Duration';
export const REPORTS_GET_SALES_DATA = 'Reports_Get_Sales_Data';
export const REPORTS_SKUS_SECTION = 'Reports_Skus_Section';
export const REPORTS_SKUS_DURATION = 'Reports_Skus_Duration';
export const REPORTS_GET_SKUS_DATA = 'Reports_Get_Skus_Data';
export const REPORTS_LOW_TURNOVER_SECTION = 'Reports_Low_Turnover_Section';
export const REPORTS_LOW_TURNOVER_DURATION = 'Reports_Low_Turnover_Duration';
export const REPORTS_GET_LOW_TURNOVER_DATA = 'Reports_Get_Low_Turnover_Data';
export const REPORTS_DOWNLOAD_INVOICE = 'Reports_Download_Invoice';
export const REPORTS_DOWNLOAD_RESUME = 'Reports_Download_Resume';

export const CLOSURE_DETAIL = 'Closure_Detail';

export const ORDERS_OPEN = 'Orders_Open';
export const ORDERS_GET_DATA = 'Orders_Get_Data';
export const ORDERS_DURATION = 'Orders_Duration';
export const ORDERS_SEARCH = 'Orders_Search';
export const ORDER_DETAIL = 'Order_Detail';
export const ORDER_PAY = 'Order_Pay';

export const PROVIDERS_OPEN = 'Providers_Open';
export const PROVIDERS_GET_DATA = 'Providers_Get_Data';
export const PROVIDERS_DURATION = 'Providers_Duration';
export const PROVIDERS_SEARCH = 'Providers_Search';
export const PROVIDERS_EDITION = 'Providers_Edition';
export const PROVIDERS_CREATION = 'Providers_Creation';
export const PROVIDERS_DOWNLOAD = 'Providers_Download';

export const INVENTORY_OPEN = 'Inventory_Open';
export const INVENTORY_GET_DATA = 'Inventory_Get_Data';
export const INVENTORY_DURATION = 'Inventory_Duration';
export const INVENTORY_SEARCH = 'Inventory_Search';
export const INVENTORY_DOWNLOAD = 'Inventory_Download';
export const INVENTORY_UPLOAD = 'Inventory_Upload';
export const INVENTORY_DETAIL = 'Inventory_Detail';

export const PRODUCT_DETAIL_OPEN = 'Product_Detail_Open';
export const PRODUCT_GET_DATA = 'Product_Get_Data';
export const PRODUCT_DURATION = 'Product_Duration';
export const PRODUCT_EDITION = 'Product_Edition';
export const PRODUCT_DISABLE = 'Product_Disable';
export const PRODUCT_ENABLE = 'Product_Enable';
export const PRODUCT_UPDATE = 'Product_Update';

export const DISCOUNTS_OPEN = 'Discounts_Open';
export const DISCOUNTS_GET_DATA = 'Discounts_Get_Data';
export const DISCOUNTS_DURATION = 'Discounts_Duration';
export const DISCOUNT_EDITION = 'Discount_Edition';
export const DISCOUNT_CREATION = 'Discount_Creation';

export const COMBOS_OPEN = 'Combos_Open';
export const COMBOS_GET_DATA = 'Combos_Get_Data';
export const COMBOS_DURATION = 'Combos_Duration';
export const COMBOS_SEARCH = 'Combos_Search';
export const COMBOS_DOWNLOAD = 'Combos_Download';
export const COMBO_CREATION_OPEN = 'Combo_Creation_Open';
export const COMBO_CREATION = 'Combo_Creation';
export const COMBO_CREATION_DURATION = 'Combo_Creation_Duration';
export const COMBO_DETAIL_GET_DATA = 'Combo_Detail_Get_Data';
export const COMBO_EDITION_OPEN = 'Combo_Edition_Open';
export const COMBO_EDITION = 'Combo_Edition';
export const COMBO_DISABLE = 'Combo_Disable';
export const COMBO_EDITION_DURATION = 'Combo_Edition_Duration';

export const CUSTOMERS_OPEN = 'Customers_Open';
export const CUSTOMERS_GET_DATA = 'Customers_Get_Data';
export const CUSTOMERS_DURATION = 'Customers_Duration';
export const CUSTOMERS_SEARCH = 'Customers_Search';
export const CUSTOMERS_DOWNLOAD = 'Customers_Download';
export const CUSTOMER_DETAIL_OPEN = 'Customer_Detail_Open';
export const CUSTOMER_DETAIL_GET_DATA = 'Customer_Detail_Get_Data';
export const CUSTOMER_DETAIL_DURATION = 'Customer_Detail_Duration';
export const CUSTOMER_CREATION = 'Customer_Creation';
export const CUSTOMER_EDITION = 'Customer_Edition';
export const CUSTOMER_SEND_MESSAGE = 'Customer_Send_Message';
export const CUSTOMER_TRANSACTION_DETAIL = 'Customer_Transaction_Detail';
export const CUSTOMER_TRANSACTION_DETAIL_GET_DATA = 'Customer_Transaction_Detail_Get_Data';
export const CUSTOMER_PAYMENT_TRANSACTION = 'Customer_Payment_Transaction';
export const CUSTOMER_LOAN_TRANSACTION = 'Customer_Loan_Transaction';

export const COLLABORATORS_OPEN = 'Collaborators_Open';
export const COLLABORATORS_GET_DATA = 'Collaborators_Get_Data';
export const COLLABORATORS_DURATION = 'Collaborators_Duration';
export const COLLABORATOR_CHANGE_PERMISSION = 'Collaborator_Change_Permission';
export const COLLABORATOR_CREATION = 'Collaborator_Creation';
export const COLLABORATOR_EDITION = 'Collaborator_Edition';
export const COLLABORATOR_DELETED = 'Colalborator_Deleted';

export const PROFILE_OPEN = 'Profile_Open';
export const PROFILE_DURATION = 'Profile_Duration';
export const PROFILE_GET_DATA = 'Profile_Get_Data';
export const PROFILE_UPDATE_PASSWORD = 'Profile_Update_Password';
export const PROFILE_UPDATE_USER_INFORMATION = 'Profile_Update_User_Information';
export const PROFILE_UPDATE_STORE_INFORMATION = 'Profile_Update_Store_Information';
export const PROFILE_UPDATE_INVOICING_INFORMATION = 'Profile_Update_Invoicing_Information';
export const PROFILE_CONSECUTIVE_CREATION = 'Profile_Consecutive_Creation';

import React, { FC, memo } from 'react';
import { Bill } from '@modules/bills/entities';
import styles from './bills-card.module.sass';
import { formatMoney } from '@utils/format-money';
import { useDispatch, useSelector } from 'react-redux';
import { actions, billsDetailSelector } from '@modules/bills/store';
import { formatTime, isLoan } from '@modules/bills/utils';
import Clevertap, { BILLS_DETAIL } from '@config/clevertap';

interface BillsCardProps {
  bill: Bill;
}

const BillsCard: FC<BillsCardProps> = ({ bill }) => {
  const dispatch = useDispatch();
  const id = useSelector(billsDetailSelector);

  const handleClick = () => {
    dispatch(actions.setDetailId(bill.id));
    Clevertap.pushEvent(BILLS_DETAIL, { type: isLoan(bill.type) ? 'Fiado' : 'Compra', value: bill.total });
  }

  return (
    <div className={`${styles.wrapper} ${id === bill.id && styles.active}`} onClick={handleClick} data-testid="card">
      <div className={styles.left}>
        <p className={styles.title}>{isLoan(bill.type) ? 'Fiado' : 'Venta'}</p>
        <p className={styles.description}>{isLoan(bill.type) ? bill.name : bill.paymentMethod}</p>
      </div>

      <div className={styles.right}>
        <p className={styles.status}>
          <span className={`${styles.point} ${!!bill.deletedAt && styles.deleted}`} />
          {bill.deletedAt ? 'Devolución' : 'Finallizada'}
        </p>
        <p className={styles.total}>{formatMoney(+bill.total)}</p>
        <p className={styles.date}>
          {formatTime(bill.date, bill.transactionHour)}
        </p>
      </div>

      <span className={`icon-arrow-2 ${styles.arrow}`} />
    </div>
  );
}

export default memo(BillsCard);

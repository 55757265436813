import { FC } from 'react';
import { formatMoney } from '@utils/format-money';

import styles from './bill-edit-preview.module.sass';
import { mockProduct } from '@mocks/responses/bills';
import BillEditPreviewHeader from '../bill-edit-preview-header';
import BillEditPreviewMessage from '../bill-edit-preview-message';

interface BillEditPreviewProps { }

const BillEditPreview: FC<BillEditPreviewProps> = () => {
  const getDummyProducts = () => {
    const content = [];
    for (let i = 0; i < 6; i++) {
      content.push(
        <div className={styles.card} key={i}>
          <div className={styles.left}>
            <span>{mockProduct.id}</span>
            <span>{mockProduct.name}</span>
          </div>
          <span className={styles.right}>
            {formatMoney(mockProduct.price)}
          </span>
        </div>
      );
    }
    return content;
  }

  return (
    <div className={styles.wrapper}>
      <BillEditPreviewHeader />
      <div className={styles.body}>
        <span className={styles.products}>Productos</span>
        { getDummyProducts() }
        <div className={styles.total}>
          <span className={styles.subTitle}>Total</span>
          <span className={styles.subTitle}>$100.000</span>
        </div>
        <div className={styles.resume}>
          <span className={styles.subTitle}>Pago</span>
          <div className={styles.info}>
            <span>Efectivo</span> <span>$110.000</span>
          </div>
          <div className={styles.info}>
            <span>Vueltas</span> <span>$10.000</span>
          </div>
        </div>
      </div>
      <BillEditPreviewMessage />
      <div className={styles.tail} />
    </div>
  )
}

export default BillEditPreview;

import React, { FC, useCallback, useEffect } from 'react';
import { format, setHours } from 'date-fns';
import { es } from 'date-fns/locale';
import { ChartData } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';

import { DAYS, DAY_HOURS } from '../../constants';
import { fetchSalesByDates, fetchSalesByDays, fetchSalesByHour } from '../../services/reports.services';
import { SalesByDate } from '@modules/reports/entities';
import { useIsMounted } from '@utils/use-is-mounted';
import { actions, reportsDatesFilterSelector, reportsSalesSelector, reportsUiSelector } from '@modules/reports/store';
import Clevertap from '@utils/clevertap';
import { REPORTS_GET_SALES_DATA, REPORTS_SALES_DURATION, REPORTS_SALES_SECTION } from '@constants';
import styles from './report-sales.module.sass';
import ReportsChart from '../../components/reports-chart';
import ReportsPageNoData from '../../components/reports-page-no-data';
import UiLoader from '@ui/ui-loader';

interface ReportSalesProps { }

const ReportSales: FC<ReportSalesProps> = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { isLoadingSales } = useSelector(reportsUiSelector);
  const { startDate, finishDate } = useSelector(reportsDatesFilterSelector);
  const { salesByDate, salesByDay, salesByHour } = useSelector(reportsSalesSelector);

  const salesByDateData = (): ChartData<'line'> => {
    const sales = salesByDate.reduce<{ [key: string]: SalesByDate[] }>((salesInfo, sale) => {
      const date = format(new Date(sale.date), 'MM-dd');
      if (!salesInfo[date]) salesInfo[date] = [];
      salesInfo[date].push(sale);
      return salesInfo;
    }, {});
    const array = Object.keys(sales).map((date) => ({
      date,
      transactions: sales[date]
    }));
    return {
      labels: array.map((item) => item.date),
      datasets: [
        {
          label: 'Por fecha',
          data: array.map((item) => item.transactions.reduce<number>((prev, current) => prev + +current.total, 0)),
        },
      ],
    };
  }

  const salesByDayData = (): ChartData<'line'> => {
    const daysChartObjects = DAYS.map((item) => ({ day: item, total: 0 }));
    salesByDay.forEach((item) => {
      const date = new Date(item.date.split('T')[0].replace('-', '/'));
      const weekDayName = format(date, 'EEE', { locale: es });
      const objectToUpdate = daysChartObjects.find((i) => i.day === weekDayName);
      const value = parseFloat(item.total);
      if (objectToUpdate) objectToUpdate.total = objectToUpdate.total + value;
    });
    return {
      labels: DAYS,
      datasets: [
        {
          label: 'Por día',
          data: daysChartObjects.map((item) => item.total),
          
        },
      ],
    };
  };

  const salesByHourData = (): ChartData<'line'> => {
    const hoursChartObjects = DAY_HOURS.map((item) => ({ hour: item, total: 0 }));
    salesByHour.forEach((item) => {
      const date = new Date(item.date);
      const dateHour = format(setHours(date, date.getUTCHours()), 'h aa');
      const objectToUpdate = hoursChartObjects.find((i) => i.hour === dateHour);
      const value = parseFloat(item.total);
      if (objectToUpdate) objectToUpdate.total = objectToUpdate.total + value;
    });
    return {
      labels: DAY_HOURS,
      datasets: [
        {
          label: 'Por hora', 
          data: hoursChartObjects.map((item) => item.total),
        },
      ],
    };
  };

  const hasSalesDataToShow = (): boolean =>
    !!salesByDate.length || !!salesByDay.length || !!salesByHour.length;

  const getSalesData = useCallback((dates: { initDate: string, endDate: string }) => {
    const args = { startDate: dates.initDate, finishDate: dates.endDate };
    dispatch(actions.setIsLoadingSalesData(true));
    Promise.all([
      fetchSalesByDates(args),
      fetchSalesByDays(args),
      fetchSalesByHour(args),
    ]).then(([responseByDate, responseByDays, responseByHour]) => {
      if (isMounted.current) {
        dispatch(actions.setSalesData({
          salesByDate: responseByDate,
          salesByDay: responseByDays,
          salesByHour: responseByHour
        }));
        dispatch(actions.setIsLoadingSalesData(false));
      }
      Clevertap.pushCheckSuccessEvent(REPORTS_GET_SALES_DATA);
    }).catch((error) => {
      if (isMounted.current) {
        dispatch(actions.setSalesData({
          salesByDate: [],
          salesByDay: [],
          salesByHour: []
        }));
        dispatch(actions.setIsLoadingSalesData(false));
      }
      Clevertap.pushCheckSuccessEvent(REPORTS_GET_SALES_DATA, error?.message);
    });
  }, [isMounted, dispatch]);

  useEffect(() => {
    if (startDate && finishDate) {
      getSalesData({ initDate: startDate, endDate: finishDate });
    }
  }, [startDate, finishDate, getSalesData]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(REPORTS_SALES_SECTION);
    return () => Clevertap.pushDurationEvent(REPORTS_SALES_DURATION, openedAt);
  }, []);

  const handleLoading = () => {
    if (isLoadingSales) {
      return 'Cargando'
    }
    return 'No hay registro de ventas durante este periodo.'
  }

  return (
    <div className={styles.wrapper}>
      {isLoadingSales && <UiLoader />}
      {!hasSalesDataToShow() ? (
        <ReportsPageNoData>
          {handleLoading()}
        </ReportsPageNoData>
      ) : (
        <div className={styles.charts}>
          <div className={styles.chart}>
            <ReportsChart data={salesByDateData()} />
          </div>
          <div className={`${styles.chart} ${styles.middle}`}>
            <ReportsChart data={salesByDayData()} />
          </div>
          <div className={`${styles.chart} ${styles.middle}`}>
            <ReportsChart data={salesByHourData()} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportSales;

import React, { FC, memo } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import styles from './reports-chart.module.sass';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ReportsChartProps {
  data: ChartData<'line'>;
}

const ReportsChart: FC<ReportsChartProps> = ({ data }) => {

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: '#1B73E8',
    datasets: {
      line: {
        pointRadius: 8,
      }
    },
    plugins: {
      tooltip: {
        usePointStyle: true,
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        titleColor: '#000000'
      },
      legend: {
        position: 'top' as const,
      }
    },
  };

  return (
    <div className={styles.wrapper}>
      <Line options={options} data={data} />
    </div>
  );
};
 
export default memo(ReportsChart);

export const EDIT_PURCHASE_PRICE_PERMISSION_ID = '1';
export const EDIT_SALE_PRICE_PERMISSION_ID = '2';
export const EDIT_INVENTORY_PERMISSION_ID = '3';
export const VIEW_CASH_DECK_CONTROL_PERMISSION_ID = '4';
export const ACCESS_POS_WEB_PERMISSION_ID = '5';
export const RECEIVE_BOX_PERMISSION_ID = '6';

export const ADMIN_ROLE_ID = '1';
export const OWNER_ROLE_ID = '3';
export const CASHIER_ROLE_ID = '2';

import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Icon, Theme } from "@chiper-inc/sb-web-chiper";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { closuresDetailSelector, closuresHistorySelector } from "@modules/closures/store";
import { formatMoney } from "@utils/format-money";
import styles from "./closures-home-history-list.module.sass";
import useGetClosure from "@modules/closures/hooks/use-get-closure";

interface ClosuresHomeHistoryListProps { }

const ClosuresHomeHistoryList: FC<ClosuresHomeHistoryListProps> = () => {
  const history = useSelector(closuresHistorySelector);
  const closure = useSelector(closuresDetailSelector);
  const { getClosure } = useGetClosure();

  return (
    <div className={styles.wrapper}>
      {history.map(e => (
        <div
          className={`${styles.closure} ${closure.id === e.balanceId ? styles.active : ""}`}
          key={`history.closure-${e.balanceId}`}
          onClick={() => getClosure(+e.balanceId)}
          data-testid="closures-home-history-item"
        >
          {format(new Date(e.closingTime), "MMM dd, yyyy - HH:MM aaaa", { locale: es })}

          <div className={styles.right}>
            {formatMoney(+e.expectedTotal)}
            <Icon name="CaretRight" size={16} color={Theme.colors.primary.yankeesBlue} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ClosuresHomeHistoryList;

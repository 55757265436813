import { format, subDays } from 'date-fns';
import { ReportsTabs } from '../constants';
import { DatesFilter, ResumeData, SalesData } from '../entities';
import { SkusData } from '../entities/skus.entities';
import { SlowTurnoverData } from '../entities/slow-turnover.entities';
import { TransactionsData } from '../entities/transaction.entities';

export interface ReportsState {
  ui: ReportsUiState;
  data: ReportsDataState;
}

export interface ReportsUiState {
  isLoadingReports: boolean;
  isLoadingSales: boolean;
  isLoadingTransactions: boolean;
  isLoadingSkus: boolean;
  isLoadingSlowTurnover: boolean;
  tab: ReportsTabs;
}

export interface ReportsDataState {
  resumeData: ResumeData;
  dates: DatesFilter;
  salesData: SalesData;
  transactionsData: TransactionsData;
  skusData: SkusData;
  slowTurnoverData: SlowTurnoverData;
}

export const initialState: ReportsState = {
  ui: {
    isLoadingReports: false,
    isLoadingSales: false,
    isLoadingTransactions: false,
    isLoadingSkus: false,
    isLoadingSlowTurnover: false,
    tab: ReportsTabs.Sales,
  },
  data: {
    resumeData: {} as ResumeData,
    dates: {
      startDate: format(subDays(new Date(), 7), 'MM-dd-yyyy'),
      finishDate: format(new Date(), 'MM-dd-yyyy'),
    } as DatesFilter,
    salesData: {
      salesByDate: [],
      salesByDay: [],
      salesByHour: [],
    } as SalesData,
    transactionsData: {
      transactionsByDate: [],
    } as TransactionsData,
    skusData: {
      skus: [],
    } as SkusData,
    slowTurnoverData: {
      products: [],
    } as SlowTurnoverData,
  },
}

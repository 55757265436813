import { RecurrencyDay, UiSelectOption } from "@entities";
import { InventoryTabs, Tab } from "../entities";
import { PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";

export const LIMIT_GET_INVENTORY = 20;

export const PATH = 'inventory';

export const TABS: InventoryTabs = {
  products: '',
  providers: 'providers',
  combos: 'combos',
  productDetail: 'product',
  productDiscounts: 'discounts'
};

export const INVENTORY_TABS_OPTIONS: Tab[] = [
  { title: 'Inventario', path: TABS.products },
  { title: 'Combos', path: TABS.combos }
];

export const PRODUCT_DETAIL_TABS_OPTIONS: Tab[] = [
  { title: 'Producto', path: '' },
  { title: 'Descuentos', path: TABS.productDiscounts }
];

export const INVENTORY_TABLE_COLUMNS: { title: string, icon: keyof typeof PhosphorIcons }[] = [
  {title: 'EAN', icon: 'ArrowsDownUp'},
  {title: 'Marca', icon: 'ArrowsDownUp'},
  {title: 'Descripción', icon: 'ArrowsDownUp'},
  {title: '$ Compra', icon: 'ArrowsDownUp'},
  {title: 'Venta', icon: 'ArrowsDownUp'},
  {title: 'Margen', icon: 'ArrowsDownUp'},
  {title: 'Costo total', icon: 'ArrowsDownUp'},
  {title: 'Tipo de unidad', icon: 'ArrowsDownUp'},
  {title: 'Cantidad', icon: 'ArrowsDownUp'},
]

export const PACKAGING_TYPE_OPTIONS: UiSelectOption[] = [
  { title: 'Kilogramos', value: 'kilos' },
  { title: 'Gramos', value: 'Gramos' },
  { title: 'Libras', value: 'Libras' },
  { title: 'Unidad', value: 'unidad' }
];

export const ICO_OPTIONS: UiSelectOption[] = [
  { title: '0%', value: 0 },
  { title: '4%', value: 4 },
  { title: '8%', value: 8 },
  { title: '16%', value: 16 }
];

export const IVA_OPTIONS: UiSelectOption[] = [
  { title: '0%', value: 0 },
  { title: '5%', value: 5 },
  { title: '19%', value: 19 },
];

export const RECURRENCY_DAYS: RecurrencyDay[] = [
  { id: '8', title: 'D', },
  { id: '2', title: 'L', },
  { id: '3', title: 'M', },
  { id: '4', title: 'M', },
  { id: '5', title: 'J', },
  { id: '6', title: 'V', },
  { id: '7', title: 'S', },
];

export const DISCOUNTS_TABLE_COLUMNS = [
  'ID',
  'Fecha',
  'Precio regular',
  'Margen $',
  'Precio con dto.',
  'Recurrente',
  'Estado',
];

export const PREVIEW_UPLOAD_TABLE_COLUMNS = [
  'EAN',
  'Descripción',
  'Marca',
  'Tipo de unidad',
  '$ Compra',
  '$ Venta',
  'Cantidad',
]

export const MIME_TYPE_FILE = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel'
}

export const MESSAGE_ERROR = {
  emptyFile: 'Lo sentimos. El archivo se encuentra vacío.',
  invalidFile: 'Lo sentimos. El formato del documento no es valido para este proceso.',
  invalidStructure: 'Lo sentimos. La estructura del archivo no es correcta. Le recomendamos descargar la plantilla.'
}

export const DEFAULT_PRODUCT_IMAGE_URL = 'https://d221xxk5mfaxk5.cloudfront.net/app/image-not-found-HJDRw_QZI-M.png';

export const FILE_STRUCTURE = {
  A1: 'EAN',
  B1: 'IMAGEN',
  C1: 'DESCRIPCION',
  D1: 'MARCA',
  E1: 'TIPO_DE_UNIDAD',
  F1: 'PRECIO_DE_COMPRA',
  G1: 'PRECIO_DE_VENTA',
  H1: 'CANTIDAD',
  I1: 'IVA',
}

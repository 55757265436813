import React, { FC } from "react";
import styles from "./closures-home-detail-skeleton.module.sass";
import UiSkeleton from "@ui/ui-skeleton/ui-skeleton";

interface ClosuresHomeDetailSkeletonProps { }

const ClosuresHomeDetailSkeleton: FC<ClosuresHomeDetailSkeletonProps> = () => {

  return (
    <div className={styles.wrapper}>
      {Array(9).fill("").map((_, i) => (
        <UiSkeleton
          className={styles[`element-${i + 1}`]}
          key={`closure-detail-skeleton-${_ + i}`}
        />
      ))}
    </div>
  );
}

export default ClosuresHomeDetailSkeleton;

import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, authUiSelector } from "@modules/auth/store";
import { CODE, NEW_PASSWORD, PHONE } from "@modules/auth/constants";
import newLogo from '@img/new-logo.png';
import computer from '@img/computer.png';
import elipse from '@img/elipse.png';
import dots from '@img/dots.png';
import styles from "./login-password-recuperation.module.sass";
import PasswordRecuperationPhone from "@modules/auth/components/password-recuperation-phone";
import PasswordRecuperationCode from "@modules/auth/components/password-recuperation-code";
import PasswordRecuperationNewPassword from "@modules/auth/components/password-recuperation-new-password";

interface LoginPasswordRecuperationProps { }

const LoginPasswordRecuperation: FC<LoginPasswordRecuperationProps> = () => {
  const { passwordRecuperationPage } = useSelector(authUiSelector);
  const dispatch = useDispatch();

  const handlePage = useCallback(() => {
    if ( passwordRecuperationPage === NEW_PASSWORD ) {
      return <PasswordRecuperationNewPassword />
    } else if ( passwordRecuperationPage === CODE ) {
      return <PasswordRecuperationCode />
    } else return <PasswordRecuperationPhone />
  }, [passwordRecuperationPage]);

  useEffect(() => {
    return () => {
      dispatch(actions.setPasswordRecuperationPage(PHONE));
    }
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <figure className={styles.logoWrapper}>
        <img src={newLogo} alt='logo' className={styles.logo} />
      </figure>

      {handlePage()}

      <div className={styles.images}>
        <img src={computer} alt='computer' className={styles.computer} />
        <img src={elipse} alt='elipse' className={styles.elipse} />
        <img src={dots} alt='dots' className={styles.rightDots} />
        <img src={dots} alt='dots2' className={styles.leftDots} />
      </div>
    </div>
  )
}

export default LoginPasswordRecuperation;
import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions, dashboardErrorsSelector, dashboardUiSelector,
  productsSearchDataSelector, searchHistorySelector
} from '@modules/dashboard/store';
import { actions as inventoryActions } from '@modules/inventory/store';
import ProductsSearchHeader from '@modules/dashboard/components/products-search-header';
import UiLoader from '@ui/ui-loader';
import styles from './products-search-mobile.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message';
import { Product } from '@modules/inventory/entities';

interface ProductsSearchMobileProps { }

const ProductsSearchMobile: FC<ProductsSearchMobileProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSearching, isLoadingProductsSearch } = useSelector(dashboardUiSelector);
  const errors = useSelector(dashboardErrorsSelector);
  const searchHistory = useSelector(searchHistorySelector);
  const searchedProducts = useSelector(productsSearchDataSelector);

  useEffect(() => {
    return () => { dispatch(actions.setIsSearching(false)) }
  }, [dispatch]);

  const handleDeleteHistoryItem = (item: string): void => {
    dispatch(actions.setSearchHistory(searchHistory.filter(e => e !== item)));
  };

  const handleOpenProductDetail = (sku: string): void => {
    dispatch(inventoryActions.setProduct({} as Product));
    dispatch(inventoryActions.setIsFromDashboardMobile(false));
    navigate(`/product/${sku}`);
  };

  const handleSelectHistoryOption = (option: string): void => {
    dispatch(actions.setHistoryOptionSelected(option));
  };

  if (errors.searchProducts) return <UiNoDataMessage />;

  return (
    <div className={styles.container}>
      {isLoadingProductsSearch && <UiLoader />}

      <ProductsSearchHeader />

      {isSearching ? (
        <>
          <h1 className={styles.title}>Tu búsqueda</h1>
          {!searchedProducts.length && (
            <span className={styles.row}>No encontramos coincidencias. Intenta nuevamente</span>
          )}
          {searchedProducts.map((item) => (
            <div
              className={styles.row}
              key={`searched-product-item-${item.id}`}
              onClick={() => handleOpenProductDetail(item.sku)}
            >
              <span className={styles.text}>{item.name}</span>
              <span className='icon-search' />
            </div>
          ))}
        </>
      ) : (
        <>
          <h1 className={styles.title}>Búsquedas recientes</h1>
          {!searchHistory.length && (
            <span className={styles.row}>No hay búsquedas en tu historial</span>
          )}
          {searchHistory.map((item, i) => (
            <div className={styles.row} key={`search-history-item-${item}-${i}`}>
              <span className={`icon-time ${styles.timeIcon}`}  />
              <span
                className={styles.text}
                onClick={() => handleSelectHistoryOption(item)}
              >
                {item}
              </span>
              <span className={`icon-close ${styles.icon}`} onClick={() => handleDeleteHistoryItem(item)} data-testid='delete-history' />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default ProductsSearchMobile;

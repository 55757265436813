import React, { ChangeEvent, ChangeEventHandler, FC, useEffect, useRef, useState } from "react";
import styles from "./ui-phone-input.module.sass";
import { CountryCodeInfo } from "@entities";
import { Icon } from "@chiper-inc/sb-web-chiper";

interface UiPhoneInputProps { 
  name: string;
  value: string;
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onChangePhoneCode: (areaCode: number) => void;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  maxLength: number;
  countrySelected: number;
  countries: CountryCodeInfo[];
  type: string;
  hasError?: boolean
}

const UiPhoneInput: FC<UiPhoneInputProps> = ({
  name,
  value,
  placeholder,
  onChange = () => true,
  onBlur,
  error,
  maxLength,
  onChangePhoneCode,
  countrySelected = 57,
  countries = [],
  type,
  hasError = false
}) => {
  const [countryCode, setCountryCode] = useState<number>(countrySelected);
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const countriesContainerRef = useRef<HTMLDivElement>(null);
  const countriesWrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!maxLength || e.target.value.length <= maxLength) onChange(e);
  };

  const handleCountrySelect = (areaCode: number) => {
    setCountryCode(areaCode);
    setShowCountries(false);
    if (onChangePhoneCode) {
      onChangePhoneCode(areaCode);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        countriesContainerRef.current &&
        !countriesContainerRef.current.contains(event.target as Node) &&
        countriesWrapperRef.current &&
        !countriesWrapperRef.current.contains(event.target as Node)
      ) {
        setShowCountries(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const country = () => {
    return countries!.find((i) => i.code === countryCode);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.countryCode}
        onClick={() => setShowCountries(!showCountries)}
        ref={countriesWrapperRef}
        data-testid="area-code-options"
      >
        {countries &&
        <div className={styles.country} data-testid="phone-indicator">
          <img src={country()!.flag} alt="Flag" className={styles.img} />
          <span className={styles.text}>+{country()!.code}</span>
          <div className={`${styles.arrow} ${showCountries && styles.up}`}>
            <Icon name="CaretDown" size="small" />
          </div>
        </div>
        }
      </div>

      <div
        className={`
        ${styles.wrapper} 
        ${hasError && styles.error} 
      `}>
          <input
            data-testid='ui-phone-input'
            name={name}
            value={value}
            placeholder={placeholder}
            className={styles.input}
            onChange={handleInputChange}
            onBlur={onBlur}
            autoComplete='off'
            maxLength={maxLength}
            type={type}
          />
          {hasError && !!error &&
            <span className={styles.errorMessage}>{error}</span>
          }
      </div>

      {showCountries && countries && (
        <div className={styles.items} ref={countriesContainerRef}>
          {countries.map((e, index) => (
            <div
              className={styles.item}
              key={`country-code-${index}`}
              onClick={() => handleCountrySelect(e.code)}
            >
              <img src={e.flag} alt="Flag" className={styles.flag} />
              <div className={styles.name}>+{e.code}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default UiPhoneInput;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomersResumeState, CustomerSwitchOption } from ".";

import { Customer, CustomerBalance, CustomerSale, TransactionDetailItem } from "../entities";
import { initialState } from './customers.state';

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    changeSwitchOption: (state, { payload }: PayloadAction<CustomerSwitchOption>) => {
      state.ui.activeSwitchOption = payload;
    },
    setNoSearchResult: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasNoSearchResult = payload;
    },
    setHasRequestedCustomers: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasRequestedCustomers = payload;
    },
    setShowSalesTable: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showSalesTable = payload;
    },
    setHasCustomers: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasCustomers = payload;
    },
    setCustomers: (state, { payload }: PayloadAction<Customer[]>) => {
      state.data.customers = payload;
    },
    setResume: (state, { payload }: PayloadAction<CustomersResumeState>) => {
      state.resume = payload;
    },
    setTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.pagination.totalPages = payload;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    },
    setCurrentSearchedValue: (state, { payload }: PayloadAction<string>) => {
      state.search.currentSearchedValue = payload;
    },
    setGetCustomersError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getCustomers = payload;
    },
    setGetCustomerDetailError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getCustomerDetail = payload;
    },
    setGetTransactionDetailError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getTransactionDetail = payload;
    },
    setCustomerDetail: (state, { payload }: PayloadAction<Customer>) => {
      state.detail.customer = payload;
    },
    setCustomerSales: (state, { payload }: PayloadAction<CustomerSale[]>) => {
      state.detail.sales = payload;
    },
    setCustomerBalances: (state, { payload }: PayloadAction<CustomerBalance[]>) => {
      state.detail.balances = payload;
    },
    setTransactionDetail: (state, { payload }: PayloadAction<TransactionDetailItem[]>) => {
      state.detail.transactionDetail = payload;
    }
  },
});

export const actions = customersSlice.actions;
export default customersSlice.reducer;

import React, { FC, memo } from 'react';
import { Bill } from '@modules/bills/entities';
import styles from './bill-detail-header.module.sass';
import { formatMoney } from '@utils/format-money';
import { formatTime, isLoan } from '@modules/bills/utils';

interface BillDetailHeaderProps {
  bill: Bill
}

const BillDetailHeader: FC<BillDetailHeaderProps> = ({ bill }) => {

  return (
    <div className={styles.wrapper}>

      <div className={styles.left}>
        <p className={styles.label}>
          Fecha
          <span className={styles.value}>
            {bill.transactionDate}, {formatTime(bill.date, bill.transactionHour)}
          </span>
        </p>
        <p className={styles.label}>
          {isLoan(bill.type) ? 'Estado' : 'Método de pago'}
          <span className={styles.value}>{isLoan(bill.type) ? 'Pagado' : bill.paymentMethod}</span>
        </p>
      </div>

      <div className={styles.right}>
        <p className={styles.label}>
          {isLoan(bill.type) ? 'Cliente' : 'Estado'}
          <span className={styles.value}>{isLoan(bill.type) ? bill.name : 'Finalizada'}</span>
        </p>
        <p className={styles.label}>
          Valor
          <span className={styles.value}>{formatMoney(+bill.total)}</span>
        </p>
      </div>
    </div>
  );
}

export default memo(BillDetailHeader);

import {
  ACCESS_POS_WEB_PERMISSION_ID, ADMIN_ROLE_ID, CASHIER_ROLE_ID, EDIT_INVENTORY_PERMISSION_ID, EDIT_PURCHASE_PRICE_PERMISSION_ID,
  EDIT_SALE_PRICE_PERMISSION_ID, OWNER_ROLE_ID, RECEIVE_BOX_PERMISSION_ID, VIEW_CASH_DECK_CONTROL_PERMISSION_ID
} from "@constants";
import { CollaboratorFormBase, CollaboratorsTabs, PermissionFormItem, RoleOptions } from "../entities";
import { PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";

export const LIMIT_GET_COLLABORATORS = 10;

export const PATH = 'collaborators';

export const TABS: CollaboratorsTabs = {
  home: '',
  creation: 'creation',
  update: 'update',
};

export const COLUMNS_TABLE: { title: string, icon?: keyof typeof PhosphorIcons }[] = [
  { title: 'Nombre', icon: 'ArrowsDownUp'},
  { title: 'Identificación', icon: 'ArrowsDownUp'},
  { title: 'Celular', icon: 'ArrowsDownUp'},
  { title: 'Rol', icon: 'ArrowsDownUp'},
  { title: 'Editar precios de venta'},
  { title: 'Editar inventario'},
  { title: 'Ingreso Admin'},
  { title: 'Editar precios de costo'},
  { title: 'Ver transacciones en cierres'},
  { title: 'Recibir caja'},
];

export const ALL_PERMISSIONS = [
  EDIT_SALE_PRICE_PERMISSION_ID,
  EDIT_INVENTORY_PERMISSION_ID,
  ACCESS_POS_WEB_PERMISSION_ID,
  EDIT_PURCHASE_PRICE_PERMISSION_ID,
  VIEW_CASH_DECK_CONTROL_PERMISSION_ID,
  RECEIVE_BOX_PERMISSION_ID,
];

export const PERMISSIONS_COLUMNS = ALL_PERMISSIONS;

export const ROLE_OPTIONS: RoleOptions[] = [
  {
    title: 'Dueño',
    id: OWNER_ROLE_ID,
    value: OWNER_ROLE_ID,
  },
  {
    title: 'Administrador',
    id: ADMIN_ROLE_ID,
    value: ADMIN_ROLE_ID,
  },
  {
    title: 'Cajero',
    id: CASHIER_ROLE_ID,
    value: CASHIER_ROLE_ID,
  },
];

export const NEW_COLLABORATOR_FORM_BASE: CollaboratorFormBase = {
  document: '',
  documentTypeId: '',
  name: '',
  phone: '',
  roleId: '',
};

export const PERMISSIONS_DESCRIPTION = 'A continuación selecciona los permisos que deseas otorgarle a tu colaborador.';

export const PERMISSIONS_FORM: PermissionFormItem[] = [
  {
    id: EDIT_SALE_PRICE_PERMISSION_ID,
    name: 'EDIT_SALE_PRICE_PERMISSION',
    title: '1. Editar precios de venta',
    description: ['Los precios de venta de tus productos ', ' podrán ser editados por este colaborador'],
  },
  {
    id: RECEIVE_BOX_PERMISSION_ID,
    name: 'RECEIVE_BOX_PERMISSION',
    title: '4. Recibir caja',
    description: ['Tu colaborador ', ' estará autorizado para recibir'],
  },
  {
    id: EDIT_INVENTORY_PERMISSION_ID,
    name: 'EDIT_INVENTORY_PERMISSION',
    title: '2. Editar inventario',
    description: ['Las cantidades en tu inventario ', ' podrán ser editadas por este colaborador'],
  },
  {
    id: EDIT_PURCHASE_PRICE_PERMISSION_ID,
    name: 'EDIT_PURCHASE_PRICE_PERMISSION',
    title: '5. Editar precios de costo',
    description: ['Los precios de costo de tus productos ', ' podrán ser editados por este colaborador'],
  },
  {
    id: ACCESS_POS_WEB_PERMISSION_ID,
    name: 'ACCESS_POS_WEB_PERMISSION',
    title: '3. Ingreso Admin',
    description: ['Tu colaborador ', ' podrá ingresar al Admin con su usuario'],
  },
  {
    id: VIEW_CASH_DECK_CONTROL_PERMISSION_ID,
    name: 'VIEW_CASH_DECK_CONTROL_PERMISSION',
    title: '6. Ver transacciones en cierres',
    description: ['Las transacciones en tus cierres ', ' podrán ser vistas por este colaborador'],
  },
];

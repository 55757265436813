import React, { ChangeEvent, ChangeEventHandler, FC, FocusEventHandler, memo } from 'react';
import styles from './ui-text-area-field.module.sass';

interface UiTextAreaFieldProps {
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  label: string;
  hasError?: boolean;
  maxLength?: number;
  value: string;
  name?: string;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
}

const UiTextAreaField: FC<UiTextAreaFieldProps> = ({
  onChange,
  placeholder = '',
  label = '',
  hasError,
  maxLength,
  value,
  name,
  onBlur,
  onFocus,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    if (!maxLength || value.length <= maxLength) onChange(event);
  };

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.top} ${(hasError || value.length === maxLength) && styles.error}`}>
        <span className={styles.label}>{label}</span>
        <textarea
          onFocus={onFocus}
          name={name}
          onBlur={onBlur}
          data-testid='ui-text-area-field'
          className={styles.textarea}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={handleInputChange}
          value={value}
        />
      </div>
      {!!maxLength &&
        <div className={`${styles.bottom} ${value.length === maxLength && styles.error}`}>
          {`${value.length}/${maxLength}`}
        </div>
      }
    </div>
  )
}

export default memo(UiTextAreaField);

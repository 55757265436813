import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closuresDetailSelector } from "@modules/closures/store";
import { fetchDownloadClosure } from "@modules/closures/services";
import { currentUserSelector, actions as globalActions } from "@config/store";
import useClosureHistory from "@modules/closures/hooks/use-closure-history";
import Clevertap, { CLOSURE_DOWNLOAD } from "@config/clevertap";
import styles from "./closure-mobile-home.module.sass";
import ClosuresMobileHomeDetailContent from "@modules/closures/components/closures-mobile-home-detail-content";
import ClosuresMobileHomeDetailSkeleton from "@modules/closures/components/closures-mobile-home-detail-skeleton";
import UiButton from "@ui/ui-button-v2";

interface ClosureMobileHomeProps {}

const ClosureMobileHome: FC<ClosureMobileHomeProps> = () => {
  const closure = useSelector(closuresDetailSelector);
  const storeId = useSelector(currentUserSelector).storeId;
  const dispatch = useDispatch();
  const { isLoading } = useClosureHistory();

  useEffect(() => {
    dispatch(
      globalActions.setMobileHeaderSection({
        section: "Cierre de Caja",
        routeTo: '',
      })
    );
  }, [dispatch]);

  const handleDownloadClick = () => {
    fetchDownloadClosure(closure.id, storeId);
    Clevertap.pushEvent(CLOSURE_DOWNLOAD, { closureId: +closure.id });
    setTimeout(() => {
      dispatch(
        globalActions.notification({
          show: true,
          title: "Informe descargado",
          message: "El informe descargado puedes encontrarlo en tu carpeta de descargas",
          state: "success",
        })
      );
    }, 1000);
  };

  if (isLoading) return <ClosuresMobileHomeDetailSkeleton />

  return (
    <div className={styles.wrapper}>
      <ClosuresMobileHomeDetailContent />

      <div className={styles.button}>
        <UiButton
          text="Descargar informe"
          leftIconSb="DownloadSimple"
          onClick={handleDownloadClick}
        />
      </div>
    </div>
  );
};

export default ClosureMobileHome;

import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "@chiper-inc/sb-web-chiper";
import { TIME_TO_HIDE_NOTIFICATION } from "@constants";
import { actions, globalUiToastSelector } from "@config/store";
import styles from "./ui-toast.module.sass";

interface UiToastProps { }

const UiToast: FC<UiToastProps> = () => {
  const dispatch = useDispatch();
  const toast = useSelector(globalUiToastSelector);

  const hideToast = useCallback(() => {
    dispatch(actions.toast({ show: false }));
  }, [dispatch]);

  useEffect(() => {
    let timeoutFunction: NodeJS.Timeout;
    if (toast.show) {
      timeoutFunction =
        setTimeout(() => hideToast(), TIME_TO_HIDE_NOTIFICATION);
    }
    return () => {
      if (timeoutFunction) clearTimeout(timeoutFunction);
    }
  }, [toast.show, hideToast]);

  return (
    <div
      className={`${styles.wrapper} ${toast.show ? styles.show : ""}`}
      data-testid="ui-toast"
    >
      <Toast
        title={toast.title}
        body={toast.body}
        className={styles.toast}
        type={toast.type as keyof object}
        onCloseClick={hideToast}
      />
    </div>
  );
}

export default UiToast;
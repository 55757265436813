import React, { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import styles from './collaborator-create-password.module.sass';
import { actions as globalActions } from '@config/store';
import greenSquareCheckImg from '../../../../assets/img/green-square-check.png';
import { createCollaboratorPassword } from '../../services';
import UiInputField from '@ui/ui-input-field';
import UiButton from '@ui/ui-button';
import UiLoader from '@ui/ui-loader';
import { createPasswordSchema } from '@modules/collaborators/schemas';
import { useDispatch, useSelector } from 'react-redux';
import { actions, collaboratorsUiSelector } from '@modules/collaborators/store';

interface CollaboratorCreatePasswordProps {}

const CollaboratorCreatePassword: FC<CollaboratorCreatePasswordProps> = () => {
  const { userPhone } = useParams();
  const { isLoading, showSuccessPasswordCreationScreen } = useSelector(collaboratorsUiSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreatePassword = ({ confirmPassword }: FormikValues): void => {
    dispatch(actions.setIsLoading(true));
    if (userPhone) {
      createCollaboratorPassword(userPhone, confirmPassword)
        .then((response) => {
          if (response) {
            dispatch(actions.setShowSuccessPasswordCreationScreen(true));
          } else {
            dispatch(globalActions.notification({
              show: true,
              state: 'error',
              message: 'Error al cambiar la contraseña, intenta de nuevo más tarde'
            }));
          }
          dispatch(actions.setIsLoading(false));
        });
    }
  };

  const handleQuitButtonClick = (): void => {
    navigate('/');
  };

  const form = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: createPasswordSchema,
    onSubmit: handleCreatePassword,
  });

  return (
    <>
      {isLoading && <UiLoader />}

      {showSuccessPasswordCreationScreen
        ? (
          <div className={styles.wrapper}>
            <div className={styles.success}>
              <div>
                <img className={styles.img} src={greenSquareCheckImg} alt="check" />
                <div className={styles.title}>Tu contraseña se ha creado exitosamente</div>
                <div className={styles.description}>
                  Ya puedes ingresar a ChiperPOS. *Recuerda que tu usuario es tu número de celular
                </div>
              </div>
              <div className={styles.button}>
                <UiButton onClick={handleQuitButtonClick} text='Salir' isFullWidth />
              </div>
            </div>
          </div>
        )
        : (
          <div className={styles.wrapper}>
            <div className={styles.header}>Contraseña</div>
            <form className={styles.form} onSubmit={form.handleSubmit}>
              <span className={styles.description}>
                Crea una contraseña para ingresar al sistema,
                recuerda que tu usuario es tú número de celular
              </span>
              <div className={styles.inputs}>
                <UiInputField
                  type="password"
                  name="newPassword"
                  label="Contraseña"
                  value={form.values.newPassword}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  hasError={!!(form.errors.newPassword && form.touched.newPassword)}
                  error={form.errors.newPassword}
                />
                <UiInputField
                  type="password"
                  name="confirmPassword"
                  label="Confirmar contraseña"
                  value={form.values.confirmPassword}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  hasError={!!(form.errors.confirmPassword && form.touched.confirmPassword)}
                  error={form.errors.confirmPassword}
                />
              </div>
              <div className={styles.footer}>
                <div className={styles.button}>
                  <UiButton type="submit" text='Confirmar' isFullWidth />
                </div>
              </div>
            </form>
          </div>
        )}
    </>
  );
};

export default CollaboratorCreatePassword;

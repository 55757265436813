import React, { FC, useEffect } from 'react';
import { actions, customerDetailSelector, customersErrorsSelector, customersUiSelector } from '@modules/customers/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styles from './customer-detail.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiTopSection from '@ui/ui-top-section';
import { PATH, TABS } from '@modules/customers/constants';
import UiButton from '@ui/ui-button';
import CustomerDetailResume from '@modules/customers/components/customer-detail-resume';
import CustomerDetailSwitch from '@modules/customers/components/customer-detail-switch';
import CustomerSalesTable from '@modules/customers/components/customer-sales-table';
import CustomerBalancesTable from '@modules/customers/components/customer-balances-table';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import Clevertap from '@utils/clevertap';
import { CUSTOMER_DETAIL_DURATION, CUSTOMER_DETAIL_OPEN } from '@constants';

interface CustomerDetailProps { }

const CustomerDetail: FC<CustomerDetailProps> = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const customer = useSelector(customerDetailSelector);
  const errors = useSelector(customersErrorsSelector);
  const { showSalesTable, isLoading } = useSelector(customersUiSelector);
  const { getCustomerDetail } = useCustomersFetch();

  useEffect(() => {
    if (!customer.id && customerId) getCustomerDetail(customerId);
    dispatch(actions.setTransactionDetail([]));
  }, [customer.id, customerId, dispatch, getCustomerDetail]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(CUSTOMER_DETAIL_OPEN);
    return () => Clevertap.pushDurationEvent(CUSTOMER_DETAIL_DURATION, openedAt);
  }, []);

  if (errors.getCustomerDetail) return <UiNoDataMessage message="No pudimos obtener la información de su cliente" />;

  if (!customer.id && isLoading) return <div className={styles.loading}>Cargando...</div>;

  return (
    <div className={styles.container}>
      <UiTopSection
        title={customer.name}
        routes={[{ name: 'Clientes', path: `/${PATH}` }]}
        rightButton={
          <Link to={`${TABS.update}`} className={styles.link}>
            <UiButton text="Editar" leftIcon="icon-pencil" />
          </Link>
        }
      />
      <CustomerDetailResume />

      <CustomerDetailSwitch />

      {showSalesTable ? <CustomerSalesTable /> : <CustomerBalancesTable />}

    </div>
  )
}

export default CustomerDetail;

import * as Yup from 'yup';
import { t } from 'i18next'
import { REG_EXP_ONLY_NUMBERS } from '@constants';

export const loginSchema = Yup.object().shape({
  user: Yup.string()
    .min(6, (min) => t('errors.min_digits', { digits: min.min }))
    .max(15, (max) => t('errors.max_digits', { digits: max.max }))
    .required(t('errors.required')),
  password: Yup.string()
    .min(6, (min) => t('errors.min_digits', { digits: min.min }))
    .max(15, (max) => t('errors.max_digits', { digits: max.max }))
    .required(t('errors.required')),
});

export const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(REG_EXP_ONLY_NUMBERS, () => t('Solo se pueden números'))
    .min(6, (min) => t('errors.min_digits', { digits: min.min }))
    .max(15, (max) => t('errors.max_digits', { digits: max.max }))
    .required(t('errors.required')),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], t('Las contraseñas deben coincidir'))
    .matches(REG_EXP_ONLY_NUMBERS, () => t('Solo se pueden números'))
    .min(6, (min) => t('errors.min_digits', { digits: min.min }))
    .max(15, (max) => t('errors.max_digits', { digits: max.max }))
    .required(t('errors.required')),
});

import React, { FC, memo } from 'react';
import image from '@img/unexpected-error.png';
import styles from './ui-unexpected-error.module.sass';

interface UiUnexpectedErrorProps { }

const UiUnexpectedError: FC<UiUnexpectedErrorProps> = () => {
  return (
    <div className={styles.wrapper} data-testid='ui-unexpected-error'>
      <img src={image} alt="Stop" className={styles.image} />
      <p className={styles.title}>Ocurrió un error inesperado</p>
      <p className={styles.message}>
        Te recomendamos refrescar la página o volver a intentar en unos segundos.
      </p>
    </div>
  );
}

export default memo(UiUnexpectedError);

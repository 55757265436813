import axios from "axios";
import { LIMIT_GET_INVENTORY } from "../constants";
import {
  Inventory, InventoryFileObject, InventoryItem, Product,
  ProductCategory, ProductDetail, ProductDiscount, ProductDiscountForm, ProductDiscountTableItem
} from "../entities";

export const fetchGetInventory = (sort?: string): Promise<Inventory> =>
  axios.get<Inventory>(`inventories?limit=${LIMIT_GET_INVENTORY}&getCount=true${!!sort ? `&sort=${sort}` : ''}`)
    .then(({ data }) => data);

export const fetchGetPage = (skip: number = 0, searchedValue: string = ''): Promise<InventoryItem[]> =>
  axios.get<InventoryItem[]>(`inventories?limit=${LIMIT_GET_INVENTORY}&skip=${skip * LIMIT_GET_INVENTORY}&search=${searchedValue}`)
    .then(({ data }) => data);

export const fetchDownloadInventory = (storeId: number, isoCode: string): Window | null =>
  window.open(`${process.env.REACT_APP_URL_POS_API}/download-inventory/${storeId}/${isoCode}`, '_blank');

export const fetchDownloadFileTemplate = (storeId: number): Window | null =>
  window.open(`${process.env.REACT_APP_URL_POS_API}/dounload-file-template/${storeId}`, '_blank');

export const fetchSearchProduct = (value: string): Promise<Inventory> =>
  axios.get<Inventory>(`inventories?limit=${LIMIT_GET_INVENTORY}&search=${value}&getCount=true`)
    .then(({ data }) => data);

export const fetchGetProductDetail = (storeId: number, sku?: string): Promise<Product> =>
  axios.get<ProductDetail>(`reference/${sku}/store/${storeId}`)
    .then(({ data }) => data.product);

export const fetchGetCategories = (storeId: number): Promise<ProductCategory[]> =>
  axios.get<ProductCategory[]>(`categories/${storeId}`)
    .then(({ data }) => data);

export const fetchUpdateProduct = (product: Product): Promise<boolean> =>
  axios.put('product', [product])
    .then(() => true);

export const fetchChangeProductState = (product: Product): Promise<boolean> =>
  axios.post(`disable-references/${product.inventoryId}`, product)
    .then(() => true);

export const fetchGetProductDiscounts = (id: string): Promise<ProductDiscountTableItem[]> =>
  axios.get<ProductDiscountTableItem[]>(`discount/history-discounts/${id}`)
    .then(({ data }) => data);

export const fetchCreateDiscount = (discount: ProductDiscountForm): Promise<boolean> =>
  axios.post('discount/create-discount', discount)
    .then(({ data }) => data?.message ? true : false);

export const fetchUpdateDiscount = (discount: ProductDiscountForm): Promise<boolean> =>
  axios.put('discount/update-discount', discount)
    .then(() => true);

export const fetchGetDiscount = (id: string): Promise<ProductDiscount> =>
  axios.get<ProductDiscount>(`discount/get-one-discount/${id}`)
    .then(({ data }) => data);

export const fetchGetLastDocumentName = (storeId: number): Promise<string> =>
  axios.get(`upload-document/last-document/${storeId}`)
    .then(({ data }) => data.fileName);

export const fetchSetNewDocument = (fileName: string, storeId: number): Promise<boolean> =>
  axios.post('upload-document/save-document', { fileName, storeId, uploadDocumentTypeId: 1 })
    .then(() => true);

export const fetchSetNewInventory = (inventory: InventoryFileObject[]): Promise<boolean> =>
  axios.post('upload-massive-inventory', inventory)
    .then(() => true);

export const fetchSetNewImage = (formData: FormData): Promise<string> =>
  axios.post(`${process.env.REACT_APP_URL_API_CHIPER}/v1/upload-images`, formData)
    .then(({ data }) => data[0].medium);

export const fetchUpdateProductPOS = (product: Product, storeId: number): Promise<boolean> => 
  axios.post(`reference/propagate-sync/${storeId}`, product)
    .then(() => true);
import { ROUTES } from '@constants';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BILLS_EDITION_PATH, BILLS_DEVOLUTION_PATH } from '../constants';
import BillEdit from '../pages/bill-edit';
import BillDevolution from '../pages/bill-devolution';
import BillsHome from '../pages/bills-home';

interface BillsRouterProps { }

const BillsRouter: FC<BillsRouterProps> = () => {
  return (
    <Routes>
      <Route index element={<BillsHome />} />
      <Route path={BILLS_EDITION_PATH} element={<BillEdit />} />
      <Route path={BILLS_DEVOLUTION_PATH} element={<BillDevolution />} />
      <Route path='*' element={<Navigate to={ROUTES.bills} />} />
    </Routes>
  );
}

export default BillsRouter;

import React, { FC, memo } from 'react'
import { Link, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Tabs from '../components/tabs';
import { PATH, PRODUCT_DETAIL_TABS_OPTIONS, TABS } from '../constants';
import ProductDetail from '../pages/product-detail/product-detail';
import ProductDiscounts from '../pages/product-discounts';
import styles from '../inventory.module.sass';
import Products from '../pages/products';

interface InventoryRouterProps { }

const InventoryOutlet = () => (
  <>
    {/* <Tabs options={INVENTORY_TABS_OPTIONS} /> */}
    <Outlet />
  </>
);

const ProductOutlet = () => (
  <>
    <Link to={`/${PATH}`} className={styles.link}>
      <span className={`icon-arrow ${styles.icon}`} />
      Inventario
    </Link>

    <Tabs options={PRODUCT_DETAIL_TABS_OPTIONS} title='Detalle de producto' />
    <Outlet />
  </>
);

const InventoryRouter: FC<InventoryRouterProps> = () => {

  return (
    <Routes>
      {/* <Route path={`${TABS.combos}/*`} element={<CombosOutlet />}>
        <Route path={`${COMBO_TABS.creation}`} element={<ComboCreation />} />
        <Route path={`${COMBO_TABS.update}/:id`} element={<ComboUpdate />} />
      </Route> */}

      <Route path='/' element={<InventoryOutlet />}>
        <Route index element={<Products />} />
        {/* <Route path={TABS.combos} element={<Combos />} /> */}
        <Route path='*' element={<Navigate to={`/${PATH}`} />} />
      </Route>

      <Route path={`${TABS.productDetail}/:sku`} element={<ProductOutlet />}>
        <Route index element={<ProductDetail />} />
        <Route path={TABS.productDiscounts} element={<ProductDiscounts />} />
      </Route>
    </Routes>
  )
}

export default memo(InventoryRouter);

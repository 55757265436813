import React, { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Bill } from '@modules/bills/entities';
import { isLoan } from '@modules/bills/utils';
import { globalUiSelector } from '@config/store';
import { BILLS_DEVOLUTION_PATH } from '@modules/bills/constants';
import Clevertap, { BILLS_DEVOLUTION_OPEN } from '@config/clevertap';
import styles from './bill-detail-footer-buttons.module.sass';
import UiButton from '@ui/ui-button-v2/ui-button';
import usePrintOrder from '@modules/bills/hooks/use-print-order';

interface BillDetailFooterButtonsProps {
  bill: Bill;
}

const BillDetailFooterButtons: FC<BillDetailFooterButtonsProps> = ({ bill }) => {
  const navigate = useNavigate();
  const { printOrder } = usePrintOrder();
  const { isFromPOS } = useSelector(globalUiSelector);

  const handleDevolutionButtonClick = () => {
    Clevertap.pushEvent(BILLS_DEVOLUTION_OPEN, { invoiceId: bill.id });
    navigate(BILLS_DEVOLUTION_PATH);
  };

  const print = () => printOrder(bill.id);

  const isDisabledDevolution = () => isLoan(bill.type) || !!bill.deletedAt;

  const canPrintOnPOS = () => (
    isFromPOS
    || !!navigator['userAgent'].match(/Android/i)
    || !!String(navigator['userAgentData' as keyof object]['platform']).match(/Android/i)
  );

  return (
    <div className={styles.wrapper}>

      {canPrintOnPOS() && (
        <div className={styles.button}>
          <UiButton
            onClick={print}
            text='Imprimir'
            isSecondary
            isDisabled={false}
          />
        </div>
      )}

      <div className={styles.button}>
        <UiButton
          onClick={handleDevolutionButtonClick}
          text='Devolución'
          isSecondary
          isDisabled={isDisabledDevolution()}
        />
      </div>
    </div>
  );
}

export default memo(BillDetailFooterButtons);

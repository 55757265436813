import React, { FC } from 'react';

import styles from './dashboard-product-item.module.sass';
import { DashboardProduct } from '@modules/dashboard/entities';
import { formatMoney } from '@utils/format-money';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as inventoryActions } from '@modules/inventory/store';
import { Product } from '@modules/inventory/entities';

interface DashboardProductItemProps {
  product: DashboardProduct;
  number: number;
}

const DashboardProductItem: FC<DashboardProductItemProps> = ({ product, number }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenDetail = (sku: string): void => {
    dispatch(inventoryActions.setProduct({} as Product));
    dispatch(inventoryActions.setIsFromDashboardMobile(true));
    navigate(`product/${sku}`);
  };

  return (
    <div className={styles.wrapper} onClick={() => handleOpenDetail(product.sku)}>
      <figure className={styles.figure}>
        <img src={product.imageUrl} alt="Product" className={styles.img} />
      </figure>
      <div className={styles.info}>
        <div className={styles.number}>0{number}</div>
        <div className={styles.name}>{product.referencename}</div>
        <div className={styles.units}>
          Unidades vendidas <div className={styles.strong}>{product.quantity}</div>
        </div>
      </div>
      <div className={styles.value}>{formatMoney(+product.salestotal, 0)}</div>
      <div className={`icon-arrow ${styles.icon}`} />
    </div>
  );
}

export default DashboardProductItem;
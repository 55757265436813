import { useDispatch, useSelector } from "react-redux";
import { actions } from "@modules/bills/store";
import { actions as globalActions, currentUserSelector } from "@config/store";
import { fetchPrintOrder } from "@modules/bills/services";
import Clevertap from "@config/clevertap/clevertap";
import { BILLS_PRINT } from "@config/clevertap";

const usePrintOrder = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);

  const printOrder = (orderId: string) => {
    dispatch(actions.setIsLoading(true));
    fetchPrintOrder({ orderId, storeId: String(user.storeId) })
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          message: "Su factura ha sido agregada a la cola de impresión con éxito",
          state: "success",
        }));
        Clevertap.pushEvent(BILLS_PRINT, { value: orderId })
      })
      .catch(() => dispatch(globalActions.notification({
        show: true,
        message: "Lo sentimos. No pudimos imprimir su factura. Intente nuevamente",
        state: "error",
      })))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

  return {
    printOrder
  };
}

export default usePrintOrder;

import React, { ChangeEvent, FC } from 'react';
import { customerSearchSelector, customersUiSelector } from '@modules/customers/store';
import { useSelector } from 'react-redux';
import styles from './customers-search-bar-mobile.module.sass';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import UiSearchBar from '@ui/ui-search-bar-v2';

interface CustomersSearchBarMobileProps { }

const CustomersSearchBarMobile: FC<CustomersSearchBarMobileProps> = () => {
  const currentSearchdedValue = useSelector(customerSearchSelector);
  const { hasNoSearchResult, hasCustomers } = useSelector(customersUiSelector);
  const { searchCustomers } = useCustomersFetch();

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    searchCustomers(target.value);
  };

  if (!hasCustomers) return <div />

  return (
    <div className={styles.wrapper}>
      <UiSearchBar
        placeholder='Busca a tu cliente'
        onChange={handleSearch}
        defaultValue={currentSearchdedValue}
        hasError={hasNoSearchResult}
      />
    </div>
  )
}

export default CustomersSearchBarMobile;
import { PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import { ProvidersFormBase } from "../entities";

export const LIMIT_GET_PROVIDERS = 15;

export const COLUMNS_TABLE: {title: string, icon: keyof typeof PhosphorIcons}[] = [
  { title: 'Código', icon: 'ArrowsDownUp'},
  { title: 'Proveedor', icon: 'ArrowsDownUp'},
  { title: 'NIT', icon: 'ArrowsDownUp'},
  { title: 'Contacto', icon: 'ArrowsDownUp'},
  { title: 'Celular', icon: 'ArrowsDownUp'},
  { title: 'Correo', icon: 'ArrowsDownUp'},
];

export const FORM_BASE: ProvidersFormBase = {
  codeProvider: '',
  nameProvider: '',
  nit: '',
  contactName: '',
  phone: '',
  email: ''
}
import * as yup from 'yup';
import { DEFAULT_PRODUCT_IMAGE_URL, FORM_BASE, ICO_OPTIONS, IVA_OPTIONS, PACKAGING_TYPE_OPTIONS } from "../constants";
import {
  Combo,
  ComboFormBase,
  ComboRecurrency,
  DiscountRecurrency, InventoryFileItem, InventoryFileObject, Product, ProductCategory,
  ProductDetailList,
  ProductDiscount, ProductDiscountForm, ProductInput, ProductUpdateProperty
} from "../entities";
import { productPurchasePriceUpdateMobileSchema, productSalePriceUpdateMobileSchema, productStockUpdateMobileSchema } from '../schemas';

export const getInputs = (categories: ProductCategory[]): ProductInput[] => [
  {
    name: 'brandName',
    title: 'Marca',
    isSelect: false,
    options: []
  },
  {
    name: 'iva',
    title: 'IVA del producto',
    isSelect: true,
    options: IVA_OPTIONS
  },
  {
    name: 'categoryId',
    title: 'Categoria del producto',
    isSelect: true,
    options: categories.map(c => { return { title: c.name, value: c.id } })
  },
  {
    name: 'ico',
    title: 'ICO del producto',
    isSelect: true,
    options: ICO_OPTIONS
  },
  {
    name: 'packagingType',
    title: 'Tipo de unidad',
    isSelect: true,
    options: PACKAGING_TYPE_OPTIONS
  },
  {
    name: 'stock',
    title: '¿Cuántos tengo en el inventario?',
    isSelect: false,
    options: []
  }
];

export const getForm = (product: Product, currentDicount?: ProductDiscount): ProductDiscountForm => {
  const currentRecurrency = currentDicount?.recurrency || ['2', '3', '4', '5', '6', '7', '8'];

  return {
    id: currentDicount?.id,
    customerDiscountPrice: currentDicount?.customerDiscountPrice || '',
    customerSubTotalPrice: product.totalPrice,
    initAt: currentDicount?.initAt ? new Date(currentDicount?.initAt) : new Date(),
    endAt: currentDicount?.endAt ? new Date(currentDicount?.endAt) : undefined,
    inventoryId: product.inventoryId,
    isActive: true,
    quantity: currentDicount?.quantity || '',
    recurrency: currentRecurrency.map(id => { return { scheduleId: id } })
  };
};

export const changeRecurrency = (recurrency: DiscountRecurrency[] | ComboRecurrency[], id: string): DiscountRecurrency[] | ComboRecurrency[] => {
  const currentRecurrency = recurrency.filter((day) => day.scheduleId !== id);

  return currentRecurrency.length === recurrency.length
    ? [...recurrency, { scheduleId: id }]
    : currentRecurrency;
};

export const getInventoryToExport = (fileRows: InventoryFileItem[]): InventoryFileObject[] => {
  return fileRows.map((row) => {
    return {
      brandId: null,
      brandName: row.MARCA ? row.MARCA : 'Not brand',
      managerPrice: Number(row.PRECIO_DE_COMPRA) ? Number(row.PRECIO_DE_COMPRA) : 0,
      ean: String(row.EAN),
      id: String(row.EAN),
      image: row.IMAGEN || DEFAULT_PRODUCT_IMAGE_URL,
      inventoryItemDays: 0,
      isActive: 1,
      iva: row.IVA || '0',
      ico: row.ICO,
      customerPrice: Number(row.PRECIO_DE_VENTA) ? Number(row.PRECIO_DE_VENTA) : 0,
      margin: '0',
      measurementUnit: row.TIPO_DE_UNIDAD ? row.TIPO_DE_UNIDAD : 'unidad',
      name: row.DESCRIPCION,
      own: false,
      packagingType: row.TIPO_DE_UNIDAD ? row.TIPO_DE_UNIDAD : 'unidad',
      stock: Number(row.CANTIDAD) ? Number(row.CANTIDAD) : 0,
      failed: false,
      total: Number(row.PRECIO_DE_VENTA) + Number(row.CANTIDAD),
      upload: true,
    };
  });
};

export const getComboForm = (currentCombo?: Combo): ComboFormBase => {
  if (!currentCombo?.id) return { ...FORM_BASE, initAt: new Date() }

  return {
    id: currentCombo.id,
    customerTotal: currentCombo.customerTotal,
    name: currentCombo.name,
    initAt: new Date(currentCombo.initAt),
    endAt: new Date(currentCombo.endAt),
    recurrency: currentCombo.schedule.filter(e => !!e.scheduleId),
    quantity: currentCombo.quantity
  };
};

export const getProductDetailList = (product: Product, categories: ProductCategory[]): ProductDetailList[] => {
  return [
    { title: 'EAN', value: product.sku },
    { title: 'Marca', value: product.brandName },
    { title: 'Categoria', value: categories.find(e => e.id === product.categoryId)?.name || '-' },
    { title: 'Tipo de unidad', value: product.packagingType },
    { title: 'IVA', value: `${product.iva}%` },
    { title: 'ICO', value: `${product.ico}%` },
  ]
};

export const getUpdateSectionName = (property: string): string => {
  switch (property) {
    case ProductUpdateProperty.managerTotal: return 'precio de compra';
    case ProductUpdateProperty.totalPrice: return 'precio de venta';
    case ProductUpdateProperty.stock: return 'unidades disponibles';
    default: return ''
  }
};

export const getUpdateSchema = (property: string): yup.AnySchema => {
  switch (property) {
    case ProductUpdateProperty.managerTotal: return productPurchasePriceUpdateMobileSchema;
    case ProductUpdateProperty.totalPrice: return productSalePriceUpdateMobileSchema;
    case ProductUpdateProperty.stock: return productStockUpdateMobileSchema;
    default: return yup.object()
  }
};

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '@utils/format-money';
import { productDetailSelector } from '@modules/inventory/store';
import { ProductUpdateProperty } from '@modules/inventory/entities';
import { currentUserPermissionsSelector } from '@config/store';
import styles from './product-update-mobile-inputs.module.sass';

interface ProductDetailMobileInputsProps { }

const ProductDetailMobileInputs: FC<ProductDetailMobileInputsProps> = () => {
  const navigate = useNavigate();
  const product = useSelector(productDetailSelector);
  const {
    canEditInventoryPurchasePrice, canEditInventoryQuantities, canEditInventorySalePrice
  } = useSelector(currentUserPermissionsSelector);

  const handleOpenUpdateStock = (): void => {
    if (canEditInventoryQuantities && product.isActive) navigate(`update/${ProductUpdateProperty.stock}`);
  };

  const handleOpenUpdatePurchasePrice = (): void => {
    if (canEditInventoryPurchasePrice && product.isActive) navigate(`update/${ProductUpdateProperty.managerTotal}`);
  };

  const handleOpenUpdateSalePrice = (): void => {
    if (canEditInventorySalePrice && product.isActive) navigate(`update/${ProductUpdateProperty.totalPrice}`);
  };

  return (
    <div className={`${styles.container} ${!product.isActive && styles.disabled}`}>
      <div className={`${styles.box}`} onClick={handleOpenUpdateStock}>
        <div className={styles.data}>
          <p className={styles.title}>¿Cuántos tengo en inventario?</p>
          {product.stock}
        </div>
        {canEditInventoryQuantities && <span className={`icon-arrow ${styles.arrow}`} />}
      </div>
      <div className={styles.box} onClick={handleOpenUpdatePurchasePrice} >
        <div className={styles.data}>
          <p className={styles.title}>Precio de compra</p>
          {formatMoney(Number(product.managerTotal), 0)}
        </div>
        {canEditInventoryPurchasePrice && <span className={`icon-arrow ${styles.arrow}`} />}
      </div>
      <div className={styles.box} onClick={handleOpenUpdateSalePrice}>
        <div className={styles.data}>
          <p className={styles.title}>Precio de venta</p>
          {formatMoney(Number(product.totalPrice), 0)}
        </div>
        {canEditInventorySalePrice && <span className={`icon-arrow ${styles.arrow}`} />}
      </div>
    </div>
  );
}

export default ProductDetailMobileInputs;

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';
import { customersDataSelector, customersPagintationSelector, customersUiSelector } from '@modules/customers/store';
import { formatMoney } from '@utils/format-money';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import styles from './customers-list-mobile.module.sass';
import UiNoDataMessage from '@ui/ui-no-data-message-v2';

interface CustomersListMobileProps { }

const CustomersListMobile: FC<CustomersListMobileProps> = () => {
  const navigate = useNavigate();
  const customers = useSelector(customersDataSelector);
  const pagination = useSelector(customersPagintationSelector);
  const { hasNoSearchResult, hasRequestedCustomers } = useSelector(customersUiSelector);
  const { getPage } = useCustomersFetch();

  const handleOpenCustomerDetail = (id: string): void => {
    navigate(id);
  };

  const handleGetPage = (): void => {
    getPage(pagination.currentPage + 1);
  };

  if (hasNoSearchResult) return (
    <div className={styles.noData}>
      <UiNoDataMessage
        title='No encontramos resultados'
        message='No hay clientes con este nombre'
      />
    </div>
  );

  if (hasRequestedCustomers && !customers.length) return (
    <div className={styles.noData}>
      <UiNoDataMessage 
        title='No encontramos resultados'
        message='Aún no has agregado clientes para fiar'
      />
    </div>
  );

  return (
    <div className={styles.wrapper}>
      {customers.map(item => (
        <div
          className={styles.item}
          key={`customers-list-item-${item.id}-${item.phone}`}
          onClick={() => handleOpenCustomerDetail(item.id)}
        >
          <div className={styles.left}>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.date}>
              {item.lastpaidloan
                ? `+${formatDistance(new Date(), new Date(item.lastpaidloan), { locale: es })}`
                : 'Aún no realizó pagos'
              }
            </span>
          </div>
          <div className={styles.right}>
            {formatMoney(item.loans - item.payments, 0)}
            <span className={`icon-arrow ${styles.arrow}`} />
          </div>
        </div>
      ))}

      {pagination.currentPage !== pagination.totalPages && (
        <div className={styles.pagination} onClick={handleGetPage} data-testid='pagination'>
          <span className={`icon-arrow ${styles.arrow}`} />
        </div>
      )}
    </div>
  );
}

export default CustomersListMobile;

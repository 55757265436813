import * as yup from 'yup';
import { t } from 'i18next'
import { REG_EXP_ONLY_NUMBERS } from '@constants';

export const comboSchema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required')),
  customerTotal: yup.string()
    .required(t('errors.required'))
    .test('test', t('errors.zero_amount'), (total) => Number(total) > 0),
  quantity: yup.string()
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers')),
  initAt: yup.date()
    .required(t('errors.required'))
    .min(new Date(new Date().setHours(0))),
  endAt: yup.date()
    .required(t('errors.required'))
    .min(yup.ref('initAt')),
});
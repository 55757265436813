import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from './orders.state';
import { Provider } from '@modules/providers/entities';
import { OrderDetail, OrdersData, OrdersFilters } from '../entities/orders.entities';

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setProviders: (state, { payload }: PayloadAction<Provider[]>) => {
      state.data.providers = payload;
    },
    setOrdersFilters: (state, { payload }: PayloadAction<OrdersFilters>) => {
      state.ui.filters = payload;
    },
    setOrdersData: (state, { payload }: PayloadAction<OrdersData>) => {
      state.data.orders = payload;
    },
    setOrdersPage: (state, { payload }: PayloadAction<number>) => {
      state.ui.page = payload;
    },
    setOrderDetail: (state, { payload }: PayloadAction<OrderDetail>) => {
      state.data.detail = payload;
    },
    setCurrentSearchedValue: (state, { payload }: PayloadAction<string>) => {
      state.search.currentSearchedValue = payload;
    },
  },
});

export const actions = ordersSlice.actions;
export default ordersSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClosureHistoryFiltersState, ClosureHistoryPaginationState, initialState } from "./closures.state";
import { CashHistoryItem, Closure } from "../entities";

export const closuresSlice = createSlice({
  name: "closures",
  initialState,
  reducers: {
    setIsLoadingClosure: (state, action: PayloadAction<boolean>) => {
      state.ui.isLoadingClosure = action.payload;
    },
    setIsEmptyClosures: (state, action: PayloadAction<boolean>) => {
      state.ui.isEmptyClosures = action.payload;
    },
    setClosureHistory: (state, action: PayloadAction<CashHistoryItem[]>) => {
      state.data.history.closures = action.payload;
    },
    setClosureHistoryPagination: (state, action: PayloadAction<Partial<ClosureHistoryPaginationState>>) => {
      state.data.history.pagination = { ...state.data.history.pagination, ...action.payload };
    },
    setClosureHistoryFilters: (state, action: PayloadAction<Partial<ClosureHistoryFiltersState>>) => {
      state.data.history.filters = { ...state.data.history.filters, ...action.payload };
    },
    setClosure: (state, action: PayloadAction<Closure>) => {
      state.data.closure = action.payload;
    },
    setClosureSelected: (state, action: PayloadAction<number>) => {
      state.data.history.closureSelected = action.payload
    }
  },
});

export const actions = closuresSlice.actions;
export default closuresSlice.reducer;

import React, { FC, useEffect } from 'react'
import { CUSTOMERS_DURATION, CUSTOMERS_OPEN, MIN_WIDTH_DESKTOP } from '@constants';
import CustomersDektopRouter from './routers';
import styles from './customers.module.sass';
import { useSelector } from 'react-redux';
import { customersUiSelector } from './store';
import UiLoader from '@ui/ui-loader';
import CustomersMobileRouter from './routers/customers-mobile-router.router';
import Clevertap from '@utils/clevertap';

interface CustomersProps {}

const Customers: FC<CustomersProps> = () => {
  const { isLoading } = useSelector(customersUiSelector);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(CUSTOMERS_OPEN);
    return () => Clevertap.pushDurationEvent(CUSTOMERS_DURATION, openedAt);
  }, []);

  return (
    <div className={styles.container}>
      {window.innerWidth > MIN_WIDTH_DESKTOP && <CustomersDektopRouter />}
      {window.innerWidth <= MIN_WIDTH_DESKTOP && <CustomersMobileRouter />}
      {isLoading && <UiLoader />}
    </div>
  );
}

export default Customers;

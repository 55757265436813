import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TABS } from '../constants';
import CustomersCreation from '../pages/customers-creation';
import CustomerDetailMobile from '../pages/customer-detail-mobile-v2';
import CustomersHomeMobile from '../pages/customers-home-mobile';
import CustomersUpdate from '../pages/customers-update';
import CustomerLoanTransactionMobile from '../pages/customer-loan-transaction-mobile';
import CustomerPaymentTransactionMobile from '../pages/customer-payment-transaction-mobile';
import CustomerSaleDetailMobile from '../pages/customer-sale-detail-mobile';
import CustomersUpdateMobile from '../pages/customers-update-mobile';

interface CustomersMobileRouterProps { }

const CustomersMobileRouter: FC<CustomersMobileRouterProps> = () => {
  return (
    <Routes>
      <Route path={`/:customerId/${TABS.transaction}/:transactionId`} element={<CustomerSaleDetailMobile />} />
      <Route path={`/:customerId/${TABS.transaction}/${TABS.paymentTransaction}`} element={<CustomerPaymentTransactionMobile />} />
      <Route path={`/:customerId/${TABS.transaction}/${TABS.loanTransaction}`} element={<CustomerLoanTransactionMobile />} />
      <Route path={`/${TABS.creation}`} element={<CustomersCreation />} />
      <Route path={`/${TABS.customers}:customerId/${TABS.update}`} element={<CustomersUpdate />} />
      <Route path={`/${TABS.customers}:customerId/${TABS.updateName}`} element={<CustomersUpdateMobile isUpdatingName />} />
      <Route path={`/${TABS.customers}:customerId/${TABS.updatePhone}`} element={<CustomersUpdateMobile />} />
      <Route path={`/${TABS.customers}:customerId`} element={<CustomerDetailMobile />} />
      <Route path={`/${TABS.customers}`} element={<CustomersHomeMobile />} />
      <Route path='*' element={<Navigate to='/customers' />} />
    </Routes>
  );
}

export default CustomersMobileRouter;

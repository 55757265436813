import { Dashboard } from "../entities";
import { SearchedProduct } from "../entities/products-search.entities";

export interface DashboardState {
  ui: DashboardUiState;
  data: DashboardDataState;
  errors: DashboardErrorsState;
}

export interface DashboardUiState {
  isLoadingDashboard: boolean;
  isLoadingProductsSearch: boolean;
  isSearching: boolean;
  hasDashboardData: boolean;
}

export interface DashboardDataState {
  dashboard: Dashboard;
  productsSearch: ProductsSearchDataState;
}

export interface ProductsSearchDataState {
  products: SearchedProduct[];
  searchHistory: string[];
  historyOptionSelected: string;
};

export interface DashboardErrorsState {
  searchProducts: boolean;
};

export const initialState: DashboardState = {
  ui: {
    isLoadingDashboard: false,
    isLoadingProductsSearch: false,
    isSearching: false,
    hasDashboardData: false,
  },
  data: {
    dashboard: {} as Dashboard,
    productsSearch: {
      products: [] as SearchedProduct[],
      searchHistory: [] as string[],
      historyOptionSelected: '',
    }
  },
  errors: {
    searchProducts: false
  }
}

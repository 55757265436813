import React, { ChangeEventHandler, FC, FocusEventHandler, memo } from "react";
import styles from "./ui-input-field.module.sass";
import { Icon, PhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";

interface UiInputFieldProps { 
  hasError?: boolean;
  disabled?: boolean;
  value?: string;
  label?: string;
  type?: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  hasAutoFocus?: boolean;
  placeholder: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onClick?: () => void;
  helperText?: string;
  iconRight?: keyof typeof PhosphorIcons;
  readOnly?: boolean;
  onIconClick?: () => void;
}

const UiInputField: FC<UiInputFieldProps> = ({
  hasError = false,
  disabled,
  value,
  label = '',
  type = 'text',
  name,
  onChange,
  error,
  onBlur,
  hasAutoFocus,
  placeholder = '',
  onFocus,
  onClick,
  helperText,
  iconRight,
  readOnly,
  onIconClick
}) => {
  return (
    <label className={`${styles.wrapper} ${hasError ? styles.error : ''} ${helperText ? styles.help : ''} ${disabled && styles.disabled}`} onClick={onClick}>
      <div className={styles.left}>
        {label && value !== '' && <span className={styles.label}>{label}</span>}
        <input
          onFocus={onFocus}
          data-testid='ui-input-field'
          type={type}
          name={name}
          value={value}
          className={styles.input}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          autoFocus={hasAutoFocus}
          disabled={disabled}
          autoComplete='off'
          readOnly={readOnly}
        />
      </div>
      <span className={styles.errorMessage}>{error}</span>
      <span className={styles.helperText}>{helperText}</span>
      {iconRight && <div className={styles.iconRight} onClick={onIconClick} data-testid='password-icon'> <Icon name={iconRight} size={'16px'}/> </div>}
    </label>
  )
}

export default memo(UiInputField);

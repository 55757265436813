import React, { FC } from 'react';
import styles from './bills-list-wrapper.module.sass';
import BillsFilters from '../bills-filters';
import BillsList from '../bills-list';

interface BillsListWrapperProps { }

const BillsListWrapper: FC<BillsListWrapperProps> = () => {

  return (
    <div className={styles.wrapper}>
      <BillsFilters />

      <BillsList />

      {/* <div className={styles.button} >
        <UiButton text='Editar información de factura' isSecondary onClick={handleEditionClick} />
      </div> */}
    </div>
  );
}

export default BillsListWrapper;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Provider } from "../entities";
import { initialState } from './providers.state';

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setShowModal: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showProvidersFormModal = payload;
    },
    setHasNoSearchResults: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasNoSearchResult = payload;
    },
    setShowFirstInputs: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showFirstInputs = payload;
    },
    setProviders: (state, { payload }: PayloadAction<Provider[]>) => {
      state.data.providers = payload;
    },
    setProviderToUpdate: (state, { payload }: PayloadAction<Provider>) => {
      state.update.provider = payload;
    },
    setResume: (state, { payload }: PayloadAction<number>) => {
      state.resume.total = payload;
    },
    setTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.pagination.totalPages = payload;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    },
    setGetProvidersError: (state, { payload }: PayloadAction<boolean>) => {
      state.errors.getProviders = payload;
    },
  },
});

export const actions = providersSlice.actions;
export default providersSlice.reducer;

import React, { FC } from 'react'
import { customersResumeSelector } from '@modules/customers/store';
import { getResumeItems } from '@modules/customers/utils';
import { useSelector } from 'react-redux';
import styles from './customers-resume-desktop.module.sass';
import CustomersSearchBar from '../customers-search-bar';

interface CustomersResumeDesktopProps {}

const CustomersResumeDesktop: FC<CustomersResumeDesktopProps> = () => {
  const resume = useSelector(customersResumeSelector);

  return (
    <div className={styles.wrapper}>
      {getResumeItems(resume).map((item, i) => (
        <div className={styles.item} key={`customers-item-resume-${String(i)}`}>
          {item.title}
          <span className={`${styles.value} ${item.isRed && styles.red}`}>
            {item.value}
          </span>
        </div>
      ))}
      
      <CustomersSearchBar />
    </div>
  )
}

export default CustomersResumeDesktop;

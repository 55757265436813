import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import UiLoader from '@ui/ui-loader';
import styles from './inventory.module.sass';
import { inventoryUiSelector } from './store';
import InventoryRouter from './routes/inventory.router';
import Clevertap from '@utils/clevertap';
import { INVENTORY_DURATION, INVENTORY_OPEN } from '@constants';

interface InventoryProps {}

const Inventory: FC<InventoryProps> = () => {
  const { isLoading } = useSelector(inventoryUiSelector);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(INVENTORY_OPEN);
    return () => Clevertap.pushDurationEvent(INVENTORY_DURATION, openedAt);
  }, []);

  return (
    <div className={styles.container}>
      <InventoryRouter />
      {isLoading && <UiLoader />}
    </div>
  );
}

export default Inventory;

import React, { FC, memo } from 'react';

import styles from './reports-table.module.sass';
import { formatMoney } from '@utils/format-money';
import { SlowTurnoverProduct } from '@modules/reports/entities';

interface ReportsTableProps {
  products: SlowTurnoverProduct[];
}

const ReportsTable: FC<ReportsTableProps> = ({ products }) => {
  const TITLES = [
    'Descripción',
    'Marca',
    'Precio de compra',
    'Precio de venta',
    'Cantidad en inventario',
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {TITLES.map((name) => (
            <div 
              key={`reports-table-title-${name}`} 
              className={`${styles.cell} ${styles.cellTitle}`}
            >
              {name}
            </div>
          ))}
        </div>
        {products.map((i: any) => (
          <div key={`reports-table-row-${i.name}`} className={styles.tableRow}>
            <div className={styles.cell}>{i.name}</div>
            <div className={styles.cell}>{i.brandname || 'Sin marca'}</div>
            <div className={styles.cell}>{formatMoney(+i.managertotal || 0)}</div>
            <div className={styles.cell}>{formatMoney(+i.customertotal || 0)}</div>
            <div className={styles.cell}>{i.quantity || 'No está en tu inventario'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
 
export default memo(ReportsTable);

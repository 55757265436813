import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Icon, Theme } from "@chiper-inc/sb-web-chiper";
import { IPhosphorIcons } from "@chiper-inc/sb-web-chiper/dist/Theme/Icon";
import { closuresDetailSelector } from "@modules/closures/store";
import { formatMoney } from "@utils/format-money";
import ClosuresHomeDetailBox from "../closures-home-detail-box";
import styles from "./closures-home-detail-center-info.module.sass";

interface ClosuresHomeDetailCenterInfoProps { }

interface Item {
  icon: IPhosphorIcons;
  title: string;
  total: number;
  isPlain?: boolean;
}

const ClosuresHomeDetailCenterInfo: FC<ClosuresHomeDetailCenterInfoProps> = () => {
  const closure = useSelector(closuresDetailSelector);

  const items: Item[] = [
    {
      icon: { name: "Coins", color: Theme.colors.secondary.cercana },
      title: "Balance",
      total: closure.balance
    },
    {
      icon: { name: "Flag", color: Theme.colors.secondary.blueJeans },
      title: "Saldo inicial caja",
      total: closure.startBase
    },
    {
      icon: { name: "Handshake", color: Theme.colors.primary.chiperBrandRed },
      title: "Fiados",
      total: closure.loan
    },
    {
      icon: { name: "Calculator", color: Theme.colors.secondary.brightYellow },
      title: "Total transacciones",
      total: closure.numberOfSales,
      isPlain: true,
    },
  ];

  return (
    <div className={styles.wrapper}>
      {items.map((e) => (
        <ClosuresHomeDetailBox key={`closures-details-${e.title}`} className={styles.box}>
          <Icon {...e.icon} size={32} weight="light" className="sbw-min-h-[32px]" />
          <p className={styles.title}>{e.title}</p>
          <p className={styles.total}>{e.isPlain ? e.total : formatMoney(e.total)}</p>
        </ClosuresHomeDetailBox>
      ))}
    </div>
  );
}

export default ClosuresHomeDetailCenterInfo;

import { STORAGE_TOKEN } from "@constants";
import { ClevertapEventPayload } from "./clevertap.entities";

const clevertap = window.clevertap as any;
const item = localStorage.getItem(STORAGE_TOKEN) || ''
const user = item ? JSON.parse(item) : {};

const pushEvent = (eventName: string, payload?: ClevertapEventPayload): void => {
  clevertap.event.push(eventName, {
    storeId: user.storeId,
    date: new Date(),
    ...payload,
  });
};

const Clevertap = {
  pushEvent
};

export default Clevertap;
import { COLOMBIA_ISO_CODE, MEXICO_ISO_CODE, STORAGE_TOKEN } from '@constants';
import React, { ChangeEvent, ChangeEventHandler, FC, memo, useCallback, useEffect, useState } from 'react';

import styles from './ui-input-money.module.sass';

interface UiInputMoneyProps {
  label?: string;
  name: string;
  value?: string | number;
  disabled?: boolean;
  hasError?: boolean;
  error?: string;
  hasAutoFocus?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
}

const UiInputMoney: FC<UiInputMoneyProps> = ({
  hasError,
  error,
  label = '',
  name,
  value,
  disabled,
  onChange,
  onBlur,
  hasAutoFocus,
}) => {
  const [moneyValue, setMoneyValue] = useState('');
  const country = JSON.parse(localStorage.getItem(STORAGE_TOKEN) || '{}').country || COLOMBIA_ISO_CODE;

  const conversion = useCallback((number?: string): string => {
    if (number) {
      let [integer, decimal] = number.split('.');
      integer = integer.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",");
      return integer + (number.includes('.') && country === MEXICO_ISO_CODE ? `.${decimal || ''}` : '')
    }
    return '';
  }, [country]);

  useEffect(() => {
    setMoneyValue(conversion(String(value)))
  }, [conversion, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let currentValue = event.target.value.replace(new RegExp('\\,', 'g'), '').replace('-', '').replace('+', '')
    if (!isNaN(+currentValue) && !(currentValue.split('.')[1]?.length > 2) && currentValue !== '00') {
      if (currentValue[0] === '0' && (currentValue[1] && currentValue[1] !== '.')) {
        currentValue = currentValue.replace(new RegExp(/^0+/), '');
      }
      event.target.value = currentValue
      onChange(event);
    }
  };

  return (
    <label className={`${styles.wrapper} ${hasError && styles.error} ${disabled && styles.disabled}`}>
      <div className={styles.left}>
        {moneyValue && <span className={styles.label}>{label}</span>}
        <div className={`${styles.inputWrapper} ${moneyValue && styles.show}`}>
          <span className={styles.sign}>$</span>
          <input
            data-testid='ui-input-money'
            type='tel'
            autoFocus={hasAutoFocus}
            name={name}
            value={moneyValue}
            className={styles.input}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={label}
            autoComplete='off'
          />
        </div>
      </div>
      <span className={styles.errorMessage}>{error}</span>
    </label>
  );
}

export default memo(UiInputMoney);
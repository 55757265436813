import React, { FC } from 'react';
import BillsListWrapper from '@modules/bills/components/bills-list-wrapper';
import BillDetailWrapper from '@modules/bills/components/bill-detail-wrapper';
import styles from './bills-home.module.sass';

interface BillsHomeProps { }

const BillsHome: FC<BillsHomeProps> = () => {

  return (
    <div className={styles.container}>

      <BillsListWrapper />

      <BillDetailWrapper />

    </div>
  );
}

export default BillsHome;

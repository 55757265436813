import * as yup from 'yup';
import { t } from 'i18next'
import { PHONE_LENGTH, PROVIDER_NAME_LENGTH, REG_EXP_ONLY_LETTERS, REG_EXP_ONLY_NUMBERS } from '@constants';

export const providersSchema = yup.object().shape({
  nameProvider: yup.string()
    .required(t('errors.required'))
    .max(PROVIDER_NAME_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  phone: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .min(PHONE_LENGTH, (min) => t('errors.min_digits', { digits: min.min }))
    .max(PHONE_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
  nit: yup.string()
    .required(t('errors.required')),
  contactName: yup.string()
    .required(t('errors.required'))
    .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters'))
    .max(PROVIDER_NAME_LENGTH, (max) => t('errors.max_digits', { digits: max.max })),
    email: yup.string()
    .required(t('errors.required'))
    .email(t('errors.invalid_email')),
});

import React, { FC, useEffect } from 'react';
import styles from './collaborators-home.module.sass';
import UiTopSection from '@ui/ui-top-section';
import { Link } from 'react-router-dom';
import { TABS } from '@modules/collaborators/constants';
import UiButton from '@ui/ui-button';
import {  useSelector } from 'react-redux';
import { collaboratorsDataSelector } from '@modules/collaborators/store';
import CollaboratorsTable from '@modules/collaborators/components/table';
import useCollaboratorsFetch from '@modules/collaborators/hooks/use-collaborators-fetch';

interface CollaboratorsHomeProps { }

const CollaboratorsHome: FC<CollaboratorsHomeProps> = () => {
  const collaborators = useSelector(collaboratorsDataSelector);
  const { getCollaborators } = useCollaboratorsFetch();

  useEffect(() => {
    if (!collaborators.length) getCollaborators();
  }, [collaborators.length, getCollaborators]);

  return (
    <div className={styles.container}>
      <UiTopSection
        title='Colaboradores'
        rightButton={(
          <Link to={TABS.creation} className={styles.link}>
            <UiButton leftIcon='icon-add' text='Crear colaborador' />
          </Link>
        )}
      />
      <CollaboratorsTable />
    </div>
  )
};

export default CollaboratorsHome;

import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions, historyOptionSelector, searchHistorySelector } from '@modules/dashboard/store';
import { fetchSearchProducts } from '@modules/dashboard/services';
import styles from './products-search-header.module.sass';
import useDebounce from '@utils/use-debounce';

interface ProductsSearchHeaderProps { }

const ProductsSearchHeader: FC<ProductsSearchHeaderProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchHistory = useSelector(searchHistorySelector);
  const historyOptionSelected = useSelector(historyOptionSelector);

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    searchProducts(target.value)
  };
  const { handleInputChange, value, clearValue, changeValue } = useDebounce(handleSearch);

  const handleClearInput = (): void => {
    dispatch(actions.setSearchedProducts([]))
    clearValue();
    dispatch(actions.setIsSearching(false));
  };

  const searchProducts = useCallback((valueSearched: string): void => {
    if (!valueSearched) {
      dispatch(actions.setSearchedProducts([]));
      dispatch(actions.setIsSearching(false));
    } else {
      dispatch(actions.setIsLoadingProductsSearch(true));
      fetchSearchProducts(valueSearched)
        .then((data) => {
          dispatch(actions.setSearchedProducts(data));
          dispatch(actions.setSearchProductsError(false));
        })
        .catch(() => dispatch(actions.setSearchProductsError(true)))
        .finally(() => {
          dispatch(actions.setIsSearching(true));
          dispatch(actions.setIsLoadingProductsSearch(false));
          dispatch(actions.setHistoryOptionSelected(''));
          dispatch(actions.setSearchHistory([valueSearched, ...searchHistory.filter(e => e.trim() !== valueSearched.trim())]));
        });
    }
  }, [dispatch, searchHistory]);

  const handleGoBack = (): void => {
    navigate('/');
  };

  useEffect(() => {
    if (!!historyOptionSelected) searchProducts(historyOptionSelected);
  }, [historyOptionSelected, searchProducts]);

  useEffect(() => {
    if (!!historyOptionSelected) changeValue(historyOptionSelected);
  }, [changeValue, historyOptionSelected]);

  return (
    <div className={styles.container}>
      <span className={`icon-arrow ${styles.arrow}`} onClick={handleGoBack} data-testid='arrow-icon' />
      <input
        autoFocus
        placeholder='Buscar producto'
        className={styles.input}
        onChange={handleInputChange}
        value={value}
      />
      {value && (
        <span className={`icon-close ${styles.closeIcon}`} onClick={handleClearInput} data-testid='close-icon' />
      )}
    </div>
  );
}

export default ProductsSearchHeader;

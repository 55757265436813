import React, { FC, memo } from 'react';

import styles from './ui-button.module.sass';

interface UiButtonProps {
  text: string;
  type?: 'submit' | 'reset' | 'button';
  leftIcon?: string;
  rightIcon?: string;
  isFullWidth?: boolean;
  isSecondary?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const UiButton: FC<UiButtonProps> = ({
  text,
  type = 'button',
  leftIcon,
  rightIcon,
  isFullWidth,
  isSecondary,
  isDisabled,
  onClick,
}) => {
  return (
    <button
      className={`
        ${styles.wrapper}
        ${isFullWidth ? styles.fullWidth : ''} 
        ${isSecondary ? styles.outline : ''}
      `}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
    >
      {leftIcon && <span className={`${leftIcon} ${styles.icon} ${styles.leftIcon}`} />}
      <div className={styles.text}>{text}</div>
      {rightIcon && <span className={`${rightIcon} ${styles.icon} ${styles.rightIcon}`} />}
    </button>
  );
}
 
export default memo(UiButton);
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import styles from './orders-home.module.sass';
import UiSearchBar from '@ui/ui-search-bar';
import UiSelect from '@ui/ui-select';
import UiPaginator from '@ui/ui-paginator';
import { formatMoney } from '@utils/format-money';
import UseGetOrders from '@modules/orders/hooks/use-get-orders';
import { Order } from '@modules/orders/entities/orders.entities';
import Clevertap from '@utils/clevertap';
import { fetchOrderProviders } from '@modules/orders/services/orders.services';
import { actions, ordersSearchSelector, ordersSelector, ordersUiSelector, providersSelector } from '@modules/orders/store';
import { ORDERS_DURATION,  ORDERS_OPEN } from '@constants';
import { ORDERS_TABLE_COLUMNS, PAID_OPTIONS, TABS } from '@modules/orders/constants';
import { Icon } from '@chiper-inc/sb-web-chiper';

interface OrdersHomeProps { }

const OrdersHome: FC<OrdersHomeProps> = () => {
  const [ activeColumn, setActiveColumn ] = useState<string>('');
  const [ sortType, setSortType ] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const providers = useSelector(providersSelector);
  const orders = useSelector(ordersSelector);
  const currentSearchedValue = useSelector(ordersSearchSelector);
  const { filters, page, isLoading } = useSelector(ordersUiSelector);
  const { getOrders } = UseGetOrders();

  const handleQueryChange = (query: string) => {
    dispatch(actions.setOrdersFilters({ ...filters, query }));
    resetPage();
  }

  const handleProviderChange = (providerId: string) => {
    dispatch(actions.setOrdersFilters({ ...filters, providerId }));
    resetPage();
  }

  const handlePaidChange = (paid: string) => {
    const isTrue = paid === 'true' ? true: false
    const isPaid = paid === 'undefined' ? undefined : isTrue;
    dispatch(actions.setOrdersFilters({ ...filters, isPaid }));
    resetPage();
  }

  const handleItemClick = (orderId: string) => {
    navigate(`${TABS.ordersDetail}/${orderId}`);
  }

  const handlePageChange = (pageNumber: number) => {
    dispatch(actions.setOrdersPage(pageNumber));
  }

  const resetPage = () => {
    if (page !== 1) dispatch(actions.setOrdersPage(1));
  }

  const providerOptions = () => {
    const options = providers.filter(p => !!p.nameProvider).map(p => ({ title: p.nameProvider, value: p.id }));
    return [{ title: 'Todos', value: '' }, ...options];
  }

  const handleTableClick = (title: string) => {
    setActiveColumn(title);
    setSortType(!sortType);
    const sort = JSON.stringify({
      field: activeColumn,
      type: !sortType ? 'ASC' : 'DESC'
    })
    getOrders(sort)
  };

  useEffect(() => {
    if (!providers.length)
      fetchOrderProviders()
        .then(r => {
          dispatch(actions.setProviders(r.providers));
        })
        .catch(() => {
          dispatch(actions.setIsLoading(false));
        });

    if (!orders.orders.length) {
      getOrders()
    }
  }, [dispatch, providers.length, getOrders, orders]);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(ORDERS_OPEN);
    return () => Clevertap.pushDurationEvent(ORDERS_DURATION, openedAt);
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerFilters}>
          <div className={styles.resume}>
            <span className={styles.resumeTitle}>Pedidos realizados</span>
            <span className={styles.resumeTotal}>{orders.ordersQuantity}</span>
          </div>
          <div className={styles.searchBar}>
            <UiSearchBar
              placeholder='Buscar pedido'
              onChange={(v) => handleQueryChange(v.target.value)}
              defaultValue={currentSearchedValue}
            />
          </div>
          <div className={styles.selects}>
            <UiSelect
              label='Proveedor'
              name='provider'
              defaultValue='Proveedor'
              value={filters.providerId}
              options={providerOptions()}
              onChange={(v) => handleProviderChange(v.target.value)}
              isMedium
            />
            <UiSelect
              label='Pago'
              name='paid'
              defaultValue='Pago'
              options={PAID_OPTIONS}
              value={String(filters.isPaid)}
              onChange={(v) => handlePaidChange(v.target.value)}
              isMedium
            />
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.header}>
            {ORDERS_TABLE_COLUMNS.map((item) => (
              <div className={`${styles.cell} ${activeColumn === item.title && styles.active}`} key={`orders-table-column-${item.title}`}>
                <div className={styles.title} onClick={() => {handleTableClick(item.title); setSortType(!sortType)}}>{item.title}</div>
                {!!item.icon && (
                <div className={styles.icon} onClick={() => {handleTableClick(item.title); setSortType(!sortType)}} data-testid="table-head-item">
                  <Icon size="12px" weight="bold" name={item.icon} />
                </div>
              )}
              </div>
            ))}
          </div>
          {orders.orders.map((item: Order, i: number) => (
            <div className={styles.body} key={`order-item-${i}}`} onClick={() => handleItemClick(item.id)}>
              <div className={`${styles.cell} ${styles.name}`}>{item.providers.nameProvider || 'Sin proveedor'}</div>
              <div className={styles.cell}>{item.id}</div>
              <div className={styles.cell}>{item.invoiceNumber || 'No registra'}</div>
              <div className={styles.cell}>{format(new Date(item.invoicePayDate), 'dd MMM yyyy', { locale: es }) || 'No registra'}</div>
              <div className={styles.cell}>{formatMoney(item.total)}</div>
              <div className={styles.cell}>
                <span className={item.isPaid ? `icon-check ${styles.checkIcon}` : `icon-close ${styles.closeIcon}`} />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.paginator}>
          {!isLoading && !orders.pages
            ? <span className={styles.empty}>Aún no tienes pedidos registrados</span>
            : <UiPaginator pagesQuantity={orders.pages} currentPage={page} onChangePage={handlePageChange} />
          }
        </div>
      </div>
    </>
  );
}

export default OrdersHome;

import React, { useEffect } from 'react';
import { collaboratorsUiSelector } from './store';
import { useSelector } from 'react-redux';
import UiLoader from '@ui/ui-loader';
import CollaboratorsRouter from './routers/collaborators-router.router';
import styles from './collaborators.module.sass';
import Clevertap from '@utils/clevertap';
import { COLLABORATORS_DURATION, COLLABORATORS_OPEN } from '@constants';

export default function Collaborators() {
  const { isLoading } = useSelector(collaboratorsUiSelector);

  useEffect(() => {
    const openedAt = new Date();
    Clevertap.pushSimpleEvent(COLLABORATORS_OPEN);
    return () => Clevertap.pushDurationEvent(COLLABORATORS_DURATION, openedAt);
  }, []);

  return (
    <div className={styles.container}>
      <CollaboratorsRouter />
      {isLoading && <UiLoader />}
    </div>
  );
}

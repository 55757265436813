import React, { FC, memo } from 'react';
import { globalUiSelector } from '@config/store';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import styles from './ui-mobile-header.module.sass';

interface UiMobileHeaderProps {
  navigate: NavigateFunction;
}

const UiMobileHeader: FC<UiMobileHeaderProps> = ({ navigate }) => {
  const { mobileHeaderSection } = useSelector(globalUiSelector);
  const handleGoBack = () => {
    navigate(mobileHeaderSection.routeTo);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.fixedWrapper}>
        <div className={styles.content} onClick={handleGoBack}>
          <span className={`icon-arrow ${styles.arrow}`} />
          <span className={styles.name}>
            {mobileHeaderSection.section}
          </span>
        </div>
      </div>
    </div>
  );
}

export default memo(UiMobileHeader);
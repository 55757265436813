import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { actions } from '@modules/profile/store';
import { currentUserSelector, actions as globalActions } from '@config/store';
import { getChangePasswordForm } from '@modules/profile/utils/security.utils';
import { changePasswordSchema } from '@modules/profile/schemas';
import { SecurityForm } from '@modules/profile/entities';
import UiModal from '@ui/ui-modal';
import UiInputField from '@ui/ui-input-field';
import UiButton from '@ui/ui-button';
import styles from './change-password-modal.module.sass';
import { fetchChangePassword } from '@modules/profile/services';
import { CHANGE_PASSWORD_INPUTS } from '@modules/profile/constants/security.constants';
import Clevertap from '@utils/clevertap';
import { PROFILE_UPDATE_PASSWORD } from '@constants';

interface ChangePasswordModalProps { }

const ChangePasswordModal: FC<ChangePasswordModalProps> = () => {
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  const handleCloseClick = useCallback((): void => {
    dispatch(actions.setShowChangePasswordModal(false))
  }, [dispatch]);

  const handleSubmit = (values: SecurityForm): void => {
    dispatch(actions.setIsLoading(true));
    fetchChangePassword(values)
      .then(() => {
        dispatch(globalActions.notification({
          show: true,
          state: 'success',
          message: 'Contraseña actualizada con éxito'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_PASSWORD);
      })
      .catch((error) => {
        dispatch(globalActions.notification({
          show: true,
          state: 'error',
          message: 'Lo sentimos. Ha ocurrido un error y no pudimos actualizar su contraseña'
        }));
        Clevertap.pushCheckSuccessEvent(PROFILE_UPDATE_PASSWORD, error?.message);
      })
      .finally(() => {
        dispatch(actions.setIsLoading(false));
        dispatch(actions.setShowChangePasswordModal(false));
      });
  };

  const form = useFormik({
    initialValues: getChangePasswordForm(user.id),
    onSubmit: handleSubmit,
    validationSchema: changePasswordSchema
  });

  return (
    <UiModal onCloseClick={handleCloseClick}>
      <form className={styles.wrapper} onSubmit={form.handleSubmit}>
        <h1 className={styles.title}>Cambiar contraseña</h1>
        <span className={styles.description}>Nueva contraseña (10 números)</span>
        <div className={styles.inputs}>
          {CHANGE_PASSWORD_INPUTS.map(input => (
            <UiInputField
              key={`security-input-${input.name}`}
              name={input.name}
              label={input.label}
              type={input.type}
              value={form.values[input.name as keyof object]}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.errors[input.name as keyof object]}
              hasError={!!(form.errors[input.name as keyof object] && form.touched[input.name as keyof object])}
            />
          ))}
        </div>
        <UiButton text='Guardar' isFullWidth isDisabled={!form.isValid} type='submit' />
      </form>
    </UiModal>
  );
}

export default ChangePasswordModal;

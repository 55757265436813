import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'

import styles from './dashboard-desktop.module.sass';
import { fetchDashboardData } from '@modules/dashboard/services';
import { currentUserSelector } from '@config/store';
import { actions, dashboardDataSelector, dashboardUiSelector } from '@modules/dashboard/store';
import { DASHBOARD_ITEMS } from '@constants';
import UiLoader from '@ui/ui-loader';
import DashboardTopProductsBox from '@modules/dashboard/components/dashboard-top-products-box';
import DashboardSalesBoxNumbers from '@modules/dashboard/components/dashboard-sales-box-numbers';
import { formatMoney } from '@utils/format-money';
import { AsideMenuItem } from '@entities';
import Clevertap, { OPEN_CLOSURES, OPEN_COLLABORATORS, OPEN_CUSTOMERS, OPEN_IVENTORY, OPEN_PROVIDERS, OPEN_REPORTS, OPEN_REQUESTS } from '@config/clevertap';

interface DashboardDesktopProps { }

const DashboardDesktop: FC<DashboardDesktopProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoadingDashboard, hasDashboardData } = useSelector(dashboardUiSelector);
  const user = useSelector(currentUserSelector);
  const dashboard = useSelector(dashboardDataSelector);

  const handleClevertapEvent = (name: string) => {
    switch (name) {
      case "Informes": return OPEN_REPORTS;
      case "Inventario": return OPEN_IVENTORY;
      case "Cierres": return OPEN_CLOSURES;
      case "Proveedores": return OPEN_PROVIDERS;
      case "Pedidos": return OPEN_REQUESTS;
      case "Clientes": return OPEN_CUSTOMERS;
      case "Colaboradores": return OPEN_COLLABORATORS;
      default: return ''
    }
  };

  const handleMenuItemClick = (item: AsideMenuItem) => {
    Clevertap.pushEvent(handleClevertapEvent(item.name), { from: 'Home', locationId: user.locationId })
    navigate(item.routeTo);
  };

  useEffect(() => {
    if (!hasDashboardData) {
      dispatch(actions.setIsLoadingDashboard(true));
      fetchDashboardData(user.storeId)
        .then(data => {
          dispatch(actions.setDashboardData(data));
          dispatch(actions.setIsLoadingDashboard(false));
          dispatch(actions.setHasDashboardData(true));
        })
        .catch(() => {
          dispatch(actions.setIsLoadingDashboard(false));
        });
    }
  }, [dispatch, hasDashboardData, user.storeId]);

  return (
    <>
      {isLoadingDashboard && <UiLoader />}
      <div className={styles.wrapper}>
        <div className={styles.dashboardItems}>
          {DASHBOARD_ITEMS.map(item => (
            <div
              key={`dashboard-menu-item-${item.name}`}
              className={styles.item}
              onClick={() => handleMenuItemClick(item)}
            >
              <span className={`icon-${item.icon} ${styles.icon}`} />
              <div className={styles.name}>{item.name}</div>
            </div>
          ))}
        </div>
        <div className={styles.todayNumbers}>
          <DashboardSalesBoxNumbers
            label='Tus ventas'
            formattedDate={format(new Date(), 'dd MMM yyyy')}
            formattedValue={formatMoney(dashboard.salesDay, 0)}
          />
          <DashboardSalesBoxNumbers
            label='Tus egresos'
            formattedDate={format(new Date(), 'dd MMM yyyy')}
            formattedValue={formatMoney(dashboard.expensesDay, 0)}
          />
        </div>
        <div className={styles.topProductSales}>
          <DashboardTopProductsBox title='Lo más vendidos del mes' products={dashboard.mostSold} />
          <DashboardTopProductsBox title='Lo menos vendidos del mes' products={dashboard.leastSold} />
        </div>
      </div>
    </>
  );
}

export default DashboardDesktop;
import { FC } from 'react';
import { useSelector } from 'react-redux';

import styles from './orders.module.sass';
import UiLoader from '@ui/ui-loader';
import { ordersUiSelector } from './store';
import OrdersRouter from './routers/orders-router.router';

interface OrdersProps {}

const Orders: FC<OrdersProps> = () => {
  const { isLoading } = useSelector(ordersUiSelector);
  
  return (
    <div className={styles.container}>
      <OrdersRouter />
      {isLoading && <UiLoader />}
    </div>
  );
}

export default Orders;

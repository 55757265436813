import { ChangePasswordInput } from "../entities";

export const CHANGE_PASSWORD_INPUTS: ChangePasswordInput[] = [
  {
    name: 'phone',
    label: 'Celular',
    type: 'text',
  },
  {
    name: 'password',
    label: 'Contraseña nueva',
    type: 'password',
  },
  {
    name: 'confirmationPassword',
    label: 'Confirme contraseña',
    type: 'password',
  },
];

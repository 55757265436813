import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TABS } from '../constants';
import StoreInformation from '../pages/business-information';
import InvoicingInformation from '../pages/invoicing-information';
import Security from '../pages/security';
import UserInformation from '../pages/user-information';

interface ProfileRouterProps { }

const ProfileRouter: FC<ProfileRouterProps> = () => {

  return (
    <Routes>
      <Route path={TABS.userInformation} element={<UserInformation />} />
      <Route path={TABS.storeInformation} element={<StoreInformation />} />
      <Route path={TABS.invoicingInformation} element={<InvoicingInformation />} />
      <Route path={TABS.security} element={<Security />} />
      <Route path="*" element={<Navigate replace to={TABS.userInformation} />} />
    </Routes>
  );
}

export default ProfileRouter;
